import React from 'react'

const Espaciador = ({espaciador}) => {
  return (
    <div className={espaciador}>
      
    </div>
  )
}

export default Espaciador
