import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader, Table, ModalHeader, Spinner, InputGroup } from 'reactstrap';
import MenuItem from '../../../components/MenuItem';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { obtenerIdInstitucion, obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { getSeccionesDocente } from '../../../../CapaDatos/DatosSeccionesDocente';
import Cargando from '../../../components/Cargando';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { getTipoInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';
import { GetGrados } from '../../../../CapaDatos/DatosCargarSelects';
import { TbPerfil } from '../../../../CapaEntidades/TbPerfil';
import { getDatosUsuario } from '../../../../CapaDatos/DatosUsuarios';
import * as Io from "react-icons/io";
import { TbMatricula } from '../../../../CapaEntidades/TbMatricula';
import { ListaMatriculados } from '../../../../CapaDatos/DatosMatricula';
import { TbAsistencia } from '../../../../CapaEntidades/TbAsistencia';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { getAsignaturasDocente } from '../../../../CapaDatos/DatosAsigDocentes';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { ActualizarAsistencia, ObtenerListaAsistencia, RegistrarAsistencia } from '../../../../CapaDatos/DatosDocentes/DatosAsistencias';
import MenuItem2 from '../../../components/MenuItem2';



const FrmAsistencia = ({ codigoPresupuestario, codigoNombre }) => {
    const TBSecciones = TbSecciones();
    const TBPerfil = TbPerfil();
    const TBMatricula = TbMatricula();
    const TBAsistencia = TbAsistencia();
    const TBFUncionario = TbFuncionario();


    const ObtenerListaSeccionesDelDocente = async () => {
        try {

            const parametros = {
                profesor: obtenerUsuario(),
                annio: new Date().getFullYear().toString(),
                institucion: obtenerIdInstitucion(),
                estado: 1
            }

            const data = await getSeccionesDocente(parametros);
            // Validar si data está vacío
            if (data.length === 0) {
                TBSecciones.setExistenSecciones(false)
                TBSecciones.setListaSeccionesDocente([]); // Puedes asignar un array vacío o manejar el caso de otra forma
            } else {
                TBSecciones.setExistenSecciones(true)
                TBSecciones.setListaSeccionesDocente(data);
            }

            //console.log(JSON.stringify(data));

            const parametro = { codigoPresupuestario: obtenerIdInstitucion() };
            const tipoIns = await getTipoInstitucion(parametro);
            TBAsistencia.setTipoInstitucion(tipoIns);



            TBAsistencia.setLoading2(false);
        } catch (error) {
            TBAsistencia.setLoading2(false);
        }
    };


    const ObtenerListaEstudiantes = async (seccion) => {
        try {
            // alert(seccion);
            TBAsistencia.setLoading(true)
            const param = {
                asignaturaAsistencia: TBAsistencia.asignatura,
                fechaAsistencia: TBAsistencia.fecha ? TBAsistencia.fecha : new Date().toISOString().split('T')[0],
                codigoInstitucion: obtenerIdInstitucion(),
                docenteAsistencia: obtenerUsuario()
            };

            const listaAsistencia = await ObtenerListaAsistencia(param);

            // Verifica si listaAsistencia no es null/undefined y si su estado es diferente de false
            if (listaAsistencia && listaAsistencia.estado !== false) {
                // Filtra la lista por la sección especificada
                const listaFiltrada = listaAsistencia.filter(item => item.nombreSeccion === seccion);

                // Si hay estudiantes en la sección filtrada
                if (listaFiltrada.length > 0) {
                    TBAsistencia.setListaEstudiantes(listaFiltrada);
                    TBAsistencia.setHayAsistencia(true);
                    TBAsistencia.setLoading(false)
                } else {
                    // Si no hay coincidencias con la sección, ejecuta el bloque else
                    const data2 = {
                        cursoLectivo: TBMatricula.añoActual,
                        codInstitucion: obtenerIdInstitucion()
                    };
                    const lista = await ListaMatriculados(data2);
                    TBAsistencia.setListaEstudiantes(lista);
                    TBAsistencia.setHayAsistencia(false);
                    TBAsistencia.setLoading(false)
                }
            } else {
                // Si no se encuentran registros en listaAsistencia
                const data2 = {
                    cursoLectivo: TBMatricula.añoActual,
                    codInstitucion: obtenerIdInstitucion()
                };
                const lista = await ListaMatriculados(data2);
                TBAsistencia.setListaEstudiantes(lista);
                TBAsistencia.setHayAsistencia(false);
                TBAsistencia.setLoading(false)
            }
        } catch (error) {
            console.error("Error al obtener la lista de estudiantes:", error);
        }
    };


    const ObtenerListaEstudiantes2 = async (asignatura, seccion) => {
        try {
            const param = {
                asignaturaAsistencia: asignatura,
                fechaAsistencia: TBAsistencia.fecha,
                codigoInstitucion: obtenerIdInstitucion(),
                docenteAsistencia: obtenerUsuario()
            };
    
            const listaAsistencia = await ObtenerListaAsistencia(param);
    
            // Verifica si listaAsistencia no es null/undefined y si su estado es diferente de false
            if (listaAsistencia && listaAsistencia.estado !== false) {
                // Filtra la lista por la sección especificada
                const listaFiltrada = listaAsistencia.filter(item => item.nombreSeccion === seccion);
    
                // Si hay estudiantes en la sección filtrada
                if (listaFiltrada.length > 0) {
                    TBAsistencia.setListaEstudiantes(listaFiltrada);
                    TBAsistencia.setHayAsistencia(true);
                } else {
                    // Si no hay coincidencias con la sección, ejecuta el bloque else
                    const data2 = {
                        cursoLectivo: TBMatricula.añoActual,
                        codInstitucion: obtenerIdInstitucion()
                    };
                    const lista = await ListaMatriculados(data2);
                    //alert(JSON.stringify(lista));
                    TBAsistencia.setListaEstudiantes(lista);
                    TBAsistencia.setHayAsistencia(false);
                }
            } else {
                // Si no se encuentran registros en listaAsistencia, ejecuta el bloque else
                const data2 = {
                    cursoLectivo: TBMatricula.añoActual,
                    codInstitucion: obtenerIdInstitucion()
                };
                const lista = await ListaMatriculados(data2);
                //alert(JSON.stringify(lista));
                TBAsistencia.setListaEstudiantes(lista);
                TBAsistencia.setHayAsistencia(false);
            }
        } catch (error) {
            console.error("Error al obtener la lista de estudiantes:", error);
        }
    };
    



    const obtenerAsignaturasDocente = async () => {
        const param = {
            docente: obtenerUsuario(),
            annio: TBFUncionario.currentYear.toString(),
            institucion: obtenerIdInstitucion(),
            estado: 1
        }

        const lista = await getAsignaturasDocente(param)
        //console.log(JSON.stringify(lista));
        TBFUncionario.setListaAsigDocente(lista);

        const primerCodigoAsignatura = lista[0].codigoAsignatura;
        //alert(primerCodigoAsignatura)
        // Llamar a ObtenerListaEstudiantes con el primer valor de codigoAsignatura
        //ObtenerListaEstudiantes(primerCodigoAsignatura);
        TBAsistencia.setAsignatura(primerCodigoAsignatura)

    };

    useEffect(() => {
        ObtenerListaSeccionesDelDocente();
        obtenerAsignaturasDocente();

        GetGrados(TBAsistencia.setListaGrados);

    }, []);

    const CargarListaEstudiantes = (e) => {
        TBAsistencia.setAsignatura('')
        TBAsistencia.setEstudiantesSeleccionados([])
        TBAsistencia.setAsignatura(e)

        //ObtenerListaEstudiantes(e);
    }


    useEffect(() => {
        const today = new Date().toISOString().split('T')[0]; // Obtiene la fecha de hoy en formato 'YYYY-MM-DD'
        TBAsistencia.setFecha(today); // Establece la fecha de hoy como valor inicial
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDatosUsuario();

                TBPerfil.setNombre(data[0].nombreCompleto)
                TBPerfil.setCedula(data[0].cedula)
                TBPerfil.setApellido1(data[0].apellido1)
                TBPerfil.setApellido2(data[0].apellido2)

            } catch (error) {
                console.error("Error al obtener los datos del usuario:", error);
            }
        };

        fetchData();
    }, []);

    const GradosFiltrados = TBAsistencia.ListaGrados.filter(grado => {
        // Verificar si la lista de secciones del docente está vacía
        if (!TBSecciones.listaSeccionesDocente || TBSecciones.listaSeccionesDocente.length === 0) {
            //console.log("Actualmente no hay secciones registradas en el sistema. para el docente.");
            return false; // O manejarlo de otra forma si lo prefieres
        }

        // Extraer los niveles de TBSecciones.listaSeccionesDocente
        const nivelesDocente = TBSecciones.listaSeccionesDocente.map(seccion => seccion.nivel);

        if (TBAsistencia.tipoInstitucion === 1) {
            // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
            return grado.id >= -1 && grado.id <= 6 && nivelesDocente.includes(grado.id);
        } else if (TBAsistencia.tipoInstitucion === 2) {
            // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
            return grado.id >= 7 && grado.id <= 12 && nivelesDocente.includes(grado.id);
        }

        return false;
    });


    const handleCheckboxChange = async (cedula, asistencia, fecha, idAsistncia, ausencias, seccion) => {

        //alert(TBAsistencia.hayAsistencia)
        if (TBAsistencia.hayAsistencia) {


            const data = {
                idAsistencia: idAsistncia, // Asigna cedulaN a cedulaEstudiante
                asistencia: parseInt(asistencia), // Asigna asistencia
                numeroAusencias: (parseInt(asistencia) === 2) ? ausencias + 0 : ausencias, // Sumar 1 si asistencia es 2
                asignaturaAsistencia: TBAsistencia.asignatura,
            };
            //alert(JSON.stringify(data));

            const success = await ActualizarAsistencia(data);

            if (success) {
                ObtenerListaEstudiantes2(TBAsistencia.asignatura, seccion);
            } else {
                ErrorMessage("Notificación del Sistema", "Ocurrio un error interno y no se realizo la actualización de la asistencia")
            }






        } else {


            TBAsistencia.setEstudiantesSeleccionados((prevEstudiantes) => {
                // Verifica si el estudiante ya existe en la lista
                const existe = prevEstudiantes.find(estudiante => estudiante.cedulaN === cedula);

                if (existe) {
                    // Si el estudiante ya existe, actualiza la asistencia
                    return prevEstudiantes.map(estudiante =>
                        estudiante.cedulaN === cedula ? { ...estudiante, asistencia } : estudiante
                    );
                } else {
                    // Si no existe, agrega el nuevo estudiante
                    return [...prevEstudiantes, { cedulaN: cedula, asistencia }];
                }
            });
        }
    };

    useEffect(() => {
        console.log(JSON.stringify(TBAsistencia.estudiantesSeleccionados))
    }, []);

    const GuardarAsistencia = async () => {
        try {
            const cantidadElement = document.getElementById('cantidad');
            const totalEstudiantes = cantidadElement ? parseInt(cantidadElement.textContent) : 0;

            //console.log("Total de estudiantes:", totalEstudiantes);
            if (TBAsistencia.estudiantesSeleccionados.length < totalEstudiantes) {
                WarningMessage("Atención", "Por favor, asegúrate de seleccionar la asistencia de todos los estudiantes antes de continuar.");
                return;

            }
            // Registra la asistencia

            for (const estudianteSeleccionado of TBAsistencia.estudiantesSeleccionados) {
                const data = {
                    cedulaEstudiante: estudianteSeleccionado.cedulaN, // Asigna cedulaN a cedulaEstudiante
                    asistencia: parseInt(estudianteSeleccionado.asistencia), // Asigna asistencia
                    numeroAusencias: 0,
                    asignaturaAsistencia: TBAsistencia.asignatura,
                    fechaAsistencia: TBAsistencia.fecha,
                    annioAsistencia: TBFUncionario.currentYear.toString(),
                    codigoInstitucion: obtenerIdInstitucion(),
                    docenteAsistencia: obtenerUsuario()
                };


                const success = await RegistrarAsistencia(data);
                TBAsistencia.setEstudiantesSeleccionados([])
                ObtenerListaEstudiantes2(TBAsistencia.asignatura,TBAsistencia.seccion);
                if (!success) {
                    ErrorMessage("Notificación del Sistema", "No se pudo registrar la asistencia.");
                }


            }
            // Recorre el array de estudiantes seleccionados



        } catch (error) {
            console.error("Error al obtener los datos del usuario:", error);
        }
    };

    const PasarCedula = (cedula) => {
        sessionStorage.removeItem('Est')
        sessionStorage.setItem('Est', cedula);//Usuario
    }


    if (TBAsistencia.loading2) {
        return (

            <Cargando />
        );
    }


    return (
        <Container fluid className=" Divcontenedor ">
            <Col className='border-bottom'>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Asistencia"
                        to="/Menu/FrmAsistencia"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='  px-0  ' >


                <Tabs className={'p-0 m-0  border mt-1 '} defaultIndex={-1}>
                    <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12'>
                            <Label for="files" className=''>
                                <strong>Lista de Asistencia</strong>
                            </Label>
                        </Col>
                        {TBSecciones.existenSecciones ? (
                            <>
                                <Col  >
                                    <Col className=' ' >
                                        <Label for="identificacion" className="me-2">
                                            Asignatura
                                        </Label>

                                        <Input
                                            required
                                            id="asignatura"
                                            name="asignatura"
                                            type="select"
                                            value={TBAsistencia.asignatura}
                                            onChange={(e) => TBAsistencia.setAsignatura(e.target.value)}
                                        >
                                            {/* //<option key={0} value={0} disabled> Selecciona una asignatura </option> */}
                                            {TBFUncionario.ListaAsigDocente.map((asignatura) => (
                                                <option key={asignatura.codigoAsignatura} value={asignatura.codigoAsignatura}>
                                                    {asignatura.nombreAsignatura}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col >
                                </Col>
                                <Col  >
                                    <Col className=' ' >
                                        <Label for="identificacion" className="me-2">
                                            Fecha
                                        </Label>

                                        <Input
                                            required
                                            id="fechaNacimiento"
                                            name="fechaNacimiento"
                                            type="date"

                                            value={TBAsistencia.fecha}
                                            onChange={(e) => TBAsistencia.setFecha(e.target.value)}

                                            className='py-1'
                                        />
                                    </Col >
                                </Col>
                            </>
                        ) : (
                            <></>
                        )}
                    </Row>

                    <TabList className={'tabs_ m-0 border-bottom1'} >
                        {GradosFiltrados.map((grados, index) => (
                            <Tab key={index} >
                                {grados.nombre}
                            </Tab>
                        ))}
                    </TabList>
                    
                    <Container fluid className=' shadow2 px-0 contenedorTabsAsistencia rounded-0 '>
                        {TBSecciones.existenSecciones ? (
                            <Container fluid className='tamañoContAsistencia px-0'>

                                {GradosFiltrados.map((grado, index) => (
                                    <TabPanel key={index}>
                                        
                                        <Container fluid className='px-0'>
                                            <Tabs className={'p-0 m-0'} defaultIndex={-1}>
                                                <TabList className={'tabs_ m-0  border-bottom1'}>
                                                    {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map(seccion => (
                                                        <Tab key={seccion.nombreSeccion} onClick={(e) => {
                                                            e.preventDefault();
                                                            TBAsistencia.setSeccion(seccion.nombreSeccion);
                                                            ObtenerListaEstudiantes(seccion.nombreSeccion); // Llamada correcta de la función
                                                        }}>
                                                            {seccion.nombreSeccion}
                                                        </Tab>
                                                    ))}
                                                </TabList>
                                                {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map((seccion, secIndex) => {
                                                    // Filtra los estudiantes para esta sección
                                                    const estudiantesFiltrados = Array.isArray(TBAsistencia.ListaEstudiantes)
                                                        ? TBAsistencia.ListaEstudiantes.filter(estudiante => estudiante.nivel === grado.id && estudiante.nombreSeccion === seccion.nombreSeccion)
                                                        : [];

                                                    return (
                                                        <TabPanel key={secIndex}>
                                                            <Container fluid className='p-0 table-responsive'>
                                                                {TBAsistencia.loading ? (
                                                                    <Container className="loader-container2">
                                                                        <div className="loader"></div>
                                                                        <span className="loading-text">Cargando...</span>

                                                                    </Container>

                                                                ) : (
                                                                    <>
                                                                        <Table key={seccion.nombreSeccion} className="table table-striped table-bordered" style={{ minWidth: '600px' }}>
                                                                            <thead className='border-bottom'>
                                                                                <tr>
                                                                                    <th className='pb-0'>
                                                                                        <a className='cursor'> Cedula </a>
                                                                                    </th>
                                                                                    <th className='pb-0'>
                                                                                        <a className='cursor'> Nombre Completo </a>
                                                                                    </th>
                                                                                    <th className='pb-0 text-center'>
                                                                                        <a className='cursor'> Presente </a>
                                                                                    </th>
                                                                                    <th className='pb-0 text-center'>
                                                                                        <a className='cursor'> Ausente </a>
                                                                                    </th>
                                                                                    <th className='pb-0 text-center'>
                                                                                        <a className='cursor'> Aus. c/perm. </a>
                                                                                    </th>
                                                                                    <th className='pb-0 text-center'>
                                                                                        <a className='cursor'> Tardia </a>
                                                                                    </th>
                                                                                    <th className='pb-0 text-center'>
                                                                                        <a className='cursor'> Feriado</a>
                                                                                    </th>
                                                                                    <th className='pb-0 text-center'>
                                                                                        <a className='cursor'> Libre </a>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='tablebody' id='tablebody'>
                                                                                {estudiantesFiltrados.map(estudiante => {
                                                                                    // Busca la asistencia actual del estudiante en estudiantesSeleccionados
                                                                                    const estudianteSeleccionado = TBAsistencia.estudiantesSeleccionados.find(e => e.cedulaN === estudiante.cedula);
                                                                                    const asistenciaActual = estudianteSeleccionado ? estudianteSeleccionado.asistencia : estudiante.asistencia;
                                                                                    TBAsistencia.grado = estudiantesFiltrados.length
                                                                                    return (
                                                                                        <tr key={estudiante.cedula}>
                                                                                            <td>
                                                                                                <Label onClick={() => PasarCedula(estudiante.cedula)}>
                                                                                                    <MenuItem2
                                                                                                        text={estudiante.cedula}
                                                                                                        to="/Menu/FrmAsistenciaEstudiante"
                                                                                                        cedula={estudiante.cedula}
                                                                                                    />
                                                                                                </Label>
                                                                                            </td>
                                                                                            <td className=''>{estudiante.nombreCompleto} {estudiante.apellido1} {estudiante.apellido2}</td>
                                                                                            <td className='justify-content-center'>
                                                                                                <Col className='d-flex justify-content-center'>
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        name="presente"
                                                                                                        value={1}
                                                                                                        checked={asistenciaActual === 1}
                                                                                                        onChange={(e) => handleCheckboxChange(estudiante.cedula, 1, TBAsistencia.fecha, estudiante.idAsistencia, estudiante.numeroAusencias,seccion.nombreSeccion)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Col className='d-flex justify-content-center'>
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        name="presente"
                                                                                                        value={2}
                                                                                                        checked={asistenciaActual === 2}
                                                                                                        onChange={(e) => handleCheckboxChange(estudiante.cedula, 2, TBAsistencia.fecha, estudiante.idAsistencia, estudiante.numeroAusencias,seccion.nombreSeccion)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Col className='d-flex justify-content-center'>
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        name="presente"
                                                                                                        value={3}
                                                                                                        checked={asistenciaActual === 3}
                                                                                                        onChange={(e) => handleCheckboxChange(estudiante.cedula, 3, TBAsistencia.fecha, estudiante.idAsistencia, estudiante.numeroAusencias,seccion.nombreSeccion)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Col className='d-flex justify-content-center'>
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        name="presente"
                                                                                                        value={4}
                                                                                                        checked={asistenciaActual === 4}
                                                                                                        onChange={(e) => handleCheckboxChange(estudiante.cedula, 4, TBAsistencia.fecha, estudiante.idAsistencia, estudiante.numeroAusencias,seccion.nombreSeccion)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Col className='d-flex justify-content-center'>
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        name="presente"
                                                                                                        value={4}
                                                                                                        checked={asistenciaActual === 5}
                                                                                                        onChange={(e) => handleCheckboxChange(estudiante.cedula, 5, TBAsistencia.fecha, estudiante.idAsistencia, estudiante.numeroAusencias,seccion.nombreSeccion)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Col className='d-flex justify-content-center'>
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        name="presente"
                                                                                                        value={4}
                                                                                                        checked={asistenciaActual === 6}
                                                                                                        onChange={(e) => handleCheckboxChange(estudiante.cedula, 6, TBAsistencia.fecha, estudiante.idAsistencia, estudiante.numeroAusencias,seccion.nombreSeccion)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        </Table>
                                                                        <div className="text-end px-3">
                                                                            <Label className='mx-1'><strong >Total de estudiantes: </strong></Label><Label ><strong value={estudiantesFiltrados.length} id='cantidad'> {estudiantesFiltrados.length}</strong></Label>
                                                                            <br></br>
                                                                            {TBAsistencia.hayAsistencia ?
                                                                                (<Label>Para modificar la asistencia, simplemente seleccione una nueva opción marcando la casilla correspondiente.</Label>
                                                                                ) :
                                                                                (<h6 className='letrapequeña'>El sistema no guardara la lista de asistencia hasta que todos los estudiantes de esta lista sean seleccionados</h6>
                                                                                )}
                                                                        </div>
                                                                        <Container fluid className='mt-2'>
                                                                            <Row>
                                                                                {estudiantesFiltrados.length > 0 && (
                                                                                    <Col className='d-flex justify-content-end'>
                                                                                        {TBAsistencia.hayAsistencia ?
                                                                                            (<></>) :
                                                                                            (<Button className='btnAceptar p-1 px-3' onClick={GuardarAsistencia}>
                                                                                                Guardar Asistencia
                                                                                            </Button>)}
                                                                                    </Col>
                                                                                )}



                                                                            </Row>
                                                                        </Container>
                                                                    </>
                                                                )}

                                                            </Container>

                                                        </TabPanel>
                                                    );
                                                })}
                                            </Tabs>

                                        </Container>

                                    </TabPanel>
                                ))}
                            </Container>
                        ) : (
                            <Container fluid className='d-flex justify-content-center' >
                                <Label className='justify-content-center mt-5'>Actualmente no hay secciones registradas en el sistema.</Label>
                            </Container>
                        )}

                    </Container>


                    {/* <Container fluid className=' shadow2 px-0 contenedorTabsAsistencia rounded-0 ' >
                        <Container fluid className='tamañoContAsistencia px-0'>

                            {GradosFiltrados.map((grado, index) => (
                                <TabPanel key={index}>
                                    <Container fluid className='px-0'>
                                        <Tabs className={'p-0 m-0    '}>
                                            <TabList className={'tabs_ m-0 pt-1'} >
                                                {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map(seccion => (
                                                    <Tab   >
                                                        {seccion.nombreSeccion}
                                                    </Tab>
                                                ))}

                                            </TabList>
                                            {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map((seccion, secIndex) => (
                                                <TabPanel key={secIndex}>
                                                    <Container fluid className='p-0 table-responsive' >

                                                        <Table className="table table-striped table-bordered" style={{ minWidth: '600px' }}>
                                                            <thead className='border-bottom'>
                                                                <tr>
                                                                    <th className='pb-0'>
                                                                        <a className='cursor' >  Cedula </a>
                                                                    </th>
                                                                    <th className='pb-0'>
                                                                        <a className='cursor' >  Nombre Completo </a>
                                                                    </th>
                                                                    <th className='pb-0 text-center'>
                                                                        <a className='cursor' >  Presente </a>
                                                                    </th>
                                                                    <th className='pb-0 text-center'>
                                                                        <a className='cursor' >  Ausente </a>
                                                                    </th>
                                                                    <th className='pb-0 text-center'>
                                                                        <a className='cursor' >  Aus. c/perm. </a>
                                                                    </th>
                                                                    <th className='pb-0 text-center'>
                                                                        <a className='cursor' >  Tardia </a>
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody className='tablebody' id='tablebody'>
                                                                {TBAsistencia.ListaEstudiantes
                                                                    .filter(estudiante => estudiante.nivel === grado.id && estudiante.nombreSeccion === seccion.nombreSeccion)
                                                                    .map(estudiante => {
                                                                        // Busca la asistencia actual del estudiante en estudiantesSeleccionados
                                                                        const estudianteSeleccionado = TBAsistencia.estudiantesSeleccionados.find(e => e.cedulaN === estudiante.cedula);
                                                                        const asistenciaActual = estudianteSeleccionado ? estudianteSeleccionado.asistencia : estudiante.asistencia;
                                                                      
                                                                        return (
                                                                            <tr key={estudiante.cedula}>
                                                                                <td>
                                                                                    <Label onClick={() => PasarCedula(estudiante.cedula)}>
                                                                                        <MenuItem2
                                                                                            text={estudiante.cedula}
                                                                                            to=""
                                                                                            cedula={estudiante.cedula}
                                                                                        />
                                                                                    </Label>
                                                                                </td>
                                                                                <td className=''>{estudiante.nombreCompleto} {estudiante.apellido1} {estudiante.apellido2}</td>
                                                                                <td className='justify-content-center'>
                                                                                    <Col className='d-flex justify-content-center'>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="presente"
                                                                                            value={1}
                                                                                            checked={asistenciaActual === 1}
                                                                                            onChange={(e) => handleCheckboxChange(estudiante.cedula, 1, TBAsistencia.fecha)}
                                                                                        />
                                                                                    </Col>
                                                                                </td>
                                                                                <td>
                                                                                    <Col className='d-flex justify-content-center'>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="presente"
                                                                                            value={2}
                                                                                            checked={asistenciaActual === 2}
                                                                                            onChange={(e) => handleCheckboxChange(estudiante.cedula, 2, TBAsistencia.fecha)}
                                                                                        />
                                                                                    </Col>
                                                                                </td>
                                                                                <td>
                                                                                    <Col className='d-flex justify-content-center'>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="presente"
                                                                                            value={3}
                                                                                            checked={asistenciaActual === 3}
                                                                                            onChange={(e) => handleCheckboxChange(estudiante.cedula, 3, TBAsistencia.fecha)}
                                                                                        />
                                                                                    </Col>
                                                                                </td>
                                                                                <td>
                                                                                    <Col className='d-flex justify-content-center'>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="presente"
                                                                                            value={4}
                                                                                            checked={asistenciaActual === 4}
                                                                                            onChange={(e) => handleCheckboxChange(estudiante.cedula, 4, TBAsistencia.fecha)}
                                                                                        />
                                                                                    </Col>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>

                                                        </Table>
                                                        
                                                    </Container>
                                                </TabPanel>
                                            ))}
                                        </Tabs>
                                    </Container>
                                </TabPanel>
                            ))}
                        </Container>
                        <Container className=' '>
                            <Row>
                                <Col className='d-flex justify-content-end'>
                                    {TBAsistencia.hayAsistencia ?
                                        (<Label>Para cambiar una asistencia solo seleccione el nuevo estado segun el checkbox </Label>) :
                                        (<Button className='btnAceptar p-1 px-3' onClick={GuardarAsistencia} >
                                            Guardar Asistencia
                                        </Button>)}

                                </Col>
                            </Row>
                        </Container>
                    </Container> */}



                </Tabs>


            </Container>
            <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<Io.IoMdReturnLeft className="me-1" />}
                        text="Volver"
                        to="/Menu"
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>

        </Container>
    )
}

export default FrmAsistencia
