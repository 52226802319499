// Define una clave para almacenar los datos en el almacenamiento local
const CACHE_KEY = "userData";

// Intenta obtener los datos de usuario desde el almacenamiento local al cargar la aplicación
const storedUserData = localStorage.getItem(CACHE_KEY);
let cacheUsuario = storedUserData ? JSON.parse(storedUserData) : null;

// Define la función para guardar los datos en la memoria caché y en el almacenamiento local
export const GuardarCahe = (datos) => {
    cacheUsuario = datos;
    localStorage.setItem(CACHE_KEY, JSON.stringify(datos));
};

// Define la función para obtener los datos del usuario desde la memoria caché
export const Roll = () => {
    try {
        return cacheUsuario.permisosUsuario;
    } catch (error) {
        console.error('Error en Roll:', error);
        return null; // o maneja el error de otra manera, según lo necesario
    }
}

// Define la función para obtener el código de institución desde la memoria caché
export const CodigoInstitucion = () => {
    return cacheUsuario ? cacheUsuario.idInstitucion : null;
}

// Define la función para limpiar la memoria caché cuando la sesión finaliza
export const limpiarCacheUsuario = () => {
    cacheUsuario = null;
    localStorage.removeItem(CACHE_KEY);
};