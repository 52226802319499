import React, { useEffect } from 'react'
import { Container, Row, Col, Button, Label, Input, Breadcrumb, Table, InputGroup } from 'reactstrap';
import MenuItem from '../../../components/MenuItem';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { obtenerIdInstitucion, obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { TbPerfil } from '../../../../CapaEntidades/TbPerfil';
import * as Io from "react-icons/io";
import { TbMatricula } from '../../../../CapaEntidades/TbMatricula';
import { TbAsistencia } from '../../../../CapaEntidades/TbAsistencia';
import { getAsignaturasDocente } from '../../../../CapaDatos/DatosAsigDocentes';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { ObtenerListaAsistenciaPorFecha, ObtenerListaAsistenciaPorFechas } from '../../../../CapaDatos/DatosDocentes/DatosAsistencias';
import { WarningMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';

const FrmAsistenciaEstuduante = () => {
    const TBSecciones = TbSecciones();
    const TBPerfil = TbPerfil();
    const TBMatricula = TbMatricula();
    const TBAsistencia = TbAsistencia();
    const TBFUncionario = TbFuncionario();

    const obtenerAsignaturasDocente = async () => {
        const param = {
            docente: obtenerUsuario(),
            annio: TBFUncionario.currentYear.toString(),
            institucion: obtenerIdInstitucion(),
            estado: 1
        }

        const lista = await getAsignaturasDocente(param)
        //console.log(JSON.stringify(lista));
        TBFUncionario.setListaAsigDocente(lista);

    };


    useEffect(() => {
        obtenerAsignaturasDocente();
    }, []);
    useEffect(() => {
        const today = new Date(); // Obtiene la fecha de hoy
        const formattedToday = today.toISOString().split('T')[0]; // Formato 'YYYY-MM-DD'

        TBAsistencia.setFecha1(formattedToday); // Establece la fecha de hoy como valor inicial
        TBAsistencia.setFecha2(formattedToday);

        // Aumenta un día a la fecha actual
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1); // Suma un día

        TBAsistencia.setFecha3(tomorrow.toISOString().split('T')[0]);
    }, []);

    const FiltrarPorFecha = async () => {
        const data = {
            cedulaEstudiante: sessionStorage.getItem('Est'),
            fechaAsistencia: TBAsistencia.fecha1,
            codigoInstitucion: obtenerIdInstitucion()
        };

        // Obtener la lista original
        const lista = await ObtenerListaAsistenciaPorFecha(data);
        //alert(JSON.stringify(lista))
        // Verificar si la lista tiene el estado en false y manejar el mensaje
        if (lista.estado === false) {

            // Si el estado es false, limpiar la lista
            TBAsistencia.setListaHistorialAsistencia([]);



        } else {
            // Modificar la clave "Año Lectivo" a "AñoLectivo" si todo está bien
            const listaModificada = lista.map(item => {
                const { "Año Lectivo": AñoLectivo, ...rest } = item; // Extraemos "Año Lectivo"
                return {
                    ...rest,
                    AñoLectivo // Añadimos la clave corregida con su valor
                };
            });

            // Actualizar la lista con la clave modificada
            TBAsistencia.setListaHistorialAsistencia(listaModificada);
        }

        TBAsistencia.setFiltrarPorFecha(false);
        TBAsistencia.setOpcionSeleccionada(true);
    };



    const FiltrarPorFechas = async () => {
        const data = {
            cedulaEstudiante: sessionStorage.getItem('Est'),
            fechaInicio: TBAsistencia.fecha2,
            fechaFin: TBAsistencia.fecha3,
            codigoInstitucion: obtenerIdInstitucion()
        }

        // Obtener la lista original
        const lista = await ObtenerListaAsistenciaPorFechas(data)
        if (lista.estado === false) {

            // Si el estado es false, limpiar la lista
            TBAsistencia.setListaHistorialAsistencia([]);



        } else {
            // Modificar la clave "Año Lectivo" a "AñoLectivo"
            const listaModificada = lista.map(item => {
                const { "Año Lectivo": AñoLectivo, ...rest } = item; // Extraemos "Año Lectivo"
                return {
                    ...rest,
                    AñoLectivo // Añadimos la clave corregida con su valor
                };
            });

            //console.log(JSON.stringify(listaModificada));
            // Actualizar la lista con la clave modificada
            TBAsistencia.setListaHistorialAsistencia(listaModificada);
        }
        TBAsistencia.setFiltrarPorFechas(false)
        TBAsistencia.setOpcionSeleccionada(true)
    };

    return (
        <Container fluid className=" Divcontenedor ">
            <Col className='border-bottom '>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Asistencia"
                        to="/Menu/FrmAsistencia"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Información de Asistencia"
                        to="/Menu/FrmAsistenciaEstudiante"
                        classname={'NoDecoration2 tlink'}
                    />
                </Breadcrumb>

            </Col>

            <Container fluid className='  px-0  ' >
                <Tabs className={'p-0 m-0  border mt-1 '}>
                    <Row className='px-0 m-0 p-0 pt-0 pb-1' xs={1} sm={2} md={3} lg={4}>
                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12'>
                            <Label for="files" className=''>
                                <strong> Seleccionar opciones de filtrado</strong>
                            </Label>
                        </Col>
                        <Col className='mb-0' >
                            <Input
                                className="form-check-input mx-2"
                                type="radio"
                                id="TipoID1"
                                name="TipoID"
                                onChange={(e) => { TBAsistencia.setFiltrarPorFecha(true); TBAsistencia.setFiltrarPorFechas(false) }}
                                checked={TBAsistencia.filtraPorFecha}
                            />
                            <Label className="form-check-label " for={"TipoID1"}>
                                Filtrar por fecha espesifica
                            </Label>
                        </Col>
                        <Col className="mb-0">
                            <Input
                                className="form-check-input mx-2"
                                type="radio"
                                id="TipoID2"
                                name="TipoID"
                                onChange={(e) => { TBAsistencia.setFiltrarPorFechas(true); TBAsistencia.setFiltrarPorFecha(false) }}
                                checked={TBAsistencia.filtraPorFechas}
                            />
                            <Label className="form-check-label " for={"TipoID2"}>
                                Filtrar por rango de fechas
                            </Label>
                        </Col>
                        <Col lg={12} md={12} className=''>
                            {TBAsistencia.filtraPorFecha && (
                                <Row className='px-0 m-0 ' xs={1} sm={2} md={3} lg={4}>
                                    <Col  >
                                        <Label for="identificacion" className="me-2">
                                            Seleccione una Fecha
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                required
                                                id="fechaNacimiento"
                                                name="fechaNacimiento"
                                                type="date"

                                                value={TBAsistencia.fecha1}
                                                onChange={(e) => TBAsistencia.setFecha1(e.target.value)}

                                                className='py-1'
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col  >
                                        <Label for="identificacion" className="me-2">

                                        </Label>
                                        <br></br>
                                        <Button className='btnAceptar p-1 px-3' onClick={FiltrarPorFecha} >Filtrar lista de asistencia</Button>
                                    </Col>

                                </Row>
                            )}
                        </Col>
                        <Col lg={12} md={12}>
                            {TBAsistencia.filtraPorFechas &&
                                (<Row className='px-0 m-0 ' xs={1} sm={2} md={3} lg={4}>
                                    <Col  >
                                        <Label for="identificacion" className="me-2">
                                            Seleccione la primer Fecha
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                required
                                                id="fechaNacimiento"
                                                name="fechaNacimiento"
                                                type="date"

                                                value={TBAsistencia.fecha2}
                                                onChange={(e) => TBAsistencia.setFecha2(e.target.value)}

                                                className='py-1'
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col  >
                                        <Label for="identificacion" className="me-2">
                                            Seleccione la segunda Fecha
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                required
                                                id="fechaNacimiento"
                                                name="fechaNacimiento"
                                                type="date"

                                                value={TBAsistencia.fecha3}
                                                onChange={(e) => TBAsistencia.setFecha3(e.target.value)}

                                                className='py-1'
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col  >
                                        <Label for="identificacion" className="me-2">

                                        </Label>
                                        <br></br>
                                        <Button className='btnAceptar p-1 px-3' onClick={FiltrarPorFechas} >Filtrar lista de asistencia</Button>
                                    </Col>

                                </Row>

                                )}
                        </Col>
                    </Row>

                    <TabList className={'tabs_ m-0'}>
                        {TBFUncionario.ListaAsigDocente.map((asignatura, index) => (
                            <Tab key={index}   >
                                {asignatura.nombreAsignatura}
                            </Tab>
                        ))}
                    </TabList>
                    <Container fluid className='shadow2 px-0 contenedorTabsAsistenciaEstuduante rounded-0'>
                        <Container fluid className='tamañoContAsistencia px-0'>
                            {TBFUncionario.ListaAsigDocente.map((asignatura, index) => (
                                <TabPanel key={index}>
                                    {TBAsistencia.opcionSeleccionada ? (
                                        <Container fluid className='p-0 table-responsive'>
                                            {
                                                TBAsistencia.listaHistorialAsistencia
                                                    .filter(LHA => LHA.Asignatura === asignatura.nombreAsignatura.toUpperCase()).length > 0
                                                    ? (
                                                        <Table className="table table-striped table-bordered" style={{ minWidth: '600px' }}>
                                                            <thead className='border-bottom'>
                                                                <tr>
                                                                    <th>Fecha</th>
                                                                    <th>Asistencia</th>
                                                                    <th>Ausencias</th>
                                                                    <th>Año Lectivo</th>
                                                                    <th>Docente</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='tablebody' id='tablebody'>
                                                                {TBAsistencia.listaHistorialAsistencia
                                                                    .filter(LHA => LHA.Asignatura === asignatura.nombreAsignatura.toUpperCase())
                                                                    .map((historial, index) => (
                                                                        <tr key={index}>
                                                                            <td>{new Date(historial.Fecha).toISOString().split('T')[0]}</td>
                                                                            <td>
                                                                                {(() => {
                                                                                    switch (historial.Estado) {
                                                                                        case 1:
                                                                                            return 'Presente';
                                                                                        case 2:
                                                                                            return 'Ausente';
                                                                                        case 3:
                                                                                            return 'Ausente con Permiso';
                                                                                        case 4:
                                                                                            return 'Tardía';
                                                                                        default:
                                                                                            return 'Desconocido';
                                                                                    }
                                                                                })()}
                                                                            </td>
                                                                            <td>{historial.Ausencias}</td>
                                                                            <td>{historial.AñoLectivo}</td>
                                                                            <td>{historial.Docente}</td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </Table>
                                                    ) : (
                                                        <div className="text-center py-3">
                                                            <p>No hay datos disponibles para la fecha indicada.</p>
                                                        </div>
                                                    )
                                            }
                                        </Container>
                                    ) : (

                                        <Container fluid className='d-flex justify-content-center' >
                                            <Label className='justify-content-center mt-5'>
                                                Por favor, selecciona si deseas consultar los registros de asistencia de un estudiante por una fecha específica o por un rango de fechas.
                                            </Label>
                                        </Container>

                                    )}
                                </TabPanel>
                            ))}
                        </Container>
                    </Container>
                </Tabs>
            </Container>
            <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<Io.IoMdReturnLeft className="me-1" />}
                        text="Volver"
                        to="/Menu/FrmAsistencia"
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>
        </Container>
    )
}

export default FrmAsistenciaEstuduante
