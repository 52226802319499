import React, { useState  } from "react";


/**
 * Componente ContactForm
 * Representa un formulario de contacto.
 * @returns {JSX.Element} Componente ContactForm.
 */
const ContacForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        institucion: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes agregar la lógica para enviar el formulario, por ejemplo, mediante una solicitud HTTP POST
        console.log(formData);
    };

    return (
        <div className="container-fluid card-contacto-correo ">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="mb-4 col-xl-6 col-lg-6">
                        <label htmlFor="name" className="form-label">Nombre Completo</label>
                        <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="mb-4 col-xl-6 col-lg-6">
                        <label htmlFor="email" className="form-label">Correo Electrónico</label>
                        <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="mb-4 col-xl-6 col-lg-6">
                        <label htmlFor="institucion" className="form-label">Institución Educativa</label>
                        <input type="text" className="form-control" id="institucion" name="institucion" value={formData.institucion} onChange={handleChange} required />
                    </div>
                    <div className="mb-4 col-xl-6 col-lg-6">
                        <label htmlFor="phone" className="form-label">Teléfono</label>
                        <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="form-label">Mensaje</label>
                        <textarea className="form-control" id="message" name="message" rows="2" value={formData.message} onChange={handleChange} required />
                    </div>
                </div>
                <button type="submit" className="btn btn-contacto-enviar">Enviar</button>
            </form>
        </div>
    );
}

export default ContacForm
