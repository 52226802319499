import React from 'react'
import { Container, Button, Label } from 'reactstrap'
import { IoMdReturnLeft } from "react-icons/io";
import MenuItem from './MenuItem';

import * as Bi from "react-icons/bi";
import { toBeEnabled } from '@testing-library/jest-dom/matchers';

/**
 * Componente para mostrar un mensaje cuando no hay datos.
 * @param {object} props - Las props del componente.
 * @param {string} props.mensaje - El mensaje que se mostrará cuando no haya datos.
 * @param {string} props.route - ruta para redireccionar el formulario
 * @returns {JSX.Element} Elemento JSX que muestra el mensaje cuando no hay datos.
 */

const SinDatos = ({ mensaje, error, route, toRoute, direccionactual, mostrarboton }) => {
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Label id='currentPage'>{direccionactual}</Label>
            <Container fluid className='fixed'>
                {mostrarboton ? (
                    <Button className='p-0 btn btnAceptar'>
                        <ul>
                            <MenuItem
                                className='p-0'
                                icon={<Bi.BiSolidInstitution className="me-1" />}
                                text="Func. Inactivos"
                                to={toRoute}
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                ) : null}

            </Container>
            <h1 className='text-center mt-5 pt-5'>{mensaje}</h1>
            <h4 className='text-center mt-5 mb-5'>{error}</h4>
            <Button type="button" className='p-0 btn   btnCancelar px-2 d-flex justify-content-start' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<IoMdReturnLeft className="me-1" />}
                        text="Volver"

                        to={route}
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>
        </Container>
    )
}

export default SinDatos
