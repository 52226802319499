import React from 'react'
import { CodigoInstitucion } from '../../../CapaUtilidades/Utils/Cahe';
import { obtenerIdInstitucion } from '../../../CapaUtilidades/Utils/encryptUserData';



const Administradores = () => {
  let hh
  const id = () => {
      hh = obtenerIdInstitucion();
      alert(hh)
  }
  return (
    <div>
      <button onClick={(e) => id()}>aqui</button>
      <h1>{hh}</h1>
    </div>
  )
}

export default Administradores
