import { useState } from "react";

export const TbSeccionFuncionario = () => {
    
    const [seccion, setSeccion] = useState(0);
    const [docente, setDocente] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [estadoSeccFun, setEstadoSeccFun] = useState(1);
    return {
        seccion, setSeccion,
        docente, setDocente,
        selectedYear, setSelectedYear,
        institucion, setInstitucion,
        estadoSeccFun, setEstadoSeccFun
    };
};