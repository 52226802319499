 
import SelectsService from '../CapaUtilidades/Utils/SelectsService';



/**
 * Metodo para obtener la lista de Condicion BucoDental si o no del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaBucoDental'
 */
export const GetBucoDental = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getCondicionBucoDental().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de Condicion BucoDental si o no del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaTrimestres'
 */
export const GetTrimestres = (lista)=> {
  const selectsService = new SelectsService();
  selectsService.getTrimestres().then(data => {
    lista(data);
  }).catch(error => {
    console.error('Error al obtener los trimestres:', error);
  });
}

/**
 * Metodo para obtener la lista de Rubros evaluativos del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaRubrosEvaluativos'
 * 
 */
export const GetRubrosEvaluativos = (setLista) => {
  const selectsService = new SelectsService();
  selectsService.getRubrosEvaliacion().then(data => {
    setLista(data);
  }).catch(error => {
    console.error('Error al obtener los rubros evaluativos:', error);
  });
}


/**
 * Metodo para obtener la lista de Tipos sanguineos si o no del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaTipoSanguineos'
 */
export const GetTiposSanguineos = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getTiposSanguineos().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de Discapacidades si o no del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaDiscapacidades'
 */
export const GetDiscapacidades = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getDiscapacidades().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de NUmeros si o no del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaNumeros'
 */
export const GetNumeros = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getNumeros().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};



/**
 * Metodo para obtener la lista de Becas del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaTipoBeca'
 */
export const GetTipoBeca = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getTipoBeca().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};


/**
 * Metodo para obtener la lista de la condicion de casa del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaCondicionCasa'
 */
export const GetCondicionCasa = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getCondicionCasa().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};


/**
 * Metodo para obtener la lista de tipos de casa del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaTipoCasa'
 */
export const GetTipoCasa = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getTipoCasa().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};


/**
 * Metodo para obtener la lista de Nivel Escolaridad del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaNivelEscolaridad'
 */
export const GetNivelEscolaridad = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getNivelEscolaridad().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};


/**
 * Metodo para obtener la lista de Ocupaciones del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaOcupacion'
 */
export const GetOcupacion = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getOcupacion().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};


/**
 * Metodo para obtener la lista de parentescos del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaparentescos'
 */
export const GetParentescos = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getParentesco().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de Estdos civil del sistema
 * @param {*} Lista Resive un arreglo basio 'setListaEstadoCivil'
 */
export const GetEstadoCivil = (Lista) => {

  const selectsService = new SelectsService();

  selectsService.getEstadoCivil().then(data => {
    
    Lista(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de Cargos del sistema
 * @param {*} ListaModos Resive un arreglo basio 'setListaCargos'
 */
export const GetModoAdqui = (ListaModo) => {

  const selectsService = new SelectsService();

  selectsService.getmodoAdquisitivo().then(data => {
    
    ListaModo(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de Cargos del sistema
 * @param {*} ListaTipoActivos Resive un arreglo basio 'setListaCargos'
 */
export const GetTipoActivos = (ListaTipoActivos) => {

  const selectsService = new SelectsService();

  selectsService.getTipoActivo().then(data => {
    
    ListaTipoActivos(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};


/**
 * Metodo para obtener la lista de Cargos del sistema
 * @param {*} ListaTipoInstitucion Resive un arreglo basio 'setListaCargos'
 */
export const GetTipoInstitucion = (ListaTipoinstitucion) => {

  const selectsService = new SelectsService();

  selectsService.getTipoInstitucion().then(data => {
    
    ListaTipoinstitucion(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de Cargos del sistema
 * @param {*} ListaTipoEdificios Resive un arreglo basio 'setListaCargos'
 */
export const GetTipoEdificios = (ListaTipoEdificios) => {

  const selectsService = new SelectsService();

  selectsService.getTipoEdificio().then(data => {
    
    ListaTipoEdificios(data);
     
  }).catch(error => {
    console.error('Error al obtener los modos:', error);
  });
};

/**
 * Metodo para obtener la lista de Cargos del sistema
 * @param {*} ListaCargos Resive un arreglo basio 'setListaCargos'
 */
export const GetCargos = (ListaCargos) => {

  const selectsService = new SelectsService();

  selectsService.getCargos().then(data => {
    
    ListaCargos(data);
     
  }).catch(error => {
    console.error('Error al obtener las paises:', error);
  });
};


export const GetGrados = (ListaGrados) => {

  const selectsService = new SelectsService();

  selectsService.getGrados().then(data => {
    
    ListaGrados(data);
     
  }).catch(error => {
    console.error('Error al obtener las grados:', error);
  });
};


/**
 * Metodo para obtener la lista de los tipos de nombramientos
 * @param {*} listaNombramientos Resive un arreglo basio 'setListaNombramientos'
 */
export const  GetNombramientos = (listaNombramientos) => {
  const selectsService = new SelectsService();
  selectsService.getNombramientos().then(data => {
    listaNombramientos(data);
  }).catch(error => {
    console.error('Error al obtener los nombramientos:', error);
  });
}

/**
 * Metodo para obtener la lista de los tipos de Roles que se usaran para el acceso al sistema
 * @param {*} listaRoles Resive un arreglo basio 'setListaRoles'
 */
export const GetRoles = (listaRoles) => { 
  const selectsService = new SelectsService();
  selectsService.getRoles().then(data => {
    listaRoles(data);
  }).catch(error => {
    console.error('Error al obtener los roles:', error);
  });
}

/**
 * Metodo para obtener la lista de los tipos de Roles que se usaran para el acceso al sistema
 * @param {*} listatipoDereccion Resive un arreglo basio 'setListaTipoDireccion'
 */
export const GetTipoDereccion = (listatipoDereccion) => {
  const selectsService = new SelectsService();
  selectsService.getTipoDereccion().then(data => {
    listatipoDereccion(data);
    
  }).catch(error => {
    console.error('Error al obtener los tipos de dereccion:', error);
  });
}

/**
 * Metodo para obtener la lista de los tipos de Roles que se usaran para el acceso al sistema
 * @param {*} listaSexo Resive un arreglo basio 'setListaSexo'
 */
export const GetSexo = (listaSexo) => {
  const selectsService = new SelectsService();
  selectsService.getSexo().then(data => {
    listaSexo(data);
    
  }).catch(error => {
    console.error('Error al obtener los tipos de dereccion:', error);
  });
}

export const GetTipoNacionalidad = (TiposID) => {
  const selectsService = new SelectsService();
  selectsService.getTipoIdentificacion().then(data => {
    TiposID(data);
    
  }).catch(error => {
    console.error('Error al obtener los tipos de dereccion:', error);
  });
}