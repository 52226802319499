import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;



export async function AsignarActivo(data) {
    
    url = endpoint.urlAsignarActivo;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
      // console.log(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}

export async function ListaActivosAsignados(data ) {
    url = endpoint.urlListaActivosAsignados;
    
   

    //const data = { idInstitucion: inst, Estado: datas }
    //alert(JSON.stringify(data));
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

     try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const ActivosResponse = await response.json();
       
        const ActivosAsiganado = ActivosResponse[0];
        
        //alert(JSON.stringify(ActivosAsiganado));

        return ActivosAsiganado;  

    } catch (error) {
        return { estado: 0 };
    }
}

export async function ActualizarActivoAsignadoProfesor(data) {
    
    url = endpoint.urlActualizarActivo;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       //console.log(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}

export async function DevolverActivo(data) {
    
    url = endpoint.urlCambiarEstadoActivo;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       //console.log(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}