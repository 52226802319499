const PaisesJson = require('../../CapaPresentacion/Json/Paises.json');
const ubicacionesJson = require('../../CapaPresentacion/Json/ubicaciones.json');

class Paises {
  constructor(id, nombre) {
    this.id = id;
    this.nombre = nombre;
  }
}


class Provincia {
  constructor(id, nombre) {
    this.id = id;
    this.nombre = nombre;
  }
}

class Canton {
  constructor(id, nombre) {
    this.id = id;
    this.nombre = nombre;
  }
}

class Distrito {
  constructor(id, nombre) {
    this.id = id;
    this.nombre = nombre;
  }
}

class Barrio {
  constructor(id, nombre) {
    this.id = id;
    this.nombre = nombre;
  }
}

class Ubicacion {
  constructor(provincia, canton, distrito, barrio) {
    this.provincia = provincia;
    this.canton = canton;
    this.distrito = distrito;
    this.barrio = barrio;
  }
}


class UbicacionService {

  async getPaises() {
    const paises = [];
    for (const [paisId, nombrePais] of Object.entries(PaisesJson)) {
      const pais = new Paises(parseInt(paisId),nombrePais);
      paises.push(pais);
      
    }
    return paises;
  }

  async getProvincias() {
    const provincias = [];

    for (const [provinciaId, provinciaData] of Object.entries(ubicacionesJson)) {
      const provincia = new Provincia(parseInt(provinciaId), provinciaData.nombre_provincia);
      provincias.push(provincia);
    }

    return provincias;
  }

  async getCantonesByProvincia(provinciaId) {
    const provinciaData = ubicacionesJson[provinciaId.toString()];
    const cantones = [];

    if (provinciaData) {
      for (const [cantonId, cantonData] of Object.entries(provinciaData.cantones)) {
        const canton = new Canton(parseInt(cantonId), cantonData.nombre_canton);
        cantones.push(canton);
      }
    }

    return cantones;
  }

  async getDistritosByCanton(provinciaId, cantonId) {
    const provinciaData = ubicacionesJson[provinciaId.toString()];
    const cantonData = provinciaData?.cantones?.[cantonId.toString()];
    const distritos = [];

    if (cantonData) {
      for (const [distritoId, distritoData] of Object.entries(cantonData.distritos)) {
        const distrito = new Distrito(parseInt(distritoId), distritoData.nombre_distrito);
        distritos.push(distrito);
      }
    }

    return distritos;
  }

  async getBarriosByDistrito(provinciaId, cantonId, distritoId) {
    const provinciaData = ubicacionesJson[provinciaId.toString()];
    const cantonData = provinciaData?.cantones?.[cantonId.toString()];
    const distritoData = cantonData?.distritos?.[distritoId.toString()];
    const barrios = [];

    if (distritoData) {
      for (const [barrioId, nombreBarrio] of Object.entries(distritoData.barrios)) {
        const barrio = new Barrio(parseInt(barrioId), nombreBarrio);
        barrios.push(barrio);
      }
    }

    return barrios;
  }

  async getUbicacion(codigo) {
    if (codigo.length !== 7) {
      throw new Error('El código debe tener 7 dígitos.');
    }

    const provinciaId = parseInt(codigo.substring(0, 1));
    const cantonId = parseInt(codigo.substring(1, 3));
    const distritoId = parseInt(codigo.substring(3, 5));
    const barrioId = parseInt(codigo.substring(5, 7));

    const provincias = await this.getProvincias();
    const provincia = provincias.find(p => p.id === provinciaId);

    const cantones = await this.getCantonesByProvincia(provinciaId);
    const canton = cantones.find(c => c.id === cantonId);

    const distritos = await this.getDistritosByCanton(provinciaId, cantonId);
    const distrito = distritos.find(d => d.id === distritoId);

    const barrios = await this.getBarriosByDistrito(provinciaId, cantonId, distritoId);
    const barrio = barrios.find(b => b.id === barrioId);

    return new Ubicacion(provincia, canton, distrito, barrio);
  }
}

module.exports = UbicacionService;
