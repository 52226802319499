import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';


import Logo from "../../assets/Nombre 3.png";
import LogoSiage1 from "../../assets/SVG/log1.svg"
import LogoSiage2 from "../../assets/SVG/log2.svg"

import PrincipalSVG from "../../assets/SVG/4662928_2450616 (1).svg"
import Encargado from "../../assets/imagenes/encargado.png";
import Profesor from "../../assets/imagenes/profesor.png";
import Administrativo from "../../assets/imagenes/administrativo.png";
import Estudiante from "../../assets/imagenes/Estudiante.png"
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, FormText, Div, InputGroup, InputGroupText, Nav, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Barra from '../../components/Barra';
import ContactForm from "../../components/ContacForm";
import { validarSoloNumeros } from "../../../CapaUtilidades/Utils/validaciones";
import { ValidarSoloNumeros } from "../../../CapaUtilidades/Utils/FuncionesDeValidacion";
import { inicioSesion, inicioSesionEncargado } from "../../../CapaDatos/DatosLogin";
import { ErrorMessage } from "../../../CapaUtilidades/Utils/FuncionesDeReutilizacion";
import { GuardarCahe } from "../../../CapaUtilidades/Utils/Cahe";
import { GuardarEnLocalStorage, encryptUserData } from "../../../CapaUtilidades/Utils/encryptUserData";

import { getInstitucion } from "../../../CapaDatos/DatosDesarrolladores";
import Swal from "sweetalert2";
import { TbComunicados } from "../../../CapaEntidades/TbComunicados";


function Login() {




    const [username, setUsername] = useState('206750945');
    const [password, setPassword] = useState('AxellDereck22@');
    const [instituciones, setInstituciones] = useState([])
    const [institucionSeleccionada, setInstitucionSeleccionada] = useState('')
    const [userValido, setUserValido] = useState(true);
    const [dataAdminDocen, setDataAdminDocen] = useState([])
    const [dataEncargado, setDataEncargado] = useState([])

    let usuarioI = '', permiso = '', nombreinstitucion = ''

    const [error, setError] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    const [isVisible, setIsVisible] = useState(false);
    const [mostrarContrasena, setMostrarContrasena] = useState(false);
    const TBComunicados = TbComunicados();

    const [mostrarPanelAdminDocen, setMostrarPanelAdminDocen] = useState(false);
    const [mostrarPanelEncargado, setMostrarPanelEncargado] = useState(false)

    // Función para hacer scroll hasta el inicio de la página
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Función para manejar el evento de desplazamiento
    const handleScroll = () => {
        if (window.pageYOffset > 100) { // Cambia 100 según lo que consideres apropiado
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Agregar evento de desplazamiento cuando el componente se monta
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('TKO');
        if (isLoggedIn === 'true') {
            setLoggedIn(true);
        }
    }, []);





    useEffect(() => {
        if (instituciones.length > 0) {
            setInstitucionSeleccionada(instituciones[0].id); // Selecciona el primer id de la lista
        }
    }, [instituciones]);


    //============================================== INICIO METODOS INICIO SESION ADMINISTRATIVO Y DOCENTE ==========================================
    const ValidarInicioSecion = async (e) => {
        e.preventDefault();

        const parametro = {
            usuario: username,
            passwd: password,
            estado: 1
        }
        //alert(JSON.stringify(parametro));
        let data = await inicioSesion(parametro)

        //alert(JSON.stringify(data));
        if (data === null) {
            ErrorMessage("ATENCIÓN", "Problemas internos con el servidor.");
            return;
        }


        if (data !== null && data.length !== 0) {
            //alert("aqui")
            setDataAdminDocen(data)
            let usuarioValido = false;
            let passwordValido = false;
            let id = '';
            let user = '';
            let pass = '';
            //alert(JSON.stringify(data));
            for (const DatosUsuario of data) {

                let { usuario, passwd, idInstitucion } = DatosUsuario;

                if (username === usuario) {

                    usuarioI = usuario
                    if (password === passwd) {
                        id = idInstitucion
                        user = usuario
                        pass = passwd
                        usuarioValido = true;
                        passwordValido = true;
                        break;

                    } else {

                        passwordValido = false;

                    }

                }
            }
            if (!passwordValido) {
                setError('La contraseña es incorrecta.');
                return; // Detener la ejecución si el nombre de usuario no coincide
            }
            if (usuarioValido && passwordValido) {

                await mostrarModalAdministrativo(data, user, pass);
            }
        }
        else {

            setUsername("");
            setPassword("");
            ErrorMessage("Inicio de Sesión", "Usuario ó contraseña incorrectos  ");
            return;
        }
    };

    const ocultarpanelAdminDocent = () => {
        setMostrarPanelAdminDocen(false);
    }

    const mostrarModalAdministrativo = async (data, user, pass) => {
        setInstituciones([])
        const institucionesTemp = []; // Array temporal para almacenar las instituciones
        const idInstituciones = data
            .filter(usuario => usuario.usuario === user && usuario.passwd === pass)
            .map(usuario => usuario.idInstitucion);

        for (const id of idInstituciones) {
            const nombreinsti = await getInstitucion({ codigoPresupuestario: id });
            institucionesTemp.push({ id, nombre: `${id} - ${nombreinsti}` }); // Almacenar el id y nombre de la institución
        }

        setInstituciones(institucionesTemp); // Almacena las instituciones en el estado
        setMostrarPanelAdminDocen(!mostrarPanelAdminDocen);
    };

    const InicioSecionAdministrativo = async (e) => {
        e.preventDefault();
        if (institucionSeleccionada === null || institucionSeleccionada === undefined) {
            return;
        }
        if (institucionSeleccionada) {

            const permisoSeleccionado = dataAdminDocen.find(dat => dat.idInstitucion === institucionSeleccionada);
            nombreinstitucion = await getInstitucion({ codigoPresupuestario: permisoSeleccionado.idInstitucion });

            permiso = permisoSeleccionado.permisosUsuario;


        }


        GuardarEnLocalStorage(username, institucionSeleccionada, permiso, nombreinstitucion);
        setInstitucionSeleccionada('')
        setLoggedIn(true);
        setMostrarPanelAdminDocen(false)
    }
    //============================================== FIN METODOS INICIO SESION ADMINISTRATIVO Y DOCENTE ==========================================



    //============================================== INICIO METODOS INICIO SESION ENCARGADOS ==========================================

    const ValidarInicioSecionEncargados = async (e) => {
        e.preventDefault();

        const parametro = {
            usuario: username,
        }
        let data2 = await inicioSesionEncargado(parametro)


        if (data2 === null) {
            ErrorMessage("ATENCIÓN", "Problemas internos con el servidor.");
            return;
        }

        // if(!data2.estado){
        //     ErrorMessage("ATENCIÓN", "El usurio proporsionado no existe o es incorrecto");
        //     return;
        // }
        //alert(JSON.stringify(data2));
        if (data2 !== null && data2.length !== 0) {
            setDataEncargado(data2)
            let usuarioValido = false;
            let passwordValido = false;
            let id = '';
            let user = '';
            let pass = '';
            //alert(JSON.stringify(data));
            for (const DatosUsuario of data2) {

                let { usuario, passwd, idInstitucion } = DatosUsuario;

                if (username === usuario) {

                    usuarioI = usuario
                    if (password === passwd) {
                        id = idInstitucion
                        user = usuario
                        pass = passwd
                        usuarioValido = true;
                        passwordValido = true;
                        break;
                    } else {
                        passwordValido = false;
                    }
                }
            }
            if (!passwordValido) {
                setError('La contraseña es incorrecta.');
                return; // Detener la ejecución si el nombre de usuario no coincide
            }
            if (usuarioValido && passwordValido) {
                await mostrarAccesoEncargado(data2, user, pass);
            }
        }
        else {

            setUsername("");
            setPassword("");
            ErrorMessage("Inicio de Sesión", "Usuario ó contraseña incorrectos  ");
            return;
        }
    };

    const ocultarPanelEncargado = () => {
        setMostrarPanelEncargado(false);
    }
    const mostrarAccesoEncargado = async (data, user, pass) => {
        setInstituciones([])
        const institucionesTemp = []; // Array temporal para almacenar las instituciones
        const idInstituciones = data
            .filter(usuario => usuario.usuario === user && usuario.passwd === pass)
            .map(usuario => usuario.idInstitucion);

        for (const id of idInstituciones) {
            const nombreinsti = await getInstitucion({ codigoPresupuestario: id });
            institucionesTemp.push({ id, nombre: `${id} - ${nombreinsti}` }); // Almacenar el id y nombre de la institución
        }

        setInstituciones(institucionesTemp); // Almacena las instituciones en el estado
        setMostrarPanelEncargado(!mostrarPanelEncargado);
    };

    const InicioSecionEncargado = async (e) => {
        e.preventDefault();
        if (institucionSeleccionada === null || institucionSeleccionada === undefined) {
            return;
        }
        if (institucionSeleccionada) {

            const permisoSeleccionado = dataEncargado.find(dat => dat.idInstitucion === institucionSeleccionada);
            nombreinstitucion = await getInstitucion({ codigoPresupuestario: permisoSeleccionado.idInstitucion });

            permiso = permisoSeleccionado.permisosUsuario;


        }


        GuardarEnLocalStorage(username, institucionSeleccionada, permiso, nombreinstitucion);
        setInstitucionSeleccionada('')
        setLoggedIn(true);
        setMostrarPanelEncargado(false)

    }
    //============================================== FIN METODOS INICIO SESION ENCARGADOS ==========================================


    if (loggedIn) {
        return <Navigate to="/Menu" />;
    }


    // Redirigir automáticamente si el usuario ya está autenticado
    if (localStorage.getItem('Token') === 'true') {
        return <Navigate to="/Menu" />;
    }

    const toggleMostrarContrasena = () => {
        setMostrarContrasena(!mostrarContrasena); // Cambia el estado para mostrar o no la contraseña
    };


    function toggleCard(card, col) {
        const cardElement = document.getElementById(card);
        const div = document.getElementById(col)
        if (cardElement) {
            div.classList.toggle('rotated');
            
        } else {
            //alert(`El elemento con ID ${card} no fue encontrado`);
        }
    }


    return (

        <Container fluid className="container-fluid p-0 cero-pading-margin">
            <Container fluid className="border">
                <header fluid className="bg-nav">
                    <nav class=" navbar  bg-nav fixed-top navbar-login p-0">
                        <div class="container-fluid">

                            <div className="row contenedor-logo">
                                <div className="col-6   logo-empresa"><img src={LogoSiage2} alt="SIGAE Logo" /></div>
                                <div className="col-6   nombre-empresa"><a>SIGÄE</a></div>
                            </div>

                            {/* <button class="navbar-toggler btn-ingresar" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                                Iniciar Sesión
                            </button> */}
                            <button
                                class="navbar-toggler btn-ingresar"
                                type="button"
                                onClick={() => {
                                    const perfilesSection = document.getElementById("perfiles");
                                    if (perfilesSection) {
                                        perfilesSection.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                            >
                                Perfiles de Acceso
                            </button>

                        </div>
                    </nav>

                </header>
            </Container>



            <div className="offcanvas offcanvas-end efecto-transparente" tabIndex="-1" data-bs-backdrop="static" id="staticBackdrop" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h6 className="offcanvas-title text-white" id="offcanvasNavbarLabel">Login Administrativo</h6>
                    <button type="button" className="btn-close text-white p-1" data-bs-dismiss="offcanvas" aria-label="Close" onClick={ocultarpanelAdminDocent}></button>
                </div>
                <div className="offcanvas-body">
                    <div class="container-fluid my-5 p-2">

                        <div class="container shadowWhite p-4 br-15  login-container">

                            <div class="row ajustarLogin px-3 shadow">
                                <div className='col-12 mb-3 row'>
                                    <h4 class="  text-center text-white">Bienvenido(a)</h4>
                                </div>
                                <div className='col-12'>
                                    {!mostrarPanelAdminDocen ? (
                                        <form onSubmit={ValidarInicioSecion}>

                                            <div class="form-floating mb-3">
                                                <FormGroup>
                                                    <Label for="password" className="text-white font-size-login">
                                                        Usuario
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="usuario"
                                                        name="usiario"
                                                        title="Formato como aparece en la cedula con los 0"
                                                        placeholder="Usuario"
                                                        value={username}
                                                        onChange={(e) => ValidarSoloNumeros(e, setUsername, setUserValido)}
                                                        className={userValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!userValido && <div className="invalid-feedback">El Usuario debe ser tu cedula de Identidad.</div>}
                                                </FormGroup>

                                            </div>


                                            <div class="form-floating mb-3">
                                                <FormGroup>
                                                    <Label for=" password" className="text-white font-size-login">
                                                        Contraseña
                                                    </Label>
                                                    <InputGroup>
                                                        <Input

                                                            required
                                                            id="password"
                                                            name="password"
                                                            placeholder="Contraseña"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            type={mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                        />
                                                        <InputGroupText addonType="append" className='p-0'>
                                                            <Button onClick={toggleMostrarContrasena} color="" className='p-0 px-2'>
                                                                {mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                            </Button>
                                                        </InputGroupText>
                                                    </InputGroup>

                                                </FormGroup>

                                            </div>

                                            <button className='btnSuscess' type="submit" >Comprobar Credenciales</button>
                                        </form>
                                    ) : (
                                        <Form className="mt-4" onSubmit={InicioSecionAdministrativo}>
                                            <Col  >
                                                <Col className='custom-form-group text-center'>
                                                    <Label className="text-white my-2 font-size-login text-center ">
                                                        Seleccione la institucion a la que desea ingresar
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="Nacionalidad"
                                                        name="Nacionalidad"
                                                        className='py-1 text-center'
                                                        type="select"
                                                        value={institucionSeleccionada}
                                                        onChange={(e) => setInstitucionSeleccionada(e.target.value)}
                                                    >

                                                        {instituciones.map((inst, index) => (
                                                            <option key={inst.id} value={inst.id}  >{inst.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </Col>
                                            </Col>
                                            <Col className='d-flex justify-content-between mt-3'>
                                                <Button className=' p-1     btnCancelar px-3' onClick={ocultarpanelAdminDocent}>
                                                    Volver
                                                </Button>
                                                <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                    Iniciar Sesión
                                                </Button>
                                            </Col>

                                        </Form>
                                    )}
                                </div>
                                <div className='col-12  my-3 text-center'>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offcanvas offcanvas-end efecto-transparente" tabIndex="-1" data-bs-backdrop="static" id="staticBackdropDocente" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h6 className="offcanvas-title text-white" id="offcanvasNavbarLabel">Login para Docentes</h6>
                    <button type="button" className="btn-close text-white p-1" data-bs-dismiss="offcanvas" aria-label="Close" onClick={ocultarpanelAdminDocent}></button>
                </div>
                <div className="offcanvas-body">
                    <div class="container-fluid my-5 p-2">

                        <div class="container shadowWhite p-4 br-15  login-container">

                            <div class="row ajustarLogin px-2 shadow">
                                <div className='col-12 mb-3 row'>
                                    <h4 class="  text-center text-white">Bienvenido(a)</h4>
                                </div>
                                <div className='col-12'>
                                    {!mostrarPanelAdminDocen ? (
                                        <form onSubmit={ValidarInicioSecion}>

                                            <div class="form-floating mb-3">
                                                <FormGroup>
                                                    <Label for="password" className="text-white font-size-login">
                                                        Usuario
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="usuario"
                                                        name="usiario"
                                                        title="Formato como aparece en la cedula con los 0"
                                                        placeholder="Usuario"
                                                        value={username}
                                                        onChange={(e) => ValidarSoloNumeros(e, setUsername, setUserValido)}
                                                        className={userValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!userValido && <div className="invalid-feedback">El Usuario debe ser tu cedula de Identidad.</div>}
                                                </FormGroup>

                                            </div>


                                            <div class="form-floating mb-3">
                                                <FormGroup>
                                                    <Label for=" password" className="text-white font-size-login">
                                                        Contraseña
                                                    </Label>
                                                    <InputGroup>
                                                        <Input

                                                            required
                                                            id="password"
                                                            name="password"
                                                            placeholder="Contraseña"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            type={mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                        />
                                                        <InputGroupText addonType="append" className='p-0'>
                                                            <Button onClick={toggleMostrarContrasena} color="" className='p-0 px-2'>
                                                                {mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                            </Button>
                                                        </InputGroupText>
                                                    </InputGroup>

                                                </FormGroup>

                                            </div>

                                            <button className='btnSuscess' type="submit" >Comprobar Credenciales</button>
                                        </form>
                                    ) : (
                                        <Form className="mt-4" onSubmit={InicioSecionAdministrativo}>
                                            <Col  >
                                                <Col className='custom-form-group text-center'>
                                                    <Label className="text-white my-2 font-size-login ">
                                                        Seleccione la institucion a la que desea ingresar
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="Nacionalidad"
                                                        name="Nacionalidad"
                                                        className='py-1 text-center'
                                                        type="select"
                                                        value={institucionSeleccionada}
                                                        onChange={(e) => setInstitucionSeleccionada(e.target.value)}
                                                    >

                                                        {instituciones.map((inst, index) => (
                                                            <option key={inst.id} value={inst.id}  >{inst.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </Col>
                                            </Col>
                                            <Col className='d-flex justify-content-between mt-3'>
                                                <Button className=' p-1     btnCancelar px-3' onClick={ocultarpanelAdminDocent}>
                                                    volver
                                                </Button>
                                                <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                    Iniciar Sesión
                                                </Button>
                                            </Col>

                                        </Form>
                                    )}

                                </div>
                                <div className='col-12  my-3 text-center'>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ESTE ESTA EN DESARROLLO */}
            <div className="offcanvas offcanvas-end efecto-transparente" tabIndex="-1" data-bs-backdrop="static" id="staticBackdropEstu" aria-labelledby="offcanvasNavbarLabel">

                <div className="offcanvas-header">
                    <h6 className="offcanvas-title text-white" id="offcanvasNavbarLabel">Login para Estudiantes</h6>
                    <button type="button" className="btn-close text-white p-1" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div class="container-fluid my-5 p-2">
                        <h2 className="offcanvas-title text-white">EN DESARROLLO</h2>
                        {/*
                        <div class="container shadowWhite p-4 br-15  login-container">

                            <div class="row ajustarLogin px-2 shadow">
                                <div className='col-12 mb-3 row'>
                                    <h4 class="  text-center text-white">Bienvenido(a)</h4>
                                </div>
                                <div className='col-12'>
                                    <form >

                                        <div class="form-floating mb-3">
                                            <FormGroup>
                                                <Label for="password" className="text-white">
                                                    Usuario
                                                </Label>
                                                <Input

                                                    required
                                                    id="usuario"
                                                    name="usiario"
                                                    title="Formato como aparece en la cedula con los 0"
                                                    placeholder="Usuario"
                                                    value={username}
                                                    onChange={(e) => ValidarSoloNumeros(e, setUsername, setUserValido)}
                                                    className={userValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!userValido && <div className="invalid-feedback">El Usuario debe ser tu cedula de Identidad.</div>}
                                            </FormGroup>

                                        </div>


                                        <div class="form-floating mb-3">
                                            <FormGroup>
                                                <Label for=" password" className="text-white">
                                                    Contraseña
                                                </Label>
                                                <InputGroup>
                                                    <Input

                                                        required
                                                        id="password"
                                                        name="password"
                                                        placeholder="Contraseña"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        type={mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                    />
                                                    <InputGroupText addonType="append" className='p-0'>
                                                        <Button onClick={toggleMostrarContrasena} color="" className='p-0 px-2'>
                                                            {mostrarContrasena ? <FiEyeOff /> : <FiEye />}
            </Button>
        </InputGroupText>
                                                </InputGroup >

                                            </FormGroup >

                                        </div >

        <button className='btnSuscess' type="submit" >Ingresar</button>
                                    </form >

                                </div >
        <div className='col-12  my-3 text-center'>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
                            </div >
                            
                        </div > */}
                    </div >
                </div >
            </div >

            <div className="offcanvas offcanvas-end efecto-transparente" tabIndex="-1" data-bs-backdrop="static" id="staticBackdropEncar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h6 className="offcanvas-title text-white" id="offcanvasNavbarLabel">Login para Encargados</h6>
                    <button type="button" className="btn-close text-white p-1" data-bs-dismiss="offcanvas" aria-label="Close" onClick={ocultarPanelEncargado}></button>
                </div>
                <div className="offcanvas-body">
                    <div class="container-fluid my-5 p-2">

                        <div class="container shadowWhite p-2 br-15  login-container">

                            <div class="row ajustarLogin px-2 shadow">
                                <div className='col-12 mb-3 row'>
                                    <h4 class="  text-center text-white">Bienvenido(a)</h4>
                                </div>
                                <div className='col-12'>
                                    {!mostrarPanelEncargado ? (
                                        <form onSubmit={ValidarInicioSecionEncargados}>

                                            <div class="form-floating mb-3">
                                                <FormGroup>
                                                    <Label for="password" className="text-white font-size-login">
                                                        Usuario
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="usuario"
                                                        name="usiario"
                                                        title="Formato como aparece en la cedula con los 0"
                                                        placeholder="Usuario"
                                                        value={username}
                                                        onChange={(e) => ValidarSoloNumeros(e, setUsername, setUserValido)}
                                                        className={userValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!userValido && <div className="invalid-feedback">El Usuario debe ser tu cedula de Identidad.</div>}
                                                </FormGroup>

                                            </div>


                                            <div class="form-floating mb-3">
                                                <FormGroup>
                                                    <Label for=" password" className="text-white font-size-login">
                                                        Contraseña
                                                    </Label>
                                                    <InputGroup>
                                                        <Input

                                                            required
                                                            id="password"
                                                            name="password"
                                                            placeholder="Contraseña"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            type={mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                        />
                                                        <InputGroupText addonType="append" className='p-0'>
                                                            <Button onClick={toggleMostrarContrasena} color="" className='p-0 px-2'>
                                                                {mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                            </Button>
                                                        </InputGroupText>
                                                    </InputGroup>

                                                </FormGroup>

                                            </div>

                                            <button className='btnSuscess' type="submit" >Comprobar Credenciales</button>
                                        </form>
                                    ) : (
                                        <Form className="mt-4" onSubmit={InicioSecionEncargado}>
                                            <Col  >
                                                <Col className='custom-form-group text-center'>
                                                    <Label className="text-white my-2 font-size-login ">
                                                        Seleccione la institucion a la que desea ingresar
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="Nacionalidad"
                                                        name="Nacionalidad"
                                                        className='py-1 text-center'
                                                        type="select"
                                                        value={institucionSeleccionada}
                                                        onChange={(e) => setInstitucionSeleccionada(e.target.value)}
                                                    >

                                                        {instituciones.map((inst, index) => (
                                                            <option key={inst.id} value={inst.id}  >{inst.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </Col>
                                            </Col>
                                            <Col className='d-flex justify-content-between mt-3'>
                                                <Button className=' p-1     btnCancelar px-3' onClick={ocultarPanelEncargado}>
                                                    Cancelar
                                                </Button>
                                                <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                    Ingresar
                                                </Button>
                                            </Col>

                                        </Form>
                                    )}

                                </div>
                                <div className='col-12  my-3 text-center'>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className=" ">
                <section class=" container-fluid p-0  steps-section banner-Clientes">
                    <div class="  layout_padding2">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12 pb-0 text-center">
                                    <h1 className="step_text1">SIGÄE</h1>

                                </div>
                                <div class="col-lg-6 col-md-6 col-12 pb-4 text-center">

                                    <h2 className="step_text">Sistema de Gestión Administrativa y Educativa</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="PrincipalSvgContainer">
                    <row className="row ">
                        <div className="PrincipalSvgContainer ">
                            <div class="step_text ">
                                <h1 className="">SIGÄE</h1>
                                <h2>Sistema de Gestión Administrativa y Educativa</h2>
                            </div>
                            {/* <img src={PrincipalSVG} alt="SIGAE EDUCACIÓN" />  
                        </div>
                    </row>
                </div> */}
                </section>
            </section>
            <section id="perfiles" >
                <Container fluid className="contenedor contenedor-perfil  " >
                    <Col className="row container-tittle mt-1 d-flex justify-content-center">
                        <h2>Perfiles de acceso</h2>
                        <Barra />
                        <Label className="text-center mt-2 p-content">
                            SIGÄE gestiona perfiles individuales para cada usuario de la plataforma
                        </Label>
                    </Col>
                    <Container>
                        <Row xs={1} sm={2} md={4} lg={4} className=" p-2 d-flex justify-content-between flex-wrap ">

                            <Col className=" ">
                                <Row className="p-2 ">
                                    <Col className="   shadow2-1 ">

                                        <Col lg={12} className="color-card position-relative">
                                            <img class="imagen-perfil" src={Encargado} id="encargado" alt="Encargado" />
                                            <div class="texto-perfil pt-3" id="encargado_">
                                                <h2>Encargado</h2>
                                                <p>
                                                    El encargado están facultado para acceder al sistema y revisar integralmente el desempeño académico del estudiante, incluyendo sus calificaciones, resultados de exámenes, autorizaciones para salir de la institución, llevar a cabo gestiones del centro educativo.
                                                </p>
                                            </div>
                                            <button class="btn-toggle"  onClick={() => toggleCard('encargado','encargado_')}>
                                                <i class="bi bi-eye"></i> {/* Ícono de Bootstrap */}
                                            </button>
                                        </Col>
                                        <Col lg={12} className="d-flex justify-content-center p-2  ">
                                            <Button className='btnAceptar p-1 px-3' data-bs-toggle="offcanvas" data-bs-target="#staticBackdropEncar" aria-controls="staticBackdrop" >
                                                Ingreso para Encargados
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>

                            </Col>

                            <Col className=" ">
                                <Row className="p-2 ">
                                    <Col className="  shadow2-1 ">

                                        <Col lg={12} className="color-card position-relative" >
                                            <img class="imagen-perfil" id="estudiante" src={Estudiante} alt="Encargado" />
                                            <div class="texto-perfil pt-3" id="estudiante_">
                                                <h2>Estudiante</h2>
                                                <p>
                                                    El encargado están facultado para acceder al sistema y revisar integralmente el desempeño académico del estudiante, incluyendo sus calificaciones, resultados de exámenes, autorizaciones para salir de la institución, llevar a cabo gestiones del centro educativo.
                                                </p>
                                            </div>
                                            <button class="btn-toggle"  onClick={() => toggleCard('estudiante','estudiante_')}>
                                                <i class="bi bi-eye"></i> {/* Ícono de Bootstrap */}
                                            </button>
                                        </Col>
                                        <Col lg={12} className="d-flex justify-content-center p-2">
                                            <Button className='btnAceptar p-1 px-3' data-bs-toggle="offcanvas" data-bs-target="#staticBackdropEstu" aria-controls="staticBackdrop" >
                                                Ingreso para Estudiantes
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>

                            </Col>

                            <Col className=" ">
                                <Row className="p-2 ">
                                    <Col className="  shadow2-1 ">

                                        <Col lg={12} className="color-card " >
                                            <img class="imagen-perfil" id="profesor" src={Profesor} alt="Encargado" />
                                            <div class="texto-perfil pt-3" id="profesor_">
                                                <h2>Profesor</h2>
                                                <p>
                                                    Los docentes administran los grupos, lo que les permite llevar a cabo evaluaciones, establecer el calendario académico, revisar calificaciones, realizar reportes al hogar y gestionar la asistencia de manera eficiente y efectiva.
                                                </p>
                                            </div>
                                            <button class="btn-toggle"  onClick={() => toggleCard('profesor','profesor_')}>
                                                <i class="bi bi-eye"></i> {/* Ícono de Bootstrap */}
                                            </button>
                                        </Col>
                                        <Col lg={12} className="d-flex justify-content-center p-2">
                                            <Button className='btnAceptar p-1 px-3' data-bs-toggle="offcanvas" data-bs-target="#staticBackdropDocente" aria-controls="staticBackdrop" >
                                                {/* data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" */}
                                                Ingreso para Docente
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>

                            </Col>

                            <Col className=" ">
                                <Row className="p-2 ">
                                    <Col className="  shadow2-1 ">

                                        <Col lg={12} className="color-card" >
                                            <img class="imagen-perfil" id="administrativo" src={Administrativo} alt="Encargado" />
                                            <div class="texto-perfil pt-3" id="administrativo_">
                                                <h2>Administrativo</h2>
                                                <p>
                                                    Los administradores personalizan la plataforma según las necesidades específicas de la institución, gestionando tanto los procesos administrativos como los educativos, como el proceso de admisión y matrícula.
                                                </p>
                                            </div>
                                            <button class="btn-toggle"  onClick={() => toggleCard('administrativo','administrativo_')}>
                                                <i class="bi bi-eye"></i> {/* Ícono de Bootstrap */}
                                            </button>
                                        </Col>
                                        <Col lg={12} className="d-flex justify-content-center p-2">
                                            <Button className='btnAceptar p-1 px-3' data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" >
                                                {/* data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" */}
                                                Ingreso para Administrativos
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                    </Container>
                    {/* <div className="row d-flex justify-content-between flex-wrap contenedor-perfiles">
                    <div className="col-lg-3 py-5 mr-lg-1 row-perfil">

                        <div className="card-perfil border">

                            <div class="imagen-perfil">
                                <img src={Encargado} alt="Encargado" />
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button className='btnAceptar p-1 px-3'>
                                    iniciar
                                </Button>
                            </div>
                             <div class="texto-perfil pt-3">
                                        <h2>Encargado</h2>
                                        <p>
                                            El encargado están facultado para acceder al sistema y revisar integralmente el desempeño académico del estudiante, incluyendo sus calificaciones, resultados de exámenes, autorizaciones para salir de la institución, llevar a cabo gestiones del centro educativo.
                                        </p>
                                    </div> 
                        </div>



                    </div>
                    <div className="col-lg-3 py-5 mr-lg-1 row-perfil">
                        <div className="card-perfil">
                            <div class="imagen-perfil">
                                <img src={Estudiante} alt="Encargado" />
                            </div>
                            <div class="texto-perfil pt-3">
                                <h2>Estudiante</h2>
                                <p>
                                    El encargado están facultado para acceder al sistema y revisar integralmente el desempeño académico del estudiante, incluyendo sus calificaciones, resultados de exámenes, autorizaciones para salir de la institución, llevar a cabo gestiones del centro educativo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 py-5 mr-lg-1 row-perfil">
                        <div className="card-perfil">
                            <div class="imagen-perfil">
                                <img src={Profesor} alt="Profesor" />
                            </div>

                            <div class="texto-perfil pt-3">
                                <h2>Profesor</h2>
                                <p>
                                    Los docentes administran los grupos, lo que les permite llevar a cabo evaluaciones, establecer el calendario académico, revisar calificaciones, realizar reportes al hogar y gestionar la asistencia de manera eficiente y efectiva.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 py-5 row-perfil">
                        <div className="card-perfil">
                            <div class="imagen-perfil">
                                <img src={Administrativo} alt="Administrativo" />
                            </div>
                            <div class="texto-perfil pt-3">
                                <h2>Administrativo</h2>
                                <p>
                                    Los administradores personalizan la plataforma según las necesidades específicas de la institución, gestionando tanto los procesos administrativos como los educativos, como el proceso de admisión y matrícula.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                </Container>
            </section>


            <div className=" container-fluid contenedor-fluido" id="sobreNosotros">
                <div className="row container-tittle">
                    <h2>Sobre Nostros</h2>
                    <Barra />
                </div>
                <div className="row div-nosotros-content mt-5">
                    <p>
                        SIGAE es una plataforma virtual de alto nivel tanto para uso administrativo como estudiantil, que facilita el establecimiento de objetivos tanto para la institución como para el estudiante. Estamos transformando la experiencia educativa.
                    </p>
                    <ul>
                        <li>Acceso fácil y conveniente</li>
                        <li>Facilitación de trámites educativos</li>
                        <li>Mayor comunicación y colaboración</li>
                        <li>Participación activa de los padres</li>
                        <li>Transparencia y claridad</li>
                        <li>Acceso único para estudiantes y padres</li>
                    </ul>
                </div>
            </div>




            <div className=" container-fluid contenedor-fluido" id="soporte">
                <div className="row container-tittle">
                    <h2>Soporte 24/7</h2>
                    <Barra />
                </div>
                <div className="row div-nosotros-content">
                    <p className="text-center mt-5">
                        SIGAE cuenta con un equipo de soporte técnico que se encuentra permanentemente disponible para brindarte asistencia, todos los días y en cualquier momento.
                    </p>
                </div>
            </div>

            <div className="contenedor" id="contacto">
                <div className="row container-tittle">
                    <h2>Contacto</h2>
                    <Barra />
                    <p className="text-center mt-5 p-content">
                        Cuéntenos sus necesidades y nosotros le proporcionaremos la solución óptima para su institución. Póngase en contacto con nosotros para comenzar.
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-4 py-5 mr-lg-3">
                        <div className="card-contacto">
                            <div className="container-fluid card-contacto-info">

                                <div className="row pb-3">
                                    <h4><i class="bi bi-geo-alt-fill fs-5 mr-3 text-primary"></i> Ubicación</h4>
                                    <p>Upala, Upala, Alajuela, 75 mts Sur del juzgado penal.</p>
                                </div>

                                <div className="row pb-3">
                                    <h4><i class="bi bi-envelope-at-fill fs-6  mr-3 text-primary px-1"></i>Correo Electrónico</h4>
                                    <a href="mailto:info@jyjtechsolutions.com" className="text-Enlaces">
                                        <p>info@sigaeapp.com</p>
                                    </a>
                                </div>
                                <div className="row pb-3">
                                    <h4><i class="bi bi-telephone-fill fs-6 mr-3 text-primary px-1"></i>Telefóno</h4>
                                    <a href="tel:+50672120281" className="   text-Enlaces" >
                                        <p>(+506) 72120281</p>
                                    </a>
                                </div>
                                <div class="row">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31342.861265935495!2d-85.03673345470096!3d10.898420535415399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f75498160737249%3A0x6da06feef33a6933!2sProvincia%20de%20Alajuela%2C%20Upala!5e0!3m2!1ses-419!2scr!4v1711996888224!5m2!1ses-419!2scr"
                                        width="400"
                                        height="300"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Mapa de Alajuela, Upala"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 py-5">
                        <div className="card-contacto pb-5">
                            <ContactForm />
                        </div>
                    </div>
                </div>

            </div>

            <footer className="pb-2 home-footer   text-black  ">
                <svg className="curveDownColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
                </svg>
                <div className="container-fluid  pt-51">
                    <div className="container-fluid">
                        <div className="row  text-white ">
                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row align-items-center">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <a className="navbar-brands footer-app-name" href="#">
                                            SIGÄE
                                        </a>
                                    </div>
                                    <div className="col-12 mt-2 d-flex justify-content-center ">
                                        <ul className="list-inline">
                                            <li className="mt-2"><a href="" className="text-uppercase   text-Enlaces">
                                                <i class="bi bi-geo-alt-fill mr-2 text-primary"></i>  Costa Rica
                                            </a></li>

                                            <li className="mt-2"><a href="tel:+50670943873" className="   text-Enlaces" style={{ textDecoration: 'none' }}>
                                                <i class="bi bi-telephone-fill mr-2 text-primary px-1"></i>
                                                <span className="span-negrita">Teléfono: </span>
                                                (+506) 7094 3873
                                            </a></li>

                                            <li className="mt-2 mb-2"><a href="mailto:info@jyjtechsolutions.com" className="enlace text-Enlaces"
                                                style={{ textDecoration: 'none' }}>
                                                <i class="bi bi-envelope-at-fill  mr-2 text-primary px-1"></i>
                                                <span className="span-negrita">Correo: </span>
                                                info@sigaeapp.com
                                            </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row ">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <h4 className="footer-tittle">Mapa del Sitio</h4>
                                    </div>
                                    <div className="col-12 mt-2 d-flex justify-content-center ">
                                        <ul className="list-inline">
                                            <li className="mt-2"><a href="#" className="text-Enlaces">Inicio</a></li>
                                            <li className="mt-2"><a href="#sobreNosotros" className="text-Enlaces">Sobre Nosotros</a></li>
                                            <li className="mt-2"><a href="#soporte" className="text-Enlaces">Soporte</a></li>
                                            <li className="mt-2"><a href="#perfiles" className="text-Enlaces">Perfiles</a></li>
                                            <li className="mt-2 mb-2"><a href="#contacto" className="text-Enlaces">Contacto</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row ">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <h4 className="footer-tittle">Términos y Condiciones</h4>
                                    </div>
                                </div>
                                <div className="col-12 mt-2 d-flex justify-content-center ">
                                    <ul className="list-inline">
                                        <li className="mt-2"><a href="#" className="text-Enlaces">Términos y Condiciones</a></li>
                                        <li className="mt-2"><a href="#" className="text-Enlaces">Políticas</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row ">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <h4 className="footer-tittle">Nuestras Redes Sociales</h4>
                                    </div>
                                    <div className="col-12 mt-2 d-flex justify-content-center icon-footer-container">

                                        <a href="https://api.whatsapp.com/send?phone=+50670943873&amp;text=Hola,%20quiero%20información%20sobre%20sus%20productos%20" className="whatsapp  px-1" >
                                            <i class="bi bi-whatsapp fs-4"></i>
                                        </a>
                                        <a href="https://www.instagram.com/jyj_techsolutions?igsh=MTJseWtmZWh4YjFiNg==" className="instagram px-1">
                                            <i class="bi bi-instagram fs-4"></i>
                                        </a>
                                        <a href="https://www.facebook.com/profile.php?id=100069400132225&locale=es_LA" className="facebook px-1">
                                            <i class="bi bi-facebook fs-4"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="div-end-footer">
                <div className="col-12 d-flex justify-content-center mt-3 fz-20 text-center d-flex">
                    <p className="text-white text-center">© 2024 All Rights Reserved. Designed by <a href="https://www.jyjtechsolutions.com"><img width="155" height="15" src={Logo} alt="J&J TechSolutions" /></a></p>
                </div>
            </div>

            <button className="btn-subir" onClick={scrollToTop} style={{ display: isVisible ? 'block' : 'none', }}>
                <i className="bi bi-arrow-up "></i>
            </button>

            {/* <Modal size='md' fullscreen='lg' isOpen={modalOpen} className='mt-5' >
                <ModalHeader className='color-header-form px-3 py-2' toggle={ocultarModalAdministrativo}>
                    Seleccionar Institución
                </ModalHeader>
                <ModalBody>
                <Form onSubmit={InicioSecionAdministrativo}>
                        <Col  >
                            <Col className='custom-form-group'>
                                <Label for="Nacionalidad">
                                    Seleccione la institucion a la que desea ingresar
                                </Label>
                                <Input
                                    required
                                    id="Nacionalidad"
                                    name="Nacionalidad"
                                    className='py-1'
                                    type="select"
                                    value={institucionSeleccionada}
                                    onChange={(e) => setInstitucionSeleccionada(e.target.value)}
                                >

                                    {instituciones.map((inst, index) => (
                                        <option key={inst.id} value={inst.id}  >{inst.nombre}</option>
                                    ))}

                                </Input>
                            </Col>
                        </Col>
                        <Col className='d-flex justify-content-between mt-3'>
                            <Button className=' p-1     btnCancelar px-3' onClick={ocultarModalAdministrativo}>
                                Cancelar
                            </Button>
                            <Button type='submit' className='btnAceptar p-1 px-3'  >
                                Ingresar
                            </Button>
                        </Col>

                    </Form>


                </ModalBody >
            </Modal > */}

        </Container >


    );
}
export default Login;
