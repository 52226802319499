import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import * as Pi from "react-icons/pi";
import * as Gi from "react-icons/gi";
import * as Fc from "react-icons/fc";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import MenuItem from "./MenuItem";
import { Roll } from "../../CapaUtilidades/Utils/Cahe";
import { Button } from "reactstrap";
import { BsArrowsFullscreen } from "react-icons/bs";
import { AiOutlineFullscreenExit } from "react-icons/ai";


/**
 * Componente Sidebar menu lateral
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.isOpen - Indica si la barra lateral está abierta.
 * @param {function} props.toggleSidebar - Función para alternar la visibilidad de la barra lateral.
 * @returns {JSX.Element} Componente Sidebar.
 */
const SidebarPadres = ({ isOpen, toggleSidebar }) => {





  const [activeSubMenu, setActiveSubMenu] = useState(null);

  /**
  * Maneja el clic en un elemento de menú para alternar la visibilidad del submenú correspondiente.
  * @param {string} menuId - Identificador del menú.
  */
  const handleNavLinkClick = (menuId) => {
    setActiveSubMenu(activeSubMenu === menuId ? null : menuId);
  };

  const handleSidebarItemClick = () => {
    if (window.innerWidth <= 1200) {
      toggleSidebar();
    }
    setActiveSubMenu(null);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.error('Error al intentar activar la pantalla completa:', err);
      });
    } else {
      document.exitFullscreen();
    }
  };
  return (
    <div className={`sidebar ${isOpen ? "" : "hidden"}`}>
      
        {/* <Button title="Pantalla Completa" onClick={toggleFullscreen} className= 'btnFullScreen'>
          {isFullscreen ? "Esc"  :<BsArrowsFullscreen className=" sizeIcon"/>}
        </Button> */}

    
      <ul>
        <MenuItem
          icon={<Fa.FaDesktop className="me-1" />}
          text="Dashboard"
          onClick={handleSidebarItemClick}
          to="/Menu/"
          classname={'text-sidebar'}
        />

        <MenuItem //Gestion Estudiantes
          icon={<Pi.PiStudentFill className="me-1 sizeIcon" />}
          text="Gestion Estudiantes"
          subMenuItems={[
            { to: "/Menu/GestionEstudiante", text: "opc1", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc2", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc3", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc4", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc5", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionEstudiantes"}
          onClick={() => handleNavLinkClick("gestionEstudiantes")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar'}
        />






      </ul>
    </div>
  );
};

export default SidebarPadres;
