import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;




export async function getAsignaturas(data) {
    url = endpoint.urlGetAsignaturas;
    let CodigoPresupuestario = obtenerIdInstitucion()
    const data2 = { codigoPresupuestario: CodigoPresupuestario, estado : data }; 
    if (!url || !data2) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data2),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const AsignaturasResponse = await response.json();

      
        return AsignaturasResponse; // Devolvemos los datos obtenidos

    } catch (error) {
        console.log(error.message);
        return { estado: 0 };
    }
}


/**
 * Metodo para guardar las Asignaturas en la base de datos
 * @param {*} data Datos a guardar en un arreglo sencillo 
 * @returns retorna true si el guardado fue exitoso false si no se puedo guardar
 */
export async function saveAsignatura(data) {

    url = endpoint.urlSaveAsignatura;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            SuccessMessage('Exitoso', 'Asignatura registrada exitosamente');
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
        return true;

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}


export async function deleteAsignatura(data) {

    url = endpoint.urlDeleteAsignatura;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            SuccessMessage('Exitoso', 'Asignatura Eliminada exitosamente');
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
        return true;

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function RestoreAsignatura(data) {

    url = endpoint.urlDeleteAsignatura;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            SuccessMessage('Exitoso', 'Asignatura Eliminada exitosamente');
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
        return true;

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function updateAsignatura(data) {

    url = endpoint.urlUpdateAsignatura;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            SuccessMessage('Exitoso', 'Asignatura registrada exitosamente');
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
        return true;

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}