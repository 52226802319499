import { useState } from 'react';


/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbFuncionario
 */
export const TbFuncionario = () => {
    const [cedula, setCedula] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido1, setapellido1] = useState('');
    const [apellido2, setapellido2] = useState('');
    const [cargo, setcargo] = useState('');
    const [tipoNombramiento, setTipoNombramiento] = useState('');
    const [pais, setPais] = useState('');
    const [selectedProvinciaFuncionario, setSelectedProvinciaFuncionario] = useState('');
    const [selectedCantonFuncionario, setSelectedCantonFuncionario] = useState('');
    const [selectedDistritoFuncionario, setSelectedDistritoFuncionario] = useState('');
    const [selectedBarrioFuncionario, setSelectedBarrioFuncionario] = useState('');
    const [DireccionFuncionario, setDireccionFuncionario] = useState('');
    const [telefonoFunPrincipal, setTelefonoFunPrincipal] = useState('');
    const [telefonoFunSecundario, setTelefonoFunSecundario] = useState('');
    const [correo, setCorreo] = useState('');
    const [fotoPerfil, setfotoPerfil] = useState(null);
    const [sexo, setSexo] = useState('');
    const [idNombramiento, setIdNombromiento] = useState();
    const [fechaIngreso, setFechaIngreso] = useState('');
    const [fechaSalida, setFechaSalida] = useState('');
    const [Observaciones, setObservaciones] = useState('');

    const [ListaPaises, setListaPaises] = useState([]);
    const [ListaProvincias, setListaProvincias] = useState([]);
    const [ListaCantones, setListaCantones] = useState([]);
    const [ListaDistritos, setListaDistritos] = useState([]);
    const [ListaBarrios, setListaBarrios] = useState([]);
    const [ListaCargos, setListaCargos] = useState([]);
    const [ListaNombramientos, setListaNombramientos] = useState([]);
    const [ListaRoles, setListaRoles] = useState([]);
    const [ListaTipoDereccion, setListaTipoDereccion] = useState([]);
    const [ListaSexo, setListaSexo] = useState([]);
    const [ListaTipoInstitucion, setListaTipoInstitucion] = useState([]);
    const [progressColor, setProgressColor] = useState('');
    const [levelMessage, setLevelMessage] = useState('');

    let CodigoPresupuestario;
    const [loading, setLoading] = useState(true);
    const [funcionarios, setFuncionarios] = useState([]);
    const [Asignaturas, setAsignaturas] = useState([]);
    const [Seleccionadas, setSeleccionadas] = useState([]);
    const [Seleccionadas2, setSeleccionadas2] = useState([]);
    const [ListaAsigDocente, setListaAsigDocente] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [openAC, setOpenAC] = useState('');
    const mensaje = "Notificacion del Sistema"
    const error = "Lo sentimos, no existen funcionarios registrados activos en esta institucion ú ocurrio un error en el servidor."
    const currentDate = new Date(); // Obtiene la fecha actual
    const currentYear = currentDate.getFullYear(); // Obtiene el año actual
    const [NombreInst, setNombreInst] = useState('');
    const [openA, setOpenA] = useState('1');

    const [ListaRegionales, setListaRegionales] = useState([]);
    const [DatosPerfil, setDatosPerfil] = useState([]);

    const [codigoValido, setCodigoValido] = useState(true); // Estado para controlar la validez del codigo
    const [telefonoValido, setTelefonoValido] = useState(true); // Estado para controlar la validez del número de teléfono
    const [directorValido, setdirectorValido] = useState(true); // Estado para controlar que sean solo letras
    const [nombreValido, setNombreValido] = useState(true); // Estado para controlar el nombre que sean solo letras
    const [apellido1Valido, setapellido1Valido] = useState(true); // Estado para controlar el primer apellido que sean solo letras
    const [apellido2Valido, setapellido2Valido] = useState(true); // Estado para controlar el primer apellido que sean solo letras
    const [telefonoFunPrincipalValido, setTelefonoFunPrincipalvalido] = useState(true); // Estado para controlar este
    const [telefonoFunSecundarioValido, setTelefonoFunSecundariovalido] = useState(true); // Estado para controlar este
    const [correoValido, setcorreoValido] = useState(true); // Estado para controlar este
    const [cedulaValida, setcedulaValidad] = useState(true);
    const [mostrarContrasena, setMostrarContrasena] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    return {
        cedula, setCedula,
        nombre, setNombre,
        apellido1, setapellido1,
        apellido2, setapellido2,
        cargo, setcargo,
        tipoNombramiento, setTipoNombramiento,
        telefonoFunPrincipal, setTelefonoFunPrincipal,
        telefonoFunSecundario, setTelefonoFunSecundario,
        correo, setCorreo,
        DireccionFuncionario, setDireccionFuncionario,
        fotoPerfil, setfotoPerfil,
        pais, setPais,
        selectedProvinciaFuncionario, setSelectedProvinciaFuncionario,
        selectedCantonFuncionario, setSelectedCantonFuncionario,
        selectedDistritoFuncionario, setSelectedDistritoFuncionario,
        selectedBarrioFuncionario, setSelectedBarrioFuncionario,
        sexo, setSexo,
        idNombramiento, setIdNombromiento,
        fechaIngreso, setFechaIngreso,
        fechaSalida, setFechaSalida,
        Observaciones, setObservaciones,

        ListaPaises, setListaPaises,
        ListaProvincias, setListaProvincias,
        ListaCantones, setListaCantones,
        ListaDistritos, setListaDistritos,
        ListaBarrios, setListaBarrios,
        ListaCargos, setListaCargos,
        ListaNombramientos, setListaNombramientos,
        ListaRoles, setListaRoles,
        ListaTipoDereccion, setListaTipoDereccion,
        ListaSexo, setListaSexo,
        ListaTipoInstitucion, setListaTipoInstitucion,
        progressColor, setProgressColor,
        levelMessage, setLevelMessage,

        selectedProvinciaFuncionario, setSelectedProvinciaFuncionario,
        selectedCantonFuncionario, setSelectedCantonFuncionario,

        ListaRegionales, setListaRegionales,
        DatosPerfil, setDatosPerfil,
        open, setOpen,
        open2, setOpen2,
        open3, setOpen3,
        openAC, setOpenAC,
        mensaje,
        error,
        CodigoPresupuestario,
        loading, setLoading,
        funcionarios, setFuncionarios,
        Asignaturas, setAsignaturas,
        Seleccionadas, setSeleccionadas,
        Seleccionadas2, setSeleccionadas2,
        ListaAsigDocente, setListaAsigDocente,
        NombreInst, setNombreInst,
        openA, setOpenA,
        currentYear,

        codigoValido, setCodigoValido,
        telefonoValido, setTelefonoValido,
        directorValido, setdirectorValido,
        nombreValido, setNombreValido,
        apellido1Valido, setapellido1Valido,
        apellido2Valido, setapellido2Valido,
        telefonoFunPrincipalValido, setTelefonoFunPrincipalvalido,
        telefonoFunSecundarioValido, setTelefonoFunSecundariovalido,
        correoValido, setcorreoValido,
        cedulaValida, setcedulaValidad,
        mostrarContrasena, setMostrarContrasena,
        selectedImage, setSelectedImage,
        selectedLogo, setSelectedLogo,
        isVisible, setIsVisible,
    };
};