import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion, obtenerUsuario } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;



/**
 * Metodo para guardar la institucion en la base de datos
 * @param {*} data Datos a guardar en un arreglo sencillo 
 * @returns retorna true si el guardado fue exitoso false si no se puedo guardar
 */
export async function saveUser(data) {
    
    url = endpoint.urlSaveUser;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

        console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            SuccessMessage('Exitoso', 'El Funcionario a sido registrado exitosamente');
            return { estado: responseData.estado};

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            return { estado: responseData.estado};
        }
        

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

/**
 * Funcion para actualizar la institucion
 * @param {*} data Arreglo con los datos en formato JSON para realizar la actualizacion de datos
 * @returns retorna true su es correcta y false si no se realizar la actualizacion
 */
export const UpdateUser = async (data) => {
    url = endpoint.urlUpdateUser;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return { estado: false, mensaje: 'URL o datos no especificados.' };
   }

   try {
        //console.log(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }
       const responseData = await response.json();
       if (responseData.estado) {
           SuccessMessage('Exitoso', responseData.mensaje);
           return { estado: responseData.estado, mensaje: responseData.mensaje };

       }
       else {
           ErrorMessage('Error inesperado', responseData.mensaje);
           estado = responseData.estado
       }

   } catch (error) {

       return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la actualizacizar el usuario' };
   }
};


/**
 * Funcion para elimiar la Institucion
 * @param {*} data parametro para realizar la eliminacion en formato JSON {codigoPresupestario: "string", estado:0} 
 * @returns retorna true su es correcta y false si no se pudo eliminar
 */
export async function  DeleteUser(data) {
     url = endpoint.urlDeleteUser;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');

    }

    try {
        console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        if (responseData.estado) {
            //SuccessMessage('Exitoso', responseData.mensaje);
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }

    } catch (error) {

        return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la eliminacion del usuario ' };
    }
}


/**
 * Funcion para elimiar la Institucion
 * @param {*} data parametro para realizar la eliminacion en formato JSON {codigoPresupestario: "string", estado:0} 
 * @returns retorna true su es correcta y false si no se pudo eliminar
 */
export async function  RestoreUser(data) {
    url = endpoint.urlDeleteUser;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');

   }

   try {
       console.log(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),

       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
       if (responseData.estado) {
           //SuccessMessage('Exitoso', responseData.mensaje);
           return { estado: responseData.estado, mensaje: responseData.mensaje };

       }
       else {
           ErrorMessage('Error inesperado', responseData.mensaje);
           estado = responseData.estado
       }

   } catch (error) {

       return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la eliminacion del usuario ' };
   }
}



export async function getDatosUsuario() {

    const CodigoPresupuestario = obtenerIdInstitucion();
    

    const IDusuario = obtenerUsuario();

    const url = endpoint.urlGetUser;

    const data = {usuario:IDusuario, idInstitucion: CodigoPresupuestario};

    if (!url) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const Datos = await response.json();
        return Datos;
        //console.log(JSON.stringify(Datos));
        if (Datos && Datos.length > 0) {
            
            
            return Datos; // Devolvemos el nombre de la institución obtenido
        } else {
            console.log('No se encontraron resultados del Usuario.');
            return { estado: 0 };
        }
        
       

        


       
    } catch (error) {
        
        return { estado: 0 };
    }
}

export async function ExisteUsuario(data) {
    
    url = endpoint.urlExisteUser;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       
       return {estado: estado, mensaje:responseData.mensaje};

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}