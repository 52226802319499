const regionalesJson = require('../../CapaPresentacion/Json/DireccionesRegionales.json');

class Regional {
    constructor(id, direccionRegional, circuitos) {
      this.id = id;
      this.direccionRegional = direccionRegional;
      this.circuitos = circuitos;
    }
  }
  
  class Circuito {
    constructor(id, nombreCircuito) {
      this.id = id;
      this.nombreCircuito = nombreCircuito;
    }
  }
  
  class RegionalService {
    async getRegionales() {
        const regionales = [];
  
        for (const [regionalId, regionalData] of Object.entries(regionalesJson)) {
            const direccionRegional = regionalData.DireccionRegional;
            const regional = new Regional(parseInt(regionalId), direccionRegional);
           
            regionales.push(regional);
        }
      
        return regionales;
    }

    async getCircuitosByRegional(regionalId) {
        
        const regionalData = regionalesJson[regionalId.toString()];
        const circuitos = [];
    
        if (regionalData) {
          for (const [circuitoId, nombreCircuito] of Object.entries(regionalData.Circuitos)) {
            const circuito = new Circuito(parseInt(circuitoId), nombreCircuito.NombreCircuito);//
            circuitos.push(circuito);
          }
        }
    
        return circuitos;
      }
  }
  
  module.exports = RegionalService;