import React, { useState, useEffect, useCallback } from 'react';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, Table, ModalHeader, Spinner, InputGroup, InputGroupText, Tooltip } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Md from "react-icons/md";
import * as Io from "react-icons/io";
import * as  Fa from "react-icons/fa";
import * as Fa6 from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { TbEvaluaciones } from '../../../../CapaEntidades/TbEvaluaciones'
import { GetGrados, GetRubrosEvaluativos, GetTrimestres } from '../../../../CapaDatos/DatosCargarSelects';
import { TbAsistencia } from '../../../../CapaEntidades/TbAsistencia';
import { obtenerIdInstitucion, obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { getSeccionesDocente } from '../../../../CapaDatos/DatosSeccionesDocente';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { getTipoInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { getAsignaturasDocente } from '../../../../CapaDatos/DatosAsigDocentes';
import Swal from 'sweetalert2';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { ActualizarRubroEvaluacion, EliminarRubroEvaluacion, ExisteRubroEvaluacion, ListarRubrosEvaluaciones, RegistrarRubroEvaluacion } from '../../../../CapaDatos/DatosDocentes/DatosCrearEvaluaciones';

const FrmCrearEvaluaciones = () => {

  const TBEvaluaciones = TbEvaluaciones();
  const TBAsistencia = TbAsistencia();
  const TBSecciones = TbSecciones();
  const TBFUncionario = TbFuncionario();

  const ObtenerListaSeccionesDelDocente = async () => {
    try {

      const parametros = {
        profesor: obtenerUsuario(),
        annio: new Date().getFullYear().toString(),
        institucion: obtenerIdInstitucion(),
        estado: 1
      }

      const data = await getSeccionesDocente(parametros);
      //alert(JSON.stringify(data))
      // Validar si data está vacío
      if (data.length === 0) {
        TBSecciones.setExistenSecciones(false)
        TBSecciones.setListaSeccionesDocente([]); // Puedes asignar un array vacío o manejar el caso de otra forma
      } else {
        TBSecciones.setExistenSecciones(true)
        TBSecciones.setListaSeccionesDocente(data);
      }

      //console.log(JSON.stringify(data));

      const parametro = { codigoPresupuestario: obtenerIdInstitucion() };
      const tipoIns = await getTipoInstitucion(parametro);
      TBAsistencia.setTipoInstitucion(tipoIns);



      TBAsistencia.setLoading(false);
    } catch (error) {
      TBAsistencia.setLoading(false);
    }
  };

  const obtenerAsignaturasDocente = async () => {
    const param = {
      docente: obtenerUsuario(),
      annio: TBFUncionario.currentYear.toString(),
      institucion: obtenerIdInstitucion(),
      estado: 1
    }

    const lista = await getAsignaturasDocente(param)
    //console.log(JSON.stringify(lista));
    TBFUncionario.setListaAsigDocente(lista);

    const primerCodigoAsignatura = lista[0].codigoAsignatura;
    //alert(primerCodigoAsignatura)
    // Llamar a ObtenerListaEstudiantes con el primer valor de codigoAsignatura
    //ObtenerListaEstudiantes(primerCodigoAsignatura);
    TBAsistencia.setAsignatura(primerCodigoAsignatura)

  };


  useEffect(() => {
    ObtenerListaSeccionesDelDocente();
    obtenerAsignaturasDocente();

    GetGrados(TBAsistencia.setListaGrados);

  }, []);

  const GradosFiltrados = TBAsistencia.ListaGrados.filter(grado => {
    // Verificar si la lista de secciones del docente está vacía
    if (!TBSecciones.listaSeccionesDocente || TBSecciones.listaSeccionesDocente.length === 0) {
      //console.log("Actualmente no hay secciones registradas en el sistema. para el docente.");
      return false; // O manejarlo de otra forma si lo prefieres
    }

    // Extraer los niveles de TBSecciones.listaSeccionesDocente
    const nivelesDocente = TBSecciones.listaSeccionesDocente.map(seccion => seccion.nivel);

    if (TBAsistencia.tipoInstitucion === 1) {
      // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
      return grado.id >= -1 && grado.id <= 6 && nivelesDocente.includes(grado.id);
    } else if (TBAsistencia.tipoInstitucion === 2) {
      // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
      return grado.id >= 7 && grado.id <= 12 && nivelesDocente.includes(grado.id);
    }

    return false;
  });

  useEffect(() => {

    GetTrimestres(TBEvaluaciones.setListaTrimestres)
    GetRubrosEvaluativos(TBEvaluaciones.setRubrosCalificacion)
    //console.log(JSON.stringify(TBEvaluaciones.rubrosCalificacion));
  }, []);


    //  =========================================   Funciones para Mostrar los panel de Registrar ===========================================

  const mostrarPanelRegisExamen = () => {
    TBEvaluaciones.setNombreExamen('');
    TBEvaluaciones.setValorEnPuntos(0);
    TBEvaluaciones.setValorEnPorcentaje(0);
    TBEvaluaciones.setEditar(false)

    TBEvaluaciones.setVerPanelExamenes(!TBEvaluaciones.verPanelExamenes)
    TBEvaluaciones.setMostrarBtnRegistrar(!TBEvaluaciones.mostrarBtnRegistrar)

  }

  const mostrarPanelRegisTareas = () => {
    TBEvaluaciones.setNombreExamen('');
    TBEvaluaciones.setValorEnPuntos(0);
    TBEvaluaciones.setValorEnPorcentaje(0);
    TBEvaluaciones.setEditarTarea(false)
    TBEvaluaciones.setVerPanelTareas(!TBEvaluaciones.verPanelTareas)
    TBEvaluaciones.setMostrarBtnRegistrarTarea(!TBEvaluaciones.mostrarBtnRegistrarTarea)

  }

  const mostrarPanelRegisCotidiano = () => {
    TBEvaluaciones.setNombreExamen('');
    TBEvaluaciones.setValorEnPuntos(0);
    TBEvaluaciones.setValorEnPorcentaje(0);
    TBEvaluaciones.setEditarCotidiano(false)
    TBEvaluaciones.setVerPanelCotidiano(!TBEvaluaciones.verPanelCotidiano)
    TBEvaluaciones.setMostrarBtnRegistrarCotidiano(!TBEvaluaciones.mostrarBtnRegistrarCotidiano)

  }

  const mostrarPanelRegisAsistencia = () => {
    TBEvaluaciones.setNombreExamen('');
    TBEvaluaciones.setValorEnPuntos(0);
    TBEvaluaciones.setValorEnPorcentaje(0);
    TBEvaluaciones.setEditarAsistencia(false)
    TBEvaluaciones.setVerPanelAsistencia(!TBEvaluaciones.verPanelAsistencia)
    TBEvaluaciones.setMostrarBtnRegistrarAsistencia(!TBEvaluaciones.mostrarBtnRegistrarAsistencia)

  }

    //  =========================================   Funciones para Mostrar los panel de Editar ===========================================

  const mostrarPanelParaEditar = (codigo, examen, puntos, porcentaje) => {

    TBEvaluaciones.setCodigo(parseInt(codigo));
    TBEvaluaciones.setNombreExamen(examen);
    TBEvaluaciones.setValorEnPuntos(puntos);
    TBEvaluaciones.setValorEnPorcentaje(porcentaje);
    TBEvaluaciones.setPorsentajeA_Editar(porcentaje)

    if (TBEvaluaciones.verPanelExamenes) {
      if (!TBEvaluaciones.editar) {
        //ActualizarRugro();
        TBEvaluaciones.setEditar(!TBEvaluaciones.editar)
        TBEvaluaciones.setMostrarBtnRegistrar(false)

      } else {
        //ActualizarRugro();
        TBEvaluaciones.setMostrarBtnRegistrar(false)
      }

    } else {
      //ActualizarRugro();
      TBEvaluaciones.setEditar(!TBEvaluaciones.editar)
      TBEvaluaciones.setVerPanelExamenes(!TBEvaluaciones.verPanelExamenes)
      TBEvaluaciones.setMostrarBtnRegistrar(false)
    }
  };

  const mostrarPanelParaEditarTareas = (codigo, examen, puntos, porcentaje) => {

    TBEvaluaciones.setCodigo(parseInt(codigo));
    TBEvaluaciones.setNombreExamen(examen);
    TBEvaluaciones.setValorEnPuntos(puntos);
    TBEvaluaciones.setValorEnPorcentaje(porcentaje);
    TBEvaluaciones.setPorsentajeA_Editar(porcentaje)

    if (TBEvaluaciones.verPanelTareas) {
      if (!TBEvaluaciones.editarTarea) {
        //ActualizarRugro();
        TBEvaluaciones.setEditarTarea(!TBEvaluaciones.editarTarea)
        TBEvaluaciones.setMostrarBtnRegistrarTarea(false)

      } else {
        //ActualizarRugro();
        TBEvaluaciones.setMostrarBtnRegistrarTarea(false)
      }

    } else {
      //ActualizarRugro();
      TBEvaluaciones.setEditarTarea(!TBEvaluaciones.editarTarea)
      TBEvaluaciones.setVerPanelTareas(!TBEvaluaciones.verPanelTareas)
      TBEvaluaciones.setMostrarBtnRegistrarTarea(false)
    }
  };

  const mostrarPanelParaEditarCotidiano = (codigo, examen, puntos, porcentaje) => {

    TBEvaluaciones.setCodigo(parseInt(codigo));
    TBEvaluaciones.setNombreExamen(examen);
    TBEvaluaciones.setValorEnPuntos(puntos);
    TBEvaluaciones.setValorEnPorcentaje(porcentaje);
    TBEvaluaciones.setPorsentajeA_Editar(porcentaje)

    if (TBEvaluaciones.verPanelCotidiano) {
      if (!TBEvaluaciones.editarCotidiano) {
        //ActualizarRugro();
        TBEvaluaciones.setEditarCotidiano(!TBEvaluaciones.editarCotidiano)
        TBEvaluaciones.setMostrarBtnRegistrarCotidiano(false)

      } else {
        //ActualizarRugro();
        TBEvaluaciones.setMostrarBtnRegistrarCotidiano(false)
      }

    } else {
      //ActualizarRugro();
      TBEvaluaciones.setEditarCotidiano(!TBEvaluaciones.editarCotidiano)
      TBEvaluaciones.setVerPanelCotidiano(!TBEvaluaciones.verPanelCotidiano)
      TBEvaluaciones.setMostrarBtnRegistrarCotidiano(false)
    }
  };

  const mostrarPanelParaEditarAsistencia = (codigo, examen, puntos, porcentaje) => {

    TBEvaluaciones.setCodigo(parseInt(codigo));
    TBEvaluaciones.setNombreExamen(examen);
    TBEvaluaciones.setValorEnPuntos(puntos);
    TBEvaluaciones.setValorEnPorcentaje(porcentaje);
    TBEvaluaciones.setPorsentajeA_Editar(porcentaje)

    if (TBEvaluaciones.verPanelAsistencia) {
      if (!TBEvaluaciones.editarAsistencia) {
        //ActualizarRugro();
        TBEvaluaciones.setEditarAsistencia(!TBEvaluaciones.editarAsistencia)
        TBEvaluaciones.setMostrarBtnRegistrarAsistencia(false)

      } else {
        //ActualizarRugro();
        TBEvaluaciones.setMostrarBtnRegistrarAsistencia(false)
      }

    } else {
      //ActualizarRugro();
      TBEvaluaciones.setEditarAsistencia(!TBEvaluaciones.editarAsistencia)
      TBEvaluaciones.setVerPanelAsistencia(!TBEvaluaciones.verPanelAsistencia)
      TBEvaluaciones.setMostrarBtnRegistrarAsistencia(false)
    }
  };


//  =================================================   Funciones para Guardar ==============================================================

  const GuardarRugro = async (event) => {

    event.preventDefault();

    const datosexiste =
    {
      nombre: TBEvaluaciones.nombreExamen,
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: TBEvaluaciones.grado,
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(),
      docente: obtenerUsuario()
    }

    const existe = await ExisteRubroEvaluacion(datosexiste)

    if (existe) {
      ErrorMessage('Alerta!', 'Ya existe un examen registrardo con ese mismo nombre, por favor cambie el nombre del rubro e intente de nuevo')
      return;
    }

    
    if (parseInt(TBEvaluaciones.valorEnPuntos) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPuntos)  < 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Puntos o Porsentaje no puede ser menor o igual a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual)
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = TotalPorcentajeActual + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const datos = {
      nombre: TBEvaluaciones.nombreExamen,
      valorPuntos: parseInt(TBEvaluaciones.valorEnPuntos),
      valorPorcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(), // Convertido a string
      docente: obtenerUsuario(),
      fechaCreada: new Date().toISOString().split('T')[0] // Fecha sin horas
    }

    const success = await RegistrarRubroEvaluacion(datos)

    if (success) {
      SuccessMessage('Exito!', 'El examen ha sido registrado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelExamenes(!TBEvaluaciones.verPanelExamenes)
      TBEvaluaciones.setMostrarBtnRegistrar(true)
    } else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se registro el examen')
    }

  }

  const GuardarRugroTarea = async (event) => {

    event.preventDefault();

    const datosexiste =
    {
      nombre: TBEvaluaciones.nombreExamen,
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: TBEvaluaciones.grado,
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(),
      docente: obtenerUsuario()
    }

    const existe = await ExisteRubroEvaluacion(datosexiste)

    if (existe) {
      ErrorMessage('Alerta!', 'Ya existe un examen registrardo con ese mismo nombre, por favor cambie el nombre del rubro e intente de nuevo')
      return;
    }

    if (parseInt(TBEvaluaciones.valorEnPuntos) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPuntos)  < 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Puntos o Porsentaje no puede ser menor o igual a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual)
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = TotalPorcentajeActual + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const datos = {
      nombre: TBEvaluaciones.nombreExamen,
      valorPuntos: parseInt(TBEvaluaciones.valorEnPuntos),
      valorPorcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(), // Convertido a string
      docente: obtenerUsuario(),
      fechaCreada: new Date().toISOString().split('T')[0] // Fecha sin horas
    }

    const success = await RegistrarRubroEvaluacion(datos)

    if (success) {
      SuccessMessage('Exito!', 'El examen ha sido registrado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelTareas(!TBEvaluaciones.verPanelTareas)
      TBEvaluaciones.setMostrarBtnRegistrarTarea(true)
    } else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se registro el examen')
    }

  }

  const GuardarRugroCotidiano = async (event) => {

    event.preventDefault();



    const datosexiste =
    {
      nombre: "Trabajo Cotidiano",
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: TBEvaluaciones.grado,
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(),
      docente: obtenerUsuario()
    }

    const existe = await ExisteRubroEvaluacion(datosexiste)

    if (existe) {
      ErrorMessage('Alerta!', 'Ya se ha resgistrado el trabajo cotidiano, no puedes registrar el mismo rubro en este periodo')
      TBEvaluaciones.setEditarCotidiano(false)
      TBEvaluaciones.setVerPanelCotidiano(!TBEvaluaciones.verPanelCotidiano)
      TBEvaluaciones.setMostrarBtnRegistrarCotidiano(!TBEvaluaciones.mostrarBtnRegistrarCotidiano)
      return;
    }

    if (parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Porsentaje no puede ser  igual o menor a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual)
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = TotalPorcentajeActual + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const datos = {
      nombre: "Trabajo Cotidiano",
      valorPuntos: parseInt(TBEvaluaciones.valorEnPuntos),
      valorPorcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(), // Convertido a string
      docente: obtenerUsuario(),
      fechaCreada: new Date().toISOString().split('T')[0] // Fecha sin horas
    }

    const success = await RegistrarRubroEvaluacion(datos)

    if (success) {
      SuccessMessage('Exito!', 'El examen ha sido registrado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelCotidiano(!TBEvaluaciones.verPanelCotidiano)
      TBEvaluaciones.setMostrarBtnRegistrarCotidiano(true)
    } else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se registro el examen')
    }

  }

  const GuardarRugroAsistencia = async (event) => {

    event.preventDefault();

    

    const datosexiste =
    {
      nombre: "Asistenia",
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: TBEvaluaciones.grado,
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(),
      docente: obtenerUsuario()
    }

    const existe = await ExisteRubroEvaluacion(datosexiste)

    if (existe) {
      ErrorMessage('Alerta!', 'Ya existe un examen registrardo con ese mismo nombre, por favor cambie el nombre del rubro e intente de nuevo')
      return;
    }

    if (parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Porsentaje no puede ser  igual o menor a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual)
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = TotalPorcentajeActual + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const datos = {
      nombre: "Asistencia",
      valorPuntos: parseInt(TBEvaluaciones.valorEnPuntos),
      valorPorcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(), // Convertido a string
      docente: obtenerUsuario(),
      fechaCreada: new Date().toISOString().split('T')[0] // Fecha sin horas
    }

    const success = await RegistrarRubroEvaluacion(datos)

    if (success) {
      SuccessMessage('Exito!', 'El examen ha sido registrado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelAsistencia(!TBEvaluaciones.verPanelAsistencia)
      TBEvaluaciones.setMostrarBtnRegistrarAsistencia(true)
    } else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se registro el examen')
    }

  }

  //  =================================================   Funciones para Actualizar ==============================================================

  const ActualizarRugro = async (event) => {

    event.preventDefault();

    if (parseInt(TBEvaluaciones.valorEnPuntos) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPuntos)  < 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Puntos o Porsentaje no pueden ser menor o igual a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual-parseFloat(TBEvaluaciones.porcentajeA_Editar))
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = (TotalPorcentajeActual - parseFloat(TBEvaluaciones.porcentajeA_Editar)) + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const data = {
      codigoEvaluacion: TBEvaluaciones.codigo,
      nombreEvaluacion: TBEvaluaciones.nombreExamen,
      puntos: parseInt(TBEvaluaciones.valorEnPuntos),
      porcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      cursoLectivo: new Date().getFullYear().toString()
    }

    const success = await ActualizarRubroEvaluacion(data)
    if (success) {
      SuccessMessage('Exito!', 'Registro actualizado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelExamenes(!TBEvaluaciones.verPanelExamenes)
      TBEvaluaciones.setMostrarBtnRegistrar(true)
      TBEvaluaciones.setEditar(false)
      TBEvaluaciones.setVerPanelTareas(!TBEvaluaciones.verPanelTareas)
      TBEvaluaciones.setMostrarBtnRegistrarTarea(true)
      TBEvaluaciones.setEditarTarea(false)
    }
    else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se actualizo el registro')
    }
  };

  const ActualizarRugroTareas = async (event) => {

    event.preventDefault();

    if (parseInt(TBEvaluaciones.valorEnPuntos) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPuntos)  < 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Puntos o Porsentaje no pueden ser menor o igual a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual)
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = (TotalPorcentajeActual - parseFloat(TBEvaluaciones.porcentajeA_Editar)) + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const data = {
      codigoEvaluacion: TBEvaluaciones.codigo,
      nombreEvaluacion: TBEvaluaciones.nombreExamen,
      puntos: parseInt(TBEvaluaciones.valorEnPuntos),
      porcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      cursoLectivo: new Date().getFullYear().toString()
    }

    const success = await ActualizarRubroEvaluacion(data)
    if (success) {
      SuccessMessage('Exito!', 'Registro actualizado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelTareas(!TBEvaluaciones.verPanelTareas)
      TBEvaluaciones.setMostrarBtnRegistrarTarea(true)
      TBEvaluaciones.setEditarTarea(false)
    }
    else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se actualizo el registro')
    }
  };

  const ActualizarRugroCotidiano = async (event) => {

    event.preventDefault();

    if (parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Porsentaje no puede ser  igual o menor a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual)
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = (TotalPorcentajeActual - parseFloat(TBEvaluaciones.porcentajeA_Editar)) + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const data = {
      codigoEvaluacion: TBEvaluaciones.codigo,
      nombreEvaluacion: TBEvaluaciones.nombreExamen,
      puntos: parseInt(TBEvaluaciones.valorEnPuntos),
      porcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      cursoLectivo: new Date().getFullYear().toString()
    }

    const success = await ActualizarRubroEvaluacion(data)
    if (success) {
      SuccessMessage('Exito!', 'Registro actualizado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelCotidiano(!TBEvaluaciones.verPanelCotidiano)
      TBEvaluaciones.setMostrarBtnRegistrarCotidiano(true)
      TBEvaluaciones.setEditarCotidiano(false)
    }
    else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se actualizo el registro')
    }
  };

  const ActualizarRugroAsistencia = async (event) => {

    event.preventDefault();

    if (parseInt(TBEvaluaciones.valorEnPorcentaje) === 0 || parseInt(TBEvaluaciones.valorEnPorcentaje) < 0) {
      WarningMessage("Alerta", "El valor de Porsentaje no puede ser  igual o menor a 0")
      return;
    }

    // Calcula el total de porcentaje actual
    const TotalPorcentajeActual = TBEvaluaciones.listaExamenes.reduce(
      (total, examen) => total + parseFloat(examen.valorPorcentaje || 0),
      0
    );

    //alert(TotalPorcentajeActual)
    // Calcula el porcentaje total si agregamos el nuevo valor
    const PorcentajeTotalConNuevo = (TotalPorcentajeActual - parseFloat(TBEvaluaciones.porcentajeA_Editar)) + parseFloat(TBEvaluaciones.valorEnPorcentaje);

    // Si la suma excede 100, mostrar advertencia y no continuar
    if (PorcentajeTotalConNuevo > 100) {
      WarningMessage("Alerta", `El porcentaje total no puede superar el 100%. Actualmente es ${TotalPorcentajeActual.toFixed(2)}% y con este nuevo valor sería ${PorcentajeTotalConNuevo.toFixed(2)}%.`);
      return;
    }

    const data = {
      codigoEvaluacion: TBEvaluaciones.codigo,
      nombreEvaluacion: TBEvaluaciones.nombreExamen,
      puntos: parseInt(TBEvaluaciones.valorEnPuntos),
      porcentaje: parseFloat(TBEvaluaciones.valorEnPorcentaje),
      periodo: TBEvaluaciones.nombreTrimestre,
      asignatura: TBEvaluaciones.asignatura,
      nivel: parseInt(TBEvaluaciones.grado),
      rubro: parseInt(TBEvaluaciones.nombreRugro),
      cursoLectivo: new Date().getFullYear().toString()
    }

    const success = await ActualizarRubroEvaluacion(data)
    if (success) {
      SuccessMessage('Exito!', 'Registro actualizado.')
      cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
      TBEvaluaciones.setNombreExamen('');
      TBEvaluaciones.setValorEnPuntos(0);
      TBEvaluaciones.setValorEnPorcentaje(0);
      TBEvaluaciones.setVerPanelAsistencia(!TBEvaluaciones.verPanelAsistencia)
      TBEvaluaciones.setMostrarBtnRegistrarAsistencia(true)
      TBEvaluaciones.setEditarAsistencia(false)
    }
    else {
      ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se actualizo el registro')
    }
  };

    //  =================================================   Funciones para Eliminar ==============================================================

  const eliminarRugro = async (codigo) => {
    Swal.fire({
      title: "Eliminar Rugro de Evaluación?",
      text: "Esta acción no se puede revertir. Estas seguro de realizar esta acción!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#003048",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          codigoEvaluacion: codigo,
          estadoEvaluacion: 0
        }

        const success = await EliminarRubroEvaluacion(data)

        if (success) {
          SuccessMessage('Eliminado!', 'El rubro ha sido eliminado y no se puede recuperar.')
          cargarListaEvaluaciones(parseInt(TBEvaluaciones.nombreRugro))
        } else {
          ErrorMessage('Alerta!', 'A ocurrido un error inesperado y no se elimino el registro')

        }
      }
    });
  };

    //  =================================================   Funciones para Listar ==============================================================

  const cargarListaEvaluaciones = async (rugro) => {
    TBEvaluaciones.setLoading(true)
    const data = {
      docente: obtenerUsuario(),
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(),
      estadoEvaluacion: 1
    }
    const lista = await ListarRubrosEvaluaciones(data)

    TBEvaluaciones.setListaExamenes(lista)


    // Filtrar la lista de evaluaciones usando los parámetros dados
    const evaluacionesFiltradas = lista.filter(evaluacion =>
      evaluacion.periodo === TBEvaluaciones.nombreTrimestre &&
      evaluacion.nombreAsignatura === TBEvaluaciones.nombreAsignatura &&
      evaluacion.nivel === TBEvaluaciones.grado &&
      evaluacion.rubro === rugro &&
      evaluacion.cursoLectivo === new Date().getFullYear().toString() &&
      evaluacion.estado === 1 // Filtrar solo evaluaciones con estado 1
    );
    //alert(JSON.stringify(evaluacionesFiltradas))
    // Asignar la lista filtrada a TBEvaluaciones.setListaExamenes1
    TBEvaluaciones.setListaExamenesFiltrada(evaluacionesFiltradas);
    TBEvaluaciones.setLoading(false)
  };




  return (
    <Container fluid className=" Divcontenedor ">
      <Col className='border-bottom mb-1 pb-0'>
        <Breadcrumb listTag="div" className='pb-0'>
          <MenuItem
            className='p-0 text-dark'

            text="Inicio"
            to="/Menu"
            classname={'NoDecoration2 tlink pb-0'}
          />
          <Label className=' px-1 py-1 pb-0'>/</Label>
          <MenuItem
            className='p-0 text-dark'

            text="Crear Evaluaciones "
            to="/Menu/FrmCrearEvaluaciones"
            classname={'NoDecoration2 tlink pb-0'}
          />
        </Breadcrumb>

      </Col>

      <Container fluid className='px-0 border tamañoCont_Evaluaciones shadow2'>
        <Tabs defaultIndex={-1} className={'p-0 m-0  '} >     {/* Primer tabs para los periodos */}
          <TabList className={'tabs_ m-0 border-bottom1 br-top pt-0'}>
            {TBEvaluaciones.listaTrimestres.map((trimestre, index) => (
              <Tab key={index} value={trimestre.id} onClick={(e) => {
                e.preventDefault(); // Para prevenir comportamiento predeterminado
                TBEvaluaciones.setNombreTrimestre(trimestre.id);
              }}>
                {trimestre.nombre}

              </Tab>
            ))}
          </TabList>

          <Container fluid className=' px-0  rounded-0'>

            {/* Paneles de Trimestres */}
            {TBEvaluaciones.listaTrimestres.map((trimestre, trimestreIndex) => (
              <TabPanel key={trimestreIndex}>
                <Container fluid className='px-0'>
                  {/* Primer tabs para las materias */}
                  <Tabs defaultIndex={-1} className={'p-0 m-0'}>
                    <TabList className={'tabs_ m-0  border-bottom1 '}>
                      {TBFUncionario.ListaAsigDocente.map((asignatura, asignaturaIndex) => (
                        <Tab key={asignaturaIndex} onClick={(e) => {
                          e.preventDefault(); // Para prevenir comportamiento predeterminado
                          TBEvaluaciones.setAsignatura(asignatura.codigoAsignatura);
                          TBEvaluaciones.setNombreAsignatura(asignatura.nombreAsignatura);
                        }}>
                          {asignatura.nombreAsignatura}
                        </Tab>
                      ))}
                    </TabList>

                    {/* Paneles de Asignaturas */}
                    {TBFUncionario.ListaAsigDocente.map((asignatura, asignaturasIndex) => (
                      <TabPanel key={asignaturasIndex}>
                        <Container fluid className='px-0'>
                          {/* Primer tabs para los Grados */}
                          <Tabs defaultIndex={-1}>
                            <TabList className={'tabs_ m-0  border-bottom1'}>
                              {GradosFiltrados.map((grados, gradoindex) => (
                                <Tab key={gradoindex} onClick={(e) => {
                                  e.preventDefault(); // Para prevenir comportamiento predeterminado
                                  TBEvaluaciones.setGrado(grados.id);
                                }}>
                                  {grados.nombre}
                                </Tab>
                              ))}
                            </TabList>
                            {TBSecciones.existenSecciones ? (
                              <>
                                {GradosFiltrados.map((grado, seccionindex) => (
                                  <TabPanel key={seccionindex}>
                                    {/* Primer tabs para los Secciones */}
                                    <Tabs defaultIndex={-1} className={'p-0 m-0'}>
                                      <TabList className={'tabs_ m-0  border-bottom1'}>
                                        {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map(seccion => (
                                          <Tab key={seccion.nombreSeccion} onClick={(e) => {
                                            e.preventDefault(); // Para prevenir comportamiento predeterminado
                                            TBEvaluaciones.setGrado(seccion.nivel);
                                          }}>
                                            {seccion.nombreSeccion}
                                          </Tab>
                                        ))}
                                      </TabList>
                                      <TabPanel>
                                        {/* Primer tabs para los rugros*/}
                                        <Tabs defaultIndex={-1} className={'p-0 m-0'}>
                                          <TabList className={'tabs_ m-0  border-bottom1 '}>
                                            {TBEvaluaciones.rubrosCalificacion.map((rugro, rugroIndex) => (
                                              <Tab key={rugroIndex} value={rugro.id} onClick={(e) => {
                                                e.preventDefault(); // Para prevenir comportamiento predeterminado
                                                TBEvaluaciones.setNombreRugro(rugro.id);
                                                TBEvaluaciones.setMostrarBtnRegistrar(true);
                                                TBEvaluaciones.setVerPanelExamenes(false);
                                                TBEvaluaciones.setMostrarBtnRegistrarTarea(true);
                                                TBEvaluaciones.setVerPanelTareas(false);
                                                cargarListaEvaluaciones(rugro.id);
                                              }}>
                                                {rugro.nombre}
                                              </Tab>
                                            ))}
                                          </TabList>

                                          <TabPanel>
                                            <Container fluid className='p-0 '>
                                              <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 py-1'>
                                                  <Label for="files" className=''>
                                                    <strong>Lista de Examenes del {trimestre.nombre} de {asignatura.nombreAsignatura}  </strong>
                                                  </Label>
                                                </Col>
                                                {TBEvaluaciones.mostrarBtnRegistrar && (
                                                  <Col xs={12} sm={12} md={12} lg={12} className='mt-2 '>
                                                    <Button for="identificacion" className="p-1 btn btnAceptar" onClick={mostrarPanelRegisExamen}>
                                                      <Fa6.FaCirclePlus className="me-1" size={18} />
                                                      Registrar Examenes
                                                    </Button>

                                                  </Col>
                                                )}
                                              </Row>
                                              {TBEvaluaciones.verPanelExamenes && (
                                                <Container fluid className='mb-2 mt-2'>

                                                  {TBEvaluaciones.editar ? (
                                                    <Form className='shadow2-1 p-2' onSubmit={ActualizarRugro}>

                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Rubro
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={TBEvaluaciones.nombreExamen}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1     btnCancelar px-3' onClick={mostrarPanelRegisExamen}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editar ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (<Button type='submit' className='btnAceptar p-1 px-3'  >
                                                          Guardar
                                                        </Button>)}

                                                      </Col>
                                                    </Form>
                                                  ) : (
                                                    <Form className='shadow2-1 p-2' onSubmit={GuardarRugro}>
                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Nombre Examen
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={TBEvaluaciones.nombreExamen}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            onFocus={() => TBEvaluaciones.setValorEnPuntos('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onFocus={() => TBEvaluaciones.setValorEnPorcentaje('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1 btnCancelar px-3' onClick={mostrarPanelRegisExamen}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editar ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Guardar
                                                          </Button>)}

                                                      </Col>
                                                    </Form>
                                                  )}


                                                </Container>
                                              )}
                                              <Container fluid className='px-2 py-2  table-responsive tamañoPanelTable'>
                                                {TBEvaluaciones.loading ? (
                                                  <Container className="loader-container2">
                                                    <div className="loader"></div>
                                                    <span className="loading-text">Cargando...</span>

                                                  </Container>

                                                ) : (

                                                  <Table className='shadow2-2 border table table-striped table-bordered ' style={{ minWidth: '400px' }}>
                                                    <thead>
                                                      <tr>
                                                        <th className='text-center'>Nombre Examen</th>
                                                        <th className='text-center'>Fecha de Creación</th>
                                                        <th className='text-center'>Valor Puntos</th>
                                                        <th className='text-center'>Valor Porcentual</th>
                                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {TBEvaluaciones.listaExamenesFiltrada.length === 0 ? (
                                                        <tr>
                                                          <td colSpan={5} className='text-center'>
                                                            No hay registros disponibles.
                                                          </td>
                                                        </tr>
                                                      ) : (
                                                        <>
                                                          {TBEvaluaciones.listaExamenesFiltrada.map((examen, index) => (
                                                            <tr key={index}>
                                                              <td>{examen.nombreEvaluacion}</td>
                                                              <td className='text-center'>{new Date(examen.fechaCreada).toISOString().split('T')[0]}</td>
                                                              <td className='text-center'>{examen.valorPuntos} pts</td>
                                                              <td className='text-center'>{examen.valorPorcentaje.toFixed(2)}%</td>
                                                              <td className='d-flex justify-content-center'>
                                                                <MdEdit title='Ver más...' className='mb-1 mx-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={() => mostrarPanelParaEditar(examen.codigo, examen.nombreEvaluacion, examen.valorPuntos, examen.valorPorcentaje)} />
                                                                <Md.MdDelete title='Eliminar' className='mb-1 mx-1 eli2' cursor={'pointer'} color='#314843' size={15} onClick={() => eliminarRugro(examen.codigo)} />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                          {/* Fila para mostrar la suma total de los porcentajes */}
                                                          <tr>
                                                            <td colSpan={3} className='text-end'><strong>Total Porcentual Asignado</strong></td>
                                                            <td className='text-center'>
                                                              <strong>
                                                                {TBEvaluaciones.listaExamenes.reduce((total, examen) => total + parseFloat(examen.valorPorcentaje || 0), 0).toFixed(2)}%
                                                              </strong>
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        </>
                                                      )}
                                                    </tbody>
                                                  </Table>

                                                )}
                                              </Container>
                                            </Container>
                                          </TabPanel>

                                          <TabPanel>
                                            <Container fluid className='p-0 '>
                                              <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 py-1'>
                                                  <Label for="files" className=''>
                                                    <strong>Lista de Tareas del {trimestre.nombre} de {asignatura.nombreAsignatura}  </strong>
                                                  </Label>
                                                </Col>
                                                {TBEvaluaciones.mostrarBtnRegistrarTarea && (
                                                  <Col xs={12} sm={12} md={12} lg={12} className='mt-2 '>
                                                    <Button for="identificacion" className="p-1 btn btnAceptar" onClick={mostrarPanelRegisTareas}>
                                                      <Fa6.FaCirclePlus className="me-1" size={18} />
                                                      Registrar Tareas
                                                    </Button>

                                                  </Col>
                                                )}
                                              </Row>
                                              {TBEvaluaciones.verPanelTareas && (
                                                <Container fluid className='mb-2 mt-2'>

                                                  {TBEvaluaciones.editarTarea ? (
                                                    <Form className='shadow2-1 p-2' onSubmit={ActualizarRugroTareas}>

                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Tarea
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={TBEvaluaciones.nombreExamen}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1     btnCancelar px-3' onClick={mostrarPanelRegisTareas}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editarTarea ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (<Button type='submit' className='btnAceptar p-1 px-3'  >
                                                          Guardar
                                                        </Button>)}

                                                      </Col>
                                                    </Form>
                                                  ) : (
                                                    <Form className='shadow2-1 p-2' onSubmit={GuardarRugroTarea}>
                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Tarea
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={TBEvaluaciones.nombreExamen}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            onFocus={() => TBEvaluaciones.setValorEnPuntos('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onFocus={() => TBEvaluaciones.setValorEnPorcentaje('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1 btnCancelar px-3' onClick={mostrarPanelRegisTareas}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editarTarea ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Guardar
                                                          </Button>)}

                                                      </Col>
                                                    </Form>
                                                  )}


                                                </Container>
                                              )}
                                              <Container fluid className='px-2 py-2  table-responsive tamañoPanelTable '>
                                                {TBEvaluaciones.loading ? (
                                                  <Container className="loader-container2">
                                                    <div className="loader"></div>
                                                    <span className="loading-text">Cargando...</span>

                                                  </Container>

                                                ) : (

                                                  <Table className='shadow2-2 border table table-striped table-bordered' style={{ minWidth: '400px' }}>
                                                    <thead>
                                                      <tr>
                                                        <th className='text-center'>Tarea</th>
                                                        <th className='text-center'>Fecha de Creación</th>
                                                        <th className='text-center'>Valor Puntos</th>
                                                        <th className='text-center'>Valor Porcentual</th>
                                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {TBEvaluaciones.listaExamenesFiltrada.length === 0 ? (
                                                        <tr>
                                                          <td colSpan={5} className='text-center'>
                                                            No hay registros disponibles.
                                                          </td>
                                                        </tr>
                                                      ) : (
                                                        <>
                                                          {TBEvaluaciones.listaExamenesFiltrada.map((examen, index) => (
                                                            <tr key={index}>
                                                              <td>{examen.nombreEvaluacion}</td>
                                                              <td className='text-center'>{new Date(examen.fechaCreada).toISOString().split('T')[0]}</td>
                                                              <td className='text-center'>{examen.valorPuntos} pts</td>
                                                              <td className='text-center'>{examen.valorPorcentaje.toFixed(2)}%</td>
                                                              <td className='d-flex justify-content-center'>
                                                                <MdEdit title='Ver más...' className='mb-1 mx-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={() => mostrarPanelParaEditarTareas(examen.codigo, examen.nombreEvaluacion, examen.valorPuntos, examen.valorPorcentaje)} />
                                                                <Md.MdDelete title='Eliminar' className='mb-1 mx-1 eli2' cursor={'pointer'} color='#314843' size={15} onClick={() => eliminarRugro(examen.codigo)} />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                          {/* Fila para mostrar la suma total de los porcentajes */}
                                                          <tr>
                                                            <td colSpan={3} className='text-end'><strong>Total Porcentual Asignado</strong></td>
                                                            <td className='text-center'>
                                                              <strong>
                                                                {TBEvaluaciones.listaExamenes.reduce((total, examen) => total + parseFloat(examen.valorPorcentaje || 0), 0).toFixed(2)}%
                                                              </strong>
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        </>
                                                      )}
                                                    </tbody>
                                                  </Table>

                                                )}
                                              </Container>
                                            </Container>
                                          </TabPanel>

                                          <TabPanel>
                                            <Container fluid className='p-0 '>
                                              <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 py-1'>
                                                  <Label for="files" className=''>
                                                    <strong>Trabajo Cotidiano del {trimestre.nombre} para {asignatura.nombreAsignatura}  </strong>
                                                  </Label>
                                                </Col>
                                                {TBEvaluaciones.mostrarBtnRegistrarCotidiano && (
                                                  <Col xs={12} sm={12} md={12} lg={12} className='mt-2 '>
                                                    <Button for="identificacion" className="p-1 btn btnAceptar" onClick={mostrarPanelRegisCotidiano}>
                                                      <Fa6.FaCirclePlus className="me-1" size={18} />
                                                      Registrar Cotidiano
                                                    </Button>

                                                  </Col>
                                                )}
                                              </Row>
                                              {TBEvaluaciones.verPanelCotidiano && (
                                                <Container fluid className='mb-2 mt-2'>

                                                  {TBEvaluaciones.editarCotidiano ? (
                                                    <Form className='shadow2-1 p-2' onSubmit={ActualizarRugroCotidiano}>

                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Trabajo Cotidiano
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={TBEvaluaciones.nombreExamen}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                            disabled
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1     btnCancelar px-3' onClick={mostrarPanelRegisCotidiano}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editarCotidiano ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (<Button type='submit' className='btnAceptar p-1 px-3'  >
                                                          Guardar
                                                        </Button>)}

                                                      </Col>
                                                    </Form>
                                                  ) : (
                                                    <Form className='shadow2-1 p-2' onSubmit={GuardarRugroCotidiano}>
                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Rubro
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={"Trabajo Cotidiano"}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                            disabled
                                                            title='Este valor no puede ser modificado'
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            title='Este valor no puede ser modificado'
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            disabled
                                                            title='Este valor no puede ser modificado'
                                                            onFocus={() => TBEvaluaciones.setValorEnPuntos('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onFocus={() => TBEvaluaciones.setValorEnPorcentaje('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1 btnCancelar px-3' onClick={mostrarPanelRegisCotidiano}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editarCotidiano ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Guardar
                                                          </Button>)}

                                                      </Col>
                                                    </Form>
                                                  )}


                                                </Container>
                                              )}
                                              <Container fluid className='px-2 py-2  table-responsive tamañoPanelTable '>
                                                {TBEvaluaciones.loading ? (
                                                  <Container className="loader-container2">
                                                    <div className="loader"></div>
                                                    <span className="loading-text">Cargando...</span>

                                                  </Container>

                                                ) : (

                                                  <Table className='shadow2-2 border table table-striped table-bordered' style={{ minWidth: '400px' }}>
                                                    <thead>
                                                      <tr>
                                                        <th className='text-center'>Tarea</th>
                                                        <th className='text-center'>Fecha de Creación</th>
                                                        <th className='text-center'>Valor Puntos</th>
                                                        <th className='text-center'>Valor Porcentual</th>
                                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {TBEvaluaciones.listaExamenesFiltrada.length === 0 ? (
                                                        <tr>
                                                          <td colSpan={5} className='text-center'>
                                                            No hay registros disponibles.
                                                          </td>
                                                        </tr>
                                                      ) : (
                                                        <>
                                                          {TBEvaluaciones.listaExamenesFiltrada.map((examen, index) => (
                                                            <tr key={index}>
                                                              <td>{examen.nombreEvaluacion}</td>
                                                              <td className='text-center'>{new Date(examen.fechaCreada).toISOString().split('T')[0]}</td>
                                                              <td className='text-center'>{examen.valorPuntos} pts</td>
                                                              <td className='text-center'>{examen.valorPorcentaje.toFixed(2)}%</td>
                                                              <td className='d-flex justify-content-center'>
                                                                <MdEdit title='Ver más...' className='mb-1 mx-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={() => mostrarPanelParaEditarCotidiano(examen.codigo, examen.nombreEvaluacion, examen.valorPuntos, examen.valorPorcentaje)} />
                                                                <Md.MdDelete title='Eliminar' className='mb-1 mx-1 eli2' cursor={'pointer'} color='#314843' size={15} onClick={() => eliminarRugro(examen.codigo)} />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                          {/* Fila para mostrar la suma total de los porcentajes */}
                                                          <tr>
                                                            <td colSpan={3} className='text-end'><strong>Total Porcentual Asignado</strong></td>
                                                            <td className='text-center'>
                                                              <strong>
                                                                {TBEvaluaciones.listaExamenes.reduce((total, examen) => total + parseFloat(examen.valorPorcentaje || 0), 0).toFixed(2)}%
                                                              </strong>
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        </>
                                                      )}
                                                    </tbody>
                                                  </Table>

                                                )}
                                              </Container>
                                            </Container>
                                          </TabPanel>

                                          <TabPanel>
                                            <Container fluid className='p-0 '>
                                              <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 py-1'>
                                                  <Label for="files" className=''>
                                                    <strong>Asistencia del {trimestre.nombre} para {asignatura.nombreAsignatura}  </strong>
                                                  </Label>
                                                </Col>
                                                {TBEvaluaciones.mostrarBtnRegistrarAsistencia && (
                                                  <Col xs={12} sm={12} md={12} lg={12} className='mt-2 '>
                                                    <Button for="identificacion" className="p-1 btn btnAceptar" onClick={mostrarPanelRegisAsistencia}>
                                                      <Fa6.FaCirclePlus className="me-1" size={18} />
                                                      Registrar Asistencia
                                                    </Button>

                                                  </Col>
                                                )}
                                              </Row>
                                              {TBEvaluaciones.verPanelAsistencia && (
                                                <Container fluid className='mb-2 mt-2'>

                                                  {TBEvaluaciones.editarAsistencia ? (
                                                    <Form className='shadow2-1 p-2' onSubmit={ActualizarRugroAsistencia}>

                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Asistencia
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={TBEvaluaciones.nombreExamen}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                            disabled
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            disabled
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1     btnCancelar px-3' onClick={mostrarPanelRegisAsistencia}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editarAsistencia ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (<Button type='submit' className='btnAceptar p-1 px-3'  >
                                                          Guardar
                                                        </Button>)}

                                                      </Col>
                                                    </Form>
                                                  ) : (
                                                    <Form className='shadow2-1 p-2' onSubmit={GuardarRugroAsistencia}>
                                                      <Row className='px-0 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                        <Col>
                                                          <Label>
                                                            Rubro
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="nombreExamen"
                                                            value={"Asistencia"}
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(e.target.value)}
                                                            required
                                                            disabled
                                                            title='Este valor no puede ser modificado'
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Asignatura
                                                          </Label>
                                                          <Input
                                                            type="text"
                                                            id="materia"
                                                            value={asignatura.nombreAsignatura}
                                                            disabled
                                                            title='Este valor no puede ser modificado'
                                                            onChange={(e) => TBEvaluaciones.setNombreExamen(asignatura.nombreAsignatura)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor de Puntos
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="puntos"
                                                            value={TBEvaluaciones.valorEnPuntos}
                                                            required
                                                            disabled
                                                            title='Este valor no puede ser modificado'
                                                            onFocus={() => TBEvaluaciones.setValorEnPuntos('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPuntos(e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Label>
                                                            Valor Porcentual
                                                          </Label>
                                                          <Input
                                                            type="number"
                                                            id="porcentaje"
                                                            value={TBEvaluaciones.valorEnPorcentaje}
                                                            required
                                                            onFocus={() => TBEvaluaciones.setValorEnPorcentaje('')}
                                                            onChange={(e) => TBEvaluaciones.setValorEnPorcentaje(e.target.value)}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Col className='d-flex justify-content-between mt-3'>
                                                        <Button className=' p-1 btnCancelar px-3' onClick={mostrarPanelRegisAsistencia}>
                                                          Cancelar
                                                        </Button>
                                                        {TBEvaluaciones.editarAsistencia ? (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Editar
                                                          </Button>
                                                        ) : (
                                                          <Button type='submit' className='btnAceptar p-1 px-3'  >
                                                            Guardar
                                                          </Button>)}

                                                      </Col>
                                                    </Form>
                                                  )}


                                                </Container>
                                              )}
                                              <Container fluid className='px-2 py-2  table-responsive tamañoPanelTable '>
                                                {TBEvaluaciones.loading ? (
                                                  <Container className="loader-container2">
                                                    <div className="loader"></div>
                                                    <span className="loading-text">Cargando...</span>

                                                  </Container>

                                                ) : (

                                                  <Table className='shadow2-2 border table table-striped table-bordered' style={{ minWidth: '400px' }}>
                                                    <thead>
                                                      <tr>
                                                        <th className='text-center'>Tarea</th>
                                                        <th className='text-center'>Fecha de Creación</th>
                                                        <th className='text-center'>Valor Puntos</th>
                                                        <th className='text-center'>Valor Porcentual</th>
                                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {TBEvaluaciones.listaExamenesFiltrada.length === 0 ? (
                                                        <tr>
                                                          <td colSpan={5} className='text-center'>
                                                            No hay registros disponibles.
                                                          </td>
                                                        </tr>
                                                      ) : (
                                                        <>
                                                          {TBEvaluaciones.listaExamenesFiltrada.map((examen, index) => (
                                                            <tr key={index}>
                                                              <td>{examen.nombreEvaluacion}</td>
                                                              <td className='text-center'>{new Date(examen.fechaCreada).toISOString().split('T')[0]}</td>
                                                              <td className='text-center'>{examen.valorPuntos} pts</td>
                                                              <td className='text-center'>{examen.valorPorcentaje.toFixed(2)}%</td>
                                                              <td className='d-flex justify-content-center'>
                                                                <MdEdit title='Ver más...' className='mb-1 mx-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={() => mostrarPanelParaEditarAsistencia(examen.codigo, examen.nombreEvaluacion, examen.valorPuntos, examen.valorPorcentaje)} />
                                                                <Md.MdDelete title='Eliminar' className='mb-1 mx-1 eli2' cursor={'pointer'} color='#314843' size={15} onClick={() => eliminarRugro(examen.codigo)} />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                          {/* Fila para mostrar la suma total de los porcentajes */}
                                                          <tr>
                                                            <td colSpan={3} className='text-end'><strong>Total Porcentual Asignado</strong></td>
                                                            <td className='text-center'>
                                                              <strong>
                                                                {TBEvaluaciones.listaExamenes.reduce((total, examen) => total + parseFloat(examen.valorPorcentaje || 0), 0).toFixed(2)}%
                                                              </strong>
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        </>
                                                      )}
                                                    </tbody>
                                                  </Table>

                                                )}
                                              </Container>
                                            </Container>
                                          </TabPanel>

                                        </Tabs>
                                      </TabPanel>
                                    </Tabs>
                                  </TabPanel>
                                ))}
                              </>
                            ) : (
                              <Container fluid className='d-flex justify-content-center' >
                                <Label className='justify-content-center mt-5'>Actualmente no hay secciones registradas en el sistema.</Label>
                              </Container>
                            )}
                          </Tabs>

                        </Container>
                      </TabPanel>
                    ))}
                  </Tabs>
                </Container>
              </TabPanel>
            ))}
          </Container>

        </Tabs>
      </Container>
      <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
        <ul>
          <MenuItem
            className='btnAceptar'
            icon={<Io.IoMdReturnLeft className="me-1" />}
            text="Volver"
            to="/Menu"
            classname={'NoDecoration'}
          />
        </ul>
      </Button>

    </Container>
  )
}

export default FrmCrearEvaluaciones
