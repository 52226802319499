import { ErrorMessage, SuccessMessage } from '../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../../CapaUtilidades/Utils/encryptUserData';
import endpoint from '../EndPoints/endPoint';

var url = '';
var estado;

export async function ExisteRubroEvaluacion(data) {

    url = endpoint.urlExisteRubro;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           // throw new Error(`HTTP error! status: ${response.status}`);
           estado = false
           return estado;
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return  estado
           

    } catch (error) {
        console.log(error)
        estado = false
        return estado;
    }
}

export async function RegistrarRubroEvaluacion(data) {

    url = endpoint.urlRegistrarRubro;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           // throw new Error(`HTTP error! status: ${response.status}`);
           estado = false
           return estado;
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return  estado
           

    } catch (error) {
        console.log(error)
        estado = false
        return estado;
    }
}

export async function ActualizarRubroEvaluacion(data) {

    url = endpoint.urlActualizarRubro;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           // throw new Error(`HTTP error! status: ${response.status}`);
           estado = false
           return estado;
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return  estado
           

    } catch (error) {
        console.log(error)
        estado = false
        return estado;
    }
}

export async function EliminarRubroEvaluacion(data) {

    url = endpoint.urlEliminarRubro;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           // throw new Error(`HTTP error! status: ${response.status}`);
           estado = false
           return estado;
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return  estado
           

    } catch (error) {
        console.log(error)
        estado = false
        return estado;
    }
}
export async function ListarRubrosEvaluaciones(data) {
    const url = endpoint.urlListaRubros;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //console.log(JSON.stringify(data))
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            //throw new Error(`HTTP error! status: ${response.status}`);
            return [];
        }

        const lista = await response.json();

        // Mapear los datos al formato deseado
        

        //console.log(lista);
        return lista; // Retornar los eventos en el formato deseado

    } catch (error) {
        console.log(error.message);
        return [];
    }
}