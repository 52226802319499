import { useState } from 'react';


export const TbMatricula = () => {
    const añoActual = new Date().getFullYear(); // Obtiene el año en curso
    const añoSiguiente = añoActual + 1; // Calcula el año siguiente
    const [añoLectivo, setAñoLectivo] = useState(0);
    const [fechaMatricula, setFechaMatricula] = useState('');
    const [medioMatricula, setMedioMatricula] = useState(0);
    const [tipoIdentificacion, setTipoIdentificacion] = useState(0);
    const [cedula, setCedula] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido1, setApellido1] = useState('');
    const [apellido2, setApellido2] = useState('');
    const [estadoMatricula, setEstadoMatricula] = useState();
    const [grado, setGrado] = useState();
    const [seccionesFiltradas, setSeccionesFiltradas] =  useState([]);
    const [seccion, setSeccion] = useState();
    const [nombreCedulaFrontal, setNombreCedulaFrontal] = useState('');
    const [nombreCedulaReverso, setNombreCedulaReverso] = useState('');
    const [nombreCedulaResibo, setNombreCedulaResibo] = useState('');
    const [encargado1, setEncargado1]= useState('');
    const [encargado2, setEncargado2]= useState('');
    const [matriculaAutoriza, setMatriculaAutoriza] = useState(''); 


    const [encargadosSeleccionados, setEncargadosSeleccionados] = useState([]);


    const [idFrontal, setIdFrontal] = useState( );
    const [idReverso, setIdReverso] = useState( );
    const [resiboLuz, setResiboLuz] = useState( );
    const [verIdFrontal, setVerIdFrontal] = useState( );
    const [verIdReverso, setVerIdReverso] = useState( );
    const [verIdResiboLuz, setVerResiboLuz] = useState( );

    const MAX_FILE_SIZE = 500 * 1024; // 500 KB en bytes
    
    return {
        añoActual,
        añoSiguiente,
        añoLectivo, setAñoLectivo,
        fechaMatricula, setFechaMatricula,
        medioMatricula, setMedioMatricula,
        tipoIdentificacion, setTipoIdentificacion,
        cedula, setCedula,
        nombre, setNombre,
        apellido1, setApellido1,
        apellido2, setApellido2,
        estadoMatricula, setEstadoMatricula,
        grado, setGrado,
        seccionesFiltradas, setSeccionesFiltradas,
        seccion, setSeccion,
        nombreCedulaFrontal, setNombreCedulaFrontal,
        nombreCedulaReverso, setNombreCedulaReverso,
        nombreCedulaResibo, setNombreCedulaResibo,
        encargadosSeleccionados, setEncargadosSeleccionados,
        encargado1, setEncargado1,
        encargado2, setEncargado2,
        matriculaAutoriza, setMatriculaAutoriza,
        

        idFrontal, setIdFrontal,
        idReverso, setIdReverso,
        resiboLuz, setResiboLuz,
        verIdFrontal, setVerIdFrontal,
        verIdReverso, setVerIdReverso,
        verIdResiboLuz, setVerResiboLuz,
        MAX_FILE_SIZE, 
    };
};