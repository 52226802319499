import { ErrorMessage, SuccessMessage } from '../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../../CapaUtilidades/Utils/encryptUserData';
import endpoint from '../EndPoints/endPoint';

var url = '';
var estado;

export async function ListaEncargadosEstudiantes(data) {
    const url = endpoint.urlListaEncardosEstudiantes;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //console.log(JSON.stringify(data))
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorResponseData = await response.json();
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responsePersona = await response.json();

        if (responsePersona && responsePersona.encargados) {
           // console.error(responsePersona.encargados);
            return responsePersona.encargados; // Retornamos directamente el array de encargados
        } else {
           // console.log(JSON.stringify(responsePersona));
            return [];
        }

    } catch (error) {
        console.log(error.message);
        return [];
    }
}

export async function EnviarComunicado(data) {

    url = endpoint.urlRegistrarComunicado;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return  estado
           

    } catch (error) {
        console.log(error)
        return estado;
    }
}
