import React, { useState, useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import { Container } from 'reactstrap';
import { ErrorMessage, ErrorMessageQR, SuccessMessage } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import sound from '../../sound/correcto.m4a';
import soundError from '../../sound/error.m4a';
import { RegistrarAsistenciaComedor } from '../../../CapaDatos/DatosEstudiantes/DatosQR';
import { obtenerIdInstitucion } from '../../../CapaUtilidades/Utils/encryptUserData';

const FrmLectorQR = () => {
  const [qrData, setQrData] = useState(null);
  const [scannedCodes, setScannedCodes] = useState([]); // Estado para almacenar códigos escaneados
  const [scannerVisible, setScannerVisible] = useState(true); // Controla si el escáner está visible
  const [isProcessing, setIsProcessing] = useState(false); // Estado para controlar si ya se está procesando un escaneo
  // Crear instancias de Audio para los sonidos
  const successSound = new Audio(sound);
  const errorSound = new Audio(soundError);

  const handleScan = async (data) => {
    try {
      if (!isProcessing) {
       console.log(JSON.stringify(data))
        if (data) {
          const parsedData = data.text;
          //console.log(parsedData);
  
  
          // Verificar si el código ya fue escaneado
          if (!scannedCodes.includes(parsedData)) {
            setIsProcessing(true);
            const datos = {
              token: parsedData,
              codPress: obtenerIdInstitucion(),
            };
  
            //Intentar registrar la asistencia
            const success = await RegistrarAsistenciaComedor(datos);
            if (success) {
              setScannerVisible(false);
              setScannedCodes((prevCodes) => [...prevCodes, parsedData]); // Solo añadir si fue exitoso
              setQrData(parsedData);
  
              // Mostrar mensaje de éxito
              SuccessMessage("Notificación", "QR escaneado");
              successSound.play();
  
              // Volver a mostrar el escáner después de 1.5 segundos
              setTimeout(() => {
                setScannerVisible(true);
                setIsProcessing(false);
              }, 1500);
            } else {
              // Si el registro falla, no añadimos el código escaneado
              errorSound.play();
              ErrorMessage("Alerta", "Error al registrar la asistencia.");
              setScannerVisible(true);
              setIsProcessing(false);
            }
          } else {
            // Código ya registrado
            errorSound.play();
            ErrorMessage("Alerta", "El código ya ha sido registrado.");
            setScannerVisible(true);
            setIsProcessing(false);
          }
        }
      }
      
    } catch (error) {
      console.error("Error al parsear JSON:", error);
      setQrData("No se pudo parsear el JSON.");
    }
  };



  const handleError = (err) => {
    console.log(err);
  };

  const previewStyle = {
    height: '98.5%',
    width: '100%',
    margin: '0',
    objectFit: 'fill',
  };

  // Comprobación de compatibilidad
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    return (
      <Container fluid className="Divcontenedor" style={{ textAlign: 'center' }}>
        <h2>Lector de Códigos QR</h2>
        <p>Tu navegador no soporta acceso a la cámara.</p>
      </Container>
    );
  }

  return (
    <Container fluid className="Divcontenedor" style={{ textAlign: 'center' }}>
      <h2>Lector de Códigos QR</h2>
      <Container className='p-0 contenedorTabsComunicado rounded-0'>
        {scannerVisible && (
          <div className="scan-box">
            {/* Solo renderiza el QrScanner cuando scannerVisible es true */}
            <QrScanner
              delay={2000} // Puedes ajustar este delay
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
              facingMode="user"
            />
            {/* Línea láser animada */}
            <div className="scan-line"></div>
          </div>
        )}
      </Container>
      {/* Mostrar el resultado del escaneo del QR */}
      {qrData ? (
        <pre>{JSON.stringify(qrData, null, 2)}</pre>
      ) : (
        <p>Escaneando...</p>
      )}
    </Container>
  );
};

export default FrmLectorQR;
