import { useState } from 'react';

export const TbCondSocio_Econimica = () => {
    const [IdSocioEconomica, setIdSocioEconomica]= useState([0]);
    const [tipoCasa, setTipoCasa] = useState(0);
    const [condicionVivienda, setCondicionVivienda] = useState(0);
    const [cantPersonas, setCantPersonas] = useState(0);
    const [accesoInternet, setAccesoInternet] = useState(0);
    const [ingresosMensuales, setIngresosMensuales] = useState('');
    const [gastosMensuales, setGastosMensuales] = useState('');
    const [tipoBeca, setTipoBeca] = useState(0);
    const [bonoEscolar, setBonoEscolar] = useState(0);
    const [otroBeneficio, setOtroBeneficio] = useState('');
    const [año, setAño] = useState('');
    const [existeSocioEco, setExisteSocioEco]= useState(false);

    const [listaTipoCasa, setListaTipoCasa] = useState([]);
    const [listaCondicionVivienda, setListaCondicionVivienda] = useState([]);
    const [ListaTipoBeca, setListaTipoBeca] = useState([]);
    const [ListaNumeros, setListaNumeros] = useState([]);

    return {
        IdSocioEconomica, setIdSocioEconomica,
        tipoCasa, setTipoCasa,
        condicionVivienda, setCondicionVivienda,
        cantPersonas, setCantPersonas,
        accesoInternet, setAccesoInternet,
        ingresosMensuales, setIngresosMensuales,
        gastosMensuales, setGastosMensuales,
        tipoBeca, setTipoBeca,
        bonoEscolar, setBonoEscolar,
        otroBeneficio, setOtroBeneficio,
        año, setAño,
        existeSocioEco, setExisteSocioEco,

        ListaNumeros, setListaNumeros,
        listaTipoCasa, setListaTipoCasa,
        listaCondicionVivienda, setListaCondicionVivienda,
        ListaTipoBeca, setListaTipoBeca,
    };


}