import { useState } from "react";







export const TbActivos = () => {
    const [placaActivo, setPlacaActivo] = useState('');
    const [nombreActivo, setNombreActivo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [activoPerteneceInstitucion, setActivoPerteneceInstitucion] = useState('');
    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');
    const [modoadquisitivo, setModoadquisitivo] = useState('');
    const [procedencia, setProcedencia] = useState('');
    const [estadoActivo, setEstadoActivo] = useState(1);
    const [tipoActivo, setTipoActivo] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [observacionesNuevas, setObservacionesNuevas] = useState('');
    const [CodNombre, setCodNombre] = useState('');
    const [FuncionarioEntregada, setFuncionarioEntregada] = useState('');
    const [NombreFuncionarioEntregada, setNombreFuncionarioEntregada] = useState('');
    const [fechaSolicitud, setFechaSolicitud] = useState('');
    const [fechaEntrega, setFechaEntrega] = useState('');
    const [placaNueva, setPlacaNueva] = useState('');
    const [CedulaFuncionario, setCedulaFuncionario] = useState('');
    const [NombreFuncionario, setNombreFuncionario] = useState('');
    const [apellido1, setapellido1] = useState('');
    const [apellido2, setapellido2] = useState('');
    const [codigo, setCodigo] = useState('');
    const [edificioAsignacion, setEdificioAsignacion]=useState('');
    const [nombreEdificio, setNombreEdificio]= useState('');
    const [ListaModos, setListaModos] = useState([]);
    const [ListaTipoActivos, setListaTipoActivos] = useState([]);

    return {
        placaActivo, setPlacaActivo,
        nombreActivo, setNombreActivo,
        descripcion, setDescripcion,
        activoPerteneceInstitucion, setActivoPerteneceInstitucion,
        marca, setMarca,
        modelo, setModelo,
        modoadquisitivo, setModoadquisitivo,
        procedencia, setProcedencia,
        estadoActivo, setEstadoActivo,
        tipoActivo, setTipoActivo,
        observaciones, setObservaciones,
        observacionesNuevas, setObservacionesNuevas,
        CodNombre, setCodNombre,
        FuncionarioEntregada, setFuncionarioEntregada,
        NombreFuncionarioEntregada, setNombreFuncionarioEntregada,
        ListaModos, setListaModos,
        ListaTipoActivos, setListaTipoActivos,
        fechaSolicitud, setFechaSolicitud,
        fechaEntrega, setFechaEntrega,
        placaNueva, setPlacaNueva,
        CedulaFuncionario, setCedulaFuncionario,
        NombreFuncionario, setNombreFuncionario,
        apellido1, setapellido1,
        apellido2, setapellido2,
        codigo, setCodigo,
        edificioAsignacion, setEdificioAsignacion,
        nombreEdificio, setNombreEdificio,
        
    };
};