import { useState } from 'react';

export const TbPerfil= () => {
    const [cedula, setCedula] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido1, setApellido1] = useState('');
    const [apellido2, setApellido2] = useState('');
    
    return {
        cedula,setCedula,
        nombre, setNombre,
        apellido1, setApellido1,
        apellido2, setApellido2,
    };
};