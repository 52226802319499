import { useState } from 'react';



/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbAsignaturas
 */
export const TbAsignaturas = () => {
 const [CodigoAsignatura, setCodigoAsignatura] = useState('');
  const [NombreAsignatura, setNombreAsignatura] = useState('');
  const [CalificacionMinima, setCalificacionMinima] = useState('');
  const [AsignaturaCentro, setAsignaturaCentro] = useState('');
  const [CalificacionValida, setCalificacionValida] = useState(true);
  const [AsignaturaValida, setAsignaturaValida] = useState(true);
  const [Asignaturas, setAsignaturas] = useState([]);
  const [NombreInst, setNombreInst] = useState('');
  let CodigoPresupuestario;

  return {
    CodigoAsignatura, setCodigoAsignatura,
    NombreAsignatura, setNombreAsignatura,
    CalificacionMinima, setCalificacionMinima,
    AsignaturaCentro, setAsignaturaCentro,
    CalificacionValida, setCalificacionValida,
    AsignaturaValida, setAsignaturaValida,
    Asignaturas, setAsignaturas,
    NombreInst, setNombreInst,
    CodigoPresupuestario,
    
  };
};