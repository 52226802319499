
import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;

/**
 * Metodo para verificar si la institución que se va a registrar ya se encuentra en el sistema
 * @returns Retorna true si existe y False si no existe
 */
export async function ExisteInstitucion(data) {
    const url = endpoint.UrlExisteInstitucion;

    if (!url || !data) {
        alert('URL o datos no especificados.');
        return false;
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        // Verificar el estado de la respuesta y devolver el valor booleano correspondiente
        console.log(responseData.estado);
        return responseData.estado;

    } catch (error) {
        console.error('Error al procesar la solicitud:', error);

    }
}


/**
 * Metodo para guardar la institucion en la base de datos
 * @param {*} data Datos a guardar en un arreglo sencillo
 * @returns retorna true si el guardado fue exitoso false si no se puedo guardar
 */
export async function saveInstitucion(data) {

    url = endpoint.UrlSaveInstitucion;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {


        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            SuccessMessage('Exitoso', 'Institucion registrada exitosamente');
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
        return true;

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

/**
 * Método para obtener información de la institución desde la base de datos.
 * @returns {Promise<Object|null>} Retorna los datos de la institución si la solicitud se realizó con éxito, de lo contrario null.
 */
export async function getInstitucion(data) {
    const url = endpoint.UrlGetInstitucion;

    if (!url) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const instituciones = await response.json();
        if (instituciones.results && instituciones.results.length > 0) {
            const nombreInstitucion = instituciones.results[0].nombreInstitucion;

            return nombreInstitucion; // Devolvemos el nombre de la institución obtenido
        } else {
            console.log('No se encontraron resultados de instituciones.');
            return null;
        }




        return instituciones.results.nombreInstitucion; // Devolvemos los datos obtenidos
    } catch (error) {
        console.log('Error durante el fetch: => ' + error);
        return null;
    }
}

export async function getInstituciondatos(data) {
    const url = endpoint.UrlGetInstitucion;
    // const CodigoPresupuestario = obtenerIdInstitucion();
    // const data = { codigoPresupuestario: CodigoPresupuestario };

    if (!url) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const instituciones = await response.json();
        if (instituciones.results && instituciones.results.length > 0) {

            //alert(JSON.stringify(instituciones.results));
            return instituciones.results; // Devolvemos el nombre de la institución obtenido
        } else {
            console.log('No se encontraron resultados de instituciones.');
            return { estado: 0 };
        }







    } catch (error) {

        return { estado: 0 };
    }
}

export async function getInstituciondatos2(data) {
    url = endpoint.UrlGetInstitucion;
    let institucionesConDatosCompletos = null;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const institucionesResponse = await response.json();

        // Verificar si la respuesta es un arreglo
        if (!institucionesResponse.hasOwnProperty('results')) {
            throw new Error('La respuesta no es un arreglo de instituciones.');
        }


        const direccionesRegionales = require('../CapaPresentacion/Json/DireccionesRegionales.json');
        const ubicaciones = require('../CapaPresentacion/Json/ubicaciones.json');


        institucionesConDatosCompletos = institucionesResponse.results.map(institucion => {
            const direccionRegionalData = direccionesRegionales[institucion.direccionRegional.toString()];
            const circuitoData = direccionRegionalData ? direccionRegionalData.Circuitos[institucion.circuito.toString()] : null;
            const provinciaData = ubicaciones[institucion.Provincia.toString()];
            const cantonData = provinciaData ? provinciaData.cantones[institucion.Canton.toString()] : null;
            const distritoData = cantonData ? cantonData.distritos[institucion.Distrito.toString()] : null;
            const barrioData = distritoData ? distritoData.barrios[institucion.Barrio.toString()] : null;

            return {
                ...institucion,
                direccionRegionalC: direccionRegionalData ? direccionRegionalData.DireccionRegional : '',
                circuitoC: circuitoData ? circuitoData.NombreCircuito : '',
                provincia: provinciaData ? provinciaData.nombre_provincia : '',
                canton: cantonData ? cantonData.nombre_canton : '',
                distrito: distritoData ? distritoData.nombre_distrito : '',
                barrio: barrioData ? barrioData : ''
            };
        });

        return institucionesConDatosCompletos; // Devolvemos los datos obtenidos

    } catch (error) {
        return { estado: 0 };
    }
}

/**
 * Funcion que retorna un arreglo con las instituciones activas
 * @param {*} data parametro de filtrado en formato Json {"estado": 1 }
 * @returns retorna un arreglo con todas las instituciones activas del sistema
 */
export async function getInstitucionActiva(data) {
    url = endpoint.UrlGetInstitucioneActivas;
    let institucionesConDatosCompletos = null;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
//alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const institucionesResponse = await response.json();

        // Verificar si la respuesta es un arreglo
        if (!institucionesResponse.hasOwnProperty('results')) {
            throw new Error('La respuesta no es un arreglo de instituciones.');
        }


        const direccionesRegionales = require('../CapaPresentacion/Json/DireccionesRegionales.json');
        const ubicaciones = require('../CapaPresentacion/Json/ubicaciones.json');


        institucionesConDatosCompletos = institucionesResponse.results.map(institucion => {
            const direccionRegionalData = direccionesRegionales[institucion.direccionRegional.toString()];
            const circuitoData = direccionRegionalData ? direccionRegionalData.Circuitos[institucion.circuito.toString()] : null;
            const provinciaData = ubicaciones[institucion.Provincia.toString()];
            const cantonData = provinciaData ? provinciaData.cantones[institucion.Canton.toString()] : null;
            const distritoData = cantonData ? cantonData.distritos[institucion.Distrito.toString()] : null;
            const barrioData = distritoData ? distritoData.barrios[institucion.Barrio.toString()] : null;

            return {
                ...institucion,
                direccionRegionalC: direccionRegionalData ? direccionRegionalData.DireccionRegional : '',
                circuitoC: circuitoData ? circuitoData.NombreCircuito : '',
                provincia: provinciaData ? provinciaData.nombre_provincia : '',
                canton: cantonData ? cantonData.nombre_canton : '',
                distrito: distritoData ? distritoData.nombre_distrito : '',
                barrio: barrioData ? barrioData : ''
            };
        });

        return institucionesConDatosCompletos; // Devolvemos los datos obtenidos

    } catch (error) {
        return { estado: 0 };
    }
}

export async function getInstitucionSeleccionada() {
    const data = {codigoPresupuestario: obtenerIdInstitucion()}
    url = endpoint.urlInstitucionSeleccionada;
    let institucionesConDatosCompletos = null;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
//alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const institucionesResponse = await response.json();

        // Verificar si la respuesta es un arreglo
        if (!institucionesResponse.hasOwnProperty('results')) {
            throw new Error('La respuesta no es un arreglo de instituciones.');
        }


        const direccionesRegionales = require('../CapaPresentacion/Json/DireccionesRegionales.json');
        const ubicaciones = require('../CapaPresentacion/Json/ubicaciones.json');


        institucionesConDatosCompletos = institucionesResponse.results.map(institucion => {
            const direccionRegionalData = direccionesRegionales[institucion.direccionRegional.toString()];
            const circuitoData = direccionRegionalData ? direccionRegionalData.Circuitos[institucion.circuito.toString()] : null;
            const provinciaData = ubicaciones[institucion.Provincia.toString()];
            const cantonData = provinciaData ? provinciaData.cantones[institucion.Canton.toString()] : null;
            const distritoData = cantonData ? cantonData.distritos[institucion.Distrito.toString()] : null;
            const barrioData = distritoData ? distritoData.barrios[institucion.Barrio.toString()] : null;

            return {
                ...institucion,
                direccionRegionalC: direccionRegionalData ? direccionRegionalData.DireccionRegional : '',
                circuitoC: circuitoData ? circuitoData.NombreCircuito : '',
                provincia: provinciaData ? provinciaData.nombre_provincia : '',
                canton: cantonData ? cantonData.nombre_canton : '',
                distrito: distritoData ? distritoData.nombre_distrito : '',
                barrio: barrioData ? barrioData : ''
            };
        });

        return institucionesConDatosCompletos; // Devolvemos los datos obtenidos

    } catch (error) {
        return { estado: 0 };
    }
}





/**
 * Funcion para actualizar la institucion
 * @param {*} data Arreglo con los datos en formato JSON para realizar la actualizacion de datos
 * @returns retorna true su es correcta y false si no se realizar la actualizacion
 */
export const getActualizarInstitucion = async (data) => {
    const url = endpoint.UrlActualizarInstitucion;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return { estado: false, mensaje: 'URL o datos no especificados.' };
    }

    try {
         
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        if (responseData.estado) {
            SuccessMessage('Exitoso', responseData.mensaje);
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }

    } catch (error) {

        return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la actualizacion de la institucion ' };
    }
};

/**
 * Funcion para elimiar la Institucion
 * @param {*} data parametro para realizar la eliminacion en formato JSON {codigoPresupestario: "string", estado:0}
 * @returns retorna true su es correcta y false si no se pudo eliminar
 */
export async function getDeleteInstitucion(data) {
    const url = endpoint.UrlDeleteInstitucion;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');

    }

    try {

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        if (responseData.estado) {
            SuccessMessage('Exitoso', responseData.mensaje);
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }

    } catch (error) {

        return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la actualizacion de la institucion ' };
    }
}

/**
 * Funcion para restaurar la Institucion
 * @param {*} data parametro para realizar la eliminacion en formato JSON {codigoPresupestario: "string", estado:1}
 * @returns retorna true su es correcta y false si no se pudo restaurar
 */
export async function getRestoreInstitucion(data) {
    const url = endpoint.UrlDeleteInstitucion;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');

    }

    try {

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        if (responseData.estado) {
            SuccessMessage('Exitoso', responseData.mensaje);
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }

    } catch (error) {

        return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la actualizacion de la institucion ' };
    }
}

export async function getTipoInstitucion(data) {
    url = endpoint.UrlGetInstitucion;
   
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
            //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const institucionesResponse = await response.json();

        // Verificar si la respuesta es un arreglo
        if (!institucionesResponse.hasOwnProperty('results')) {
            throw new Error('La respuesta no es un arreglo de instituciones.');
        }



       //alert(JSON.stringify(institucionesResponse.results[0].tipoInstitucion));
        
        
        return institucionesResponse.results[0].tipoInstitucion; // Devolvemos los datos obtenidos

    } catch (error) {
        return [{ estado: 0 }];
    }
}