import { useState } from 'react';



/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbInstitucion
 */
export const TbInstitucion = () => {
  const [tipoDireccion, setTipoDireccion] = useState('');
 const [tipoInstitucion, setTipoInstitucion] = useState('');
  const [codigoInstitucion, setCodigoInstitucion] = useState('');
  const [nombreInstitucion, setNombreInstitucion] = useState('');
  const [direccionRegional, setDireccionRegional] = useState('');
  const [circuito, setCircuito] = useState('');
  const [telefono, setTelefono] = useState('');
  const [provinciaInstitucion, setProvinciaInstitucion] = useState('');
  const [cantonInstitucion, setCantonInstitucion] = useState('');
  const [distritoInstitucion, setDistritoInstitucion] = useState('');
  const [barrioInstitucion, setBarrioInstitucion] = useState('');
  const [direccion, setDireccion] = useState('');
  const [logo, setLogo] = useState(null);
  const [director, setDirector] = useState('');
  const [estadoInst , setEstadoInst] = useState(1);

  const [ListaRegionales, setListaRegionales] = useState([]);
    const [ListaCircuitos, setListaCircuitos] = useState([]);
    const [ListaProvinciasInst, setListaProvinciasInst] = useState([]);
    const [ListaCantonesInst, setListaCantonesInst] = useState([]);
    const [ListaDistritosInst, setListaDistritosInst] = useState([]);
    const [ListaBarriosInst, setListaBarriosInst] = useState([]);
    const [ListaTipoDereccion, setListaTipoDereccion] = useState([]);
    const [ListaTipoInstitucion, setListaTipoInstitucion] = useState([]);


  return {
    tipoDireccion, setTipoDireccion,
    tipoInstitucion, setTipoInstitucion,
    codigoInstitucion, setCodigoInstitucion,
    nombreInstitucion, setNombreInstitucion,
    direccionRegional, setDireccionRegional,
    circuito, setCircuito,
    telefono, setTelefono,
    provinciaInstitucion, setProvinciaInstitucion,
    cantonInstitucion, setCantonInstitucion,
    distritoInstitucion, setDistritoInstitucion,
    barrioInstitucion, setBarrioInstitucion,
    direccion, setDireccion,
    logo, setLogo,
    director, setDirector,
    estadoInst , setEstadoInst,

    ListaRegionales, setListaRegionales, 
    ListaCircuitos, setListaCircuitos,
    ListaProvinciasInst, setListaProvinciasInst,
    ListaCantonesInst, setListaCantonesInst,
    ListaDistritosInst, setListaDistritosInst,
    ListaBarriosInst, setListaBarriosInst,
    ListaTipoDereccion, setListaTipoDereccion,
    ListaTipoInstitucion, setListaTipoInstitucion,
  };
};
