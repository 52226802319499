import { ErrorMessage, SuccessMessage } from '../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../../CapaUtilidades/Utils/encryptUserData';
import endpoint from '../EndPoints/endPoint';

var url = '';
var estado;

export async function RegistrarEventoAgenda(data) {

    url = endpoint.urlGuardarAgenda;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}

export async function ActualizarEventoAgenda(data) {

    url = endpoint.urlActualizarAgenda;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
       //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}

export async function ListarAgenda(data) {
    const url = endpoint.urlListarAgenda;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseAgenda = await response.json();

        // Mapear los datos al formato deseado
        const eventos = responseAgenda.datos.map(evento => {
            return {
                ID: evento.ID,
                title: evento.Titulo,
                start: new Date(
                    evento.AnioInicial,
                    evento.MesInicial - 1, // Mes en JavaScript es 0-indexado
                    evento.DiaInicial,
                    evento.HoraInicial,
                    evento.MinutoInicial
                ),
                end: new Date(
                    evento.AnioFinal,
                    evento.MesFinal - 1, // Mes en JavaScript es 0-indexado
                    evento.DiaFinal,
                    evento.HoraFinal,
                    evento.MinutoFinal
                ),
            };
        });

        //console.log(eventos);
        return eventos; // Retornar los eventos en el formato deseado

    } catch (error) {
        console.log(error.message);
        return [];
    }
}


export async function EliminarEventoAgenda(data) {

    url = endpoint.urlEliminarAgenda;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}