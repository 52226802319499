import { useState } from "react";

export const TbAsignaturasDocente = () => {
    
    const [CodigoAsignatura, setCodigoAsignatura] = useState('');
    const [docente, setDocente] = useState(true);
    const [selectedYear, setSelectedYear] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [estadoAsig, setEstadoAsig] = useState();
    return {
        CodigoAsignatura, setCodigoAsignatura,
        docente, setDocente,
        selectedYear, setSelectedYear,
        institucion, setInstitucion,
        estadoAsig, setEstadoAsig
    };
};

