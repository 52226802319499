import { useState } from 'react';

export const TbQR = () => {
    const [qr, setQr] = useState('')
    const [loading, setLoading] = useState(true);
    
    return {
        qr, setQr,
        loading, setLoading,
        
    };
};