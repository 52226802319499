import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader, Table, ModalHeader, Spinner, InputGroup } from 'reactstrap';
import MenuItem from '../../../components/MenuItem';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { obtenerIdInstitucion, obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { TbPerfil } from '../../../../CapaEntidades/TbPerfil';
import { TbMatricula } from '../../../../CapaEntidades/TbMatricula';
import { TbAsistencia } from '../../../../CapaEntidades/TbAsistencia';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { getSeccionesDocente } from '../../../../CapaDatos/DatosSeccionesDocente';
import { getTipoInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';
import { GetGrados } from '../../../../CapaDatos/DatosCargarSelects';
import { getDatosUsuario } from '../../../../CapaDatos/DatosUsuarios';
import { TbComunicados } from '../../../../CapaEntidades/TbComunicados';
import { EnviarComunicado, ListaEncargadosEstudiantes } from '../../../../CapaDatos/DatosDocentes/DatosComunicados';
import * as Io from "react-icons/io";
import Cargando from '../../../components/Cargando';
import { SuccessMessage, WarningMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import 'emoji-picker-element';
import EmojiPicker from 'emoji-picker-react'; // Importamos el picker de emojis
import { BsEmojiSmile } from "react-icons/bs";
import { FcCheckmark } from "react-icons/fc";

const FrmComunicados = ({ codigoPresupuestario, codigoNombre }) => {

    const TBSecciones = TbSecciones();
    const TBPerfil = TbPerfil();
    const TBMatricula = TbMatricula();
    const TBAsistencia = TbAsistencia();
    const TBFUncionario = TbFuncionario();
    const TBComunicados = TbComunicados();


    const ObtenerListaSeccionesDelDocente = async () => {
        try {

            const parametros = {
                profesor: obtenerUsuario(),
                annio: new Date().getFullYear().toString(),
                institucion: obtenerIdInstitucion(),
                estado: 1
            }

            const data = await getSeccionesDocente(parametros);
            // Validar si data está vacío
            if (data.length === 0) {
                TBSecciones.setExistenSecciones(false)
                TBSecciones.setListaSeccionesDocente([]); // Puedes asignar un array vacío o manejar el caso de otra forma
            } else {
                TBSecciones.setExistenSecciones(true)
                TBSecciones.setListaSeccionesDocente(data);
            }

            //console.log(JSON.stringify(data));

            const parametro = { codigoPresupuestario: obtenerIdInstitucion() };
            const tipoIns = await getTipoInstitucion(parametro);
            TBAsistencia.setTipoInstitucion(tipoIns);



            TBComunicados.setLoading(false);
        } catch (error) {
            TBComunicados.setLoading(true);
        }
    };

    const ObtenerListaEncargados = async () => {
        try {
            const data2 = {

                institucion: obtenerIdInstitucion(),
                cursoLectivo: TBMatricula.añoActual,

            }
            const lista = await ListaEncargadosEstudiantes(data2);
            TBComunicados.setListaEncargados(lista)
            //TBComunicados.setLoading(false);
        } catch (error) {
            //TBComunicados.setLoading(false);
        }
    };

    useEffect(() => {
        ObtenerListaSeccionesDelDocente();
        ObtenerListaEncargados();

        GetGrados(TBAsistencia.setListaGrados);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDatosUsuario();

                TBPerfil.setNombre(data[0].nombreCompleto)
                TBPerfil.setCedula(data[0].cedula)
                TBPerfil.setApellido1(data[0].apellido1)
                TBPerfil.setApellido2(data[0].apellido2)

            } catch (error) {
                console.error("Error al obtener los datos del usuario:", error);
            }
        };

        fetchData();
    }, []);

    const GradosFiltrados = TBAsistencia.ListaGrados.filter(grado => {
        // Extraer los niveles de TBSecciones.listaSeccionesDocente
        const nivelesDocente = TBSecciones.listaSeccionesDocente.map(seccion => seccion.nivel);

        if (TBAsistencia.tipoInstitucion === 1) {
            // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
            return grado.id >= -1 && grado.id <= 6 && nivelesDocente.includes(grado.id);
        } else if (TBAsistencia.tipoInstitucion === 2) {
            // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
            return grado.id >= 7 && grado.id <= 12 && nivelesDocente.includes(grado.id);
        }

        return false;
    });





    // Función para manejar el evento de seleccionar/deseleccionar todos los encargados
    const handleSelectAll = (gradoId, seccion, isSelected) => {
        TBComunicados.setSelectedEncargados(prevState => {
            let newState = [...prevState]; // Copiamos el array anterior

            TBComunicados.ListaEncargados
                .filter(encargado => encargado.nivel === gradoId && encargado.seccion === seccion)
                .forEach(encargado => {
                    if (isSelected) {
                        // Si está seleccionado, agregamos el teléfono si no está ya presente
                        if (!newState.some(item => item.telefono === encargado.telefono)) {
                            newState.push({ telefono: encargado.telefono });
                        }
                    } else {
                        // Si se deselecciona, lo eliminamos comparando solo el teléfono
                        newState = newState.filter(item => item.telefono !== encargado.telefono);
                    }
                });

            console.log('Teléfonos seleccionados:', newState);
            return newState; // Retornamos el array actualizado
        });
    };



    const handleCheckboxChange = (telefono, isSelected) => {
        TBComunicados.setSelectedEncargados(prevState => {
            let newState = [...prevState];

            if (isSelected) {
                if (!newState.some(item => item.telefono === telefono)) {
                    newState.push({ telefono });
                }
            } else {
                newState = newState.filter(item => item.telefono !== telefono);
            }
            console.log('Teléfono seleccionado:', newState);
            return newState;
        });
    };

    // Para sincronizar los checkboxes con el estado de selectedEncargados
    const isCheckboxSelected = (telefono) => {
        return TBComunicados.selectedEncargados.some(item => item.telefono === telefono);
    };

    const mostrarModal = () => {
        // Verifica si hay datos seleccionados
        if (TBComunicados.selectedEncargados.length > 0) {
            TBComunicados.setModalOpen(!TBComunicados.modalOpen);
        } else {
            // Aquí puedes mostrar un mensaje o alerta si no hay encargados seleccionados
            WarningMessage("", "Por favor, selecciona al menos un encargado antes de continuar.");
        }
    };

    const onEmojiClick = (emojiData) => {
        // Obtener el valor actual del textarea
        const mensajeActual = document.getElementById('eventTitle').value; // Obtener el texto directamente del textarea

        // Concatenar el emoji seleccionado al texto actual
        TBComunicados.setMensaje(mensajeActual + emojiData.emoji);
    };

    const EnviarMensaje = async (event) => {
        event.preventDefault();
        TBComunicados.setEnviando(true)
        TBComunicados.setShowEmojiPicker(false)
        // Contador de envíos
        let totalEnvios = TBComunicados.selectedEncargados.length;

        // Recorrer cada encargado seleccionado
        TBComunicados.selectedEncargados.forEach((encargado, index) => {
            const destinatario1 = encargado.telefono;
            const destinatario = `506${encargado.telefono}`;  // Obtener el teléfono del encargado
            const mensaje = `${TBComunicados.detalleComunicado}, ${TBComunicados.mensaje}`;
            const data = {
                mensaje: mensaje,
                destinatario: destinatario,
                fechaEnvio: TBComunicados.fechaEnvio,
                enviadoPor: TBComunicados.enviadoPor,
                institucionEnvia: TBComunicados.institucionEnvia,
                cursoLectivo: TBComunicados.cursoLectivo,
                detalleComunicado: TBComunicados.detalleComunicado,
                medioComunicado: "whatsapp"  // o "mail" si se envía por correo
            };
            //console.log(JSON.stringify(data))
            // Generar un intervalo aleatorio entre 0 y 10 segundos (1000ms = 1s)
            const delay = Math.random() * 10000;  // Aleatorio entre 0 y 10000ms (0 a 10 segundos)

            // Usar setTimeout para retrasar el envío
            setTimeout(async () => {
                //console.log("Enviando comunicado:", data);  // Reemplaza esto con tu lógica real de envío
                const estado = await EnviarComunicado(data);
                //alert(destinatario1)
                totalEnvios--;  // Disminuir el contador de envíos

                if (estado) {

                    // Asegúrate de que ListaEncargadosEstudiantes esté definido y sea un array
                    if (Array.isArray(TBComunicados.ListaEncargados)) {
                        const encargadoExitoso = TBComunicados.ListaEncargados.find(enc => enc.telefono === destinatario1);

                        if (encargadoExitoso) {
                            const nombreEncargado = encargadoExitoso.NombreEncargado || 'Nombre no disponible';

                            TBComunicados.setListaEnviados(prev => [...prev, nombreEncargado]);
                        } else {
                            //console.log("No se encontró al encargado con el teléfono:", destinatario1);
                        }
                    } else {
                        //console.error("ListaEncargadosEstudiantes no está definida o no es un array");
                    }
                } else {
                    // Manejo de error para envíos fallidos
                    if (Array.isArray(TBComunicados.ListaEncargados)) {
                        const encargadoFallido = TBComunicados.ListaEncargados.find(enc => enc.telefono === destinatario1);
                        const nombreEncargado = encargadoFallido ? encargadoFallido.NombreEncargado : 'Encargado desconocido';

                        WarningMessage("", `Error al enviar mensaje a ${nombreEncargado} (Tel: ${destinatario})`);
                    } else {
                        //console.error("ListaEncargadosEstudiantes no está definida o no es un array en el caso de error.");
                    }
                }


                // Cerrar el modal si todos los mensajes han sido procesados
                if (totalEnvios === 0) {
                    TBComunicados.setModalOpen(false);
                    TBComunicados.setSelectedEncargados([])
                    TBComunicados.setSelectAll(false)
                    TBComunicados.setEnviando(false)
                    TBComunicados.setListaEnviados([])
                    TBComunicados.setDetalleComunicado('')
                    TBComunicados.setMensaje('')
                }

            }, delay);

            //console.log(`Mensaje para ${destinatario} se enviará en ${Math.round(delay / 1000)} segundos`);
        });
    };


    if (TBComunicados.loading) {
        return (
            <Cargando />
        );
    }




    return (
        <Container fluid className=" Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Comunicados"
                        to="/Menu/FrmComunicados"
                        classname={'NoDecoration2 tlink'}
                    />
                </Breadcrumb>
            </Col>

                <Container fluid className='  px-0  ' >
                    <Tabs className={'p-0 m-0  border  '}>
                        <TabList className={'tabs_ m-0'}>
                            {GradosFiltrados.map((grados, index) => (
                                <Tab key={index} >
                                    {grados.nombre}
                                </Tab>
                            ))}
                        </TabList>
                        <Container fluid className=' shadow2 px-0 contenedorTabsComunicado rounded-0 ' >
                        {TBSecciones.existenSecciones ?(
                            <Container fluid className='tamañoContComunicados px-0'>
                                {GradosFiltrados.map((grado, index) => (
                                    <TabPanel key={index}>
                                        <Container fluid className='px-0'>
                                            <Tabs className={'p-0 m-0    '}>
                                                <TabList className={'tabs_ m-0 pt-1'} >
                                                    {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map(seccion => (
                                                        <Tab>{seccion.nombreSeccion}</Tab>
                                                    ))}

                                                </TabList>
                                                {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map((seccion, secIndex) => (
                                                    <TabPanel key={secIndex}>

                                                        <Container fluid className='p-0 table-responsive p-1' >
                                                            <Table className="table table-striped table-bordered" style={{ minWidth: '600px' }}>
                                                                <thead className='border-bottom border'>
                                                                    <tr>
                                                                        <th className='pb-1 fixed-column50'>
                                                                            <Col className='d-flex justify-content-center' >
                                                                                <Input
                                                                                    required
                                                                                    type="checkbox"
                                                                                    checked={TBComunicados.selectAll}
                                                                                    onChange={(e) => {
                                                                                        handleSelectAll(grado.id, seccion.nombreSeccion, e.target.checked);
                                                                                        TBComunicados.setSelectAll(e.target.checked);
                                                                                    }}
                                                                                />

                                                                            </Col>
                                                                        </th>
                                                                        <th className='pb-0 fixed-column100'>
                                                                            <a className='cursor' >  Cedula </a>
                                                                        </th>
                                                                        <th className='pb-0 fixed-column350'>
                                                                            <a className='cursor' >  Encargado </a>
                                                                        </th>
                                                                        <th className='pb-0 fixed-column350'>
                                                                            <a className='cursor' >  Estudiante </a>
                                                                        </th>
                                                                        <th className='pb-0 fixed-column100'>
                                                                            <a className='cursor' >  Telefono </a>
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className='tablebody' id='tablebody'>
                                                                    {
                                                                        TBComunicados.ListaEncargados
                                                                            .filter(encargado => encargado.nivel === grado.id && encargado.seccion === seccion.nombreSeccion)
                                                                            .map(encargado => (
                                                                                <tr >
                                                                                    <td>
                                                                                        <Col className='d-flex justify-content-center'>
                                                                                            <Input
                                                                                                type="checkbox"
                                                                                                checked={isCheckboxSelected(encargado.telefono)} // Sincronizar estado del checkbox
                                                                                                onChange={(e) => handleCheckboxChange(encargado.telefono, e.target.checked)} // Manejar el cambio
                                                                                            />
                                                                                        </Col>

                                                                                    </td>
                                                                                    <td  >{encargado.cedulaEncargado}</td>
                                                                                    <td  >{encargado.NombreEncargado} </td>
                                                                                    <td  >{encargado.estudiante}</td>
                                                                                    <td  >{encargado.telefono}</td>

                                                                                </tr>
                                                                            ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Container>
                                                    </TabPanel>
                                                ))}

                                            </Tabs>
                                        </Container>
                                    </TabPanel>
                                ))}
                            </Container>
                            ): (
                                <Container fluid className='d-flex justify-content-center' >
                                <Label className='justify-content-center mt-5'>Actualmente no hay secciones registradas en el sistema.</Label>
                            </Container>
                               )}
                            <Container fluid className=' '>
                                <Row className=' '>
                                    <Col className='d-flex justify-content-end'>
                                    {TBSecciones.existenSecciones ? (
                                        <Button className='btnAceptar p-1 px-3' onClick={mostrarModal}>
                                            Crear Comunicado
                                        </Button>
                                        ): (
                                            <Label></Label>
                                           )}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>

                    </Tabs>

                </Container>
            
            <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<Io.IoMdReturnLeft className="me-1" />}
                        text="Volver"
                        to="/Menu"
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>

            <Modal size='lg' fullscreen='lg' isOpen={TBComunicados.modalOpen} className='mt-0' >
                <ModalHeader className='color-header-form px-3 py-2' toggle={mostrarModal}>
                    Crear Comunicado
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={EnviarMensaje}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Asunto</Label>
                                    <Input
                                        type='text'
                                        required
                                        value={TBComunicados.detalleComunicado}
                                        onChange={(e) => TBComunicados.setDetalleComunicado(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <Row className=''>
                                    <Col xs={12} sm={12} md={12} lg={12} className='mb-0 pb-0'>
                                        <FormGroup className="message-input-container m-0">
                                            {/* Botón para emojis a la izquierda */}
                                            <Button
                                                type="button"
                                                onClick={() => TBComunicados.setShowEmojiPicker(!TBComunicados.showEmojiPicker)}
                                                className='boton-emoji p-1'
                                            >
                                                <BsEmojiSmile className='emoji-color' />
                                            </Button>

                                            {/* Campo de entrada de texto */}
                                            <Input
                                                type="textarea"
                                                id="eventTitle"
                                                required
                                                placeholder="Escribe un mensaje"
                                                value={TBComunicados.mensaje}
                                                onChange={(e) => {
                                                    TBComunicados.setMensaje(e.target.value);
                                                    TBComunicados.setShowEmojiPicker(false)
                                                    e.target.style.height = 'auto'; // Resetea la altura para recalcular
                                                    e.target.style.height = `${e.target.scrollHeight}px`; // Ajusta la altura según el contenido

                                                }}
                                                style={{ border: 'none', backgroundColor: 'transparent', width: '100%', resize: 'none', flex: 1, minHeight: '40px', maxHeight: '150px' }}
                                            />

                                            {/* Selector de emojis */}

                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} className='mt-0 pt-0'>
                                        {TBComunicados.showEmojiPicker && (
                                            <div className="custom-emoji-picker-container">
                                                <EmojiPicker
                                                    height={250}
                                                    width={'100%'}
                                                    onEmojiClick={onEmojiClick}
                                                    disableSearchBar={true} // Si deseas ocultar la barra de búsqueda
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                            </Col>


                        </Row>
                        <Col className='d-flex justify-content-between mt-3'>
                            <Button className=' p-1     btnCancelar px-3' onClick={mostrarModal}>
                                Cancelar
                            </Button>
                            <Button type='submit' className='btnAceptar p-1 px-3'  >
                                Enviar Comunicado
                            </Button>
                        </Col>
                    </Form>
                    <Col className='mt-4'>
                        {TBComunicados.enviando && (
                            <Col md={12} className="me-2 d-flex justify-content-center">
                                <Label className="me-2 mx-2">
                                    Un momento por favor enviando mensajes...
                                </Label>
                                <Spinner
                                    color="success"
                                    size="sm"
                                    type="grow"
                                    className='mx-2'
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="success"
                                    size="sm"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </Col>
                        )}
                        <h6>Mensajes envidados a </h6>
                        <ul className='enviados-list'>
                            {TBComunicados.listaEnviados.length > 0 ? (
                                TBComunicados.listaEnviados.map((nombreEncargado, index) => (
                                    <li key={index}>
                                        <FcCheckmark className='mx-2' />
                                        <Label className='font-enviados'>
                                            {nombreEncargado}
                                        </Label>
                                    </li>
                                ))
                            ) : (
                                <Label>No se ha enviado ningún comunicado aún.</Label>
                            )}
                        </ul>
                    </Col>
                </ModalBody>
            </Modal>
        </Container >
    )
}

export default FrmComunicados
