import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, 
    Label, 
    Breadcrumb
} from 'reactstrap';
import Camara from '../../assets/faces/Camara plus.png';
import { getInstitucionActiva, getRestoreInstitucion } from '../../../CapaDatos/DatosDesarrolladores';
import { removeBase64Prefix } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Cargando from '../../components/Cargando';
import SinDatos from '../../components/SinDatos';
import MenuItem from '../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { ErrorMessage } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';

const ListaInstitucionesInactivas = () => {
    const [loading, setLoading] = useState(true);
    const [instituciones, setInstituciones] = useState([]);
    const [codigo, setCodigo] = useState('');


    const fetchData = async () => {
        try {
            const parametro = { estado: 0 };
            const data = await getInstitucionActiva(parametro);
            setInstituciones(data);
            setLoading(false);
        } catch (error) {
            console.error('Error al obtener las instituciones:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    if (loading) {
        return (
            <Cargando />
        );
    }

    if (instituciones.estado === 0) {
        return (
            <SinDatos
                mensaje={"error 404"}
                error={"Lo sentimos, a ocurrido un error con el servidor comunicate con el departamento de TI"}
                route="/Menu" 
                direccionactual = {"Desarrolladores / Instituciones Inactivas"} 
                mostrarboton = {false}
                />
        );
    }
    if (!instituciones?.length) {
        // Mensaje si no hay instituciones registradas
        return (
            
            <SinDatos
                mensaje={"No existen instituciones suspendidas."}
                route="/Menu/ListaInstituciones"
                direccionactual = {"Desarrolladores / Instituciones Inactivas"} 
                mostrarboton = {false}
                />
                


        );
    }





    const Restaurar = async (data, institucion) => {
        Swal.fire({
            title: "Estas seguro que deseas restaurar la Institución " + institucion + "?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, restaurar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await getRestoreInstitucion(data)
                if (success.estado) {
                    Swal.fire({
                        title: "¡Restaurado!",
                        text: "La Institucion ha sido restaurada",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false
                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });





    }










    // Renderizar las instituciones
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Desarrolladores "
                        to="/Menu/ListaInstituciones"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Instituciones Activas"
                        to="/Menu/ListaInstitucionesInactivas"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>
                
            </Col>
           
            <Container fluid className='p-0 Mymt-5'>
                <Row>
                    {instituciones
                        .filter(institucion => institucion.codigoPresupuestario !== '0000')
                        .map((institucion, index) => (
                            <Col key={index} className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                                <Card className="mb-3 shadow rounded-3">
                                    <CardHeader>
                                        <CardTitle> <strong>{institucion.nombreInstitucion}</strong></CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <CardText>
                                            <Row>
                                                <Col md={8}>
                                                    <strong>Código Presupuestario:</strong> {institucion.codigoPresupuestario}<br />
                                                    <strong>Dirección Regional:</strong> {institucion.direccionRegionalC}<br />
                                                    <strong>Circuito:</strong> {institucion.circuitoC}<br />

                                                </Col>
                                                <Col md={4} className='p-0 dflex justify-content-center'>
                                                    <div className="text-center">
                                                        {institucion.logo !== null ? (
                                                            <img src={`data:image/jpeg;base64,${removeBase64Prefix(institucion.logo)}`} alt="Foto de institución" className="img-fluid" />
                                                        ) : (
                                                            <img src={Camara} alt="Imagen por defecto" className="img-fluid" />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardText>
                                    </CardBody>
                                    <CardFooter>
                                        <Button type='button' className='mx-1 btnAceptar'
                                            onClick={(e) => Restaurar(
                                                {
                                                    codigoPresupuestario: institucion.codigoPresupuestario,

                                                    Estado: 1
                                                }, institucion.nombreInstitucion
                                            )} >
                                            Restaurar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        ))}
                </Row>
                <Button type="button" className='p-0 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className='btnAceptar'
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"

                            to="/Menu/ListaInstituciones"
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>


        </Container>
    );
};

export default ListaInstitucionesInactivas;
