import React, { useState, useEffect, useCallback } from 'react';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, Table, ModalHeader, Spinner, InputGroup, InputGroupText, Tooltip } from 'reactstrap';
import { TbQR } from '../../../../CapaEntidades/TbQR';
import { CrearQR } from '../../../../CapaDatos/DatosEstudiantes/DatosQR';
import Cargando from '../../../components/Cargando';
import * as Io from "react-icons/io";

const FrmGenerarQR = () => {

    const TBQR = TbQR();

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const generarQR = async () => {
        try {
            TBQR.setLoading(true); // Inicia el estado de carga

            const Qr = await CrearQR({ cedulaEstudiante: "209690488" });

            // Validar si Qr tiene datos y si el campo qr existe
            if (Qr && Qr.qr) {
                TBQR.setQr(Qr.qr);
            } else {
                console.error("El objeto Qr no tiene datos válidos o no contiene la propiedad 'qr'.");
            }

        } catch (error) {
            console.error("Ocurrió un error al generar el código QR:", error);
        } finally {
            TBQR.setLoading(false); // Finaliza el estado de carga
        }
    };

    useEffect(() => {
        generarQR()

    }, []);

    if (TBQR.loading) {
        return (
            <Cargando />
        );
    }

    return (
        <Container fluid className="Divcontenedor  ">
            <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center'>
                <Label for="files" className=''>
                    <strong> QR para asistencia a comedor y transporte</strong>
                </Label>
            </Col>
            <Container fluid className=' p-2 contenedorQR shadow2 rounded-0'>


                <img className='qr' src={TBQR.qr} alt="Código QR"  />

            </Container>
            <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<Io.IoMdReturnLeft className="me-1" />}
                        text="Volver"
                        to="/Menu"
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>
        </Container>
    )
}

export default FrmGenerarQR
