/**
 * Valida si una cadena contiene solo números.
 * @param {string} valor - La cadena que se va a validar.
 * @returns {boolean} Devuelve true si la cadena contiene solo números, de lo contrario, devuelve false.
 */
export const validarSoloNumeros = (valor) => {
    // Expresión regular para validar si una cadena contiene solo números
    return /^\d+$/.test(valor);
};
export const validarSoloNumerosPuntoDecimal = (valor) => {
    // Expresión regular para validar números enteros o con hasta dos decimales
    return /^(\d+(\.\d{0,2})?)?$/.test(valor);
};

/**
 * Valida si una cadena contiene solo letras y espacios.
 * @param {string} valor - La cadena que se va a validar.
 * @returns {boolean} Devuelve true si la cadena contiene solo letras y espacios, de lo contrario, devuelve false.
 */
export const validarSoloLetrasYEspacios = (valor) => {
    return /^[a-zA-ZáéíóúüÜÁÉÍÓÚñÑ\s'-]*$/.test(valor);
};

/**
 * Valida un número de teléfono en un formato común.
 * @param {string} telefono - El número de teléfono que se va a validar.
 * @returns {boolean} Devuelve true si el número de teléfono tiene el formato correcto, de lo contrario, devuelve false.
 */
export const validarNumeroTelefono = (telefono) => {
    // Expresión regular para validar un número de teléfono en un formato común (###-###-####)
    const regexTelefono = /^\d{3}-\d{3}-\d{4}$/;
    return regexTelefono.test(telefono);
};


/**
 * Valida una cédula con una longitud exacta de 9 o 12 dígitos.
 * @param {string} cedula - La cédula que se va a validar.
 * @returns {boolean} Devuelve true si la cédula tiene una longitud exacta de 9 o 12 dígitos, de lo contrario, devuelve false.
 */
export const validarCedula = (cedula) => {
    // Expresión regular para validar una cédula con una longitud exacta de 9 o 12 dígitos
    const regexCedula = /^\d{9}$|^\d{12}$/;
    return regexCedula.test(cedula);
};


/**
 * Valida un correo electrónico.
 * @param {string} correo - El correo electrónico que se va a validar.
 * @returns {boolean} Devuelve true si el correo electrónico tiene un formato válido (ej. usuario@dominio.com), de lo contrario, devuelve false.
 */
export const validarCorreoElectronico = (correo) => {
    // Expresión regular para validar un correo electrónico
    const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexCorreo.test(correo);
};


