import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import * as Fa6 from "react-icons/fa6";
import * as Io  from 'react-icons/io';
import * as Pi from "react-icons/pi";
import * as Gi from "react-icons/gi";
import * as Fc from "react-icons/fc";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import * as Bi from "react-icons/bi";
import { IoBookOutline } from "react-icons/io5";
import MenuItem from "./MenuItem";
import { Roll } from "../../CapaUtilidades/Utils/Cahe";
import { Button } from "reactstrap";
import { BsArrowsFullscreen } from "react-icons/bs";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import * as  Ai  from 'react-icons/ai';
import * as  Hi  from 'react-icons/hi';
/**
 * Componente Sidebar menu lateral
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.isOpen - Indica si la barra lateral está abierta.
 * @param {function} props.toggleSidebar - Función para alternar la visibilidad de la barra lateral.
 * @returns {JSX.Element} Componente Sidebar.
 */
const SidebarDocentes = ({ isOpen, toggleSidebar }) => {
  const [menuContraido, setMenuContraido] = useState(false);




  const [activeSubMenu, setActiveSubMenu] = useState(null);

  /**
  * Maneja el clic en un elemento de menú para alternar la visibilidad del submenú correspondiente.
  * @param {string} menuId - Identificador del menú.
  */
  const handleNavLinkClick = (menuId) => {
    setActiveSubMenu(activeSubMenu === menuId ? null : menuId);
  };

  const handleSidebarItemClick = () => {
    if (window.innerWidth <= 1200) {
      toggleSidebar();
    }
    setActiveSubMenu(null);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  const ContraerMenu = () => {
    setMenuContraido(!menuContraido);
  };

  return (
    <div className={`sidebar p-0 ${menuContraido ? "sidebarContrac" : ""} ${isOpen ? "" : "hidden"}`} id="sidebar">

      <div className="d-flex justify-content-end pr-3 ocultarbtnMenu" id="btnMenu" >
        <Button title="Pantalla Completa" onClick={ContraerMenu} className='btnMenu ocultarbtnMenu'>
           <Md.MdMenu size={25} className=" " />
        </Button>
      </div>


      <ul>
        <MenuItem
          icon={<Md.MdHome size={22} className="me-1  " />}
          text={menuContraido ? "" : "Inicio"}
          onClick={handleSidebarItemClick}
          to="/Menu/"
          classname={'text-sidebar '}
        />



        <MenuItem // Gestion Docentes
          icon={<Gi.GiTeacher size={22} className="me-1 " />}
          text={menuContraido ? "" : "Docentes"}
          subMenuItems={[
            { to: "/Menu/FrmRegistrarAsistencia",icon2:<Io.IoMdCheckboxOutline className="me-1" size={21} />, text: menuContraido ? "" : "Reg. Asistencia", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Io.IoMdWarning  className="me-1" size={21} />, text: menuContraido ? "" : "Reporte Incidencias", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Io.IoMdCreate className="me-1" size={21} />, text: menuContraido ? "" : "Creación Evaluaciones", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Io.IoMdStar className="me-1" size={21} />, text: menuContraido ? "" : "Reg. de Calificaciones", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Io.IoMdMail className="me-1" size={21} />, text: menuContraido ? "" : "Notificaciones a Padres", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Fa.FaTasks className="me-1" size={21} />, text: menuContraido ? "" : "Asignación de Tareas", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Fa.FaRegCalendarAlt  className="me-1" size={21} />, text: menuContraido ? "" : "Calendario Actividades", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Fa.FaCheckSquare className="me-1" size={21} />, text: menuContraido ? "" : "Seguimiento de Tareas", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Fa.FaRegComments className="me-1" size={21} />, text: menuContraido ? "" : "Mensajería Interna", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Ai.AiOutlineTeam className="me-1" size={21} />, text: menuContraido ? "" : "Foros y Debates", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Hi.HiOutlineDocumentText className="me-1" size={21} />, text: menuContraido ? "" : "Materiales Didácticos", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Fa.FaBook  className="me-1" size={21} />, text: menuContraido ? "" : "Biblioteca Virtual", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Md.MdInsertChart className="me-1" size={21} />, text: menuContraido ? "" : "Generación de Informes", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Fa.FaBookOpen className="me-1" size={21} />, text: menuContraido ? "" : "Historial Académico", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Md.MdChat className="me-1" size={21} />, text: menuContraido ? "" : "Reuniones con Padres", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Md.MdPieChart className="me-1" size={21} />, text: menuContraido ? "" : "Análisis de Resultados", onClick: handleSidebarItemClick },
            { to: "/Menu/",icon2:<Fa.FaChartBar className="me-1" size={21} />, text: menuContraido ? "" : "Informes de Progreso", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionProfesores"}
          onClick={() => handleNavLinkClick("gestionProfesores")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar '}
        />
      </ul>
    </div>
  );
};

export default SidebarDocentes;
