import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, 
    Label,
    Breadcrumb} from 'reactstrap';
import Camara from '../../../assets/faces/Camara plus.png';
import { removeBase64Prefix } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { ErrorMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import { DeleteNombramiento, getFuncionariosActivos, getFuncionariosActivos2 } from '../../../../CapaDatos/DatosFuncionarios';
import { useParams } from 'react-router-dom';
import { RestoreUser } from '../../../../CapaDatos/DatosUsuarios';





const FuncionariosInactivos = () => {
    let { codigoPresupuestario } = useParams();
    const [loading, setLoading] = useState(true);
    const [funcionarios, setFuncionarios] = useState([]);

 



 

 


    const fetchData = async () => {
        try {
            const parametro = { idInstitucion: codigoPresupuestario, Estado: 0 };

            const data = await getFuncionariosActivos2(parametro);

            setFuncionarios(data);
            setLoading(false);
  
        } catch (error) {

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

    }, []);




    if (loading) {
        return (
            <Cargando />
        );
    }

    if (funcionarios.estado === 0) {
        return (
            <SinDatos
                mensaje={"error 404"}
                error={"Lo sentimos, a ocurrido un error con el servidor comunicate con el departamento de TI"}
                route={`/Menu/Funcionarios/${codigoPresupuestario}`}
                direccionactual = {"Desarrolladores / Funcionarios Suspendidos"}  />
        );
    }
    if (!funcionarios?.length) {
        // Mensaje si no hay instituciones registradas
        return (
            <SinDatos
                mensaje={"Notificacion del Sistema"}
                error={"Lo sentimos, no existen funcionarios suspendidos para esta institucion"}
                route={`/Menu/Funcionarios/${codigoPresupuestario}`}
                 
                 />
        );
    }










    const Restaurar = async (data,data2) => {
        Swal.fire({
            title: "Estas seguro que deseas restaurar este funcionario ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, restaurar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await RestoreUser(data)

                 const success2 = await DeleteNombramiento(data2);
                if (success.estado && success2.estado) {
                    Swal.fire({
                        title: "¡Restaurado!",
                        text: "El Usuario ha sido restaurado",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false
                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });



    }



    // Renderizar las instituciones
    return (
        <Container fluid className="pt-2 Divcontenedor ">
           <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Funcionarios"
                        to={`/Menu/Funcionarios/${codigoPresupuestario}`}
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Funcionarios Suspendidos"
                        to={`/Menu/FuncionariosInactivos/${codigoPresupuestario}`}
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>
            <h3>Funcionarios suspendidos</h3>
            <Container fluid className='p-0 Mymt-5'>
                <Row>
                    {funcionarios

                        .map((Funcionario, index) => (
                            <Col key={index} className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 pb-5'>
                                <Card className="mb-3 shadow rounded-3">
                                    <CardHeader>
                                        <CardTitle>
                                            <strong>Funcionario:</strong> <br /> {Funcionario.nombreCompleto} {Funcionario.apellido1} {Funcionario.apellido2}<br />
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <CardText>
                                            <Row>
                                                <Col md={8}>
                                                    <strong> Cedula: </strong><br />{Funcionario.cedulaFuncionario}<br />
                                                    <strong>Cargo:</strong><br /> {Funcionario.CargoC} <br />
                                                    <strong>Telefono: </strong> <br />{Funcionario.telefonoPrincipal} / {Funcionario.telefono1}

                                                </Col>
                                                <Col md={4} className='p-0 dflex justify-content-center'>
                                                    <div className="text-center mt-3">
                                                        {Funcionario.foto !== null ? (
                                                            <img src={`data:image/jpeg;base64,${removeBase64Prefix(Funcionario.foto)}`} alt="Foto de institución" className="img-fluid" />
                                                        ) : (
                                                            <img src={Camara} alt="Imagen por defecto" className="img-fluid" />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardText>
                                    </CardBody>
                                    <CardFooter className='d-flex justify-content-end'>
                                        <Button color=' ' type='button' className='mx-1 btn-eliminar'
                                            onClick={(e) => Restaurar(
                                                {
                                                    usuario: Funcionario.cedulaFuncionario,
                                                    idInstitucion: Funcionario.idInstitucion,
                                                    estado: 1
                                                },
                                                {
                                                    idNombramiento: Funcionario.codigoNombramientoInstitucion,
                                                    estado: 0
                                                }
                                            )}
                                        >
                                            Restaurar
                                        </Button>


                                    </CardFooter>
                                </Card>
                            </Col>
                        ))}
                </Row>
                <Button type="button" className='p-0 btn   btnCancelar px-2 d-flex justify-content-start' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className=' '
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"

                            to={`/Menu/Funcionarios/${codigoPresupuestario}`}
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>


        </Container>
    );
};

export default FuncionariosInactivos;

