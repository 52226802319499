import { useState } from 'react';



/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbAsignaturas
 */
export const TbAgenda = () => {
    let AnioInicial = 2024
    let MesInicial = 8
    let DiaInicial = 16
    let HoraInicial = 12
    let MinutoInicial = 0
    let AnioFinal = 2024
    let MesFinal = 8
    let DiaFinal = 16
    let HoraFinal = 14
    let MinutoFinal = 0
    const [eventos, setEventos] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalActualizarOpen, setModalActualizarOpen] = useState(false);
    const [eventModalOpen, setEventModalOpen] = useState(false); // Nuevo estado para el modal del evento
    const [nuevoEvento, setNuevoEvento] = useState({ title: '', start: '', end: '' });
    const [seleccionarSlot, setSeleccionarSlot] = useState(null);
    const [seleccionarEvento, setSeleccionarEvento] = useState(null); // Nuevo estado para el evento seleccionado

    return {
        eventos, setEventos,
        modalOpen, setModalOpen,
        eventModalOpen, setEventModalOpen,
        nuevoEvento, setNuevoEvento,
        seleccionarSlot, setSeleccionarSlot,
        seleccionarEvento, setSeleccionarEvento,
        modalActualizarOpen, setModalActualizarOpen,
 
    };
};