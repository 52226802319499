import RegionalesService from '../CapaUtilidades/Utils/RegionalesService';




/**
 * Método para obtener la lista de regionales
 * @param {Function} setListaRegionales Función para establecer la lista de regionales obtenidas
 */
export const GetRegionales = (setListaRegionales) => {
    const regionalService = new RegionalesService();
  
    regionalService.getRegionales().then(data => {
      setListaRegionales(data);
    }).catch(error => {
      alert('Error al obtener las regionales:', error);
    });
  };


    /**
   * Metodo para obtener la lista de Cantones de la Provincia seleccionada
   * @param {*} e Evento que activo el metodo medante el cual se optiene el valor selecionado del control
   * @param {*} IdProvincia Resibe el ID de la Provincia seleccionada mediante el Set de la const 
   * @param {*} listaCantones Resive un arreglo basio 'setListaCantones'
   */
export const GetCircuitos = (e, IdRegional, listaCircuitos) => {
    const SelectRegionalID = parseInt(e.target.value);
    IdRegional(SelectRegionalID);
    const regionalService = new RegionalesService();
  
    regionalService.getCircuitosByRegional(SelectRegionalID).then(data => {
        listaCircuitos(data);
    }).catch(error => {
      console.error('Error al obtener los cantones:', error);
    });
  };