import { useState } from "react";

export const TbEvaluaciones = () => {
    const añoActual = new Date().getFullYear();

 const [ListaEstudiantes, setListaEstudiantes] = useState([]);
    const [listaTrimestres, setListaTrimestres] = useState([]);
    const [rubrosCalificacion, setRubrosCalificacion] = useState([]);
    const [listaExamenes, setListaExamenes] = useState([])
    const [listaExamenesFiltrada, setListaExamenesFiltrada] = useState([])
    const [listaRubrosFiltrada, setListaRubrosFiltrada] = useState([]);
    const [codigo, setCodigo] = useState('')
    const [nombreTrimestre, setNombreTrimestre] = useState('')
    const [nombreRugro, setNombreRugro] = useState('')
    const [nombreExamen, setNombreExamen] = useState('')
    const [nombreAsignatura, setNombreAsignatura] = useState('')
    const [asignatura, setAsignatura] = useState('')
    const [valorEnPuntos, setValorEnPuntos] = useState()
    const [valorEnPorcentaje, setValorEnPorcentaje] = useState()
    const [porcentajeA_Editar, setPorsentajeA_Editar] = useState();
    const [grado, setGrado] = useState('')
    const [seccion, setSeccion] = useState('')


    const [mostrarBtnRegistrar, setMostrarBtnRegistrar] = useState(true)
    const [mostrarBtnRegistrarTarea, setMostrarBtnRegistrarTarea] = useState(true)
    const [mostrarBtnRegistrarCotidiano, setMostrarBtnRegistrarCotidiano]= useState(true)
    const [mostrarBtnRegistrarAsistencia, setMostrarBtnRegistrarAsistencia]= useState(true)
    const [editar, setEditar] = useState(false)
    const [editarTarea, setEditarTarea] = useState(false)
    const [editarCotidiano, setEditarCotidiano] = useState(false)
    const [editarAsistencia, setEditarAsistencia] = useState(false)
    const [existenExamenes, setExistenExamenes] = useState(true)
    const [existeCotidiano, setExisteCotidiano] = useState(false);
    const [verPanelExamenes, setVerPanelExamenes] = useState(false)
    const [verPanelTareas, setVerPanelTareas] = useState(false)
    const [verPanelCotidiano, setVerPanelCotidiano] = useState(false)
    const [verPanelAsistencia, setVerPanelAsistencia] = useState(false)
    const [selecPeriodo, setSelecPeriodo] = useState(false)
    const [loading, setLoading] = useState(true);
    return {
        añoActual,

        
        listaExamenesFiltrada, setListaExamenesFiltrada,
        listaRubrosFiltrada, setListaRubrosFiltrada,
        ListaEstudiantes, setListaEstudiantes,

        listaTrimestres, setListaTrimestres,
        rubrosCalificacion, setRubrosCalificacion,
        listaExamenes, setListaExamenes,
        codigo, setCodigo,
        nombreRugro, setNombreRugro,
        nombreTrimestre, setNombreTrimestre,
        nombreExamen, setNombreExamen,
        nombreAsignatura, setNombreAsignatura,
        asignatura, setAsignatura,
        valorEnPuntos, setValorEnPuntos,
        valorEnPorcentaje, setValorEnPorcentaje,
        porcentajeA_Editar, setPorsentajeA_Editar,
        grado, setGrado,
        seccion, setSeccion,



        mostrarBtnRegistrar, setMostrarBtnRegistrar,
        mostrarBtnRegistrarTarea, setMostrarBtnRegistrarTarea,
        mostrarBtnRegistrarCotidiano, setMostrarBtnRegistrarCotidiano,
        mostrarBtnRegistrarAsistencia, setMostrarBtnRegistrarAsistencia,

        editar, setEditar,
        editarTarea, setEditarTarea,
        editarCotidiano, setEditarCotidiano,
        editarAsistencia, setEditarAsistencia,
        
        existenExamenes, setExistenExamenes,
        existeCotidiano, setExisteCotidiano,
        loading, setLoading,


        verPanelExamenes, setVerPanelExamenes,
        verPanelTareas, setVerPanelTareas,
        verPanelCotidiano, setVerPanelCotidiano,
        verPanelAsistencia, setVerPanelAsistencia,

        selecPeriodo, setSelecPeriodo,

    };
}
