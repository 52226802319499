import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal,
    ModalBody,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText, Progress,
    Breadcrumb,
    BreadcrumbItem,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Accordion,
    Tooltip
} from 'reactstrap';
import { ValidarSoloLetras, ValidarSoloNumeros, ValidarCorreo } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import Camara from '../../../assets/faces/Camara plus.png';
import * as Bi from "react-icons/bi";
import { ContraseñaSegura, EvaluarSeguridadContraseña, NivelSeguridad, removeBase64Prefix } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { GetCargos, GetNombramientos, GetRoles, GetSexo, GetTipoDereccion } from '../../../../CapaDatos/DatosCargarSelects';
import { GetProvincias, GetCantones, GetDistritos, GetBarrios, GetPaises } from '../../../../CapaDatos/DatosCargarUbicaciones'
import { GetRegionales } from '../../../../CapaDatos/DatosCargarRegionales'
import { ErrorMessage, ObtenerImagen } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import Foto from '../../../assets/faces/user (3).png'
import { EstadosIngresarInstitucion } from '../../../../CapaUtilidades/Estados/EstadosIngresarInstitucion';
import * as Fa6 from "react-icons/fa6";
import Espaciador from '../../../components/Espaciador';
import { ActualizarNombramientoFunc, DeleteNombramiento, getActualizarFuncionario, getFuncionariosActivos, getFuncionariosActivos2, saveHistoricoNombramientos } from '../../../../CapaDatos/DatosFuncionarios';
import { useParams } from 'react-router-dom';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { TbUsuarios } from '../../../../CapaEntidades/TbUsuarios';
import * as  Tb from "react-icons/tb";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { DeleteUser, UpdateUser, getDatosUsuario } from '../../../../CapaDatos/DatosUsuarios';
import * as Fas from 'react-icons/fa';
import { TbInstitucion } from '../../../../CapaEntidades/TbInstitucion';




const Funcionarios = () => {
    let { codigoPresupuestario } = useParams();
    const [loading, setLoading] = useState(true);
    const [funcionarios, setFuncionarios] = useState([]);
    const mensaje = "Notificacion del Sistema"
    const error = "Lo sentimos, no existen funcionarios registrados activos en esta institucion ú ocurrio un error en el servidor."
    const [DatosPerfil, setDatosPerfil] = useState([]);
    const [open, setOpen] = useState(false);
    const [openAC, setOpenAC] = useState('');




    const tbInstitucion = TbInstitucion();

    const tbFuncionario = TbFuncionario();
    const estadosValidacion = EstadosIngresarInstitucion();

    const tbUsuarios = TbUsuarios();

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const CargarDatos = () => {
        GetPaises(tbFuncionario.setListaPaises)
        GetProvincias(tbFuncionario.setListaProvincias)
        GetRegionales(tbInstitucion.setListaRegionales)
        GetCargos(tbFuncionario.setListaCargos)
        GetNombramientos(tbFuncionario.setListaNombramientos)
        GetRoles(tbFuncionario.setListaRoles)
        GetTipoDereccion(tbFuncionario.setListaTipoDereccion)
        GetSexo(tbFuncionario.setListaSexo)
        tbUsuarios.setEstadoUser(1)

    }


    const fetchData = async () => {
        try {
            const parametro = { idInstitucion: codigoPresupuestario, Estado: 1 };

            const data = await getFuncionariosActivos2(parametro);
            console.log(JSON.stringify(data));
            setFuncionarios(data);
            setLoading(false);
            CargarDatos();
            const data2 = await getDatosUsuario();
            setDatosPerfil(data2);
        } catch (error) {

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

    }, []);

    const MostrarContrasena = () => {
        estadosValidacion.setMostrarContrasena(! estadosValidacion.mostrarContrasena); // Cambia el estado para mostrar o no la contraseña
    };

    const Contraseña_TextChanged = (event) => {
        tbUsuarios.setPassword(event.target.value);
    };

    const nivelSeguridad = EvaluarSeguridadContraseña(tbUsuarios.password);

    const SeleccionarFecha = (e, set) => {
        set(e.target.value)
    }


    const toggleAC = (id) => {
        if (openAC === id) {
            setOpenAC();
        } else {
            setOpenAC(id);
        }
    };
    if (loading) {
        return (
            <Cargando />
        );
    }

    if (funcionarios.estado === 0) {
        return;
        // return (
        //     <SinDatos
        //         mensaje={"error 404"}
        //         error={"Lo sentimos, a ocurrido un error con el servidor comunicate con el departamento de TI"}
        //         route="/Menu/ListaInstituciones"
        //         toRoute={`/Menu/FuncionariosInactivos/${codigoPresupuestario}`}
        //         direccionactual={"Desarrolladores / Funcionarios Registrados"}
        //         mostrarboton={false} />
        // );
    }
    if (!funcionarios?.length) {
        fetchData();
        // Mensaje si no hay instituciones registradas
        // return (
        //     <SinDatos
        //         mensaje={"Notificacion del Sistema"}
        //         error={"Lo sentimos, no existen funcionarios registrados activos en esta institucion"}
        //         route="/Menu/ListaInstituciones"
        //         toRoute={`/Menu/FuncionariosInactivos/${codigoPresupuestario}`}
        //         direccionactual={"Desarrolladores / Funcionarios Registrados"}
        //         mostrarboton={false}
        //     />

        // );
    }

    const toggle = () => {
        setOpen(!open);

    }

    const Abrirmodal = (data) => {
        const FuncionarioSeleccionado = funcionarios.find(funcionario => funcionario.cedulaFuncionario === data);

        if (FuncionarioSeleccionado) {
            tbFuncionario.setCedula(FuncionarioSeleccionado.cedulaFuncionario);
             tbFuncionario.setNombre(FuncionarioSeleccionado.nombreCompleto);
             tbFuncionario.setapellido1(FuncionarioSeleccionado.apellido1);
             tbFuncionario.setapellido2(FuncionarioSeleccionado.apellido2);
             tbFuncionario.setcargo(FuncionarioSeleccionado.cargo);
             tbFuncionario.setTipoNombramiento(FuncionarioSeleccionado.tipoNombramiento);
             tbFuncionario.setTelefonoFunPrincipal(FuncionarioSeleccionado.telefonoPrincipal);
             tbFuncionario.setTelefonoFunSecundario(FuncionarioSeleccionado.telefono1);
             tbFuncionario.setCorreo(FuncionarioSeleccionado.correo);
             tbFuncionario.setDireccionFuncionario(FuncionarioSeleccionado.direccion);
            if (FuncionarioSeleccionado.foto !== null) {
                 tbFuncionario.setfotoPerfil(`data:image/jpeg;base64,${removeBase64Prefix(FuncionarioSeleccionado.foto)}`);
                 tbFuncionario.setSelectedImage(`data:image/jpeg;base64,${removeBase64Prefix(FuncionarioSeleccionado.foto)}`);
            }
             tbFuncionario.setPais(FuncionarioSeleccionado.nacionalidad);
             tbFuncionario.setSelectedProvinciaFuncionario(FuncionarioSeleccionado.Provincia);

             tbFuncionario.setSelectedCantonFuncionario(FuncionarioSeleccionado.Canton);
             tbFuncionario.setSelectedDistritoFuncionario(FuncionarioSeleccionado.Distrito);
             tbFuncionario.setSelectedBarrioFuncionario(FuncionarioSeleccionado.Barrio);
             tbFuncionario.setSexo(FuncionarioSeleccionado.sexo);
             tbFuncionario.setIdNombromiento(FuncionarioSeleccionado.codigoNombramientoInstitucion);
             tbFuncionario.setFechaIngreso(FuncionarioSeleccionado.fechaInicio.substring(0, 10));
             tbFuncionario.setFechaSalida(FuncionarioSeleccionado.fechaFinaliza.substring(0, 10));
             tbFuncionario.setObservaciones(FuncionarioSeleccionado.observaciones);
            tbUsuarios.setUsuario(FuncionarioSeleccionado.usuario);
            tbUsuarios.setPassword(FuncionarioSeleccionado.passwd);
            tbUsuarios.setInstitucionUser(codigoPresupuestario);
            tbUsuarios.setPermisoUsuario(FuncionarioSeleccionado.permisosUsuario);
            tbUsuarios.setEstadoUser(FuncionarioSeleccionado.estado);
        }

        setOpen(!open)

    }



    const Eliminar = async (data, data2) => {
        Swal.fire({
            title: "Estas seguro que deseas dar de baja este funcionario?",
            text: "¡No podrás revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, bórrar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await DeleteUser(data)
                const success2 = await DeleteNombramiento(data2);
                if (success.estado && success2.estado) {
                    Swal.fire({
                        title: "¡Eliminado!",
                        text: "El Usuario ha sido dada de baja",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false
                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });





    }



    const ActualizarFuncionario = async (event) => {
        event.preventDefault();
        if (nivelSeguridad === NivelSeguridad.MuySegura || nivelSeguridad === NivelSeguridad.MediaSegura) {

            const data =
            {
                cedulaFuncionario:  tbFuncionario.cedula,
                nombreCompleto:  tbFuncionario.nombre,
                apellido1:  tbFuncionario.apellido1,
                apellido2:  tbFuncionario.apellido2,

                telefonoPrincipal:  tbFuncionario.telefonoFunPrincipal,
                telefono1:  tbFuncionario.telefonoFunSecundario,
                correo:  tbFuncionario.correo,
                direccion:  tbFuncionario.DireccionFuncionario,
                foto:  tbFuncionario.fotoPerfil,
                nacionalidad: parseInt( tbFuncionario.pais),
                Provincia:  tbFuncionario.selectedProvinciaFuncionario.toString(),
                Canton:  tbFuncionario.selectedCantonFuncionario.toString(),
                Distrito:  tbFuncionario.selectedDistritoFuncionario.toString(),
                Barrio:  tbFuncionario.selectedBarrioFuncionario.toString(),
                sexo:  tbFuncionario.sexo

            }

            const success = await getActualizarFuncionario(data);
            if (success.estado) {

                let Observacion = '';
                if ( tbFuncionario.Observaciones === '') {
                    Observacion = 'No hay observaciones';
                } else {
                    Observacion =  tbFuncionario.Observaciones
                }

                const tbFuncLaboraInstitucion = {
                    idNombramiento:  tbFuncionario.idNombramiento,
                    cargo: parseInt( tbFuncionario.cargo),
                    tipoNombramiento: parseInt( tbFuncionario.tipoNombramiento),
                    fechaInicio:  tbFuncionario.fechaIngreso,
                    fechaFinaliza:  tbFuncionario.fechaSalida,
                    observaciones:  tbFuncionario.Observacion
                }

                const successFuncLaboraInstitucion = await ActualizarNombramientoFunc(tbFuncLaboraInstitucion);

                if (successFuncLaboraInstitucion.estado) {

                    const dataUser =
                    {
                        usuario: tbUsuarios.usuario,
                        passwd: tbUsuarios.password,
                        idInstitucion: codigoPresupuestario,
                        permisosUsuario: parseInt(tbUsuarios.permisoUsuario),
                        estado: tbUsuarios.estadoUser

                    }

                

                    const fechaActual = new Date();
                    const year = fechaActual.getFullYear();
                    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
                    const day = String(fechaActual.getDate()).padStart(2, '0');
                    const hours = String(fechaActual.getHours()).padStart(2, '0');
                    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
                    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
                    const tbHistorico = {
                        cedulaFunc:  tbFuncionario.cedula,
                        cargo: parseInt( tbFuncionario.cargo),
                        tipoNombramiento: parseInt( tbFuncionario.tipoNombramiento),
                        fechaInicio:  tbFuncionario.fechaIngreso,
                        fechaFinaliza:  tbFuncionario.fechaSalida,
                        institucion: codigoPresupuestario,
                        registradoPor: DatosPerfil[0].nombreCompleto + " " + DatosPerfil[0].apellido1 + " " + DatosPerfil[0].apellido2,
                        fechaRegistro: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000`
                    }
                    
                    await saveHistoricoNombramientos(tbHistorico)
                    const successUser = await UpdateUser(dataUser)
                    if (successUser.estado) {

                        setOpen(!open);
                        fetchData();

                    } else {
                        ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                        return;
                    }

                } else {
                    ErrorMessage('Advertencia', 'Ocurrio un error durante el proceso de guardado.');
                    return;
                }









            } else {
                ErrorMessage('Notificación del Sistema', success.mensaje || 'Ocurrió un error durante la solicitud al servidor.');
            }

        } else {
            ErrorMessage('Notificación del Sistema', 'Nivel de seguridad de la contraseña no apto.');
            return;
        }






    };






    // Renderizar las instituciones
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio "
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text=" Funcionarios"
                        to=""
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Col className="floating-container" id="floating-container">

                <Button className=" d-flex justify-content-center  btnAceptar p-2">
                    <Fas.FaPlus />
                </Button>
                <Col className="element-container" >
                    <Button className='p-0  btn btnAceptar float-element tooltip-left  '>
                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Fa6.FaCirclePlus className="me-1 sizeIcon2" />}
                                text="Ingresar Func."

                                to={`/Menu/RegistrarFuncionario/${codigoPresupuestario}`}
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                    <Espaciador espaciador='espaciador-SM' />
                    <Button className='p-0  btn btnAceptar float-element '>
                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Bi.BiSolidInstitution className="me-1" />}
                                text="Func. Inactivos"

                                to={`/Menu/FuncionariosInactivos/${codigoPresupuestario}`}
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                </Col>
            </Col>


            <Container fluid className='p-0 Mymt-5'>
                {funcionarios.length > 0 ? (
                    <Row>
                        {funcionarios
                            .map((Funcionario, index) => (
                                <Col key={index} className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 pb-5'>
                                    <Card className="mb-3 shadow2 rounded-3">
                                        <CardHeader>
                                            <CardTitle>
                                                <strong>Funcionario:</strong> <br /> {Funcionario.nombreCompleto} {Funcionario.apellido1} {Funcionario.apellido2}<br />
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <CardText>
                                                <Row>
                                                    <Col md={8}>
                                                        <strong> Cedula: </strong><br />{Funcionario.cedulaFuncionario}<br />
                                                        <strong>Cargo:</strong><br /> {Funcionario.CargoC} <br />
                                                        <strong>Telefono: </strong> <br />{Funcionario.telefonoPrincipal} / {Funcionario.telefono1}

                                                    </Col>
                                                    <Col md={4} className='p-0 dflex justify-content-center'>
                                                        <div className="text-center mt-3">
                                                            {Funcionario.foto !== null ? (
                                                                <img src={`data:image/jpeg;base64,${removeBase64Prefix(Funcionario.foto)}`} alt="Foto de institución" className="FotoPerfil p-2" />
                                                            ) : (
                                                                <img src={Camara} alt="Imagen por defecto" className="FotoPerfil p-2" />
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </CardText>
                                        </CardBody>
                                        <CardFooter className='d-flex justify-content-end'>

                                            <Button color='' className='mx-1 btn-editar' onClick={(e) => Abrirmodal(Funcionario.cedulaFuncionario)} >Actualizar</Button>
                                            <Button color=' ' type='button' className='mx-1 btn-eliminar'
                                                onClick={(e) => Eliminar(
                                                    {
                                                        usuario: Funcionario.cedulaFuncionario,
                                                        idInstitucion: Funcionario.idInstitucion,
                                                        estado: 0
                                                    },
                                                    {
                                                        idNombramiento: Funcionario.codigoNombramientoInstitucion,
                                                        estado: 0
                                                    }
                                                )}
                                            >
                                                Suspender
                                            </Button>


                                        </CardFooter>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Container fluid className="pt-2 ">
                        <h1 className='text-center mt-5 pt-5'>{mensaje}</h1>
                        <h4 className='text-center mt-5 mb-5'>{error}</h4>
                    </Container>
                    // <SinDatos
                    //     mensaje={"Notificacion del Sistema"}
                    //     error={"Lo sentimos, no existen funcionarios registrados activos en esta institucion"}
                    //     route="/Menu/"
                    //     toRoute={"/Menu/FrmFuncionariosDocentesInactivos"}
                    //     direccionactual={""}
                    //     mostrarboton={false}
                    // />
                )}
                <Button type="button" className='p-0 btn   btnCancelar px-2 d-flex justify-content-start' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className=' '
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"

                            to="/Menu/ListaInstituciones"
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>

            <Modal isOpen={open} size='xl' fullscreen='lg' className='mt-0'>
                <ModalBody>
                    <Container fluid className='  p-3 shadow border rounded  ' id='institucion'  >
                        <Form onSubmit={ActualizarFuncionario}>
                            <h5 className='py-2' > Actualizar informacion Administrador </h5>
                            <Accordion className='mb-5' flush open={openAC} toggle={toggleAC}>
                                <AccordionItem className='mb-3 '>
                                    <AccordionHeader targetId="1" className=' accordionBorder accordion-button2'>Información Personal </AccordionHeader>
                                    <AccordionBody accordionId="1" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="Provincia">
                                                        Nacionalidad
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="NacionalidadFuncionario"
                                                        name="NacionalidadFuncionario"
                                                        placeholder="NacionalidadFuncionario"
                                                        type="select"
                                                        defaultValue={ tbFuncionario.pais}
                                                        onChange={(e) =>  tbFuncionario.setPais(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione la Nacionalidad</option>
                                                        { tbFuncionario.ListaPaises.map((paises, index) => (
                                                            <option key={paises.id} value={paises.id}  >{paises.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={9}></Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="identificacion" className="me-2">
                                                        Identificación
                                                    </Label>

                                                    <Input

                                                        required
                                                        id="identificacion"
                                                        name="identificacion"
                                                        placeholder="0-0000-0000"
                                                        type="text"
                                                        value={ tbFuncionario.cedula}
                                                        onChange={(event) => {
                                                            ValidarSoloNumeros(event,  tbFuncionario.setCedula,  estadosValidacion.setcedulaValidad);
                                                            tbUsuarios.setUsuario(event.target.value);
                                                        }}
                                                        maxLength={12}
                                                        minLength={9}
                                                        className={ estadosValidacion.cedulaValida ? ' ' : 'is-invalid  '}
                                                    />

                                                    {! estadosValidacion.cedulaValida && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="identificacion"
                                                    toggle={toggleTooltip}
                                                >
                                                   Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )}


                                                </FormGroup>
                                            </Col>

                                            <Col md={3} >
                                                <FormGroup >
                                                    <Label for="nombre" className="me-2">
                                                        Nombre
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="nombre"
                                                        name="nombre"
                                                        placeholder=""
                                                        type="text"
                                                        value={ tbFuncionario.nombre}
                                                        onChange={(event) => ValidarSoloLetras(event,  tbFuncionario.setNombre, estadosValidacion.setNombreValido)}
                                                        className={estadosValidacion.nombreValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!estadosValidacion.nombreValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="nombre"
                                                    toggle={toggleTooltip}
                                                >
                                                   No se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                                </FormGroup>

                                            </Col>

                                            <Col md={3} >
                                                <FormGroup >
                                                    <Label for="primerApellido" className="me-2">
                                                        Primer Apellido
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="primerApellido"
                                                        name="primerApellido"
                                                        placeholder=""
                                                        type="text"
                                                        value={tbFuncionario.apellido1}
                                                        onChange={(event) => ValidarSoloLetras(event, tbFuncionario.setapellido1, estadosValidacion.setapellido1Valido)}
                                                        className={estadosValidacion.apellido1Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!estadosValidacion.apellido1Valido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="primerApellido"
                                                    toggle={toggleTooltip}
                                                >
                                                   No se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                                </FormGroup>

                                            </Col>

                                            <Col md={3} >
                                                <FormGroup >
                                                    <Label for="segundoApellido" className="me-2">
                                                        Segundo Apellido
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="segundoApellido"
                                                        name="segundoApellido"
                                                        placeholder=""
                                                        type="text"
                                                        value={tbFuncionario.apellido2}
                                                        onChange={(event) => ValidarSoloLetras(event, tbFuncionario.setapellido2, estadosValidacion.setapellido2Valido)}
                                                        className={estadosValidacion.apellido2Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!estadosValidacion.apellido2Valido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="segundoApellido"
                                                    toggle={toggleTooltip}
                                                >
                                                   No se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                                </FormGroup>

                                            </Col>

                                            <Col md={4} className='py-1'>
                                                <FormGroup>
                                                    <Label for="Sexo">
                                                        Sexo
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="Sexo"
                                                        name="Sexo"
                                                        placeholder="NacionalidadFuncionario"
                                                        type="select"
                                                        defaultValue={tbFuncionario.sexo}
                                                        onChange={(e) => tbFuncionario.setSexo(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione el tipo Sexo</option>
                                                        {tbFuncionario.ListaSexo.map(sexo => (
                                                            <option key={sexo.id} value={sexo.id}>{sexo.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={3} className='py-1'>

                                                <FormGroup>
                                                    <Label for="files" className='pr-2'>
                                                        Foto de Perfil
                                                    </Label>
                                                    <Input
                                                        hidden
                                                        id="files"
                                                        name="files"
                                                        type="file"
                                                        accept='image/*'
                                                        onChange={(e) => ObtenerImagen(e, estadosValidacion.setSelectedImage, tbFuncionario.setfotoPerfil)}
                                                    />
                                                    <Label for="files" className="custom-file-upload">Seleccionar Archivo</Label>
                                                </FormGroup>
                                            </Col>

                                            <Col md={4} >

                                                {estadosValidacion.selectedImage ? (
                                                    <img src={estadosValidacion.selectedImage} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                                ) : (
                                                    <img src={Foto} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                                )}


                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3'>
                                    <AccordionHeader targetId="2" className=' accordionBorder accordion-button2'>Lugar de Residencia </AccordionHeader>
                                    <AccordionBody accordionId="2" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="Provincia">
                                                        Provincia
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="ProvinciaFuncionario"
                                                        name="ProvinciaFuncionario"
                                                        placeholder="ProvinciaFuncionario"
                                                        type="select"
                                                        defaultValue={tbFuncionario.selectedProvinciaFuncionario}
                                                        onChange={(event) => GetCantones(event, tbFuncionario.setSelectedProvinciaFuncionario, tbFuncionario.setListaCantones)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione una Provincia</option>
                                                        {tbFuncionario.ListaProvincias.map(provincia => (
                                                            <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="CantonFuncionario">
                                                        Cantón
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="CantoFuncionarion"
                                                        name="CantonFuncionario"

                                                        type="select"
                                                        defaultValue={tbFuncionario.selectedCantonFuncionario}
                                                        onChange={(event) => GetDistritos(event, tbFuncionario.selectedProvinciaFuncionario, tbFuncionario.setSelectedCantonFuncionario, tbFuncionario.setListaDistritos)}

                                                    >
                                                        <option value="0" disabled selected  >Seleccione el Cantón</option>
                                                        {tbFuncionario.ListaCantones.map(canton => (
                                                            <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="DistritoFuncionario">
                                                        Distrito
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="DistritoFuncionario"
                                                        name="DistritoFuncionario"

                                                        type="select"
                                                        defaultValue={tbFuncionario.selectedDistritoFuncionario}
                                                        onChange={(event) => GetBarrios(event, tbFuncionario.selectedProvinciaFuncionario, tbFuncionario.selectedCantonFuncionario, tbFuncionario.setSelectedDistritoFuncionario, tbFuncionario.setListaBarrios)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione el Distrito</option>
                                                        {tbFuncionario.ListaDistritos.map(distrito => (
                                                            <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="BarrioFuncionario">
                                                        Barrio
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="BarrioFuncionario"
                                                        name="BarrioFuncionario"

                                                        type="select"
                                                        defaultValue={tbFuncionario.selectedBarrioFuncionario}


                                                        onChange={(e) => tbFuncionario.setSelectedBarrioFuncionario(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Seleccione el Barrio</option>
                                                        {tbFuncionario.ListaBarrios.map(barrio => (
                                                            <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12} className='py-1'>
                                                <Label for="DireccionFuncionario">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    required
                                                    id="DireccionFuncionario"
                                                    name="DireccionFuncionario"
                                                    placeholder="200 mt al este de la calle 10"
                                                    value={tbFuncionario.DireccionFuncionario}
                                                    onChange={(e) => tbFuncionario.setDireccionFuncionario(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3'>
                                    <AccordionHeader targetId="3" className=' accordionBorder accordion-button2'>Información de Contacto </AccordionHeader>
                                    <AccordionBody accordionId="3" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="telefonfuncionarioo">
                                                        Telefono Principal
                                                    </Label>
                                                    <Input
                                                        maxLength={8}
                                                        minLength={8}
                                                        id="telefonofuncionario"
                                                        name="telefonofuncionario"
                                                        placeholder=""
                                                        type="tel"
                                                        value={tbFuncionario.telefonoFunPrincipal}
                                                        onChange={(event) => ValidarSoloNumeros(event, tbFuncionario.setTelefonoFunPrincipal, estadosValidacion.setTelefonoFunPrincipalvalido)}
                                                        className={estadosValidacion.telefonoFunPrincipalValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!estadosValidacion.telefonoFunPrincipalValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="telefonofuncionario"
                                                    toggle={toggleTooltip}
                                                >
                                                   Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="telefonSecundariofuncionarioo">
                                                        Telefono Secundario
                                                    </Label>
                                                    <Input
                                                        maxLength={8}
                                                        minLength={8}
                                                        id="telefonoSecundariofuncionario"
                                                        name="telefonoSecundariofuncionario"
                                                        placeholder=""
                                                        type="tel"
                                                        value={tbFuncionario.telefonoFunSecundario}
                                                        onChange={(event) => ValidarSoloNumeros(event, tbFuncionario.setTelefonoFunSecundario, estadosValidacion.setTelefonoFunSecundariovalido)}
                                                        className={estadosValidacion.telefonoFunSecundarioValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!tbFuncionario.telefonoFunSecundarioValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="telefonoSecundariofuncionario"
                                                    toggle={toggleTooltip}
                                                >
                                                   Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} className='py-1'>
                                                <FormGroup>
                                                    <Label for="email">
                                                        Correo Electronico
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="email"
                                                        name="email"
                                                        placeholder=""
                                                        type="mail"
                                                        value={tbFuncionario.correo}
                                                        onChange={(event) => ValidarCorreo(event, tbFuncionario.setCorreo, estadosValidacion.setcorreoValido)}
                                                        className={estadosValidacion.correoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!estadosValidacion.correoValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="email"
                                                    toggle={toggleTooltip}
                                                >
                                                  Formato de correo invalido.
                                                </Tooltip>
                                            )}

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3'>
                                    <AccordionHeader targetId="4" className=' accordionBorder accordion-button2'>Información Laboral </AccordionHeader>
                                    <AccordionBody accordionId="4" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="cargo">
                                                        Cargo
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="cargo"
                                                        name="cargo"
                                                        placeholder="Nombre centro Educativo"
                                                        type="select"
                                                        defaultValue={tbFuncionario.cargo}

                                                        onChange={(e) => tbFuncionario.setcargo(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Seleccione el Cargo</option>
                                                        {tbFuncionario.ListaCargos.slice(0, 2)
                                                            .map(listaCargo => (
                                                                <option key={listaCargo.id} value={listaCargo.id}>{listaCargo.nombre}</option>
                                                            ))}

                                                    </Input>

                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="tiponombramiento">
                                                        Tipo de Nombramiento
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="tiponombramiento"
                                                        name="tiponombramiento"

                                                        type="select"
                                                        defaultValue={tbFuncionario.tipoNombramiento}
                                                        onChange={(e) => tbFuncionario.setTipoNombramiento(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Selec. tipo de Nombramiento</option>
                                                        {tbFuncionario.ListaNombramientos.map(listaNombramiento => (
                                                            <option key={listaNombramiento.id} value={listaNombramiento.id}>{listaNombramiento.nombre}</option>
                                                        ))}

                                                    </Input>

                                                </FormGroup>
                                            </Col >
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="fechaingreso">
                                                        Fecha de Ingreso
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="fechaingreso"
                                                        name="fechaingreso"
                                                        placeholder=""
                                                        type="date"
                                                        value={tbFuncionario.fechaIngreso}
                                                        onChange={(e) => SeleccionarFecha(e, tbFuncionario.setFechaIngreso)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="fechasalida">
                                                        Fecha de Salida
                                                    </Label>
                                                    <Input

                                                        id="fechasalida"
                                                        name="fechasalida"
                                                        placeholder=""
                                                        type="date"
                                                        value={tbFuncionario.fechaSalida}
                                                        onChange={(e) => SeleccionarFecha(e, tbFuncionario.setFechaSalida)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="observaciones">
                                                        Observaciones
                                                    </Label>
                                                    <Input

                                                        id="observaciones"
                                                        name="observaciones"
                                                        placeholder=""
                                                        type="text"
                                                        value={tbFuncionario.Observaciones}
                                                        onChange={(e) => tbFuncionario.setObservaciones(e.target.value)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3'>
                                    <AccordionHeader targetId="5" className=' accordionBorder accordion-button2'>Información de Usuario </AccordionHeader>
                                    <AccordionBody accordionId="5" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="usuario">
                                                        Usuario
                                                    </Label>
                                                    <Input
                                                        readOnly
                                                        required
                                                        id="usuario"
                                                        name="usuario"
                                                        placeholder=""
                                                        type="text"
                                                        value={tbUsuarios.usuario}
                                                        onChange={(e) => tbUsuarios.setUsuario(e.target.value)}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <InputGroup className='d-flex justify-content-between '>
                                                        <Label for=" password">
                                                            Contraseña
                                                        </Label>
                                                        <InputGroupText addonType="append" className='p-0 mr-5  border-0 '>
                                                            <Button
                                                                onClick={(e) => ContraseñaSegura(tbUsuarios.setPassword)}

                                                                className=' p-0 px-2 rounded btn-editar '
                                                                title='Generar contraseña segura'>
                                                                <Tb.TbPasswordFingerprint />
                                                            </Button>
                                                        </InputGroupText>
                                                    </InputGroup>

                                                    <InputGroup>
                                                        <Input
                                                            required
                                                            id="password"
                                                            name="password"
                                                            placeholder=""
                                                            type={estadosValidacion.mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                            value={tbUsuarios.password}
                                                            onChange={Contraseña_TextChanged}
                                                        />
                                                        <InputGroupText addonType="append" className='p-0'>
                                                            <Button onClick={MostrarContrasena} color="" className='p-0 px-2'>
                                                                {estadosValidacion.mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                            </Button>
                                                        </InputGroupText>
                                                    </InputGroup>
                                                    {NivelSeguridad && (
                                                        <div>
                                                            {nivelSeguridad}
                                                            <Progress
                                                                animated
                                                                value={nivelSeguridad === NivelSeguridad.MuySegura ? 100 : nivelSeguridad === NivelSeguridad.MediaSegura ? 75 : nivelSeguridad === NivelSeguridad.PocoSegura ? 25 : 1}
                                                                color={nivelSeguridad === NivelSeguridad.MuySegura ? 'success' : nivelSeguridad === NivelSeguridad.MediaSegura ? 'warning' : nivelSeguridad === NivelSeguridad.PocoSegura ? 'danger' : 'danger'}
                                                            />
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for=" tipoUsuario">
                                                        Tipo Usuario
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="tipoUsuario"
                                                        name=" tipoUsuario"
                                                        placeholder=""
                                                        type="select"
                                                        defaultValue={tbUsuarios.permisoUsuario}
                                                        onChange={(e) => tbUsuarios.setPermisoUsuario(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Selec. roll de Usuario</option>
                                                        {tbFuncionario.ListaRoles.slice(1, 3).map(roles => (
                                                            <option key={roles.id} value={roles.id}>{roles.nombre}</option>
                                                        ))}
                                                    </Input>


                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                            <Col className='d-flex justify-content-between'>
                                <Button type="button" onClick={toggle} className=' btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className='btnAceptar ' id='GuardarInstitucion'>
                                    Actualizar Funcionario
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

        </Container>
    );
};

export default Funcionarios;

