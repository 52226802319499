

const endPoint = {
    urlDatosPadron: 'https://apis.gometa.org/cedulas/',
    


    // ===============   END POINT INSTITUCION =============================
    UrlSaveInstitucion: 'https://sigaeapp.com:3001/registrarInstitucion',
    UrlGetInstitucion: 'https://sigaeapp.com:3001/obtenerInstitucionPorCodigo',
    UrlGetInstitucioneActivas: 'https://sigaeapp.com:3001/institucionesPorEstado',
    UrlExisteInstitucion: 'https://sigaeapp.com:3001/institucionExiste',
    UrlActualizarInstitucion: 'https://sigaeapp.com:3001/actualizarInstitucion',
    UrlDeleteInstitucion: 'https://sigaeapp.com:3001/cambiarEstadoInstitucion',
    urlInstitucionSeleccionada: 'https://sigaeapp.com:3001/obtenerInstitucionPorCodigo',

    //================ END POINT FUNCIONARIOS ===============================
    urlSaveFuncionario: 'https://sigaeapp.com:3001/registrarFuncionario',
    urlUpdateFuncionario: 'https://sigaeapp.com:3001/actualizarFuncionario',
    urlGetFuncionarios: 'https://sigaeapp.com:3001/funcionariosPorEstado',
    urlExisteFuncionario: 'https://sigaeapp.com:3001/funcionarioExiste',

    //================ END POINT USUARIO FUNCIONARIO ========================
    urlSaveUser: 'https://sigaeapp.com:3001/crearUsuarioFuncionario',
    urlUpdateUser: 'https://sigaeapp.com:3001/actualizarUsuarioFunc',
    urlGetUser: 'https://sigaeapp.com:3001/obtenerDatosUsuarioFunc',
    urlExisteUser: 'https://sigaeapp.com:3001/usuariofuncExiste',
    urlDeleteUser: 'https://sigaeapp.com:3001/cambiarEstadoUsuarioFunc',

    //================ END POINT LOGIN  ===============================
    urlLogin: 'https://sigaeapp.com:3001/validarUsuarioFuncionario',
    urlLoginEncargado: 'https://sigaeapp.com:3001/validarUsuarioEncargado',


    //================ END POINT ASIGNATURAS ===============================
    urlGetAsignaturas: 'https://sigaeapp.com:3001/listaAsignaturas',
    urlSaveAsignatura: 'https://sigaeapp.com:3001/registrarAsignatura',
    urlUpdateAsignatura: 'https://sigaeapp.com:3001/actualizarAsignatura',
    urlDeleteAsignatura: 'https://sigaeapp.com:3001/cambiarEstadoAsignatura',
    URLSearchAsignatura: 'https://sigaeapp.com:3001/buscarAsignatura',

    //================ END POINT ASIGNATURAS DOCENTE ===============================
    urlGetListaAsigDocentes: 'https://sigaeapp.com:3000/listaProfesAsignaturas',
    urlGetAsignDocente: 'https://sigaeapp.com:3001/asignaturasProfePorEstado',
    urlSaveAsigDocente: 'https://sigaeapp.com:3001/registrarAsignaturaProfesor',
    //urlUpdateAsigDocente: 'httpss://sigaeapp.com:3001',
    urlDeleteAsigDocente: 'https://sigaeapp.com:3001/cambiarEstadoAsignaturaProfesor',
    URLResetAsigDocente: 'https://sigaeapp.com:3001/cambiarEstadoAsignaturaProfesor',
    URLExisteAsigDocente: 'https://sigaeapp.com:3001/asignaturaProfesorExiste',


    //================== END POINT SECCIONES =================================
    urlGetSecciones: 'https://sigaeapp.com:3001/listaSeccionesInstitucion',
    urlSaveSeccion: 'https://sigaeapp.com:3001/registrarSeccion',
    urlUpdateSeccion: 'https://sigaeapp.com:3001/editarSeccion',
    urlDeleteSeccion: 'https://sigaeapp.com:3001/cambiarEstadoSeccion',
    urlExisteSeccion: 'https://sigaeapp.com:3001/existeSeccion',


    //=================== END POINT SECCIONES DOCENTE =================================================
    urlExisteSeccDocente: 'https://sigaeapp.com:3001/seccionProfesorExiste',
    urlSeccionesDocente: 'https://sigaeapp.com:3001/seccionesProfePorEstado',
    urlListSeccDocent: 'https://sigaeapp.com:3001/seccionesProfesParametrizado',
    urlListaSeccionesDocente: 'https://sigaeapp.com:3001/listaProfesSecciones',
    urlSaveSeccionDocente: 'https://sigaeapp.com:3001/registrarSeccionProfesor',
    urlRestoreSeccionDocente: 'https://sigaeapp.com:3001/cambiarEstadoSeccionProfesor',
    urlDeleteSeccionDocente: 'https://sigaeapp.com:3001/cambiarEstadoSeccionProfesor',
    urlUpdateSeccionDocente: 'https://sigaeapp.com:3001/editarSeccionProfesor',
    // urlDeleteSeccionDocente: 'httpss://sigaeapp.com:3001/cambiarEstadoSeccionProfesor',

    //=================== END POINT NOMBRAMIENTO FUNCIONARIO =================================================
    urlNombramientoExiste: 'https://sigaeapp.com:3001/nombramientoFuncExiste',
    urlSaveFunLaborarInstitucion: 'https://sigaeapp.com:3001/registrarNombramientoFunc',
    urlUpdateFunLaborarInstitucion: 'https://sigaeapp.com:3001/actualizarNombramientoFunc',
    urlGetFunLaborarInstitucion: 'https://sigaeapp.com:3001/nombramientosFuncPorEstado',
    urlDeleteFunLaborarInstitucion: 'https://sigaeapp.com:3001/cambiarEstadoNombramientoFunc',
    urlRestoreFunLaborarInstitucion: 'https://sigaeapp.com:3001/cambiarEstadoNombramientoFunc',
    urlSaveHistoricoNombramientoFunc: 'https://sigaeapp.com:3001/registrarHistoricoNombramiento',

    //=================== END POINT ACTIVOS =================================================
    urlExisteActivo: 'https://sigaeapp.com:3001/verificarExistenciaActivo',
    urlRegistrarActivo: 'https://sigaeapp.com:3001/registrarActivo',
    urlListaActivos: 'https://sigaeapp.com:3001/listarActivosPorInstitucion',
    urlCambiarEstadoActivos: 'https://sigaeapp.com:3001/cambiarEstadoActivo',


    //=================== END POINT ASIGNAR ACTIVOS =================================================

    urlAsignarActivo: 'https://sigaeapp.com:3001/asignarActivo',
    urlListaActivosAsignados: 'https://sigaeapp.com:3001/listaAsigActivoInstitucion',
    urlActualizarActivo: 'https://sigaeapp.com:3001/actualizarActivo',
    urlActualizarActivo: 'https://sigaeapp.com:3001/actualizarAsignacionActivo',
    urlCambiarEstadoActivo: 'https://sigaeapp.com:3001/cambiarEstadoAsignacionActivo',

    //=================== END POINT EDIFICIOS =================================================
    urlListaEdificios: 'https://sigaeapp.com:3001/listaEdificiosPorEstado',
    urlExisteEdificio: 'https://sigaeapp.com:3001/edificioExiste',
    urlCambiarEstadoEdificio: 'https://sigaeapp.com:3001/cambiarEstadoEdificio',
    urlRegistrarEdificio: 'https://sigaeapp.com:3001/registrarEdificio',
    urlActualizarEdificio: 'https://sigaeapp.com:3001/actualizarEdificio',

    //=================== END POINT PERSONA ESTUDIANTE =================================================
    urlExistePersona: 'https://sigaeapp.com:3001/personaEstudExiste',
    urlDatosPersona: 'https://sigaeapp.com:3001/datosPersonaEstud',
    urlRegistrarPersona: 'https://sigaeapp.com:3001/registrarPersonaEstud',
    urlActualizarPersona: 'https://sigaeapp.com:3001/actualizarPersonaEstud',

    //=================== END POINT ESTUDIANTE =================================================

    urlExisteEstudiante: 'https://sigaeapp.com:3001/buscarEstudiante',
    urlGuardarEstudiante: 'https://sigaeapp.com:3001/registrarEstudiante',
    urlActualizarEstudiante: 'https://sigaeapp.com:3001/actualizarEstudiante',
    urlListarEstudiantes: 'https://sigaeapp.com:3001/listarEstudiantes',

    //=================== END POINT GRUPO FAMILIAR ESTUDIANTE =================================================

    urlBuscarGrupoFamiliarEstudiante: 'https://sigaeapp.com:3001/buscarGrupoFamiliarEstudiante',
    urlRegistrarGrupoFamiliar: 'https://sigaeapp.com:3001/registrarGrupoFamiliar ',
    urlEditarGrupoFamiliar: 'https://sigaeapp.com:3001/editarGrupoFamiliar',
    urlExisteEncargado: 'https://sigaeapp.com:3001/encargadoEstudExiste',
    //=================== END POINT CONDICION SOCIO-ECONOMICA =================================================
    urlListarCondicionSocio_Economica: 'https://sigaeapp.com:3001/listarCondicionSocioEconomica',
    urlRegistrarCondicionSocio_Economica: 'https://sigaeapp.com:3001/registrarCondicionSocioEconomica ',
    urlActualizarCondicionSocio_Economica: 'https://sigaeapp.com:3001/actualizarCondicionSocioEconomica ',

    //================== END POINT DATOS MEDICOS ESTUDIANTE =====================================
    urlListarDatosMedicosEstudiante: 'https://sigaeapp.com:3001/listarDatosMedicos',
    urlRegistrarDatosMedicosEstudiante: 'https://sigaeapp.com:3001/registrarDatosMedicos ',
    urlActualizarDatosMedicosEstudiante: 'https://sigaeapp.com:3001/actualizarDatosMedicos ',
    urlBuscarDatosMedicosEstudiante: 'https://sigaeapp.com:3001/buscarDatosMedicos',
    urlEliminarDatosMedicos: 'https://sigaeapp.com:3001/eliminarDatosMedicos',

    //================== END POINT DATOS MATRICULA ESTUDIANTE =====================================
    urlExisteMatricula: 'https://sigaeapp.com:3001/estaMatriculado',//revisar
    urlListaMatriculas: 'https://sigaeapp.com:3001/listaMatricula',// malo
    urlMatricularEstudiante: 'https://sigaeapp.com:3001/matricularEstudiante',// revisar
    urlActualizarMatricula: 'https://sigaeapp.com:3001/actualizarMatricula',// revisar

    //================== END POINT DATOS BECAS ===================================================
    urlFirmarAsistenciaComedor: 'https://sigaeapp.com:3001/beca/firmar',

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                  DOCENTES                                                                     //
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    urlGuardarAgenda: 'https://sigaeapp.com:3001/agenda/crear',// Buenos
    urlActualizarAgenda: 'https://sigaeapp.com:3001/agenda/editar',// Buenos
    urlEliminarAgenda: 'https://sigaeapp.com:3001/agenda/eliminar',// Buenos
    urlListarAgenda: 'https://sigaeapp.com:3001/agenda/individual',// Buenos

    //================================= EP ASISTENCIA ======================================================
    urlRegistrarAsistencia: 'https://sigaeapp.com:3001/registrarAsistencia',//bueno
    urlListaAsistecia: 'https://sigaeapp.com:3001/listaAsistenciaSeccion',//Malo
    urlActualizarAsistencia: 'https://sigaeapp.com:3001/actualizarAsistencia',// bueno
    urlAsistenciaPorFecha: 'https://sigaeapp.com:3001/listaAsistenciaEstudianteFecha',//Malo
    urlAsistenciaPorFechas: 'https://sigaeapp.com:3001/listaAsistenciaEstudianteFechas',//Malo
    
    //========================= EP COMUNICADOS ==================================================
    urlListaEncardosEstudiantes: 'https://sigaeapp.com:3001/contactos-encargados',//malo
    urlRegistrarComunicado: 'https://sigaeapp.com:3001/registrarComunicado',// revisar

    //================================= EP CREAR RUBROS DE EVALUCION ======================================================
    urlExisteRubro: 'https://sigaeapp.com:3001/evaluacionExiste',
    urlRegistrarRubro: 'https://sigaeapp.com:3001/crearEvaluacion',
    urlActualizarRubro: 'https://sigaeapp.com:3001/actualizarEvaluacion',
    urlEliminarRubro: 'https://sigaeapp.com:3001/cambiarEstadoEvaluacion',
    urlListaRubros:'https://sigaeapp.com:3001/evaluacionesProf',
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                      Estudiantes                                                              //
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    urlGenerarQR : 'https://sigaeapp.com:3001/carnets/crear',


};

export default endPoint
