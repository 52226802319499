import React, { useState, useEffect, useCallback } from 'react';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, Table, ModalHeader, Spinner, InputGroup, InputGroupText, Tooltip } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Md from "react-icons/md";
import * as Io from "react-icons/io";
import * as  Fa from "react-icons/fa";
import { BuscarEstudiante, BuscarEstudiante2, BuscarPersonaFamiliar, BuscarPersonaFamiliar2, ErrorMessage, InfoMessage, ObtenerEdad, ObtenerImagen, SuccessMessage, WarningMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Foto from '../../../assets/faces/user (3).png';
import idFrontal from '../../../assets/imagenes/IdFrontal.jpg'
import idReverso from '../../../assets/imagenes/IdReverso.jpg'
import resiboLuz from '../../../assets/imagenes/pasted image 0.png'
import Webcam from 'react-webcam';
import { TbEstudiante } from '../../../../CapaEntidades/TbEstudiante';
import { ValidarCorreo, ValidarSoloLetras, ValidarSoloNumeros, ValidarSoloNumerosPuntoDecimal } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { GetBucoDental, GetCondicionCasa, GetDiscapacidades, GetEstadoCivil, GetGrados, GetNivelEscolaridad, GetNumeros, GetOcupacion, GetParentescos, GetSexo, GetTipoBeca, GetTipoCasa, GetTipoNacionalidad, GetTiposSanguineos } from '../../../../CapaDatos/DatosCargarSelects';
import { GetBarrios, GetCantones, GetDistritos, GetPaises, GetProvincias } from '../../../../CapaDatos/DatosCargarUbicaciones';
import { TbPersona } from '../../../../CapaEntidades/TbPersona';
import { TbDatosMedicos } from '../../../../CapaEntidades/TbDatosMedicos';
import { ActualizarPersona, ExistePersona, GetPersona, RegistrarPersona } from '../../../../CapaDatos/DatosPersonaEstudiante';
import { ActualizarCondicionSocioEconomica, ActualizarEstudiante, ExisteEstudiante, RegistrarCondicionSocioEconomica, RegistrarEstudiante } from '../../../../CapaDatos/DatosEstudiante';
import { ActualizarGrupoFamiliarEstudiante, ListarGrupoFamiliar, RegistrarGrupoFamiliarEstudiante } from '../../../../CapaDatos/DatosGrupoFamiliar';
import Swal from 'sweetalert2';
import { TbCondSocio_Econimica } from '../../../../CapaEntidades/TbCondSocio_Economica';
import { ActualizarDatosMedicos, ListarDatosMedicos, RegistrarDatosMedicos } from '../../../../CapaDatos/DatosMedicosEstudiante';
import { TbMatricula } from '../../../../CapaEntidades/TbMatricula';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { TbPerfil } from '../../../../CapaEntidades/TbPerfil';
import { getDatosUsuario } from '../../../../CapaDatos/DatosUsuarios';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { getSecciones } from '../../../../CapaDatos/DatosSecciones';
import { obtenerIdInstitucion, obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { useLocation } from 'react-router-dom';
import Cargando from '../../../components/Cargando';
import { ActualizarMatriculaEstudiante, MatricularEstudiante } from '../../../../CapaDatos/DatosMatricula';

const FrmNuevoExpedienteEstudiante = ({ codigoPresupuestario, codigoNombre }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    let CodigoNombre = codigoNombre;
    const location = useLocation();



    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    const TBEstudiante = TbEstudiante();

    const TBPersona = TbPersona();

    const TBCondSocioEco = TbCondSocio_Econimica();

    const TBDatosMedicos = TbDatosMedicos();

    const TBMatricula = TbMatricula();

    const TBPerfil = TbPerfil();

    const TBSecciones = TbSecciones();


    const [activeTab, setActiveTab] = useState(0);
    const [modal, setModal] = useState(false);

    const webcamRef = React.useRef(null);
    const [habilitarDatosFamiliares, setHabilitarDatosFamiliares] = useState(true);
    const [habilitarDatosSocioEconomicos, setHabilitarDatosSocioEconomicos] = useState(true);
    const [habilitarAreaSalud, setHabilitarAreaSalud] = useState(true);
    const [habilitarMatricula, setHabilitarMatricula] = useState(true);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const SeleccionarFotoCedulaFrontal = (event) => {

        const file = event.target.files[0];
        if (file) {
            // Verificar si el archivo tiene una extensión válida
            const validExtensions = ['jpg', 'jpeg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                ErrorMessage("Formato no permitido", "Solo se permiten imágenes en formato JPG o JPEG.");
                return;
            }
            if (TBMatricula.nombreCedulaFrontal === file.name) {
                // Si es el mismo archivo, no hagas nada
                return;
            }
            if (file.size > TBMatricula.MAX_FILE_SIZE) {
                ErrorMessage("Imagen no permitida", "El tamaño máximo permitido es 500 KB. Por favor, seleccione una imagen más pequeña.")
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                TBMatricula.setIdFrontal(reader.result);
                TBMatricula.setNombreCedulaFrontal(file.name)
            };
            reader.readAsDataURL(file);
        }
    };
    const SeleccionarFotoCedulaReverso = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Verificar si el archivo tiene una extensión válida
            const validExtensions = ['jpg', 'jpeg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                ErrorMessage("Formato no permitido", "Solo se permiten imágenes en formato JPG o JPEG.");
                return;
            }
            if (TBMatricula.nombreCedulaReverso === file.name) {
                // Si es el mismo archivo, no hagas nada
                return;
            }

            if (file.size > TBMatricula.MAX_FILE_SIZE) {

                ErrorMessage("Imagen no permitida", "El tamaño máximo permitido es 500 KB. Por favor, seleccione una imagen más pequeña.")

                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                TBMatricula.setIdReverso(reader.result);
                TBMatricula.setNombreCedulaReverso(file.name)
            };
            reader.readAsDataURL(file);
        }
    };

    const SeleccionarResiboLuz = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Verificar si el archivo tiene una extensión válida
            const validExtensions = ['jpg', 'jpeg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                ErrorMessage("Formato no permitido", "Solo se permiten imágenes en formato JPG o JPEG.");
                return;
            }
            if (TBMatricula.nombreCedulaResibo === file.name) {
                // Si es el mismo archivo, no hagas nada
                return;
            }
            if (file.size > TBMatricula.MAX_FILE_SIZE) {

                ErrorMessage("Imagen no permitida", "El tamaño máximo permitido es 500 KB. Por favor, seleccione una imagen más pequeña.")

                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                TBMatricula.setResiboLuz(reader.result);
                TBMatricula.setNombreCedulaResibo(file.name)
            };
            reader.readAsDataURL(file);
        }
    };

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const obtenerFechaActual = () => {
        const hoy = new Date();
        const año = hoy.getFullYear();
        const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11, por eso se suma 1
        const dia = String(hoy.getDate()).padStart(2, '0');

        return `${año}-${mes}-${dia}`;
    };


    useEffect(() => {

        TBEstudiante.setCedula(sessionStorage.getItem('Est'))
        //console.log('Cédula recibida en FrmExpedienteEstudiante:', TBEstudiante.cedula); // Verifica si cédula se recibe
        if (TBEstudiante.cedula) {
            BuscarEstudiante2(TBEstudiante.cedula, TBEstudiante, 'nombre', 'primerApellido', 'segundoApellido', setHabilitarDatosFamiliares, TBCondSocioEco, TBDatosMedicos);
        }
        setLoading(false);
    }, [TBEstudiante.cedula]);


    useEffect(() => {
        const fetchData = async () => {

            const datos = {
                cedula: sessionStorage.getItem('Est'),
                institucionGrupoFamiliar: obtenerIdInstitucion(),
                annioLectivo: new Date().getFullYear()
            };

            try {
                const grupoFamiliar = await ListarGrupoFamiliar(datos);
                //console.log(JSON.stringify(grupoFamiliar));
                if (grupoFamiliar.estado) {
                    TBEstudiante.setGrupoFamiliar(grupoFamiliar.gruposFamiliares);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {

        GetGrados(TBSecciones.setListaGrados)
        //alert(JSON.stringify(TBSecciones.listaGrados));
    }, []);

    const fetchData = async () => {
        try {

            const Estado = 1;

            const data = await getSecciones(Estado);
            TBSecciones.setListaSecciones(data);

        } catch (error) {

        }
    };

    useEffect(() => {
        TBMatricula.setIdFrontal(idFrontal)
        TBMatricula.setIdReverso(idReverso)
        TBMatricula.setResiboLuz(resiboLuz)
        fetchData();
        GetSexo(TBEstudiante.setListaSexo);
        GetSexo(TBPersona.setListaGeneroFami);
        GetPaises(TBEstudiante.setListaPaises);
        GetTipoNacionalidad(TBEstudiante.setListaTipoID);
        GetTipoNacionalidad(TBPersona.setListaTipoID);
        GetPaises(TBPersona.setListaPaisesFami);
        GetProvincias(TBPersona.setListaProvinciasFami);
        GetProvincias(TBEstudiante.setListaProvincias);
        GetEstadoCivil(TBPersona.setListaEstadoCivil);
        GetParentescos(TBPersona.setListaParentescos);
        GetNivelEscolaridad(TBPersona.setListaNivelEscolaridad);
        GetOcupacion(TBPersona.setListaOcupacion);
        GetTipoCasa(TBCondSocioEco.setListaTipoCasa);
        GetCondicionCasa(TBCondSocioEco.setListaCondicionVivienda);
        GetTipoBeca(TBCondSocioEco.setListaTipoBeca);
        GetNumeros(TBCondSocioEco.setListaNumeros);
        GetDiscapacidades(TBDatosMedicos.setListaDiscapacidades);
        GetTiposSanguineos(TBDatosMedicos.setListaTiposSanguineos);
        GetBucoDental(TBDatosMedicos.setListaBucoDental);
        GetDiscapacidades(TBDatosMedicos.setListaDiscapacidades);
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDatosUsuario();

                TBPerfil.setNombre(data[0].nombreCompleto)
                TBPerfil.setCedula(data[0].cedula)
                TBPerfil.setApellido1(data[0].apellido1)
                TBPerfil.setApellido2(data[0].apellido2)

            } catch (error) {
                console.error("Error al obtener los datos del usuario:", error);
            }
        };

        fetchData();
    }, []);

    const Seleccion = (e, lista) => {
        const value = parseInt(e.target.value);
        //alert(value);
        lista('');

        lista(value);




    };
    const SeleccionGrado = (e, lista) => {
        const value = parseInt(e.target.value);
        //alert(value);
        lista('');

        lista(value);

        const seccionesDelGrado = TBSecciones.listaSecciones.filter(seccion => seccion.nivel == value);
        TBMatricula.setSeccionesFiltradas(seccionesDelGrado);


    };
    const toggleModal = () => setModal(!modal);

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
    };
    
    const capture = useCallback(() => {
        try {
            // Asegúrate de que webcamRef esté correctamente inicializado
            if (webcamRef.current) {
                const imageSrc = webcamRef.current.getScreenshot();
                if (imageSrc) {
                    alert(imageSrc);
                    TBEstudiante.setSelectedImage(imageSrc);
                    TBEstudiante.setfotoPerfil(imageSrc);
                    toggleModal(); // Cerrar el modal
                } else {
                    alert("Error: No se pudo capturar la imagen");
                }
            } else {
                alert("Error: webcamRef no está inicializado");
            }
        } catch (error) {
            // Capturar y mostrar cualquier error
            console.error("Error al capturar la imagen o acceder a la cámara:", error);
            alert(`Error al acceder a la cámara: ${error.message}`);
        }
    }, [webcamRef]);
    
    




    const GuardarEstudiante = async (e) => {
        e.preventDefault();

        // alert(TBEstudiante.cedula)
        // console.log('cedula:', TBEstudiante.cedula, typeof TBEstudiante.cedula);

        const cedula = TBEstudiante.cedula;
        const Parametro = { cedulaEstudiante: cedula }
        if (cedula.length === 9 || cedula.length === 12) {
            if (TBEstudiante.genero !== 0) {
                alert(TBEstudiante.pais);
                if (TBEstudiante.pais !== 0) {
                    if (TBEstudiante.provincia !== 0) {
                        if (TBEstudiante.canton !== 0) {
                            if (TBEstudiante.distrito !== 0) {
                                if (TBEstudiante.barrio !== 0) {
                                    const existe = await ExisteEstudiante(Parametro)
                                    if (existe.estado) {
                                        const estudiante = {
                                            cedulaEstudiante: TBEstudiante.cedula,
                                            nombreCompleto: TBEstudiante.nombre,
                                            apellido1: TBEstudiante.apellido1,
                                            apellido2: TBEstudiante.apellido2,
                                            genero: TBEstudiante.genero,
                                            fechaNacimiento: TBEstudiante.fechaNacimiento,
                                            lugarNacimiento: TBEstudiante.lugarNacimiento,
                                            nacionalidad: TBEstudiante.pais,
                                            condicion: TBEstudiante.condicion,
                                            provincia: TBEstudiante.provincia,
                                            canton: TBEstudiante.canton,
                                            distrito: TBEstudiante.distrito,
                                            barrio: TBEstudiante.barrio,
                                            direccionExacta: TBEstudiante.direccionExacta,
                                            telefono: TBEstudiante.telefono,
                                            celular: TBEstudiante.celular,
                                            correo: TBEstudiante.correo,
                                            contactarA: TBEstudiante.casoEmergencia,
                                            contactoEmergencia: TBEstudiante.telefonoEmergencia,
                                            foto: TBEstudiante.fotoPerfil

                                        }
                                        const success = await ActualizarEstudiante(estudiante);

                                        if (success) {


                                            SuccessMessage('Actualización Existosa', 'Estudiante Actualizado correctamente')



                                        } else {
                                            ErrorMessage('Notificación del sistema', 'Ocurrio un error inesperado al actualizar los datos del estudiante.')
                                            return;
                                        }
                                    }
                                } else {
                                    ErrorMessage('Invalido', 'Debes seleccionar un barrio valido')
                                }
                            } else {
                                ErrorMessage('Invalido', 'Debes seleccionar un distrito valido')
                            }
                        } else {
                            ErrorMessage('Invalido', 'Debes seleccionar un cantón valido')
                        }
                    } else {
                        ErrorMessage('Invalido', 'Debes seleccionar una provincia valida')
                    }
                } else {
                    ErrorMessage('Invalido', 'Debes seleccionar una nacionalidad valida')
                }

            }
            else {
                ErrorMessage('Invalido', 'Debes seleccionar el genero')
            }



        } else {
            ErrorMessage('Notificación del sistema', 'Ingrese un número de cédula valido.')
            return;
        }


    }

    const GuardarFamiliar = async (e) => {
        e.preventDefault();

        const identificacion = TBPersona.identificacion;

        if (TBPersona.tipoIdentificacion !== 0) {
            if (identificacion.length === 9 || identificacion.length === 12) {
                if (TBPersona.generoFami !== 0) {
                    if (TBPersona.estadoCivil !== 0) {
                        if (TBPersona.viveConEstudiante !== 0) {
                            if (TBPersona.parentesco !== 0) {

                                if (TBPersona.nacionalidadFami !== 0) {
                                    if (TBPersona.provinciaFami !== 0) {
                                        if (TBPersona.cantonFami !== 0) {
                                            if (TBPersona.distritoFami !== 0) {
                                                if (TBPersona.barrioFami !== 0) {
                                                    if (TBPersona.nivelEscolaridad !== 0) {
                                                        if (TBPersona.ocupacion !== 0) {

                                                            const existe = await ExistePersona({ cedula: TBPersona.identificacion });
                                                            if (existe) {
                                                                const familiarUpdate = {
                                                                    cedula: TBPersona.identificacion,
                                                                    tipoIdentificacion: parseInt(TBPersona.tipoIdentificacion),
                                                                    nombreCompleto: TBPersona.nombreFami,
                                                                    apellido1: TBPersona.apellido1Fami,
                                                                    apellido2: TBPersona.apellido2Fami,
                                                                    genero: parseInt(TBPersona.generoFami),
                                                                    nacionalidad: TBPersona.nacionalidadFami,
                                                                    tipoNacionalidad: parseInt(TBPersona.tipoNacionalidad),
                                                                    condicion: parseInt(TBPersona.condicionFami),
                                                                    provincia: TBPersona.provinciaFami,
                                                                    canton: TBPersona.cantonFami,
                                                                    distrito: TBPersona.distritoFami,
                                                                    barrio: TBPersona.barrioFami,
                                                                    direccion: TBPersona.direccionFami,
                                                                    parentesco: parseInt(TBPersona.parentesco),
                                                                    otroParentesco: TBPersona.otroParentesco,
                                                                    estadoCivil: parseInt(TBPersona.estadoCivil),
                                                                    viveConEstudiante: parseInt(TBPersona.viveConEstudiante),
                                                                    telefono: TBPersona.telefonoFami,
                                                                    email: TBPersona.correoFami,
                                                                    nivelEscolaridad: parseInt(TBPersona.nivelEscolaridad),
                                                                    ocupacion: parseInt(TBPersona.ocupacion),
                                                                    otraOcupacion: TBPersona.otraOcupacion,
                                                                    lugarTrabajo: TBPersona.lugarTrabajo,
                                                                }

                                                                const success = await ActualizarPersona(familiarUpdate);
                                                                const fechaActual = obtenerFechaActual();
                                                                if (success) {

                                                                    Swal.fire({
                                                                        background: "#474747",
                                                                        color: "#FFFFFF",
                                                                        title: "Registro Exitoso",
                                                                        text: "Familiar actualizado correctamente, si deceas actualizar los datos de otro familiar por favor seleccione el numero de cedula del familar?",
                                                                        icon: "success",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Actualiza otro Familiar",
                                                                        cancelButtonText: "No"
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            resetFormPersona();

                                                                        } else {

                                                                        }
                                                                    });

                                                                    const datos = {
                                                                        cedula: TBEstudiante.cedula,
                                                                        institucionGrupoFamiliar: codigoPresupuestario,
                                                                        annioLectivo: new Date().getFullYear()
                                                                    }


                                                                    const grupoFamiliar = await ListarGrupoFamiliar(datos);
                                                                    if (grupoFamiliar.estado) {
                                                                        TBEstudiante.setGrupoFamiliar(grupoFamiliar.gruposFamiliares);
                                                                        const listaFamilia = await Promise.all(grupoFamiliar.gruposFamiliares.map(async (grupo) => {
                                                                            const persona = await GetPersona({ cedula: grupo.personaGrupoFamiliar });
                                                                            return persona;
                                                                        })
                                                                        );
                                                                        const listaFamiliaPlana = listaFamilia.flat();

                                                                        TBEstudiante.setListaGrupoFamiliar(listaFamiliaPlana);
                                                                    }

                                                                } else {
                                                                    ErrorMessage('Notificación del Sistema', 'Ocurrio un error al actualizar el familiar')
                                                                }
                                                            }


                                                        } else {
                                                            ErrorMessage('Invalido', 'Debes seleccionar  la ocupacion ')
                                                        }
                                                    } else {
                                                        ErrorMessage('Invalido', 'Debes seleccionar  el nivel de escolaridad ')
                                                    }
                                                } else {
                                                    ErrorMessage('Invalido', 'Debes seleccionar un barrio valido')
                                                }
                                            } else {
                                                ErrorMessage('Invalido', 'Debes seleccionar un distrito valido')
                                            }
                                        } else {
                                            ErrorMessage('Invalido', 'Debes seleccionar un cantón valido')
                                        }
                                    } else {
                                        ErrorMessage('Invalido', 'Debes seleccionar una provincia valida')
                                    }
                                } else {
                                    ErrorMessage('Invalido', 'Debes seleccionar una nacionalidad valida')
                                }

                            } else {
                                ErrorMessage('Invalido', 'Debes seleccionar el parentesco ')
                            }
                        } else {
                            ErrorMessage('Invalido', 'Debes seleccionar si vive o no con el estudiante')
                        }

                    } else {
                        ErrorMessage('Invalido', 'Debes seleccionar el estado civil')
                    }
                } else {
                    ErrorMessage('Invalido', 'Debes seleccionar el genero')
                }
            } else {
                ErrorMessage('Invalido', 'Formato de Cedula invalido ')
            }
        } else {
            ErrorMessage('Invalido', 'Debes seleccionar un tipo de identificación ')
        }
    }

    const GuardarSocio_Economica = async (e) => {
        e.preventDefault();

        if (TBCondSocioEco.tipoCasa !== 0) {
            if (TBCondSocioEco.condicionVivienda !== 0) {
                if (TBCondSocioEco.cantPersonas !== 0) {
                    if (TBCondSocioEco.accesoInternet !== 0) {
                        if (TBCondSocioEco.tipoBeca !== 0) {
                            if (TBCondSocioEco.bonoEscolar !== 0) {

                                if (TBCondSocioEco.existeSocioEco) {
                                    const socio_economica = {
                                        IdSocioEconomica: TBCondSocioEco.IdSocioEconomica,
                                        tipoCasa: parseInt(TBCondSocioEco.tipoCasa),
                                        condicionCasa: parseInt(TBCondSocioEco.condicionVivienda),
                                        accesoInternet: parseInt(TBCondSocioEco.accesoInternet),
                                        gastoMensuales: parseInt(TBCondSocioEco.gastosMensuales),
                                        cantidadFamiliares: parseInt(TBCondSocioEco.cantPersonas),
                                        ingresosTotales: parseInt(TBCondSocioEco.ingresosMensuales),
                                        posseBeca: parseInt(TBCondSocioEco.tipoBeca),
                                        bonoEscolar: parseInt(TBCondSocioEco.bonoEscolar),
                                        otroTipoBeneficio: TBCondSocioEco.otroBeneficio,
                                        annio: new Date().getFullYear()

                                    }
                                    const success = await ActualizarCondicionSocioEconomica(socio_economica);
                                    if (success) {

                                        SuccessMessage('Actualización Existosa', 'Los datos Socio-Ecomonómicos han sido Actualizados');

                                    }
                                    else {
                                        ErrorMessage('Notificación del sistema', 'Ocurrio un error inesperado al guardar los datos del estudiante.')
                                        return;
                                    }
                                }
                            } else {
                                ErrorMessage('Notificación del sistema', 'Debes seleccionar si el estudiante cuenta con bono escolar.')
                            }
                        } else {
                            ErrorMessage('Notificación del sistema', 'Debes seleccionar si el estudiante posee algun tipo de beca.')
                        }
                    } else {
                        ErrorMessage('Notificación del sistema', 'Debes seleccionar si posee acceso a Internet')
                    }
                } else {
                    ErrorMessage('Notificación del sistema', 'Debes seleccionar la cantidada de personas que viven con el estudiante.');
                }
            } else {
                ErrorMessage('Notificación del sistema', 'Debes seleccionar la condición en la que se encuentra la vivienda.')
            }
        } else {
            ErrorMessage('Notificación del sistema', 'Debes seleccionar el tipo de vivienda en la que habita el estudiante.')
        }

    }

    const GuardarAreaSalud = async (e) => {
        e.preventDefault();

        if (TBDatosMedicos.agudezaVisual !== 0) {
            if (TBDatosMedicos.agudezaAuditiva !== 0) {
                if (TBDatosMedicos.condicionBucoDental !== 0) {
                    if (TBDatosMedicos.tipoSangre !== 0) {
                        if (TBDatosMedicos.situacionSalud !== 0) {
                            if (TBDatosMedicos.poseeTratamientoMedico !== 0) {
                                if (TBDatosMedicos.medicarseDuranteClases !== 0) {
                                    if (TBDatosMedicos.esquemaVacunas !== 0) {
                                        if (TBDatosMedicos.variedadAlimenticia !== 0) {
                                            if (TBDatosMedicos.horariosRegularesComida !== 0) {
                                                if (TBDatosMedicos.alergiaAlimento !== 0) {
                                                    if (TBDatosMedicos.alergiaMedicamento !== 0) {
                                                        if (TBDatosMedicos.discapacidades !== 0) {
                                                            const fechaActual = new Date();
                                                            const anio = fechaActual.getFullYear();
                                                            const areaSalud = {
                                                                cedulaEstudiante: TBEstudiante.cedula,
                                                                peso: parseFloat(TBDatosMedicos.peso),
                                                                talla: parseFloat(TBDatosMedicos.talla),
                                                                agudezaVisual: parseInt(TBDatosMedicos.agudezaVisual),
                                                                agudezaAuditiva: parseInt(TBDatosMedicos.agudezaAuditiva),
                                                                condicionBucoDental: parseInt(TBDatosMedicos.condicionBucoDental),
                                                                hemogramas: TBDatosMedicos.hemogramas,
                                                                situacionsalud: parseInt(TBDatosMedicos.situacionSalud),
                                                                situacion: TBDatosMedicos.situacion,
                                                                poseeTratamientoMedico: parseInt(TBDatosMedicos.poseeTratamientoMedico),
                                                                medicarseDuranteClases: parseInt(TBDatosMedicos.medicarseDuranteClases),
                                                                medicamentos: TBDatosMedicos.medicamentos,
                                                                esquemaVacunas: parseInt(TBDatosMedicos.esquemaVacunas),
                                                                nombreVacunasFaltantes: TBDatosMedicos.nombreVacunasFaltantes,
                                                                variedadAlimenticia: parseInt(TBDatosMedicos.variedadAlimenticia),
                                                                horariosRegulaesComida: parseInt(TBDatosMedicos.horariosRegularesComida),
                                                                alergiaMedicamento: parseInt(TBDatosMedicos.alergiaAlimento),
                                                                nombreMedicamentoAlergico: TBDatosMedicos.nombreMedicamentoAlergico,
                                                                alergiaAlimento: parseInt(TBDatosMedicos.alergiaAlimento),
                                                                nombreAlimentoAlergia: TBDatosMedicos.nombreAlimentoAlergico,
                                                                otrasAlergias: TBDatosMedicos.otrasAlergias,
                                                                poseeDiscapasidades: parseInt(TBDatosMedicos.poseeDiscapasidades),
                                                                discapacidades: TBDatosMedicos.discapacidadesSeleccionadas,
                                                                tipoSangre: parseInt(TBDatosMedicos.tipoSangre),
                                                                anio: anio
                                                            }
                                                            const DatosMedicos = await ListarDatosMedicos({ cedulaEstudiante: TBEstudiante.cedula })
                                                            if (DatosMedicos.estado) {
                                                                const success = await ActualizarDatosMedicos(areaSalud);
                                                                if (success) {
                                                                    SuccessMessage('Actualización Existosa', 'Los datos medicos han sido acrualizados.')


                                                                } else {
                                                                    ErrorMessage('Notificación del sistema', 'Ocurrio un error al registrar la informción.')

                                                                }
                                                            }



                                                        } else {
                                                            InfoMessage('Notificación del sistema', 'Debes indicar si el estudiante padece de alguna discapacidad.')
                                                        }
                                                    } else {
                                                        InfoMessage('Notificación del sistema', 'Debes indicar si el estudiante es aleérgico algún medicamento.')
                                                    }
                                                } else {
                                                    InfoMessage('Notificación del sistema', 'Debes indicar si el estudiante es alérgico algún tipo de alimento.')
                                                }
                                            } else {
                                                InfoMessage('Notificación del sistema', 'Debes indicar si el estudiante cumple con horarios regulares de alimentación.')
                                            }
                                        } else {
                                            InfoMessage('Notificación del sistema', 'Debes seleccionar si el estuduante cumple con una variedad alimenticia.')
                                        }
                                    } else {
                                        InfoMessage('Notificación del sistema', 'Debes seleccionar el estado del esquema de vacunas del estuduante.')
                                    }
                                } else {
                                    InfoMessage('Notificación del sistema', 'Debes indicar si el estudiante se debe medicar mientras se encuentra en la institución.')
                                }
                            } else {
                                InfoMessage('Notificación del sistema', 'Debes indicar si el estudiante se encuentra bajo algun tratamiento medico.')
                            }
                        } else {
                            InfoMessage('Notificación del sistema', 'Debes indicar si el estudiante presenta o presento alguna situación de salud.')
                        }
                    } else {
                        InfoMessage('Notificación del sistema', 'Debes seleccionar el grupo sanguineo del estudiante.')
                    }
                } else {
                    InfoMessage('Notificación del sistema', 'Debes seleccionar la condición bucodental del estudiante.')
                }
            } else {
                InfoMessage('Notificación del sistema', 'Debes seleccionar el nivel de agudeza auditiva del estudiante.')
            }
        } else {
            InfoMessage('Notificación del sistema', 'Debes seleccionar el nivel de agudeza visual del estudiante.')
        }

    }

    const GuardarMatricula = async (e) => {
        e.preventDefault();
        if (TBMatricula.fechaMatricula === '') {
            InfoMessage('Notificación del sistema', 'Debes seleccionar la fecha de la matrícula.');
            return;
        }
        if (TBMatricula.añoLectivo === 0) {
            InfoMessage('Notificación del sistema', 'Debes seleccionar un curso lectivo válido.');
            return;
        }
        if (TBMatricula.cedula === '') {
            InfoMessage('Notificación del sistema', 'Debes seleccionar la identificación del matriculante.');
            return;
        }
        if (TBMatricula.medioMatricula === 0) {
            InfoMessage('Notificación del sistema', 'Debes seleccionar el medio de matrícula.');
            return;
        }
        if (TBMatricula.estadoMatricula === 0) {
            InfoMessage('Notificación del sistema', 'Debes seleccionar el estado de la matrícula.');
            return;
        }
        // Validar y asignar encargados
        const encargadosArray = TBMatricula.encargadosSeleccionados;
        //alert(encargadosArray)
        if (encargadosArray.length === 0) {
            InfoMessage('Notificación del sistema', 'Debes seleccionar al menos 1 encargado.');
            return;
        }

        if (encargadosArray.length === 1) {
            TBMatricula.setEncargado1(encargadosArray[0].trim());

        } else if (encargadosArray.length === 2) {
            TBMatricula.setEncargado1(encargadosArray[0].trim());
            TBMatricula.setEncargado2(encargadosArray[1].trim());

        }

        if (!TBMatricula.seccion) {
            InfoMessage('Notificación del sistema', 'Debes seleccionar una sección.');
            return;
        }

        const formData = new FormData();
        formData.append('cedulaEstuMatricula', TBEstudiante.cedula);
        formData.append('cursoLectivo', TBMatricula.añoLectivo);
        formData.append('fecha', TBMatricula.fechaMatricula);
        formData.append('medioPrematricula', TBMatricula.medioMatricula);
        formData.append('realizadaPor', TBMatricula.cedula);
        formData.append('estadoPrematricula', TBMatricula.estadoMatricula);
        formData.append('codInstitucion', codigoPresupuestario);
        formData.append('encargado1', TBMatricula.encargado1);
        formData.append('encargado2', TBMatricula.encargado2);
        formData.append('aprobadoPor', obtenerUsuario());
        formData.append('seccion', TBMatricula.seccion);
        formData.append('fotoEstu', TBEstudiante.selectedImage);
        formData.append('fotoCedula', TBMatricula.idFrontal);
        formData.append('reciboLuz', TBMatricula.resiboLuz);
        formData.append('otroDoc', TBMatricula.idReverso);

        
        const estado = await ActualizarMatriculaEstudiante(formData);
        if (estado) {
            SuccessMessage('Registro Exitoso', 'La matricula se actualizo correctamente')
        } else {
            ErrorMessage('Notificación del Sistema', 'Ocurrio un error al realizar la matricula.')
        }
    };

    const longitud = (valor, tb, tbnombre, tbape1, tbape2, id, fun) => {
        const idElement = document.getElementById(id);
        const nombreElement = document.getElementById('nombre');
        const primerApellidoElement = document.getElementById('primerApellido');
        const segundoApellidoElement = document.getElementById('segundoApellido');

        if (idElement) idElement.removeAttribute('disabled');
        if (nombreElement) nombreElement.setAttribute('disabled', true);
        if (primerApellidoElement) primerApellidoElement.setAttribute('disabled', true);
        if (segundoApellidoElement) segundoApellidoElement.setAttribute('disabled', true);

        tb('');
        tbnombre('');
        tbape1('');
        tbape2('');
        resetFormPersona();

        if (valor === '1') {
            if (idElement) {
                idElement.setAttribute('maxLength', 9);
                idElement.setAttribute('minLength', 9);
                idElement.setAttribute('placeholder', '0-0000-0000');
                resetFormEstudiante();
            }
        }
        if (valor === '2') {
            if (idElement) {
                idElement.setAttribute('placeholder', '000000000000');
                idElement.setAttribute('maxLength', 12);
                idElement.setAttribute('minLength', 12);
                resetFormEstudiante();
            }
        }
    }

    const longitudPersona = (valor, tb, tbnombre, tbape1, tbape2, id, fun) => {
        const idElement = document.getElementById(id);
        const nombreElement = document.getElementById('nombre');
        const primerApellidoElement = document.getElementById('primerApellido');
        const segundoApellidoElement = document.getElementById('segundoApellido');

        if (idElement) idElement.removeAttribute('disabled');
        if (nombreElement) nombreElement.setAttribute('disabled', true);
        if (primerApellidoElement) primerApellidoElement.setAttribute('disabled', true);
        if (segundoApellidoElement) segundoApellidoElement.setAttribute('disabled', true);

        tb('');
        tbnombre('');
        tbape1('');
        tbape2('');
        resetFormPersona();

        if (valor === '1') {
            if (idElement) {
                idElement.setAttribute('maxLength', 9);
                idElement.setAttribute('minLength', 9);
                idElement.setAttribute('placeholder', '0-0000-0000');
                resetFormPersona();
            }
        }
        if (valor === '2') {
            if (idElement) {
                idElement.setAttribute('placeholder', '000000000000');
                idElement.setAttribute('maxLength', 12);
                idElement.setAttribute('minLength', 12);
                resetFormPersona();
            }
        }
    }
    const resetFormSocioEconomica = () => {

        TBCondSocioEco.setTipoCasa(0);
        TBCondSocioEco.setCondicionVivienda(0);
        TBCondSocioEco.setCantPersonas(0);
        TBCondSocioEco.setAccesoInternet(0);
        TBCondSocioEco.setIngresosMensuales('');
        TBCondSocioEco.setGastosMensuales('');
        TBCondSocioEco.setTipoBeca(0);
        TBCondSocioEco.setBonoEscolar(0);
        TBCondSocioEco.setOtroBeneficio('');
        TBCondSocioEco.setIdSocioEconomica(0);
        TBCondSocioEco.setAño('');
    }

    const resetFormEstudiante = () => {
        TBEstudiante.setTipoIdentificacion('');
        TBEstudiante.setCedula('');
        TBEstudiante.setNombre('');
        TBEstudiante.setapellido1('');
        TBEstudiante.setapellido2('');
        TBEstudiante.setGenero(0);
        TBEstudiante.setFechaNacimiento('');
        TBEstudiante.setEdad('');
        TBEstudiante.setlugarNacimiento('');
        TBEstudiante.setfotoPerfil('');
        TBEstudiante.setSelectedImage('');
        TBEstudiante.setPais(0);
        TBEstudiante.setCondicion(0);
        TBEstudiante.setProvincia(0);
        TBEstudiante.setCanton(0);
        TBEstudiante.setDistrito(0);
        TBEstudiante.setBarrio(0);
        TBEstudiante.setDireccionExacta('');
        TBEstudiante.setTelefono('');
        TBEstudiante.setCelular('');
        TBEstudiante.setCorreo('');
        TBEstudiante.setTelefonoEmergencia('');
        TBEstudiante.setCasoEmergencia('');
    }
    const resetFormPersona = () => {
        TBPersona.setExiste(false)
        TBPersona.setTipoIdentificacion(0)
        TBPersona.setIdentificacion('')
        TBPersona.setNombreFami('')
        TBPersona.setApellido1Fami('')
        TBPersona.setApellido2Fami('')
        TBPersona.setGeneroFami(0)
        TBPersona.setEstadoCivil(0)
        TBPersona.setViveConEstudiante(0)
        TBPersona.setTelefonoFami('')
        TBPersona.setCorreoFami('')
        TBPersona.setParentesco(0)
        TBPersona.setOtroParentesco('')
        TBPersona.setNacionalidadFami('')
        TBPersona.setCondicionFami(0)
        TBPersona.setProvinciaFami(0)
        TBPersona.setCantonFami(0)
        TBPersona.setDistritoFami(0)
        TBPersona.setBarrioFami(0)
        TBPersona.setDireccionFami('')
        TBPersona.setNivelEscolaridad(0)
        TBPersona.setOtraOcupacion('')
        TBPersona.setOCupacion(0)
        TBPersona.setLugarTrabajo('')
        TBPersona.setTipoNacionalidad(0)

    }

    const resetFormDatosMedicos = () => {
        TBDatosMedicos.setPeso(0)
        TBDatosMedicos.setTalla(0)
        TBDatosMedicos.setImc(0)
        TBDatosMedicos.setAgudezaVisual(0)
        TBDatosMedicos.setAgudezaAuditiva(0)
        TBDatosMedicos.setCondicionBucoDental(0)
        TBDatosMedicos.setHemogramas(0)
        TBDatosMedicos.setSituacionSalud(0)
        TBDatosMedicos.setSituacion('')
        TBDatosMedicos.setPoseeTratamientoMedico(0)
        TBDatosMedicos.setMedicarseDuranteClases(0)
        TBDatosMedicos.setMedicamentos([])
        TBDatosMedicos.setEsequemaVacunas(0)
        TBDatosMedicos.setNombreVacunasFaltantes('')
        TBDatosMedicos.setVariedadAlimenticia(0)
        TBDatosMedicos.setHorariosRegularesComida(0)
        TBDatosMedicos.setAlergiaMedicamento(0)
        TBDatosMedicos.setNombreMedicamentoAlergico('')
        TBDatosMedicos.setAlergiaAlimento(0)
        TBDatosMedicos.setNombreAlimentoAlergico('')
        TBDatosMedicos.setOtrasAlergias('')
        TBDatosMedicos.setPoseeDiscapasidades(0)
        TBDatosMedicos.setDiscapacidades([])
        TBDatosMedicos.setTipoSangre(0)


    };

    const resetMatricula = () => {

        TBMatricula.setAñoLectivo(0);
        TBMatricula.setFechaMatricula('');
        TBMatricula.setMedioMatricula(0);
        TBMatricula.setNombre('')
        TBMatricula.setApellido1('')
        TBMatricula.setApellido2('')
        TBMatricula.setEstadoMatricula();
        TBMatricula.setGrado();
        TBMatricula.setSeccion();
        TBMatricula.setNombreCedulaFrontal('');
        TBMatricula.setNombreCedulaReverso('');
        TBMatricula.setNombreCedulaResibo('');
        TBMatricula.setEncargado1('');
        TBMatricula.setEncargado2('');
        TBMatricula.setMatriculaAutoriza('');
        TBMatricula.setIdFrontal(idFrontal);
        TBMatricula.setIdReverso(idReverso);
        TBMatricula.setResiboLuz(resiboLuz);
        TBMatricula.setEncargadosSeleccionados([]);
        TBMatricula.setSeccionesFiltradas([]);

    }

    const manejarSeleccionDiscapacidad = (e) => {
        const { value, checked } = e.target;

        if (checked) {
            // Si se selecciona la discapacidad, la agregamos al array
            TBDatosMedicos.setDiscapacidadesSeleccionadas((prev) => {
                const nuevoArray = [...prev, value];
                console.log('Discapacidad agregada:', value);
                console.log('Estado actual:', nuevoArray);
                return nuevoArray;
            });
        } else {
            // Si se deselecciona, la removemos del array
            TBDatosMedicos.setDiscapacidadesSeleccionadas((prev) => {
                const nuevoArray = prev.filter((id) => id !== value);

                return nuevoArray;
            });
        }
    };

    const SeleccionarEncargado = (e) => {
        const { value, checked } = e.target;

        TBMatricula.setEncargadosSeleccionados((prev) => {
            let nuevoArray;

            if (checked && prev.length >= 2) {
                // Si ya hay dos encargados seleccionados, no permitir seleccionar más
                WarningMessage("Notificación del Sistema", "Solo puedes seleccionar hasta dos encargados legales como máximo.");
                return prev;
            }

            if (checked) {
                // Si se selecciona un encargado y aún no se ha alcanzado el límite, agregarlo al array
                nuevoArray = [...prev, value];
            } else {
                // Si se deselecciona un encargado, removerlo del array
                nuevoArray = prev.filter((id) => id !== value);
            }

            // Mostrar los datos seleccionados en un alert
            //alert(`Encargados seleccionados: ${nuevoArray.join(', ')}`);

            return nuevoArray;
        });
    };



    const calcularIMC = () => {
        const pesoNum = parseFloat(TBDatosMedicos.peso);
        const tallaNum = parseFloat(TBDatosMedicos.talla);
        if (pesoNum > 0 && tallaNum > 0) {
            const imcValue = (pesoNum / (tallaNum * tallaNum)).toFixed(2);
            //setImc(imcValue);
            TBDatosMedicos.setImc(imcValue);
        } else {
            //setImc('');
            TBDatosMedicos.setImc('');
        }
    };
    const setField = (field, value) => {
        TBDatosMedicos.setMedicamentos(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const agregarMedicamento = (event) => {
        event.preventDefault();
        //alert(JSON.stringify(TBDatosMedicos.medicamentos));
        if (TBDatosMedicos.medicamentoIn !== '' && TBDatosMedicos.dosis !== '' && TBDatosMedicos.horario !== '') {
            const nuevoMedicamento = {
                medicamento: TBDatosMedicos.medicamentoIn,
                dosis: TBDatosMedicos.dosis,
                frecuencia: TBDatosMedicos.horario
            };
            TBDatosMedicos.setMedicamentos([...TBDatosMedicos.medicamentos, nuevoMedicamento]);
            TBDatosMedicos.setMedicamentoIn('');
            TBDatosMedicos.setDosis('');
            TBDatosMedicos.setHorario('');
        } else {
            InfoMessage('Notificación del Sistema', 'Debes completar los campos de Medicamento, Dosis y Horario.')
        }

    };

    const eliminarMedicamento = (index) => {
        TBDatosMedicos.setMedicamentos(prevState => {
            if (Array.isArray(prevState)) {
                // Filtrar el arreglo de medicamentos para eliminar el medicamento en la posición 'index'
                const nuevosMedicamentos = prevState.filter((_, i) => i !== index);
                return nuevosMedicamentos;
            } else {
                // En caso de que el estado no sea un arreglo, retornar el estado sin cambios
                return prevState;
            }
        });
    };

    const Ver = (id, accion) => {
        if (accion === true) {
            document.getElementById(id).removeAttribute('hidden')
        } else {
            document.getElementById(id).setAttribute('hidden', true);
        }

    }


    useEffect(() => {
        if (TBEstudiante.provincia) {
            GetCantones({ target: { value: TBEstudiante.provincia } }, TBEstudiante.setProvincia, TBEstudiante.setListaCantones);
        }
    }, [TBEstudiante.provincia]);

    useEffect(() => {
        if (TBEstudiante.canton) {
            GetDistritos({ target: { value: TBEstudiante.canton } }, TBEstudiante.provincia, TBEstudiante.setCanton, TBEstudiante.setListaDistritos);
        }
    }, [TBEstudiante.canton]);

    useEffect(() => {
        if (TBEstudiante.distrito) {
            GetBarrios({ target: { value: TBEstudiante.distrito } }, TBEstudiante.provincia, TBEstudiante.canton, TBEstudiante.setDistrito, TBEstudiante.setListaBarrios);
        }
    }, [TBEstudiante.distrito]);

    useEffect(() => {
        if (TBPersona.provinciaFami) {
            GetCantones({ target: { value: TBPersona.provinciaFami } }, TBPersona.setProvinciaFami, TBPersona.setListaCantonesFami);
        }
    }, [TBPersona.provinciaFami]);

    useEffect(() => {
        if (TBPersona.cantonFami) {
            GetDistritos({ target: { value: TBPersona.cantonFami } }, TBPersona.provinciaFami, TBPersona.setCantonFami, TBPersona.setListaDistritosFami);
        }
    }, [TBPersona.cantonFami]);

    useEffect(() => {
        if (TBPersona.distritoFami) {
            GetBarrios({ target: { value: TBPersona.distritoFami } }, TBPersona.provinciaFami, TBPersona.cantonFami, TBPersona.setDistritoFami, TBPersona.setListaBarriosFami);
        }
    }, [TBPersona.distritoFami]);

    if (loading) {
        return (
            <Cargando />
        );
    }

    return (
        <Container fluid className=" Divcontenedor ">

            <Col className='border-bottom mb-1 pb-0'>
                <Breadcrumb listTag="div" className='pb-0'>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink pb-0'}
                    />
                    <Label className=' px-1 py-1 pb-0'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Expedientes"
                        to="/Menu/FrmExpedientes"
                        classname={'NoDecoration2 tlink pb-0'}
                    />
                    <Label className=' px-1 py-1 pb-0'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Expediente del Estudiante"
                        to="/Menu/FrmNuevoExpediente"
                        classname={'NoDecoration2 tlink pb-0'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='  px-0  ' >
                <Tabs className={'p-0 m-0  border  '}>
                    <TabList className={'tabs_ '} >

                        <Tab selected="false">
                            Datos Personales
                        </Tab>
                        <Tab  >
                            Datos Familiares
                        </Tab>
                        <Tab  >
                            Datos Socio-Económicos
                        </Tab>
                        <Tab  >
                            Area de Salud
                        </Tab>

                        <Tab  >
                            Matricula
                        </Tab>


                    </TabList>
                    <Container fluid className=' shadow2 px-0 contenedorTabsExpediente rounded-0' >

                        <TabPanel color='#3085d6'>
                            <Container fluid className=' p-0   mt-0' >
                                <Form onSubmit={GuardarEstudiante} id='guardarEstudiante'>


                                    <Row className='px-2' xs={1} sm={2} md={3} lg={4}>

                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <Label for="files" className=''>
                                                <strong> Datos del Estudiante</strong>
                                            </Label>
                                        </Col>

                                        <Col className='col-sm-12 col-12' >
                                            <Col className='custom-form-group'>
                                                <Label for="TipoID">
                                                    Tipo Identificación
                                                </Label>
                                                <FormGroup className="d-flex align-items-center">
                                                    {TBEstudiante.ListaTipoID.map(tipoID => (
                                                        <div key={tipoID.id} className="form-check mr-3">
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id={`TipoID${tipoID.id}`}
                                                                name="TipoID"
                                                                value={tipoID.id}
                                                                checked={TBEstudiante.tipoIdentificacion === tipoID.id}
                                                                onChange={(e) => Seleccion(e, TBEstudiante.setTipoIdentificacion)}
                                                                onClick={(e) => longitud(e.target.value, TBEstudiante.setCedula, TBEstudiante.setNombre, TBEstudiante.setapellido1, TBEstudiante.setapellido2, 'identificacion')}
                                                                disabled
                                                            />
                                                            <Label className="form-check-label ml-2" for={`TipoID${tipoID.id}`}>
                                                                {tipoID.nombre}
                                                            </Label>
                                                        </div>
                                                    ))}
                                                </FormGroup>
                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group' >
                                                <Label for="identificacion" className="me-2">
                                                    Identificación
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        required
                                                        title='Debes seleccionar el tipo de cedula primero'
                                                        disabled
                                                        id="identificacion"
                                                        name="identificacion"
                                                        placeholder="0-0000-0000"
                                                        type="text"
                                                        value={TBEstudiante.cedula}
                                                        onChange={(event) => ValidarSoloNumeros(event, TBEstudiante.setCedula, TBEstudiante.setCedulaValidad)}
                                                        maxLength={12}
                                                        minLength={9}
                                                        className={`${TBEstudiante.cedulaValida ? '' : 'is-invalid'} py-1`}
                                                    />

                                                    {!TBEstudiante.cedulaValida && (
                                                        <Tooltip
                                                            placement="right"
                                                            isOpen={tooltipOpen}
                                                            target="identificacion"
                                                            toggle={toggleTooltip}
                                                        >
                                                            Formato de cedula invalido.
                                                        </Tooltip>
                                                    )}
                                                </InputGroup>


                                            </Col >
                                        </Col>

                                        <Col className='col-lg-6 col-md-4 col-sm-12 col-12'>
                                            {isLoading2 ?
                                                <Col className='custom-form-group' >
                                                    <Row>
                                                        <Col md={12} className="me-2"> </Col>
                                                        <br></br>
                                                        <Col md={12} className="me-2">
                                                            <Spinner
                                                                color="primary"
                                                                size="sm"
                                                                type="grow"
                                                            >
                                                                Loading...
                                                            </Spinner>
                                                            <Label className="me-2 mx-2">
                                                                Un momento por favor mientras se realiza la consulta...
                                                            </Label>
                                                        </Col>
                                                    </Row>
                                                </Col> : null}
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group'  >
                                                <Label for="nombre" className="me-2">
                                                    Nombre
                                                </Label>
                                                <Input
                                                    disabled
                                                    required
                                                    id="nombre"
                                                    name="nombre"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBEstudiante.nombre}
                                                    onChange={(event) => ValidarSoloLetras(event, TBEstudiante.setNombre, TBEstudiante.setNombreValido)}
                                                    className={`${TBEstudiante.nombreValido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBEstudiante.nombreValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="nombre"
                                                        toggle={toggleTooltip}
                                                    >
                                                        No se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col >

                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group'   >
                                                <Label for="primerApellido" className="me-2">
                                                    Primer Apellido
                                                </Label>
                                                <Input
                                                    disabled
                                                    required
                                                    id="primerApellido"
                                                    name="primerApellido"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBEstudiante.apellido1}
                                                    onChange={(event) => ValidarSoloLetras(event, TBEstudiante.setapellido1, TBEstudiante.setapellido1Valido)}
                                                    className={`${TBEstudiante.apellido1Valido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBEstudiante.apellido1Valido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="primerApellido"
                                                        toggle={toggleTooltip}
                                                    >
                                                        No se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col >

                                        </Col>

                                        <Col  >
                                            < Col className='custom-form-group'>
                                                <Label for="segundoApellido" className="me-2">
                                                    Segundo Apellido
                                                </Label>
                                                <Input
                                                    disabled
                                                    required
                                                    id="segundoApellido"
                                                    name="segundoApellido"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBEstudiante.apellido2}
                                                    onChange={(event) => ValidarSoloLetras(event, TBEstudiante.setapellido2, TBEstudiante.setapellido2Valido)}
                                                    className={`${TBEstudiante.apellido2Valido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBEstudiante.apellido2Valido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="segundoApellido"
                                                        toggle={toggleTooltip}
                                                    >
                                                        No se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col >

                                        </Col>

                                        <Col >
                                            <Col className='custom-form-group'>
                                                <Label for="Genero">
                                                    Género
                                                </Label>
                                                <FormGroup className="d-flex align-items-center">
                                                    {TBEstudiante.ListaSexo.map(sexo => (
                                                        <div key={sexo.id} className="form-check mr-3">
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id={`sexo${sexo.id}`}
                                                                name="genero"
                                                                value={sexo.id}
                                                                checked={TBEstudiante.genero === sexo.id}
                                                                onChange={() => TBEstudiante.setGenero(sexo.id)}
                                                            />
                                                            <Label className="form-check-label ml-2" for={`sexo${sexo.id}`}>
                                                                {sexo.nombre}
                                                            </Label>
                                                        </div>
                                                    ))}
                                                </FormGroup>
                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group'>
                                                <Label for="fechaNacimiento">
                                                    Fecha de Nacimiento
                                                </Label>
                                                <Input
                                                    required
                                                    id="fechaNacimiento"
                                                    name="fechaNacimiento"
                                                    type="date"

                                                    defaultValue={TBEstudiante.fechaNacimiento}
                                                    onChange={(e) => ObtenerEdad(e, TBEstudiante.setEdad, TBEstudiante.setFechaNacimiento)}

                                                    className='py-1'
                                                />

                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group'>
                                                <Label for="fechaNacimiento">
                                                    Edad del Estudiante
                                                </Label><br></br>
                                                <Label for="fechaNacimiento">
                                                    <strong>{TBEstudiante.edad}</strong>
                                                </Label>

                                            </Col>
                                        </Col>

                                        <Col  >
                                            < Col className='custom-form-group'>
                                                <Label for="lugarNacimiento" className="me-2">
                                                    Lugar de Nacimiento
                                                </Label>
                                                <Input

                                                    required
                                                    id="lugarNacimiento"
                                                    name="lugarNacimiento"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBEstudiante.lugarNacimiento}
                                                    onChange={(event) => TBEstudiante.setlugarNacimiento(event.target.value)}
                                                    className={'py-1'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                            </Col >

                                        </Col>

                                        <Col className='col-lg-12 col-md-12 col-sm-12' >
                                            <Col className='px-1 mt-1'>
                                                <Input
                                                    className=' '
                                                    hidden
                                                    id="files"
                                                    name="files"
                                                    type="file"
                                                    accept='image/*'
                                                    onChange={(e) => ObtenerImagen(e, TBEstudiante.setSelectedImage, TBEstudiante.setfotoPerfil)}
                                                />

                                                {TBEstudiante.selectedImage ? (
                                                    <img src={TBEstudiante.selectedImage} className='FotoPerfilExpediente  ' alt="Foto de Perfil" />
                                                ) : (
                                                    <img src={Foto} className='FotoPerfilExpediente  ' alt="Foto de Perfil" />
                                                )}


                                            </Col>
                                            <Col>
                                                <Label for="files" className="custom-file-upload mx-1">Seleccionar Archivo</Label>

                                                <Label onClick={toggleModal} className=" custom-file-upload mx-1">Tomar Foto</Label>
                                            </Col>




                                        </Col>
                                    </Row>

                                    <Row className='px-2 mt-2' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <Label for="files" className='  '>
                                                <strong>Lugar de Residencia</strong>
                                            </Label>
                                        </Col>
                                        <Col  >
                                            <Col className='custom-form-group'>
                                                <Label for="Nacionalidad">
                                                    Nacionalidad
                                                </Label>
                                                <Input
                                                    required
                                                    id="Nacionalidad"
                                                    name="Nacionalidad"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBEstudiante.pais}
                                                    onChange={(e) => TBEstudiante.setPais(e.target.value)}
                                                >

                                                    {TBEstudiante.ListaPaises.map((paises, index) => (
                                                        <option key={paises.id} value={paises.id}  >{paises.nombre}</option>
                                                    ))}

                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col  >



                                            <Label className='mt-1'>Condición <Label className='mx-2 LetraPeq'>* Seleccione solo si aplica</Label></Label>
                                            <Col md={12} sm={12} xs={12} radio >
                                                <Input
                                                    className='mr-2'
                                                    type="radio"
                                                    id={`radio-${1}`}
                                                    name="funcionario" // Aquí agregamos el atributo 'name'
                                                    value={1}
                                                    onChange={(e) => Seleccion(e, TBEstudiante.setCondicion)}
                                                />
                                                <Label radio className='negrita ' htmlFor={`radio-${1}`}>
                                                    Refugiado
                                                </Label>
                                                <Input
                                                    className='mx-2'
                                                    type="radio"
                                                    id={`radio-${2}`}
                                                    name="funcionario" // Aquí agregamos el atributo 'name'
                                                    value={2}
                                                    onChange={(e) => Seleccion(e, TBEstudiante.setCondicion)}
                                                />
                                                <Label radio className='negrita' htmlFor={`radio-${2}`}>
                                                    Indigena
                                                </Label>

                                            </Col>

                                        </Col>

                                        <Col className='col-lg-6 col-md-12 col-sm-12'  ></Col>

                                        <Col  >

                                            <Col className='custom-form-group' >
                                                <Label for="Provincia">
                                                    Provincia
                                                </Label>
                                                <Input
                                                    className='py-1'
                                                    required
                                                    id="Provincia"
                                                    name="Provincia"

                                                    type="select"
                                                    value={TBEstudiante.provincia}
                                                    onChange={(event) => GetCantones(event, TBEstudiante.setProvincia, TBEstudiante.setListaCantones)}
                                                >
                                                    {/* <option value={0} disabled selected  >Seleccione una Provincia</option> */}
                                                    {TBEstudiante.ListaProvincias.map(provincia => (
                                                        <option key={provincia.id} value={provincia.id} >{provincia.nombre} </option>
                                                    ))}

                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group' >
                                                <Label for="Canton">
                                                    Cantón
                                                </Label>
                                                <Input
                                                    required
                                                    id="Canto"
                                                    name="Canton"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBEstudiante.canton}
                                                    onChange={(event) => GetDistritos(event, TBEstudiante.provincia, TBEstudiante.setCanton, TBEstudiante.setListaDistritos)}

                                                >
                                                    <option value={0} disabled selected  >Seleccione el Cantón</option>
                                                    {TBEstudiante.ListaCantones.map(canton => (
                                                        <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                                    ))}

                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col   >
                                            <Col className='custom-form-group' >
                                                <Label for="Distrito">
                                                    Distrito
                                                </Label>
                                                <Input
                                                    required
                                                    id="Distrito"
                                                    name="Distrito"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBEstudiante.distrito}
                                                    onChange={(event) => GetBarrios(event, TBEstudiante.provincia, TBEstudiante.canton, TBEstudiante.setDistrito, TBEstudiante.setListaBarrios)}

                                                >
                                                    <option value={0} disabled selected  >Seleccione el Distrito</option>
                                                    {TBEstudiante.ListaDistritos.map(distrito => (
                                                        <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col   >
                                            <Col className='custom-form-group' >
                                                <Label for="Barrio">
                                                    Barrio
                                                </Label>
                                                <Input
                                                    required
                                                    id="Barrio"
                                                    name="Barrio"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBEstudiante.barrio}


                                                    onChange={(e) => TBEstudiante.setBarrio(e.target.value)}
                                                >
                                                    <option value={0} disabled selected>Seleccione el Barrio</option>
                                                    {TBEstudiante.ListaBarrios.map(barrio => (
                                                        <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col className='col-lg-12 col-md-8 col-sm-12 col-12'  >
                                            <Col className='custom-form-group' >
                                                <Label for="DireccionExacta">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    className='py-1'
                                                    required
                                                    id="DireccionExacta"
                                                    name="DireccionExacta"
                                                    placeholder="200 mt al este de la calle 10"
                                                    value={TBEstudiante.direccionExacta}
                                                    onChange={(e) => TBEstudiante.setDireccionExacta(e.target.value)}
                                                />
                                            </Col>
                                        </Col>
                                    </Row>

                                    <Row className='px-2 mt-2'>
                                        <Col md={123} className='filaColor'>
                                            <Label for="files" className='  '>
                                                <strong>Información de Contacto</strong>
                                            </Label>
                                        </Col>


                                        <Col md={3} className='py-1'>
                                            <Col className='custom-form-group'>
                                                <Label for="telefonS">
                                                    Telefono
                                                </Label>
                                                <Input
                                                    maxLength={8}
                                                    minLength={8}
                                                    id="telefonoS"
                                                    name="telefonoS"
                                                    placeholder=""
                                                    type="tel"
                                                    value={TBEstudiante.telefono}
                                                    onChange={(event) => ValidarSoloNumeros(event, TBEstudiante.setTelefono, TBEstudiante.setTelefonoValido)}
                                                    className={TBEstudiante.telefonoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBEstudiante.telefonoValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="telefonoS"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Solo se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Col>

                                        <Col md={3} className='py-1'>
                                            <Col className='custom-form-group'>
                                                <Label for="telefonSe">
                                                    Celular
                                                </Label>
                                                <Input
                                                    maxLength={8}
                                                    minLength={8}
                                                    id="telefonoSe"
                                                    name="telefonoSe"
                                                    placeholder=""
                                                    type="tel"
                                                    value={TBEstudiante.celular}
                                                    onChange={(event) => ValidarSoloNumeros(event, TBEstudiante.setCelular, TBEstudiante.setcelularValido)}
                                                    className={TBEstudiante.celularValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBEstudiante.celularValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="telefonoSe"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Solo se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Col>

                                        <Col md={6} className='py-1'>
                                            <Col className='custom-form-group'>
                                                <Label for="email">
                                                    Correo Electronico
                                                </Label>
                                                <Input
                                                    required
                                                    id="email"
                                                    name="email"
                                                    placeholder=""
                                                    type="mail"
                                                    value={TBEstudiante.correo}
                                                    onChange={(event) => ValidarCorreo(event, TBEstudiante.setCorreo, TBEstudiante.setCorreoValido)}
                                                    className={TBEstudiante.correoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBEstudiante.correoValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="email"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Correo invalido
                                                    </Tooltip>
                                                )}

                                            </Col>
                                        </Col>

                                        <Col md={6} className='py-1'>
                                            <Col className='custom-form-group'>
                                                <Label for="telefonSecundariofuncionarioo">
                                                    En caso de emergencia comunicarse con
                                                </Label>
                                                <Input
                                                    required
                                                    id="telefonoSecundariofuncionario"
                                                    name="telefonoSecundariofuncionario"
                                                    placeholder=""
                                                    type="tel"
                                                    value={TBEstudiante.casoEmergencia}
                                                    onChange={(event) => TBEstudiante.setCasoEmergencia(event.target.value)}
                                                />
                                            </Col>
                                        </Col>

                                        <Col md={3} className='py-1'>
                                            <Col className='custom-form-group'>
                                                <Label for="telefonEmer">
                                                    Telefono Emergencia
                                                </Label>
                                                <Input
                                                    maxLength={8}
                                                    minLength={8}
                                                    id="telefonoEmer"
                                                    name="telefonoEmer"
                                                    placeholder=""
                                                    type="tel"
                                                    value={TBEstudiante.telefonoEmergencia}
                                                    onChange={(event) => ValidarSoloNumeros(event, TBEstudiante.setTelefonoEmergencia, TBEstudiante.setEmergenciaValido)}
                                                    className={TBEstudiante.emergenciaValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBEstudiante.emergenciaValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="telefonoEmer"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Solo se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Col>


                                    </Row>
                                    <Col md={12} className='d-flex justify-content-end px-3 mb-2'>
                                        <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                            Guardar
                                        </Button>
                                    </Col>

                                </Form>
                            </Container>

                        </TabPanel>

                        <TabPanel>
                            <Container fluid className=' p-0   mt-0 ' >
                                <Form onSubmit={GuardarFamiliar}>

                                    <Row className='px-2 mt-0' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <Label for="files" className=''>
                                                <strong> Datos Personales del Familiar</strong>
                                            </Label>
                                        </Col>

                                        <Col className='col-sm-12 col-12' >
                                            <Col className='custom-form-group'>
                                                <Label for="IDPerso">
                                                    Tipo Identificación
                                                </Label>
                                                <FormGroup className="d-flex align-items-center">
                                                    {TBPersona.ListaTipoID.map(tipoID => (
                                                        <div key={tipoID.id} className="form-check mr-3">
                                                            <Input
                                                                className="form-check-input"
                                                                disabled
                                                                type="radio"
                                                                id={`IDPerso${tipoID.id}`}
                                                                name="IDPerso"
                                                                value={tipoID.id}
                                                                checked={TBPersona.tipoIdentificacion === tipoID.id}
                                                                onChange={(e) => Seleccion(e, TBPersona.setTipoIdentificacion)}
                                                                onClick={(e) => longitudPersona(e.target.value, TBPersona.setIdentificacion, TBPersona.setNombreFami, TBPersona.setApellido1Fami, TBPersona.setApellido2Fami, 'idFamiliar')}

                                                            />
                                                            <Label className="form-check-label ml-2" for={`IDPerso${tipoID.id}`}>
                                                                {tipoID.nombre}
                                                            </Label>
                                                        </div>
                                                    ))}
                                                </FormGroup>
                                            </Col>
                                        </Col>


                                        <Col >
                                            <Col className='custom-form-group' >
                                                <Label for="idFamiliar" className="me-2">
                                                    Identificación
                                                </Label>

                                                <Input
                                                    title='Debes seleccionar el tipo de cedula primero'
                                                    required
                                                    id="idFamiliar"
                                                    name="idFamiliar"
                                                    placeholder="0-0000-0000"
                                                    type="select"
                                                    value={TBPersona.identificacion}
                                                    onChange={(e) => {

                                                        TBPersona.setIdentificacion(e.target.value);
                                                        BuscarPersonaFamiliar(e.target.value, TBPersona, setHabilitarDatosSocioEconomicos, setHabilitarAreaSalud, setHabilitarMatricula);

                                                    }}
                                                    className={'py-1'}
                                                >
                                                    <option value={0}>Seleccione la identificación a actualizar</option>
                                                    {TBEstudiante.grupoFamiliar.map(familiar => (
                                                        <option key={familiar.idGrupoFamiliar} value={familiar.personaGrupoFamiliar}>{familiar.personaGrupoFamiliar}</option>
                                                    ))}
                                                </Input>


                                            </Col >
                                        </Col>

                                        <Col className='col-lg-6 col-md-4 col-sm-6 col-6' > {isLoading ?
                                            <Col className='custom-form-group' >
                                                <Row>
                                                    <Col md={12} for="idFamiliar" className="me-2"> </Col>
                                                    <br></br>
                                                    <Col md={12} for="idFamiliar" className="me-2">
                                                        <Spinner
                                                            color="primary"
                                                            size="sm"
                                                            type="grow"
                                                        >
                                                            Loading...
                                                        </Spinner>
                                                        <Label for="idFamiliar" className="me-2 mx-2">
                                                            Un momento por favor mientras se realiza la consulta...
                                                        </Label>
                                                    </Col>
                                                </Row>
                                            </Col> : null}</Col>

                                        <Col  >
                                            <Col className='custom-form-group'  >
                                                <Label for="nombreFami" className="me-2">
                                                    Nombre
                                                </Label>
                                                <Input
                                                    disabled
                                                    required
                                                    id="nombreFami"
                                                    name="nombreFami"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBPersona.nombreFami}
                                                    onChange={(event) => ValidarSoloLetras(event, TBPersona.setNombreFami, TBPersona.setNombreFamiValido)}
                                                    className={`${TBPersona.nombreFamiValido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBPersona.nombreFamiValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="nombreFami"
                                                        toggle={toggleTooltip}
                                                    >
                                                        No se admiten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col >

                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group'   >
                                                <Label for="primerApellidoFami" className="me-2">
                                                    Primer Apellido
                                                </Label>
                                                <Input
                                                    disabled
                                                    required
                                                    id="primerApellidoFami"
                                                    name="primerApellidoFami"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBPersona.apellido1Fami}
                                                    onChange={(event) => ValidarSoloLetras(event, TBPersona.setApellido1Fami, TBPersona.setapellido1FamiValido)}
                                                    className={`${TBPersona.apellido1FamiValido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBPersona.apellido1FamiValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="primerApellidoFami"
                                                        toggle={toggleTooltip}
                                                    >
                                                        No se admiten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col >

                                        </Col>

                                        <Col  >
                                            < Col className='custom-form-group'>
                                                <Label for="segundoApellidoFami" className="me-2">
                                                    Segundo Apellido
                                                </Label>
                                                <Input
                                                    disabled
                                                    required
                                                    id="segundoApellidoFami"
                                                    name="segundoApellidoFami"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBPersona.apellido2Fami}
                                                    onChange={(event) => ValidarSoloLetras(event, TBPersona.setApellido2Fami, TBPersona.setapellido2FamiValido)}
                                                    className={`${TBPersona.apellido2FamiValido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBPersona.apellido2FamiValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="segundoApellidoFami"
                                                        toggle={toggleTooltip}
                                                    >
                                                        No se admiten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col >

                                        </Col>

                                        <Col >
                                            <Col className='custom-form-group'>
                                                <Label for="Genero">
                                                    Género
                                                </Label>
                                                <FormGroup className="d-flex align-items-center">
                                                    {TBEstudiante.ListaSexo.map(sexo => (
                                                        <div key={sexo.id} className="form-check mr-3">
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id={`sexo${sexo.id}`}
                                                                name="genero"
                                                                value={sexo.id}
                                                                checked={TBPersona.generoFami === sexo.id}
                                                                onChange={() => TBPersona.setGeneroFami(sexo.id)}
                                                            />
                                                            <Label className="form-check-label ml-2" for={`sexo${sexo.id}`}>
                                                                {sexo.nombre}
                                                            </Label>
                                                        </div>
                                                    ))}
                                                </FormGroup>
                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group'>
                                                <Label for="EstadoCivil">
                                                    Estado Civil
                                                </Label>
                                                <Input
                                                    required
                                                    id="EstadoCivil"
                                                    name="EstadoCivil"
                                                    placeholder="NacionalidadFuncionario"
                                                    type="select"
                                                    value={TBPersona.estadoCivil}
                                                    onChange={(e) => TBPersona.setEstadoCivil(e.target.value)}
                                                    className='py-1'

                                                >
                                                    <option value={0} disabled>Selec. estado civil</option>
                                                    {TBPersona.ListaEstadoCivil.map(sexo => (
                                                        <option key={sexo.id} value={sexo.id}>{sexo.nombre}</option>
                                                    ))}

                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col className='' >
                                            <Label className='mt-1'>Vive con el estudiante</Label>

                                            <Col md={12} sm={12} xs={12} radio >
                                                <Input
                                                    className='mr-2'
                                                    type="radio"
                                                    id={`viveEstudiante-${1}`}
                                                    name="viveEstudiante" // Aquí agregamos el atributo 'name'
                                                    value={1}
                                                    onChange={(e) => Seleccion(e, TBPersona.setViveConEstudiante)}
                                                    checked={TBPersona.viveConEstudiante === 1}

                                                />
                                                <Label radio className='negrita ' htmlFor={`viveEstudiante-${1}`}>
                                                    Si
                                                </Label>
                                                <Input
                                                    className='mx-2'
                                                    type="radio"
                                                    id={`viveEstudiante-${2}`}
                                                    name="viveEstudiante" // Aquí agregamos el atributo 'name'
                                                    value={2}
                                                    onChange={(e) => Seleccion(e, TBPersona.setViveConEstudiante)}
                                                    checked={TBPersona.viveConEstudiante === 2} />
                                                <Label radio className='negrita' htmlFor={`viveEstudiante-${2}`}>
                                                    No
                                                </Label>
                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group'>
                                                <Label for="telefonoFami">
                                                    Telefono
                                                </Label>
                                                <Input
                                                    maxLength={8}
                                                    minLength={8}
                                                    id="telefonoFami"
                                                    name="telefonoFami"
                                                    placeholder=""
                                                    type="tel"
                                                    value={TBPersona.telefonoFami}
                                                    onChange={(event) => ValidarSoloNumeros(event, TBPersona.setTelefonoFami, TBPersona.setTelefonoFamiValido)}
                                                    className={TBPersona.telefonoFamiValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBPersona.telefonoFamiValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="telefonoFami"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Solo se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Col>

                                        <Col className='' >
                                            <Col className='custom-form-group'>
                                                <Label for="emailFami">
                                                    Correo Electronico
                                                </Label>
                                                <Input
                                                    required
                                                    id="emailFami"
                                                    name="emailFami"
                                                    placeholder=""
                                                    type="mail"
                                                    value={TBPersona.correoFami}
                                                    onChange={(event) => ValidarCorreo(event, TBPersona.setCorreoFami, TBPersona.setCorreoFamiValido)}
                                                    className={TBPersona.correoFamiValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBPersona.correoFamiValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="emailFami"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Correo invalido.
                                                    </Tooltip>
                                                )}

                                            </Col>
                                        </Col>

                                        <Col className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <Label className='mt-1 '>Parentesco</Label>
                                            <Row className='border rounded  mx-1 p-2'>
                                                {TBPersona.ListaParentescos.map((parentesco, index) => (
                                                    <Col md={3} sm={6} xs={6} radio className='' >
                                                        <Input
                                                            className='mr-2'
                                                            type="radio"
                                                            id={`parentesco-${index}`}
                                                            name="parentesco" // Aquí agregamos el atributo 'name'
                                                            value={parentesco.id}
                                                            onChange={(e) => Seleccion(e, TBPersona.setParentesco)}
                                                            checked={TBPersona.parentesco === parentesco.id} // Aquí comparamos con el estado para marcar el radio
                                                        />
                                                        <Label radio className='negrita ' htmlFor={`parentesco-${index}`}>
                                                            {parentesco.nombre}
                                                        </Label>
                                                    </Col>
                                                ))}


                                            </Row>
                                        </Col>

                                        <Col >
                                            < Col className='custom-form-group p-0'>
                                                <Label for="otroParentesco" className="me-2">
                                                    Si selecciona otro parentesco espesifique:
                                                </Label>
                                                <Input

                                                    id="otroParentesco"
                                                    name="otroParentesco"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBPersona.otroParentesco}
                                                    onChange={(event) => ValidarSoloLetras(event, TBPersona.setOtroParentesco, TBPersona.setOtroparentescoValido)}
                                                    className={`${TBPersona.otroParentescoValido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!TBPersona.otroParentescoValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="otroPerentesco"
                                                        toggle={toggleTooltip}
                                                    >
                                                        No se adminten caracteres numericos.
                                                    </Tooltip>
                                                )}
                                            </Col >

                                        </Col>
                                    </Row>

                                    <Row className='px-2 mt-2' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <Label for="files" className='  '>
                                                <strong>Lugar de Residencia</strong>
                                            </Label>
                                        </Col>

                                        <Col  >

                                            <Col className='custom-form-group'>
                                                <Label for="NacionalidadFami">
                                                    Nacionalidad
                                                </Label>
                                                <Input
                                                    required
                                                    id="NacionalidadFami"
                                                    name="NacionalidadFami"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBPersona.nacionalidadFami}
                                                    onChange={(e) => TBPersona.setNacionalidadFami(e.target.value)}
                                                >

                                                    {TBPersona.ListaPaisesFami.map((paises, index) => (
                                                        <option key={paises.id} value={paises.id}  >{paises.nombre}</option>
                                                    ))}


                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Label className='mt-1'>Condición</Label>
                                            <Col md={12} sm={12} xs={12} radio >
                                                <Input
                                                    className='mr-2'
                                                    type="radio"
                                                    id={`CondicionFami-${1}`}
                                                    name="Condicion1" // Aquí agregamos el atributo 'name'
                                                    value={1}
                                                    onChange={(e) => Seleccion(e, TBPersona.setCondicionFami)}
                                                    checked={TBPersona.condicionFami === 1}
                                                />
                                                <Label radio className='negrita ' htmlFor={`CondicionFami-${1}`}>
                                                    Refuguiado
                                                </Label>
                                                <Input
                                                    className='mx-2'
                                                    type="radio"
                                                    id={`CondicionFami-${2}`}
                                                    name="Condicion2" // Aquí agregamos el atributo 'name'
                                                    value={2}
                                                    onChange={(e) => Seleccion(e, TBPersona.setCondicionFami)}
                                                    checked={TBPersona.condicionFami === 2}
                                                />
                                                <Label radio className='negrita' htmlFor={`CondicionFami-${2}`}>
                                                    Indigena
                                                </Label>
                                            </Col>
                                        </Col>

                                        <Col className='col-lg-6 col-md-12 col-sm-12 col-12' ></Col>

                                        <Col  >
                                            <Col className='custom-form-group' >
                                                <Label for="ProvinciaFami">
                                                    Provincia
                                                </Label>
                                                <Input
                                                    className='py-1'
                                                    required
                                                    id="ProvinciaFami"
                                                    name="ProvinciaFami"

                                                    type="select"
                                                    value={TBPersona.provinciaFami}
                                                    onChange={(event) => GetCantones(event, TBPersona.setProvinciaFami, TBPersona.setListaCantonesFami)}
                                                >
                                                    <option value="0" disabled  >Seleccione una Provincia</option>
                                                    {TBPersona.ListaProvinciasFami.map(provincia => (
                                                        <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                                                    ))}

                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col >
                                            <Col className='custom-form-group' >
                                                <Label for="CantonFami">
                                                    Cantón
                                                </Label>
                                                <Input
                                                    required
                                                    id="CantoFami"
                                                    name="CantonFami"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBPersona.cantonFami}
                                                    onChange={(event) => GetDistritos(event, TBPersona.provinciaFami, TBPersona.setCantonFami, TBPersona.setListaDistritosFami)}

                                                >
                                                    <option value="0" disabled selected  >Seleccione el Cantón</option>
                                                    {TBPersona.ListaCantonesFami.map(canton => (
                                                        <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                                    ))}

                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col >
                                            <Col className='custom-form-group' >
                                                <Label for="DistritoFami">
                                                    Distrito
                                                </Label>
                                                <Input
                                                    required
                                                    id="DistritoFami"
                                                    name="DistritoFami"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBPersona.distritoFami}
                                                    onChange={(event) => GetBarrios(event, TBPersona.provinciaFami, TBPersona.cantonFami, TBPersona.setDistritoFami, TBPersona.setListaBarriosFami)}
                                                >
                                                    <option value="0" disabled selected  >Seleccione el Distrito</option>
                                                    {TBPersona.ListaDistritosFami.map(distrito => (
                                                        <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group' >
                                                <Label for="BarrioFami">
                                                    Barrio
                                                </Label>
                                                <Input
                                                    required
                                                    id="BarrioFami"
                                                    name="BarrioFami"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBPersona.barrioFami}


                                                    onChange={(e) => TBPersona.setBarrioFami(e.target.value)}
                                                >
                                                    <option value="0" disabled selected>Seleccione el Barrio</option>
                                                    {TBPersona.ListaBarriosFami.map(barrio => (
                                                        <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Col>

                                        <Col className='col-lg-8 col-md-8 col-sm-12 col-12' >
                                            <Col className='custom-form-group' >
                                                <Label for="DireccionFami">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    className='py-1'
                                                    required
                                                    id="DireccionFami"
                                                    name="DireccionFami"
                                                    placeholder="Ejemplo :200 mt al este de la calle 10"
                                                    value={TBPersona.direccionFami}
                                                    onChange={(e) => TBPersona.setDireccionFami(e.target.value)}
                                                />
                                            </Col>
                                        </Col>
                                    </Row>

                                    <Row className='px-2 mt-2'>
                                        <Col md={12} className='filaColor'>
                                            <Label for="files" className='  '>
                                                <strong>Información Academica</strong>
                                            </Label>
                                        </Col>
                                        <Col md={12} >
                                            <Label className='mt-1'>Nivel de Escolaridad</Label>
                                            <Row className='border rounded  mx-1 p-2'>
                                                {TBPersona.ListaNivelEscolaridad.map((NE, index) => (
                                                    <Col md={3} sm={6} xs={12} radio >
                                                        <Input
                                                            className='mr-2'
                                                            type="radio"
                                                            id={`escolaridad-${index}`}
                                                            name="escolaridad" // Aquí agregamos el atributo 'name'
                                                            value={NE.id}
                                                            onChange={(e) => Seleccion(e, TBPersona.setNivelEscolaridad)}
                                                            checked={TBPersona.nivelEscolaridad === NE.id}
                                                        />
                                                        <Label radio className='negrita ' htmlFor={`escolaridad-${index}`}>
                                                            {NE.nombre}
                                                        </Label>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className='px-2 mt-2'>
                                        <Col md={12} className='filaColor'>
                                            <Label for="files" className='  '>
                                                <strong>Información Laboral</strong>
                                            </Label>
                                        </Col>
                                        <Col md={12} >
                                            <Label className='mt-1'>Ocupacion</Label>
                                            <Row className='border rounded  mx-1 p-2'>
                                                {TBPersona.ListaOcupacion.map((ocupa, index) => (
                                                    <Col md={3} sm={6} xs={12} radio >
                                                        <Input
                                                            className='mr-2'
                                                            type="radio"
                                                            id={`ocupacion-${index}`}
                                                            name="ocupacion" // Aquí agregamos el atributo 'name'
                                                            value={ocupa.id}
                                                            onChange={(e) => Seleccion(e, TBPersona.setOCupacion)}
                                                            checked={TBPersona.ocupacion === ocupa.id}
                                                        />
                                                        <Label radio className='negrita ' htmlFor={`ocupacion-${index}`}>
                                                            {ocupa.nombre}
                                                        </Label>
                                                    </Col>
                                                ))}



                                            </Row>
                                        </Col>
                                        < Col md={3} className='custom-form-group '>
                                            <Label for="otraOcupacion" className="me-2">
                                                Si selecciona otra ocupación espesifique:
                                            </Label>
                                            <Input

                                                id="otraOcupacion"
                                                name="otraOcupacion"
                                                placeholder=""
                                                type="text"
                                                value={TBPersona.otraOcupacion}
                                                onChange={(event) => ValidarSoloLetras(event, TBPersona.setOtraOcupacion, TBPersona.setOtraOcupacionValido)}
                                                className={`${TBPersona.otraOcupacionValido ? '' : 'is-invalid'} py-1`} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!TBPersona.otraOcupacionValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="otraOcupacion"
                                                    toggle={toggleTooltip}
                                                >
                                                    No se adminten caracteres numericos.
                                                </Tooltip>
                                            )}
                                        </Col >
                                        <Col md={9}></Col>
                                        <Col md={6} >
                                            <Col className='custom-form-group'>
                                                <Label for="lugarTrabajo">
                                                    Lugar de trabajo
                                                </Label>
                                                <Input

                                                    id="lugarTrabajo"
                                                    name="lugarTrabajo"
                                                    placeholder=""
                                                    type="tel"
                                                    value={TBPersona.lugarTrabajo}
                                                    onChange={(e) => TBPersona.setLugarTrabajo(e.target.value)}

                                                />
                                            </Col>
                                        </Col>
                                    </Row>

                                    <Col md={12} className='d-flex justify-content-end px-3 mb-2'>

                                        <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarFami'>
                                            Actualizar
                                        </Button>
                                    </Col>
                                </Form>
                            </Container>
                        </TabPanel>


                        <TabPanel>
                            <Container fluid className=' p-0   mt-0 ' >
                                <Form onSubmit={GuardarSocio_Economica}>
                                    <Row className='px-2 mt-0'>
                                        <Col md={12} className='filaColor'>
                                            <Label for="files" className='  '>
                                                <strong>Información Socio-Económica</strong>
                                            </Label>
                                        </Col>

                                        <Col md={6} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>La casa en la que usted vive es:</Label>
                                                <Row className='   mx-1 p-1'>
                                                    {TBCondSocioEco.listaCondicionVivienda.map((NE, index) => (
                                                        <Col md={3} sm={6} xs={6} radio className='px-0'>
                                                            <Input
                                                                className='mr-2'
                                                                type="radio"
                                                                id={`tipoCasa-${index}`}
                                                                name="tipoCasa" // Aquí agregamos el atributo 'name'
                                                                value={NE.id}
                                                                onChange={(e) => Seleccion(e, TBCondSocioEco.setTipoCasa)}
                                                                checked={TBCondSocioEco.tipoCasa === NE.id}
                                                            />
                                                            <Label radio className='negrita ' htmlFor={`tipoCasa-${index}`}>
                                                                {NE.nombre}
                                                            </Label>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </Col>
                                        </Col>

                                        <Col md={10} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Estado de la casa:</Label>
                                                <Row className='  mx-1 p-1'>
                                                    {TBCondSocioEco.listaTipoCasa.map((NE, index) => (
                                                        <Col md={2} sm={6} xs={6} radio className='px-0' >
                                                            <Input
                                                                className='mr-2'
                                                                type="radio"
                                                                id={`condicionCasa-${index}`}
                                                                name="condicionCasa" // Aquí agregamos el atributo 'name'
                                                                value={NE.id}
                                                                onChange={(e) => Seleccion(e, TBCondSocioEco.setCondicionVivienda)}
                                                                checked={TBCondSocioEco.condicionVivienda === NE.id}
                                                            />
                                                            <Label radio className='negrita ' htmlFor={`condicionCasa-${index}`}>
                                                                {NE.nombre}
                                                            </Label>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </Col>
                                        </Col>

                                        <Col md={10}  >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Cantidad de personas que viven en la casa:</Label>
                                                <Row className='  mx-1 p-1'>
                                                    {TBCondSocioEco.ListaNumeros.map((NE, index) => (
                                                        <Col md={1} sm={6} xs={6} radio className='px-0'>
                                                            <Input
                                                                className='mr-2'
                                                                type="radio"
                                                                id={`cantPersonas-${index}`}
                                                                name="cantPersonas" // Aquí agregamos el atributo 'name'
                                                                value={NE.id}
                                                                onChange={(e) => Seleccion(e, TBCondSocioEco.setCantPersonas)}
                                                                checked={TBCondSocioEco.cantPersonas === NE.id}
                                                            />
                                                            <Label radio className='negrita ' htmlFor={`cantPersonas-${index}`}>
                                                                {NE.nombre}
                                                            </Label>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </Col>
                                        </Col>

                                        <Col md={2}></Col>

                                        <Col md={2} >
                                            <Col className=''  >
                                                <Label className='mt-1'>Posse acceso a internet</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`accesoInternet-${1}`}
                                                        name="accesoInternet" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBCondSocioEco.setAccesoInternet)}
                                                        checked={TBCondSocioEco.accesoInternet === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`accesoInternet-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`accesoInternet-${2}`}
                                                        name="accesoInternet" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBCondSocioEco.setAccesoInternet)}
                                                        checked={TBCondSocioEco.accesoInternet === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`accesoInternet-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={3} >
                                            <Col className=''  >
                                                <Label for="nombreFami" className="me-2">
                                                    Ingresos Mensuales
                                                </Label>
                                                <Input
                                                    min={0}
                                                    required
                                                    id="nombreFami"
                                                    name="nombreFami"
                                                    placeholder=" "
                                                    type="number"
                                                    value={TBCondSocioEco.ingresosMensuales}
                                                    onChange={(event) => TBCondSocioEco.setIngresosMensuales(event.target.value)}

                                                />

                                            </Col >

                                        </Col>

                                        <Col md={3} >
                                            <Col className=''  >
                                                <Label for="nombreFami" className="me-2">
                                                    Gastos Mensuales
                                                </Label>
                                                <Input
                                                    min={0}
                                                    required
                                                    id="nombreFami"
                                                    name="nombreFami"
                                                    placeholder=""
                                                    type="number"
                                                    value={TBCondSocioEco.gastosMensuales}
                                                    onChange={(event) => TBCondSocioEco.setGastosMensuales(event.target.value)}
                                                />
                                            </Col >

                                        </Col>

                                    </Row>
                                    <Row className='px-2 mt-1'>
                                        <Col md={12} className='filaColor'>
                                            <Label for="files" className='  '>
                                                <strong>Beneficios Sociales Resibidos</strong>
                                            </Label>
                                        </Col>

                                        <Col md={8} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Becas o tranferencias condicionadas que resibe el estudiante</Label>
                                                <Row className=' mx-1 p-1'>
                                                    {TBCondSocioEco.ListaTipoBeca.map((NE, index) => (
                                                        <Col md={2} sm={6} xs={6} radio >
                                                            <Input
                                                                className='mr-2'
                                                                type="radio"
                                                                id={`tipoBeca-${index}`}
                                                                name="tipoBeca" // Aquí agregamos el atributo 'name'
                                                                value={NE.id}
                                                                onChange={(e) => Seleccion(e, TBCondSocioEco.setTipoBeca)}
                                                                checked={TBCondSocioEco.tipoBeca === NE.id}
                                                            />
                                                            <Label radio className='negrita ' htmlFor={`tipoBeca-${index}`}>
                                                                {NE.nombre}
                                                            </Label>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </Col>
                                        </Col>

                                        <Col md={4} ></Col>

                                        <Col md={3} >
                                            <Col className=''  >
                                                <Label className='mt-1'>Posse bono escolar</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`bonoEscolar-${1}`}
                                                        name="bonoEscolar" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBCondSocioEco.setBonoEscolar)}
                                                        checked={TBCondSocioEco.bonoEscolar === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`bonoEscolar-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`bonoEscolar-${2}`}
                                                        name="bonoEscolar" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBCondSocioEco.setBonoEscolar)}
                                                        checked={TBCondSocioEco.bonoEscolar === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`bonoEscolar-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={6} >
                                            <Col className=''  >
                                                <Label for="otroBeneficio" className="me-2">
                                                    Otros beneficios
                                                </Label>
                                                <Input


                                                    id="otroBeneficio"
                                                    name="otroBeneficio"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBCondSocioEco.otroBeneficio}
                                                    onChange={(event) => TBCondSocioEco.setOtroBeneficio(event.target.value)}
                                                />
                                            </Col >

                                        </Col>


                                    </Row>
                                    <Col md={12} className='d-flex justify-content-end px-3 mb-1'>
                                        <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarFami'>
                                            Actualizar
                                        </Button>
                                    </Col>
                                </Form>
                            </Container>
                        </TabPanel>

                        <TabPanel>
                            <Container fluid className=' p-0   mt-0 ' >
                                <Form onSubmit={GuardarAreaSalud}>
                                    <Row className='px-2 mt-0'>
                                        <Col md={12} className='filaColor'>
                                            <Label for="files" className='  '>
                                                <strong>Información Medica y Salud</strong>
                                            </Label>
                                        </Col>

                                        <Col md={1} sm={6} xs={6}>
                                            <Col className='custom-form-group'>
                                                <Label for="peso" className="">
                                                    Peso (kg)
                                                </Label>
                                                <Input
                                                    onClick={() => {
                                                        TBDatosMedicos.setPeso('')
                                                        TBDatosMedicos.setImc(0);
                                                    }}
                                                    autoComplete='off'
                                                    min={0}
                                                    required
                                                    id="peso"
                                                    name="peso"
                                                    placeholder=" "
                                                    type="text"
                                                    value={TBDatosMedicos.peso}
                                                    onChange={(event) => {
                                                        ValidarSoloNumerosPuntoDecimal(event, TBDatosMedicos.setPeso, TBDatosMedicos.setPesoValido);
                                                        calcularIMC()
                                                    }}
                                                    className={`${TBDatosMedicos.pesoValido ? '' : 'is-invalid'} py-1`}
                                                />
                                                {!TBDatosMedicos.pesoValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="peso"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Ingrese un valor numérico válido con hasta dos decimales.
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Col>

                                        <Col md={1} sm={6} xs={6}>
                                            <Col className='custom-form-group'>
                                                <Label for="talla" className="">
                                                    Talla (Mts)
                                                </Label>
                                                <Input
                                                    onClick={() => {
                                                        TBDatosMedicos.setTalla('');
                                                        TBDatosMedicos.setImc(0);
                                                    }}
                                                    autoComplete='off'
                                                    min={0}
                                                    required
                                                    id="talla"
                                                    name="talla"
                                                    placeholder=" "
                                                    type="text"
                                                    value={TBDatosMedicos.talla}
                                                    onChange={(event) => {
                                                        ValidarSoloNumerosPuntoDecimal(event, TBDatosMedicos.setTalla, TBDatosMedicos.setTallaValida);
                                                        calcularIMC()
                                                    }}
                                                    className={`${TBDatosMedicos.tallaValida ? '' : 'is-invalid'} py-1`}
                                                />
                                                {!TBDatosMedicos.tallaValida && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="talla"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Ingrese un valor numérico válido con hasta dos decimales.
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Col>

                                        <Col md={2}>
                                            <Col className='custom-form-group'>
                                                <Label for="imc">
                                                    Indice masa Corporal (IMC)
                                                </Label>
                                                <br />
                                                <Label for="imc">
                                                    <strong>{TBDatosMedicos.imc}</strong>
                                                </Label>
                                            </Col>
                                        </Col>

                                        <Col md={3} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Nivel de agudeza visual</Label>
                                                <Col md={12} sm={12} xs={12} radio required >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`agudezaVisual-${1}`}
                                                        name="agudezaVisual" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAgudezaVisual)}
                                                        checked={TBDatosMedicos.agudezaVisual === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`agudezaVisual-${1}`}>
                                                        Adecuada
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`agudezaVisual-${2}`}
                                                        name="agudezaVisual" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAgudezaVisual)}
                                                        checked={TBDatosMedicos.agudezaVisual === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`agudezaVisual-${2}`}>
                                                        Deficiente
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={3} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Nivel de agudeza auditiva</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`agudezaAuditiva-${1}`}
                                                        name="agudezaAuditiva" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAgudezaAuditiva)}
                                                        checked={TBDatosMedicos.agudezaAuditiva === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`agudezaAuditiva-${1}`}>
                                                        Adecuada
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`agudezaAuditiva-${2}`}
                                                        name="agudezaAuditiva" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAgudezaAuditiva)}
                                                        checked={TBDatosMedicos.agudezaAuditiva === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`agudezaAuditiva-${2}`}>
                                                        Deficiente
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={12} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Condición BucoDental del estudiante:</Label>
                                                <Row className=' mx-1 p-1'>
                                                    {TBDatosMedicos.listaBucoDental.map((NE, index) => (
                                                        <Col md={2} sm={6} xs={6} radio >
                                                            <Input
                                                                className='mr-2'
                                                                type="radio"
                                                                id={`bucodental-${index}`}
                                                                name="bucodental" // Aquí agregamos el atributo 'name'
                                                                value={NE.id}
                                                                onChange={(e) => Seleccion(e, TBDatosMedicos.setCondicionBucoDental)}
                                                                checked={TBDatosMedicos.condicionBucoDental === NE.id}
                                                            />
                                                            <Label radio className='negrita ' htmlFor={`bucodental-${index}`}>
                                                                {NE.nombre}
                                                            </Label>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </Col>
                                        </Col>

                                        <Col md={2} >
                                            <Col className='custom-form-group'>
                                                <Label for="hemogramas" className="">
                                                    Hemogramas:
                                                </Label>
                                                <Input
                                                    onClick={() => TBDatosMedicos.setHemogramas('')}
                                                    autoComplete='off'
                                                    min={0}
                                                    required
                                                    id="hemogramas"
                                                    name="hemogramas"
                                                    placeholder=" "
                                                    type="text"
                                                    value={TBDatosMedicos.hemogramas}
                                                    onChange={(event) => ValidarSoloNumeros(event, TBDatosMedicos.setHemogramas, TBDatosMedicos.setHemogramaValido)}
                                                    className={`${TBDatosMedicos.hemogramaValido ? '' : 'is-invalid'} py-1`}
                                                />
                                                {!TBDatosMedicos.hemogramaValido && (
                                                    <Tooltip
                                                        placement="right"
                                                        isOpen={tooltipOpen}
                                                        target="hemogramas"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Ingrese un valor numérico válido.
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Col>

                                        <Col md={12} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Grupo sanguineo del estudiante:</Label>
                                                <Row className=' mx-1 p-1'>
                                                    {TBDatosMedicos.listaTiposSanguineos.map((NE, index) => (
                                                        <Col md={1} sm={4} xs={4} radio >
                                                            <Input
                                                                className='mr-2'
                                                                type="radio"
                                                                id={`tipoSangre-${index}`}
                                                                name="tipoSangre" // Aquí agregamos el atributo 'name'
                                                                value={NE.id}
                                                                onChange={(e) => Seleccion(e, TBDatosMedicos.setTipoSangre)}
                                                                checked={TBDatosMedicos.tipoSangre === NE.id}
                                                            />
                                                            <Label radio className='negrita ' htmlFor={`tipoSangre-${index}`}>
                                                                {NE.nombre}
                                                            </Label>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </Col>
                                        </Col>

                                    </Row>
                                    <Row className='px-2 mt-0'>
                                        <Col md={12} className='filaColor'>
                                            <Label for="files" className='  '>
                                                <strong>Condiciones medicas que pueden comprometer el proceso aducativo del estudiante</strong>
                                            </Label>
                                        </Col>

                                        <Col md={6} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Presenta o presento alguna situación de salud o enfermadad</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`presentoPresenta-${1}`}
                                                        name="presentoPresenta" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setSituacionSalud)}
                                                        onClick={(e) => TBDatosMedicos.setSituacion('sin antesecedentes')}
                                                        checked={TBDatosMedicos.situacionSalud === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`presentoPresenta-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`presentoPresenta-${2}`}
                                                        name="presentoPresenta" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setSituacionSalud)}
                                                        onClick={(e) => TBDatosMedicos.setSituacion('sin antesecedentes')}
                                                        checked={TBDatosMedicos.situacionSalud === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`presentoPresenta-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={6} ></Col>

                                        <Col md={10} >
                                            <Col className='custom-form-group'>
                                                <Label for="condicionesSalud" className="">
                                                    "Indique las situaciones de salud o enfermedad que le provoque al estudiante sueño, ansiedad, cambios de humor, mucha sed, frecuentes ganas de ir al
                                                    servicio sanitario, entre otras, de manera que se compromete el procesi del o la estudiante."
                                                </Label>
                                                <Input

                                                    autoComplete='off'
                                                    className='textarea'
                                                    required
                                                    id="condicionesSalud"
                                                    name="condicionesSalud"
                                                    placeholder=" "
                                                    type="textarea"
                                                    value={TBDatosMedicos.situacion}
                                                    onChange={(event) => TBDatosMedicos.setSituacion(event.target.value)}

                                                />

                                            </Col>
                                        </Col>

                                        <Col md={3} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Se encuentra bajo tratamiento medico:</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`enTratamiento-${1}`}
                                                        name="enTratamiento" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setPoseeTratamientoMedico)}
                                                        checked={TBDatosMedicos.poseeTratamientoMedico === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`enTratamiento-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`enTratamiento-${2}`}
                                                        name="enTratamiento" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setPoseeTratamientoMedico)}
                                                        checked={TBDatosMedicos.poseeTratamientoMedico === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`enTratamiento-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={3} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Debe medicarse durante la jornada lectiva:</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`mediarse-${1}`}
                                                        name="mediarse" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setMedicarseDuranteClases)}
                                                        checked={TBDatosMedicos.medicarseDuranteClases === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`mediarse-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`mediarse-${2}`}
                                                        name="mediarse" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setMedicarseDuranteClases)}
                                                        checked={TBDatosMedicos.medicarseDuranteClases === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`mediarse-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={12}>

                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Si debe medicarse por favor ingrese los siguientes datos:</Label>
                                            </Col>
                                            <Form >
                                                <Row>
                                                    <Col md={2} >
                                                        <Col className='custom-form-group'  >
                                                            <Label className='mt-1'>Medicamento:</Label>
                                                            <Input
                                                                autoComplete='off'
                                                                required
                                                                id="medicamentoIn"
                                                                name="medicamentoIn"
                                                                placeholder=" "
                                                                type="text"
                                                                value={TBDatosMedicos.medicamentoIn}
                                                                onChange={(event) => TBDatosMedicos.setMedicamentoIn(event.target.value)}

                                                            />
                                                        </Col>
                                                    </Col>
                                                    <Col md={2} >
                                                        <Col className='custom-form-group'  >
                                                            <Label className='mt-1'>Dosis:</Label>
                                                            <Input
                                                                autoComplete='off'
                                                                required
                                                                id="dosis"
                                                                name="dosis"
                                                                placeholder=" "
                                                                type="text"
                                                                value={TBDatosMedicos.dosis}
                                                                onChange={(event) => TBDatosMedicos.setDosis(event.target.value)}

                                                            />
                                                        </Col>
                                                    </Col>
                                                    <Col md={2} >
                                                        <Col className='custom-form-group'  >
                                                            <Label className='mt-1'>Frecuencia:</Label>
                                                            <Input
                                                                autoComplete='off'
                                                                required
                                                                id="horario"
                                                                name="horario"
                                                                placeholder=" "
                                                                type="text"
                                                                value={TBDatosMedicos.horario}
                                                                onChange={(event) => TBDatosMedicos.setHorario(event.target.value)}

                                                            />
                                                        </Col>
                                                    </Col>
                                                    <Col md={1} className=' px-3 mb-1 ajustarBoton'>
                                                        <Button type="button" className='btnAceptar p-1 px-3' id='agregar' onClick={agregarMedicamento}>
                                                            Guardar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <Col md={8} >

                                                <Container className='border px-0 contenedorMedicamentos custom-form-group' >
                                                    <Table responsive size="sm" className='tamañoMinimoTabla border'>
                                                        <thead className='tablaMedicamento'>
                                                            <tr>
                                                                <th className='pb-0'>#</th>
                                                                <th className='pb-0'>Medicamento</th>
                                                                <th className='pb-0'>Dosis</th>
                                                                <th className='pb-0'>Frecuencia</th>
                                                                <th className='pb-0'></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {TBDatosMedicos.medicamentos.map((fila, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fila.medicamento}</td>
                                                                    <td>{fila.dosis}</td>
                                                                    <td>{fila.frecuencia}</td>
                                                                    <td>
                                                                        <Md.MdDelete className='mb-1 eli2' cursor={'pointer'} color='#314843' size={18} onClick={() => eliminarMedicamento(index)} />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Container>



                                            </Col>
                                        </Col>
                                        <Col md={4} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Es alérgico algun medicamento:</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`alergiamedicamento-${1}`}
                                                        name="alergiamedicamento" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAlergiaMedicamento)}
                                                        onClick={(e) => TBDatosMedicos.setNombreMedicamentoAlergico('')}
                                                        checked={TBDatosMedicos.alergiaMedicamento === 1}
                                                    // onClick={(e) => Ver('vacunas', false)}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`alergiamedicamento-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        onClick={(e) => TBDatosMedicos.setNombreMedicamentoAlergico('No es alérgico a nungún medicamento')}
                                                        // onClick={(e) => Ver('vacunas', true)}
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`alergiamedicamento-${2}`}
                                                        name="alergiamedicamento" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAlergiaMedicamento)}
                                                        checked={TBDatosMedicos.alergiaMedicamento === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`alergiamedicamento-${2}`}>
                                                        No
                                                    </Label>

                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={10} id='vacunas'  >
                                            <Col className='custom-form-group'>
                                                <Label for="nombreMedicamentoAlergico" className="">
                                                    Si es alérgico algun medicamento por favor ingrese el nombre del medicamento.
                                                </Label>
                                                <Input

                                                    autoComplete='off'
                                                    className='textarea'
                                                    required
                                                    id="nombreMedicamentoAlergico"
                                                    name="nombreMedicamentoAlergico"
                                                    placeholder=" "
                                                    type="textarea"
                                                    value={TBDatosMedicos.nombreMedicamentoAlergico}
                                                    onChange={(event) => TBDatosMedicos.setNombreMedicamentoAlergico(event.target.value)}

                                                />

                                            </Col>
                                        </Col>

                                        <Col md={4} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Esquema de Vacunación:</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`esquemaVacunas-${1}`}
                                                        name="esquemaVacunas" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setEsequemaVacunas)}
                                                        checked={TBDatosMedicos.esquemaVacunas === 1}
                                                        onClick={(e) => TBDatosMedicos.setNombreVacunasFaltantes('Esquema completo')}
                                                    // onClick={(e) => Ver('vacunas', false)}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`esquemaVacunas-${1}`}>
                                                        Completo
                                                    </Label>
                                                    <Input
                                                        onClick={(e) => TBDatosMedicos.setNombreVacunasFaltantes('')}
                                                        // onClick={(e) => Ver('vacunas', true)}
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`esquemaVacunas-${2}`}
                                                        name="esquemaVacunas" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setEsequemaVacunas)}
                                                        checked={TBDatosMedicos.esquemaVacunas === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`esquemaVacunas-${2}`}>
                                                        Incompleto
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`esquemaVacunas-${3}`}
                                                        name="esquemaVacunas" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setEsequemaVacunas)}
                                                        onClick={(e) => TBDatosMedicos.setNombreVacunasFaltantes('')}
                                                        checked={TBDatosMedicos.esquemaVacunas === 3}
                                                    // onClick={(e) => Ver('vacunas', true)}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`esquemaVacunas-${3}`}>
                                                        No posse Carnet
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={10} id='vacunas'  >
                                            <Col className='custom-form-group'>
                                                <Label for="vacunasFaltantes" className="">
                                                    Si el esquema de de vacunación se encuentra incompleto o no cuenta con carnet por favor ingrese el nombre de las vacunas faltantes.
                                                </Label>
                                                <Input

                                                    autoComplete='off'
                                                    className='textarea'
                                                    required
                                                    id="vacunasFaltantes"
                                                    name="vacunasFaltantes"
                                                    placeholder=" "
                                                    type="textarea"
                                                    value={TBDatosMedicos.nombreVacunasFaltantes}
                                                    onChange={(event) => TBDatosMedicos.setNombreVacunasFaltantes(event.target.value)}

                                                />

                                            </Col>
                                        </Col>

                                        <Col md={6} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Variedad en la alimentación "Entiendase como alimentación variada y saludable"</Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`variedadAlimentaria-${1}`}
                                                        name="variedadAlimentaria" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setVariedadAlimenticia)}
                                                        checked={TBDatosMedicos.variedadAlimenticia === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`variedadAlimentaria-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`variedadAlimentaria-${2}`}
                                                        name="variedadAlimentaria" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setVariedadAlimenticia)}
                                                        checked={TBDatosMedicos.variedadAlimenticia === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`variedadAlimentaria-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={6} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Mantiene horarios regulares para realizar sus comidas: </Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`horariosComida-${1}`}
                                                        name="horariosComida" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setHorariosRegularesComida)}
                                                        checked={TBDatosMedicos.horariosRegularesComida === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`horariosComida-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`horariosComida-${2}`}
                                                        name="horariosComida" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setHorariosRegularesComida)}
                                                        checked={TBDatosMedicos.horariosRegularesComida === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`horariosComida-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={4} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Es alérgico algun alimento: </Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`esAlergicoComida-${1}`}
                                                        name="esAlergicoComida" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAlergiaAlimento)}
                                                        onClick={(e) => TBDatosMedicos.setNombreAlimentoAlergico('')}
                                                        checked={TBDatosMedicos.alergiaAlimento === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`esAlergicoComida-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`esAlergicoComida-${2}`}
                                                        name="esAlergicoComida" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBDatosMedicos.setAlergiaAlimento)}
                                                        checked={TBDatosMedicos.alergiaAlimento === 2}
                                                        onClick={(e) => TBDatosMedicos.setNombreAlimentoAlergico('No es alérgico a ningún alimento')}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`esAlergicoComida-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>

                                        <Col md={10} id=' '  >
                                            <Col className='custom-form-group'>
                                                <Label for="vacunasFaltantes" className="">
                                                    Si es alérgico algun alimento por favor ingrese el nombre del alimento:
                                                </Label>
                                                <Input

                                                    autoComplete='off'
                                                    className='textarea'
                                                    required
                                                    id="vacunasFaltantes"
                                                    name="vacunasFaltantes"
                                                    placeholder=" "
                                                    type="textarea"
                                                    value={TBDatosMedicos.nombreAlimentoAlergico}
                                                    onChange={(event) => TBDatosMedicos.setNombreAlimentoAlergico(event.target.value)}

                                                />

                                            </Col>
                                        </Col>

                                        <Col md={10} id=' '  >
                                            <Col className='custom-form-group'>
                                                <Label for="vacunasFaltantes" className="">
                                                    Si padece de alguna otra alérgia por favor ingrese a que es alérgico:
                                                </Label>
                                                <Input

                                                    autoComplete='off'
                                                    className='textarea'

                                                    id="vacunasFaltantes"
                                                    name="vacunasFaltantes"
                                                    placeholder=" "
                                                    type="textarea"
                                                    value={TBDatosMedicos.otrasAlergias}
                                                    onChange={(event) => TBDatosMedicos.setOtrasAlergias(event.target.value)}

                                                />

                                            </Col>


                                        </Col>

                                        <Col md={12} >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Presenta alguna discapacidad: </Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`padeseDiscapacidades-${1}`}
                                                        name="padeseDiscapacidades" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => {
                                                            Seleccion(e, TBDatosMedicos.setPoseeDiscapasidades);
                                                            TBDatosMedicos.setVerDiscapacidad(true);
                                                        }}
                                                        checked={TBDatosMedicos.poseeDiscapasidades === 1}

                                                    />
                                                    <Label radio className='negrita ' htmlFor={`padeseDiscapacidades-${1}`}>
                                                        Si
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`padeseDiscapacidades-${2}`}
                                                        name="padeseDiscapacidades" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => {
                                                            Seleccion(e, TBDatosMedicos.setPoseeDiscapasidades);
                                                            TBDatosMedicos.setVerDiscapacidad(false);
                                                        }}
                                                        checked={TBDatosMedicos.poseeDiscapasidades === 2}

                                                    />
                                                    <Label radio className='negrita' htmlFor={`padeseDiscapacidades-${2}`}>
                                                        No
                                                    </Label>
                                                </Col>
                                                {TBDatosMedicos.verDiscapacidad &&
                                                    <Col md={12} sm={12} xs={12}>
                                                        <Col className='custom-form-group'>
                                                            <Label className='mt-1'>Seleccione la o las discapacidades que posee el estudiante:</Label>
                                                            <Row className='mx-1 p-1'>
                                                                {TBDatosMedicos.listaDiscapacidades.map((NE, index) => (
                                                                    <Col md={4} sm={6} xs={6} className='px-0' key={NE.id}>
                                                                        <Input
                                                                            className='mr-2'
                                                                            type="checkbox"
                                                                            id={`tipoDiscapacidad-${index}`}
                                                                            name="tipoDiscapacidad"
                                                                            value={NE.id}
                                                                            onChange={manejarSeleccionDiscapacidad}
                                                                            checked={TBDatosMedicos.discapacidadesSeleccionadas.includes(NE.id.toString())}
                                                                        />
                                                                        <Label radio className='negrita' htmlFor={`tipoDiscapacidad-${index}`}>
                                                                            {NE.nombre}
                                                                        </Label>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Col>
                                                    </Col>
                                                }
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Col md={12} className='d-flex justify-content-end px-3 mb-1'>

                                        <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarFami'>
                                            Actualizar
                                        </Button>
                                    </Col>
                                </Form>
                            </Container>
                        </TabPanel>



                        <TabPanel>
                            <Container fluid className=' p-0   mt-0 ' >
                                <Form onSubmit={GuardarMatricula}>
                                    <Row className='px-2 ' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 mt-0 pt-0'>
                                            <Label for="files" className='  '>
                                                <strong>Matricula</strong>
                                            </Label>
                                        </Col>

                                        <Col  >
                                            <Col className='custom-form-group' >
                                                <Label for="identificacion" className="me-2">
                                                    Identificación del Estudiante
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        title='Cedula del estudiante a matricular'
                                                        disabled
                                                        id="identificacion"
                                                        name="identificacion"
                                                        placeholder="0-0000-0000"
                                                        type="text"
                                                        value={TBEstudiante.cedula}

                                                    />

                                                </InputGroup>


                                            </Col >
                                        </Col>

                                        <Col className='col-lg-4 col-md-4 col-sm-6 col-12'  >
                                            <Col className='custom-form-group'  >
                                                <Label for="nombre" className="me-2">
                                                    Nombre del Estudiante
                                                </Label>
                                                <Input
                                                    disabled
                                                    title='Nombre del estudiante a matricular'
                                                    id="nombre"
                                                    name="nombre"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBEstudiante.nombre + ' ' + TBEstudiante.apellido1 + ' ' + TBEstudiante.apellido2}

                                                />

                                            </Col >

                                        </Col>

                                        <Col className='col-lg-2 col-md-3 col-sm-6 col-12' >
                                            <Col className='custom-form-group'>
                                                <Label>Fecha de matricula</Label>
                                                <Input
                                                    required
                                                    id='fechaMatri'
                                                    name='fechaMatri'
                                                    placeholder='DD/MM/YYYY'
                                                    type='date'
                                                    value={TBMatricula.fechaMatricula}
                                                    onChange={(e) => TBMatricula.setFechaMatricula(e.target.value)}
                                                />
                                            </Col>
                                        </Col>

                                        <Col className='col-lg-3 col-md-3 col-sm-6 col-12' >
                                            <Col className='custom-form-group'>
                                                <Label htmlFor="año-select">Año lectivo:</Label>

                                                <Input
                                                    required
                                                    id="CursoLectivo"
                                                    name="CursoLectivo"
                                                    className='py-1'
                                                    type="select"
                                                    value={TBMatricula.añoLectivo}
                                                    onChange={(e) => TBMatricula.setAñoLectivo(e.target.value)}

                                                >
                                                    <option value={0} disabled selected  >Seleccione el año lectivo</option>
                                                    <option value={TBMatricula.añoActual}>{TBMatricula.añoActual}</option>
                                                    <option value={TBMatricula.añoSiguiente}>{TBMatricula.añoSiguiente}</option>

                                                </Input>
                                            </Col>

                                        </Col>

                                        <Col>
                                            <Col className='custom-form-group' >
                                                <Label for="idFamiliar2" className="me-2">
                                                    Identificación del Matriculante
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        required
                                                        type="select"
                                                        id="idFamiliar2"
                                                        onChange={(e) => BuscarPersonaFamiliar2(e.target.value, TBMatricula)}

                                                    >
                                                        <option value="">Seleccione una identificación</option>
                                                        {TBEstudiante.grupoFamiliar.map((grupo, index) => (
                                                            <option key={index} value={grupo.personaGrupoFamiliar}>
                                                                {grupo.personaGrupoFamiliar}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                        </Col>

                                        <Col className='col-lg-5 col-md-5 col-sm-6 col-12' >
                                            <Col className='custom-form-group'  >
                                                <Label for="nombreFami2" className="me-2">
                                                    Nombre
                                                </Label>
                                                <Input
                                                    disabled
                                                    required
                                                    id="nombreFami2"
                                                    name="nombreFami2"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBMatricula.nombre + ' ' + TBMatricula.apellido1 + ' ' + TBMatricula.apellido2}

                                                />

                                            </Col >

                                        </Col>
                                    </Row>
                                    <Row className='px-2 ' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 mt-0 pt-0'>
                                            <Label for="files" className='  '>
                                                <strong>Documentos</strong>
                                            </Label>
                                        </Col>
                                        <Col className='col-lg-12 col-md-12 col-sm-12 col-12  '>
                                            <Row >
                                                <Col md={4} sm={6} xs={12} >
                                                    <Label>Foto frontal de su documento de identificación</Label>
                                                    <Col className='d-flex justify-content-center'>
                                                        <img src={TBMatricula.idFrontal} width={250} height={150} className='p-1  mb-1 imagenDocumento' alt="Foto de Perfil" />
                                                    </Col>
                                                    <Input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={SeleccionarFotoCedulaFrontal}
                                                    />
                                                </Col>
                                                <Col md={4} sm={6} xs={12}>
                                                    <Label>Foto reverso de su documento de identificación</Label>
                                                    <Col className='d-flex justify-content-center'>
                                                        <img src={TBMatricula.idReverso} width={250} height={150} className='p-1  mb-1 imagenDocumento' alt="Foto de Perfil" />
                                                    </Col>
                                                    <Input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={SeleccionarFotoCedulaReverso}
                                                    />
                                                </Col>
                                                <Col md={4} sm={6} xs={12}>
                                                    <Label>Resibo electrico "no obligatorio"</Label>
                                                    <Col className='d-flex justify-content-center'>
                                                        <img src={TBMatricula.resiboLuz} width={250} height={150} className='p-1  mb-1 imagenDocumento' alt="Foto de Perfil" />
                                                    </Col>
                                                    <Input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={SeleccionarResiboLuz}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='px-2 ' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 mt-2 pt-0'>
                                            <Label for="files" className='  '>
                                                <strong>Información de Matricula</strong>
                                            </Label>
                                        </Col>
                                        <Col  >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Medio de matricula </Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`medioMatricula-${1}`}
                                                        name="medioMatricula" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBMatricula.setMedioMatricula)}
                                                        checked={TBMatricula.medioMatricula === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`medioMatricula-${1}`}>
                                                        Presencial
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`medioMatricula-${2}`}
                                                        name="medioMatricula" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBMatricula.setMedioMatricula)}
                                                        checked={TBMatricula.medioMatricula === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`medioMatricula-${2}`}>
                                                        Online
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>
                                        <Col className='col-lg-6 col-md-6 col-sm-12 col-12  ' >
                                            <Col className='custom-form-group'  >
                                                <Label className='mt-1'>Estado de matricula </Label>
                                                <Col md={12} sm={12} xs={12} radio  >
                                                    <Input
                                                        className='mr-2'
                                                        type="radio"
                                                        id={`estadoMatricula-${1}`}
                                                        name="estadoMatricula" // Aquí agregamos el atributo 'name'
                                                        value={1}
                                                        onChange={(e) => Seleccion(e, TBMatricula.setEstadoMatricula)}
                                                        checked={TBMatricula.estadoMatricula === 1}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`estadoMatricula-${1}`}>
                                                        Matriculado
                                                    </Label>
                                                    <Input
                                                        className='mx-2 '
                                                        type="radio"
                                                        id={`estadoMatricula-${2}`}
                                                        name="estadoMatricula" // Aquí agregamos el atributo 'name'
                                                        value={2}
                                                        onChange={(e) => Seleccion(e, TBMatricula.setEstadoMatricula)}
                                                        checked={TBMatricula.estadoMatricula === 2}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`estadoMatricula-${2}`}>
                                                        Pendiente
                                                    </Label>
                                                    <Input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`estadoMatricula-${3}`}
                                                        name="estadoMatricula" // Aquí agregamos el atributo 'name'
                                                        value={3}
                                                        onChange={(e) => Seleccion(e, TBMatricula.setEstadoMatricula)}
                                                        checked={TBMatricula.estadoMatricula === 3}
                                                    />
                                                    <Label radio className='negrita ' htmlFor={`estadoMatricula-${3}`}>
                                                        Traslado
                                                    </Label>
                                                    <Input
                                                        className='mx-2 '
                                                        type="radio"
                                                        id={`estadoMatricula-${4}`}
                                                        name="estadoMatricula" // Aquí agregamos el atributo 'name'
                                                        value={4}
                                                        onChange={(e) => Seleccion(e, TBMatricula.setEstadoMatricula)}
                                                        checked={TBMatricula.estadoMatricula === 4}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`estadoMatricula-${4}`}>
                                                        Desertor
                                                    </Label>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className='px-2 ' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 mt-2 pt-0'>
                                            <Label for="files" className='  '>
                                                <strong>Grupo familiar del estudiante</strong>
                                            </Label>
                                        </Col>
                                        <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <Col className="custom-form-group">
                                                <Label for="idFamiliar2" className="me-2">
                                                    Seleccione quienes serán los encargados del estudiante, puede seleccionar varios encargados
                                                </Label>
                                                {TBEstudiante.listaGrupoFamiliar && Array.isArray(TBEstudiante.listaGrupoFamiliar) ? (
                                                    <Col className="d-flex flex-wrap border p-2">  {/* Contenedor flexible que permite múltiples líneas */}
                                                        {TBEstudiante.listaGrupoFamiliar.map((persona, index) => (
                                                            <Col key={index} lg={4} md={4} sm={6} xs={12} className="d-flex align-items-center me-0 mb-2 px-2">  {/* Espacio entre elementos */}

                                                                <Input
                                                                    type="checkbox"
                                                                    id={`persona-${index}`}
                                                                    name="persona"
                                                                    value={persona.cedula}
                                                                    className="me-2"
                                                                    checked={TBMatricula.encargadosSeleccionados.includes(persona.cedula.toString())}
                                                                    onChange={SeleccionarEncargado}

                                                                />
                                                                <Label htmlFor={`persona-${index}`} className="mb-0 mt-1">
                                                                    {persona.nombreCompleto} {persona.apellido1} {persona.apellido2}
                                                                </Label>

                                                            </Col>
                                                        ))}
                                                    </Col>
                                                ) : (
                                                    <p>No hay datos de grupo familiar disponibles.</p>
                                                )}
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className='px-2 ' xs={1} sm={2} md={3} lg={4}>
                                        <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12 mt-2 pt-0'>
                                            <Label for="files" className='  '>
                                                <strong>Grado y sección</strong>
                                            </Label>
                                        </Col>
                                        <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <Col className="custom-form-group">
                                                <Label for="idFamiliar2" className="me-2">
                                                    Seleccione grado que cursara el estudiante
                                                </Label>
                                                {TBSecciones.listaSecciones && Array.isArray(TBSecciones.listaSecciones) ? (
                                                    <>
                                                        <Col className="d-flex flex-wrap p-2 border">
                                                            {Object.entries(
                                                                TBSecciones.listaSecciones.reduce((acc, Secc) => {
                                                                    let firstChar = Secc.nombreSeccion.substring(0, 2).replace('-', '').replace('a', '').replace('r', '');
                                                                    if (firstChar === 'M') {
                                                                        firstChar = -1; // Si es 'M', asignar '-1'
                                                                    } else if (firstChar === 'T') {
                                                                        firstChar = 0; // Si es 'T', asignar '0'
                                                                    }
                                                                    if (!acc[firstChar]) {
                                                                        acc[firstChar] = [];
                                                                    }
                                                                    acc[firstChar].push(Secc);
                                                                    return acc;
                                                                }, {})
                                                            ).sort((a, b) => a[0] - b[0]).map(([firstChar, Secciones]) => (
                                                                <Col key={firstChar} lg={1} md={2} sm={2} xs={5} className="d-flex align-items-center me-1 mb-1" >
                                                                    <Input
                                                                        type="radio"
                                                                        id={`grado-${Secciones[0].nivel}`}
                                                                        name="grado"
                                                                        value={Secciones[0].nivel}
                                                                        className="me-2 "
                                                                        onChange={(e) => SeleccionGrado(e, TBMatricula.setGrado)}
                                                                        checked={TBMatricula.grado === Secciones[0].nivel}
                                                                    />
                                                                    <Label htmlFor={`grado-${Secciones[0].nivel}`} className="mb-0 mt-1">
                                                                        {Secciones[0].NombreNivel}
                                                                    </Label>

                                                                </Col>
                                                            ))}
                                                        </Col>
                                                        {TBMatricula.seccionesFiltradas.length > 0 && (
                                                            <Col className="border col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <Label for="secciones" className="mx-2">
                                                                    Seleccione una sección
                                                                </Label>
                                                                <Col className='custom-form-group d-flex flex-wrap '>
                                                                    {TBMatricula.seccionesFiltradas.map((Secc, index) => (
                                                                        <Col lg={2} md={3} sm={4} xs={6} key={index} className='d-flex align-items-center mb-1 p-2'>
                                                                            <Input
                                                                                type="radio"
                                                                                id={`seccion-${index}`}
                                                                                name="seccion"
                                                                                value={Secc.idSeccion}
                                                                                className="me-1"
                                                                                onChange={(e) => Seleccion(e, TBMatricula.setSeccion)}
                                                                                checked={TBMatricula.seccion === Secc.idSeccion}
                                                                            />
                                                                            <Label htmlFor={`seccion-${index}`} className="mb-0 mt-1">
                                                                                {Secc.nombreSeccion}
                                                                            </Label>
                                                                        </Col>
                                                                    ))}
                                                                </Col>
                                                            </Col>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Col>
                                                        <p>No hay Secciones Registradas.</p>
                                                    </Col>
                                                )}

                                            </Col>
                                        </Col>






                                        <Col className='mt-1' >
                                            <Col className='custom-form-group'  >
                                                <Label for="nombre" className="me-2">
                                                    Aprobado por:
                                                </Label>
                                                <Input
                                                    disabled
                                                    id="nombre"
                                                    name="nombre"
                                                    placeholder=""
                                                    type="text"
                                                    value={TBPerfil.nombre + ' ' + TBPerfil.apellido1 + ' ' + TBPerfil.apellido2}

                                                />

                                            </Col >

                                        </Col>
                                    </Row>
                                    <Col md={12} className='d-flex justify-content-end px-3 mb-1'>
                                        <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarFami'>
                                            Actualizar
                                        </Button>
                                    </Col>
                                </Form>
                            </Container>
                        </TabPanel>

                    </Container>

                </Tabs>
            </Container >
            <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<Io.IoMdReturnLeft className="me-1" />}
                        text="Volver"
                        to="/Menu/FrmExpedientes"
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>



            <Modal isOpen={modal} toggle={toggleModal} size='lg' fullscreen='lg' fade className='mt-2 shadow2 ' >
                <ModalHeader toggle={toggleModal}>Tomar Foto</ModalHeader>
                <ModalBody className='p-1 mx-5'>
                    <Webcam
                        width={680}

                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => capture(webcamRef)}>Capturar Foto</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </Container >
    )
}

export default FrmNuevoExpedienteEstudiante
