import { useState } from 'react';

export const TbSecciones = () => {

  const [IdSeccion, setIDSeccion] = useState(0);
  const [NombreSeccion, setNombreSeccion] = useState('');
  const [Nivel, setNivel] = useState(0);
  const [CantMax, setCantMax] = useState(0);
  const [IdInstitucion, setIdInstitucion] = useState('');
  const [NombreInst, setNombreInst] = useState('');
  const [EstadoSeccion, setEstadoSeccion] = useState(1);
  const [CantidadValida, setCantidadValida] = useState(true);
  const [SeccionValida, setSeccionValida] = useState(true);
  const [selectedYear, setSelectedYear] = useState('');
  const [listaSecciones, setListaSecciones] = useState([]);
  const [listaGrados, setListaGrados]= useState([]);
  const [listaSeccionesDocente, setListaSeccionesDocente] = useState([])
  const [existenSecciones, setExistenSecciones] = useState(false)
  return {
    IdSeccion, setIDSeccion,
    NombreSeccion, setNombreSeccion,
    Nivel, setNivel,
    CantMax, setCantMax,
    IdInstitucion, setIdInstitucion,
    NombreInst, setNombreInst,
    EstadoSeccion, setEstadoSeccion,
    CantidadValida, setCantidadValida,
    SeccionValida, setSeccionValida,
    selectedYear, setSelectedYear,
    listaSecciones, setListaSecciones,
    listaGrados, setListaGrados,
    listaSeccionesDocente, setListaSeccionesDocente,
    existenSecciones, setExistenSecciones,
  };

};


