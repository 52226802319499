// ubicacionHandlers.js

import UbicacionService from '../CapaUtilidades/Utils/UbicacionService';

/**
 * Metodo para obtener la lista de provincias de Costa Rica
 * @param {*} ListaPaises Resive un arreglo basio 'setListaProvincias'
 */
export const GetPaises = (ListaPaises) => {
  const ubicacionService = new UbicacionService();

  ubicacionService.getPaises().then(data => {
    
    ListaPaises(data);
  }).catch(error => {
    console.error('Error al obtener las paises:', error);
  });
};


/**
 * Metodo para obtener la lista de provincias de Costa Rica
 * @param {*} ListaProvincias Resive un arreglo basio 'setListaProvincias'
 */
export const GetProvincias = (ListaProvincias) => {
    const ubicacionService = new UbicacionService();
  
    ubicacionService.getProvincias().then(data => {
      ListaProvincias(data);
    }).catch(error => {
      console.error('Error al obtener las provincias:', error);
    });
  };


  /**
   * Metodo para obtener la lista de Cantones de la Provincia seleccionada
   * @param {*} e Evento que activo el metodo medante el cual se optiene el valor selecionado del control
   * @param {*} IdProvincia Resibe el ID de la Provincia seleccionada mediante el Set de la const 
   * @param {*} listaCantones Resive un arreglo basio 'setListaCantones'
   */
export const GetCantones = (e, IdProvincia, listaCantones) => {
  const SelectProvinciaID = parseInt(e.target.value);
  IdProvincia(SelectProvinciaID);
  const ubicacionService = new UbicacionService();

  ubicacionService.getCantonesByProvincia(SelectProvinciaID).then(data => {
    listaCantones(data);
  }).catch(error => {
    console.error('Error al obtener los cantones:', error);
  });
};
export const GetCantones2 = (dato, IdProvincia, listaCantones) => {
  const SelectProvinciaID = parseInt(dato);
  IdProvincia(SelectProvinciaID);
  const ubicacionService = new UbicacionService();

  ubicacionService.getCantonesByProvincia(SelectProvinciaID).then(data => {
    listaCantones(data);
  }).catch(error => {
    console.error('Error al obtener los cantones:', error);
  });
};

/**
 *  Metodo para obtener la lista de Distritos del Canton seleccionado, de la Provincia seleccionada
 * @param {*} e Evento que activo el metodo medante el cual se optiene el valor selecionado del control
 * @param {*} IdProvincia Resibe el ID de la Provincia seleccionada mediante el Set de la const 
 * @param {*} IdCanton Resibe el ID del Canton seleccionado mediante el Set de la const 
 * @param {*} listaDistritos Resive un arreglo basio 'setListaDistritos'
 */
export const GetDistritos = (e, IdProvincia, IdCanton, listaDistritos) => {
  const SelectCantonId = parseInt(e.target.value);
  IdCanton(SelectCantonId);
  const ubicacionService = new UbicacionService();

  ubicacionService.getDistritosByCanton(IdProvincia, SelectCantonId).then(data => {
    listaDistritos(data);
  }).catch(error => {
    console.error('Error al obtener los distritos:', error);
  });
};


export const GetDistritos2 = (data, IdProvincia, IdCanton, listaDistritos) => {
  const SelectCantonId = parseInt(data);
  IdCanton(SelectCantonId);
  const ubicacionService = new UbicacionService();

  ubicacionService.getDistritosByCanton(IdProvincia, SelectCantonId).then(data => {
    listaDistritos(data);
  }).catch(error => {
    console.error('Error al obtener los distritos:', error);
  });
};

/**
 * Metodo para obtener la lista de Barrios de Distrito seleccionado, del Canton seleccionado, de la Provincia seleccionada
 * @param {*} e Evento que activo el metodo medante el cual se optiene el valor selecionado del control
 * @param {*} IdProvincia Resibe el ID de la Provincia seleccionada mediante el Set de la const 
 * @param {*} IdCanton Resibe el ID del Canton seleccionado mediante el Set de la const 
 * @param {*} IdDistrito Resibe el ID del Distrito seleccionado mediante el Set de la const 
 * @param {*} listaBarrios Resive un arreglo basio 'setListaBarrios'
 */
export const GetBarrios = (e, IdProvincia, IdCanton, IdDistrito, listaBarrios) => {
  const SelectDistritoId = parseInt(e.target.value);
  IdDistrito(SelectDistritoId);
  const ubicacionService = new UbicacionService();

  ubicacionService.getBarriosByDistrito(IdProvincia, IdCanton, SelectDistritoId).then(data => {
    listaBarrios(data);
  }).catch(error => {
    console.error('Error al obtener los barrios:', error);
  });
};

export const GetBarrios2 = (data, IdProvincia, IdCanton, IdDistrito, listaBarrios) => {
  const SelectDistritoId = parseInt(data);
  IdDistrito(SelectDistritoId);
  const ubicacionService = new UbicacionService();

  ubicacionService.getBarriosByDistrito(IdProvincia, IdCanton, SelectDistritoId).then(data => {
    listaBarrios(data);
  }).catch(error => {
    console.error('Error al obtener los barrios:', error);
  });
};
