import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import * as Io  from "react-icons/io";
import { MdKeyboardArrowUp } from "react-icons/md";

/**
 * Componente MenuItem
 * @param {Object} props - Propiedades del componente.
 * @param {JSX.Element} props.icon - Icono del menú que se quiere mostrar.
 * @param {string} props.text - Texto del menú para el item.
 * @param {Array<Object>} props.subMenuItems - Elementos del submenú.
 * @param {boolean} props.isActive - Indica si el elemento está activo.
 * @param {function} props.onClick - Función a ejecutar al hacer clic en el elemento.
 * @param {string} props.to - Ruta del elemento.
 * @param {string} props.perfil - Clase CSS para el perfil.
 * @param {string} props.classname - Clase CSS adicional para el elemento.
 * @returns {JSX.Element} Componente MenuItem.
 */
const MenuItem = ({ icon, icon2, text, subMenuItems, isActive, onClick, to, perfil, classname }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  useEffect(() => {
    if (subMenuItems && isActive) {
      setIsSubMenuOpen(true);
    } else {
      setIsSubMenuOpen(false);
    }
  }, [subMenuItems, isActive]);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleClick = () => {
    if (subMenuItems) {
      onClick();
    } else {
      onClick && onClick();
    }
  };

  return (
    <li>
      <div className='d-flex  align-items-center  ' >
        <NavLink
          to={to}
          className={`w-100 d-inline-block ${classname} p-0  py-1 NavLink  `}
          onClick={handleClick}
        >
          {icon} {text}
        </NavLink>

        {subMenuItems && (
          isSubMenuOpen ? <Io.IoIosArrowUp size={22} className="ms-1 " /> : <Io.IoIosArrowForward size={22} className="ms-1" />
        )}
      </div>

      {subMenuItems && isActive && (
        <ul className={` p-0 ${isSubMenuOpen ? 'submenu-animation active shadow5-black  ' : ''}`}>
          {subMenuItems.map((item, index) => (
            <li key={index}>
              <NavLink
                to={item.to}
                className="w-100 d-inline-block text-sidebar-submenu NavLink  pt-1  "
                onClick={item.onClick}
                title={item.text}
              >
                {item.icon2} {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
