import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import { ValidarSoloLetras, ValidarSoloNumeros, ValidarCorreo } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { TbInstitucion } from '../../../../CapaEntidades/TbInstitucion';
import { GetTipoDereccion, GetTipoInstitucion } from '../../../../CapaDatos/DatosCargarSelects';
import { GetCircuitos, GetRegionales } from '../../../../CapaDatos/DatosCargarRegionales';
import { GetBarrios, GetCantones, GetDistritos, GetProvincias } from '../../../../CapaDatos/DatosCargarUbicaciones';
import { ErrorMessage, ObtenerLogo, removeBase64Prefix } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import Camara from '../../../assets/faces/Camara plus.png';
import { EstadosIngresarInstitucion } from '../../../../CapaUtilidades/Estados/EstadosIngresarInstitucion';
import { getActualizarInstitucion, getInstitucionActiva, getInstitucionSeleccionada } from '../../../../CapaDatos/DatosDesarrolladores';
import Cargando from '../../../components/Cargando';
import { obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';


let globalInstitucionData = null;
let institucionSeleccionada = null;
const FrmInstitucion = ({ codigoPresupuestario }) => {
    let CodigoPresupuestario = obtenerIdInstitucion();
    const [instituciones, setInstituciones] = useState([]);
    const [institucionSeleccionada, setInstitucionSeleccionada] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ListaTipoDereccion, setListaTipoDereccion] = useState([]);
    const [ListaRegionales, setListaRegionales] = useState([]);
    const [ListaCircuitos, setListaCircuitos] = useState([]);
    const [ListaProvinciasInst, setListaProvinciasInst] = useState([]);
    const [ListaCantonesInst, setListaCantonesInst] = useState([]);
    const [ListaDistritosInst, setListaDistritosInst] = useState([]);
    const [ListaBarriosInst, setListaBarriosInst] = useState([]);
    const [ListaTipoInstitucion, setListaTipoInstitucion] = useState([]);

    const {
        tipoDireccion, setTipoDireccion,
        tipoInstitucion, setTipoInstitucion,
        codigoInstitucion, setCodigoInstitucion,
        nombreInstitucion, setNombreInstitucion,
        direccionRegional, setDireccionRegional,
        circuito, setCircuito,
        telefono, setTelefono,
        provinciaInstitucion, setProvinciaInstitucion,
        cantonInstitucion, setCantonInstitucion,
        distritoInstitucion, setDistritoInstitucion,
        barrioInstitucion, setBarrioInstitucion,
        direccion, setDireccion,
        logo, setLogo,
        director, setDirector,
        estadoInst, setEstadoInst
    } = TbInstitucion();

    const {
        codigoValido, setCodigoValido,
        telefonoValido, setTelefonoValido,
        directorValido, setdirectorValido,
        selectedLogo, setSelectedLogo,
    } = EstadosIngresarInstitucion();

    const fetchData = async () => {
        try {

            const data = await getInstitucionSeleccionada();
            globalInstitucionData = data[0];
            setInstituciones(data[0]);


            setTipoDireccion(data[0].TipoDireccion);
            setTipoInstitucion(data[0].tipoInstitucion);

            setCodigoInstitucion(data[0].codigoPresupuestario);
            setNombreInstitucion(data[0].nombreInstitucion);
            setDireccionRegional(data[0].direccionRegionalC);
            setCircuito(data[0].circuitoC);
            setTelefono(data[0].tel);
            setProvinciaInstitucion(data[0].provincia);
            setCantonInstitucion(data[0].canton);
            setDistritoInstitucion(data[0].distrito);
            setBarrioInstitucion(data[0].barrio);
            setDireccion(data[0].direccion);
            if (data[0].logo !== null) {
                setLogo(`data:image/jpeg;base64,${removeBase64Prefix(data[0].logo)}`);
            }


            setDirector(data[0].nombreDirector);
            setEstadoInst(data[0].Estado);
            setLoading(false);

           

            setLoading(false);
        } catch (error) {

            setLoading(false);
        }
    };

    useEffect(() => {

       
            fetchData();
            GetTipoInstitucion(setListaTipoInstitucion)
            GetProvincias(setListaProvinciasInst);
            GetRegionales(setListaRegionales);
            GetTipoDereccion(setListaTipoDereccion);
       




    }, []);

    if (loading) {
        return (
            <Cargando />
        );
    }


    const CargarDatos = (data) => {
        const institucionSeleccionada = instituciones.find(institucion => institucion.codigoPresupuestario === data);

        if (institucionSeleccionada) {
            setTipoDireccion(institucionSeleccionada.tipoDireccion)
            setTipoInstitucion(institucionSeleccionada.tipoInstitucion);
            setCodigoInstitucion(institucionSeleccionada.codigoPresupuestario);
            setNombreInstitucion(institucionSeleccionada.nombreInstitucion);
            setDireccionRegional(institucionSeleccionada.direccionRegionalC);
            setCircuito(institucionSeleccionada.circuitoC);
            setTelefono(institucionSeleccionada.tel);
            setProvinciaInstitucion(institucionSeleccionada.provincia);
            setCantonInstitucion(institucionSeleccionada.canton);
            setDistritoInstitucion(institucionSeleccionada.distrito);
            setBarrioInstitucion(institucionSeleccionada.barrio);
            setDireccion(institucionSeleccionada.direccion);
            if (institucionSeleccionada.logo !== null) {
                setLogo(`data:image/jpeg;base64,${removeBase64Prefix(institucionSeleccionada.logo)}`);
            }


            setDirector(institucionSeleccionada.nombreDirector);
            setEstadoInst(institucionSeleccionada.Estado);
            setLoading(false);
        } else {

            ErrorMessage('Notificación del Sistema', 'A ocurrido un error inesperado y no se pudo mostrar los datos de la institucion')
        }



    }

    const Actualizar = async (event) => {
        event.preventDefault();

        
        setEstadoInst(true);

        const data = {
            codigoPresupuestario: codigoInstitucion,
            nombreInstitucion: nombreInstitucion,
            direccionRegional: globalInstitucionData.direccionRegional,
            circuito: globalInstitucionData.circuito,
            nombreDirector: "",
            tel: telefono,
            direccion: direccion,
            logo: logo,
            Provincia: globalInstitucionData.Provincia,
            Canton: globalInstitucionData.Canton,
            Distrito: globalInstitucionData.Distrito,
            Barrio: globalInstitucionData.Barrio,
            TipoDireccion: tipoDireccion,
            Estado: estadoInst,
            tipoInstitucion: parseInt(tipoInstitucion)
        };

        

        const success = await getActualizarInstitucion(data);
        if (success.estado) {
            fetchData();

        } else {
            ErrorMessage('Notificación del Sistema', 'Ocurrió un error durante la solicitud al servidor.');
        }

    };




    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio "
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Institución"
                        to="/Menu/FrmInstitucion"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>
            <Container fluid className='  p-3 shadow2 border rounded  ' id='institucion'  >
                <Form onSubmit={Actualizar} id='form1' >
                    <h5 className='py-1' > Actualizar Institución </h5>

                    <Row >
                        <Col md={3} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="direccionRegional">
                                    Direc. Administrativa
                                </Label>
                                <Input
                                    className='input'
                                    required
                                    id="direccionAdministrativa"
                                    name="direccionRegional"
                                    placeholder="Nombre centro Educativo"
                                    type="select"
                                    value={tipoDireccion}

                                    onChange={(e) => setTipoDireccion(e.target.value)}
                                >
                                    <option value="" disabled selected>Seleccione tipo Dirección</option>
                                    {ListaTipoDereccion.map((tipoDirec, index) => (
                                        <option key={tipoDirec.id} value={tipoDirec.id} selected={index == tipoDireccion}>{tipoDirec.nombre}</option>
                                    ))}
                                </Input>

                            </Col>
                        </Col>
                        <Col md={3} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="tipoInstitucion">
                                    Tipo de Institución
                                </Label>
                                <Input
                                    className='input'
                                    required
                                    id="tipoInstitucion"
                                    name="tipoInstitucion"
                                    placeholder="Nombre centro Educativo"
                                    type="select"
                                    defaultValue={tipoInstitucion}

                                    onChange={(e) => setTipoInstitucion(e.target.value)}
                                >
                                    <option key="" value="" disabled selected >Seleccione tipo Institución</option>
                                    {ListaTipoInstitucion.map((tipoInstitu, index) => (
                                        <option key={tipoInstitu.id} value={tipoInstitu.id} selected={index == tipoInstitucion}>{tipoInstitu.nombre}</option>
                                    ))}
                                </Input>

                            </Col>
                        </Col>
                        <Col md={3} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="codigoInstitucion">
                                    Codigo Presupuestario
                                </Label>
                                <Input
                                    readOnly
                                    required
                                    id="codigoInstitucion"
                                    name="codigoInstitucion"
                                    placeholder="0000"
                                    type="text"
                                    value={codigoInstitucion}
                                    onChange={(event) => ValidarSoloNumeros(event, setCodigoInstitucion, setCodigoValido)}
                                    maxLength={4}
                                    minLength={4} className={codigoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                    title="Este campo no es editable"
                                />
                                {!codigoValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>}
                            </Col>


                        </Col>
                        <Col md={6} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="nombreinstitucion">
                                    Institución
                                </Label>
                                <Input
                                    required
                                    id="nombreinstitucion"
                                    name="nombreinstitucion"
                                    placeholder="Nombre centro Educativo"
                                    type="text"
                                    value={nombreInstitucion}
                                    onChange={(e) => setNombreInstitucion(e.target.value)}
                                />
                            </Col>
                        </Col>
                        <Col md={4} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="direccionRegional">
                                    Dirección Regional
                                </Label>
                                <Input
                                    readOnly
                                    required

                                    id="direccionRegional"
                                    name="direccionRegional"
                                    placeholder="Nombre centro Educativo"
                                    type="text"
                                    defaultValue={direccionRegional}
                                    title="Este campo no es editable"
                                    onChange={(event) => GetCircuitos(event, setDireccionRegional, setListaCircuitos)}
                                />




                            </Col>
                        </Col>
                        <Col md={4} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="circuito">
                                    Circuito
                                </Label>
                                <Input
                                    readOnly
                                    required
                                    id="circuito"
                                    name="circuito"
                                    placeholder="Nombre centro Educativo"
                                    type="text"
                                    defaultValue={circuito}
                                    title="Este campo no es editable"
                                    onChange={(e) => setCircuito(e.target.value)}
                                />

                            </Col>
                        </Col>
                        <Col md={4} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="telefono">
                                    Telefono
                                </Label>
                                <Input
                                    required
                                    maxLength={8}
                                    minLength={8}
                                    id="telefono"
                                    name="telefono"
                                    placeholder=""
                                    type="tel"
                                    value={telefono}
                                    onChange={(event) => ValidarSoloNumeros(event, setTelefono, setTelefonoValido)}
                                    className={telefonoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                />
                                {!telefonoValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                            </Col>
                        </Col>
                        <Col md={3} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="Provincia">
                                    Provincia
                                </Label>
                                <Input
                                    readOnly
                                    required
                                    id="Provincia"
                                    name="Provincia"
                                    placeholder="Provincia"
                                    type="text"
                                    Value={provinciaInstitucion}
                                    title="Este campo no es editable"
                                    onChange={(event) => GetCantones(event, setProvinciaInstitucion, setListaCantonesInst)}
                                />
                            </Col>
                        </Col>
                        <Col md={3} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="Canton">
                                    Cantón
                                </Label>
                                <Input
                                    readOnly
                                    required
                                    id="Canton"
                                    name="Canton"
                                    placeholder="Canton"
                                    type="text"
                                    defaultValue={cantonInstitucion}
                                    onChange={(event) => GetDistritos(event, provinciaInstitucion, setCantonInstitucion, setListaDistritosInst)}
                                    title="Este campo no es editable"
                                />
                            </Col>
                        </Col>
                        <Col md={3} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="Distrito">
                                    Distrito
                                </Label>
                                <Input
                                    readOnly
                                    required
                                    id="Distrito"
                                    name="Distrito"
                                    placeholder="Distrito"
                                    type="text"
                                    defaultValue={distritoInstitucion}
                                    title="Este campo no es editable"
                                    onChange={(event) => GetBarrios(event, provinciaInstitucion, cantonInstitucion, setDistritoInstitucion, setListaBarriosInst)}
                                />
                            </Col>
                        </Col>
                        <Col md={3} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="Barrio">
                                    Barrio
                                </Label>
                                <Input
                                    readOnly
                                    required
                                    id="Barrio"
                                    name="Barrio"
                                    placeholder="Barrio"
                                    type="text"
                                    defaultValue={barrioInstitucion}
                                    selected={barrioInstitucion}
                                    onChange={(e) => setBarrioInstitucion(e.target.value)}
                                    title="Este campo no es editable"
                                />
                            </Col>
                        </Col>

                        <Col md={8} className='py-0'>
                            <Col className='custom-form-group' >
                                <Label for="Direccion">
                                    Dirección
                                </Label>
                                <Input
                                    required
                                    id="Direccion"
                                    name="Direccion"
                                    placeholder="200 mt al este de la calle 10"
                                    value={direccion}

                                    onChange={(e) => setDireccion(e.target.value)}

                                />
                            </Col>
                        </Col>
                        {/* <Col md={4} className='py-3'>
                            <FormGroup>
                                <Label for="director">
                                    Nombre del Director
                                </Label>
                                <Input
                                    required
                                    id="director"
                                    name="director"
                                    placeholder=""
                                    type="text"
                                    value={director}
                                    onChange={(event) => ValidarSoloLetras(event, setDirector, setdirectorValido)}
                                    className={directorValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                />
                                {!directorValido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}

                            </FormGroup>
                        </Col> */}
                        <Col md={4} className='py-0'>
                            <Col className='custom-form-group' >
                                <Row lg={2} md={2} sm={2} xs={2}>
                                    <Col  >
                                        <Label for="file" className='pr-2'>
                                            Escudo
                                        </Label>
                                        <Input
                                            id="file"
                                            name="file"
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            onChange={(e) => ObtenerLogo(e, setLogo, setSelectedLogo)}
                                        />
                                        <br></br>
                                        <Label for="file" className="custom-file-upload">Selec. Archivo</Label>
                                    </Col>

                                    <Col   >
                                        {logo ? (
                                            <img src={logo} className='LogoInstitucion p-2' alt="Logo Instituión" />
                                        ) : (
                                            <img src={Camara} className='LogoInstitucion p-2' alt="Logo Instituión" />
                                        )}
                                    </Col>


                                </Row>



                            </Col>
                        </Col>

                    </Row>

                    <Col className='d-flex justify-content-between py-3'>
                        <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className='btnAceptar'
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"
                                    to="/Menu"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className='btnAceptar ' id='GuardarInstitucion'>
                            Actualizar Institución
                        </Button>
                    </Col>

                </Form>

            </Container>
        </Container>
    )
}

export default FrmInstitucion
