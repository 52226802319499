import { useState } from 'react';



/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbInstitucion
 */
export const TbUsuarios = () => {
 const [idUsuario, setidUsurio] = useState('');
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [ultIngreso, setultIngreso] = useState('');
  const [institucionUser, setInstitucionUser] = useState('');
  const [permisoUsuario, setPermisoUsuario] = useState('');
  const [estadoUser, setEstadoUser] = useState(1);
  

  return {
    idUsuario, setidUsurio,
    usuario, setUsuario,
    password, setPassword,
    ultIngreso, setultIngreso,
    institucionUser, setInstitucionUser,
    permisoUsuario, setPermisoUsuario,
    estadoUser, setEstadoUser,
  };
};