import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, Tooltip } from 'reactstrap';
import { ValidarSoloLetras, ValidarSoloNumeros, ValidarCorreo } from '../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import Camara from '../../assets/faces/Camara plus.png';
import { TbInstitucion } from '../../../CapaEntidades/TbInstitucion'
import * as Fas from 'react-icons/fa';
import * as Fa from "react-icons/fa6";
import * as Bi from "react-icons/bi";
import { getActualizarInstitucion, getDeleteInstitucion, getInstitucion, getInstitucionActiva } from '../../../CapaDatos/DatosDesarrolladores';
import { removeBase64Prefix } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Cargando from '../../components/Cargando';
import SinDatos from '../../components/SinDatos';
import MenuItem from '../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { GetTipoDereccion, GetTipoInstitucion } from '../../../CapaDatos/DatosCargarSelects';
import { GetProvincias, GetCantones, GetDistritos, GetBarrios } from '../../../CapaDatos/DatosCargarUbicaciones'
import { GetRegionales, GetCircuitos } from '../../../CapaDatos/DatosCargarRegionales'
import { ErrorMessage, ImageDefecto, ObtenerImagen, ObtenerLogo } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import ActualizarInstitucion from './Administradores';
import { EstadosIngresarInstitucion } from '../../../CapaUtilidades/Estados/EstadosIngresarInstitucion';
import * as Fa6 from "react-icons/fa6";
import Espaciador from '../../components/Espaciador';
import RedireccionItem from '../../components/RedireccionItem';



const ListaInstituciones = () => {
    const [loading, setLoading] = useState(true);
    const [instituciones, setInstituciones] = useState([]);
    const [codigo, setCodigo] = useState('');

    const [open, setOpen] = useState(false);

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const tbInstitucion = TbInstitucion();
    const estadosValidacion = EstadosIngresarInstitucion();



    const fetchData = async () => {
        try {
            const parametro = { estado: 1 };
            const data = await getInstitucionActiva(parametro);

            setInstituciones(data);
            setLoading(false);
        } catch (error) {

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        GetProvincias(tbInstitucion.setListaProvinciasInst);
        GetRegionales(tbInstitucion.setListaRegionales);
        GetTipoDereccion(tbInstitucion.setListaTipoDereccion)
        GetTipoInstitucion(tbInstitucion.setListaTipoInstitucion)
    }, []);



    if (loading) {
        return (
            <Cargando />
        );
    }

    if (instituciones.estado === 0) {
        return (
            <SinDatos
                mensaje={"error 404"}
                error={"Lo sentimos, a ocurrido un error con el servidor comunicate con el departamento de TI"}
                route="/Menu" />
        );
    }
    if (!instituciones?.length) {
        // Mensaje si no hay instituciones registradas
        return (
            <SinDatos
                mensaje={"Notificacion del Sistema"}
                error={"Lo sentimos, no existen instituciones registradas"}
                route="/Menu" />
        );
    }

    const toggle = () => {
        setOpen(!open);
        tbInstitucion.setLogo(null);
    }

    const Abrirmodal = (data) => {
        const institucionSeleccionada = instituciones.find(institucion => institucion.codigoPresupuestario === data);

        if (institucionSeleccionada) {
            tbInstitucion.setTipoDireccion(institucionSeleccionada.tipoDireccion);
            tbInstitucion.setTipoInstitucion(institucionSeleccionada.tipoInstitucion);
            tbInstitucion.setCodigoInstitucion(institucionSeleccionada.codigoPresupuestario);
            tbInstitucion.setNombreInstitucion(institucionSeleccionada.nombreInstitucion);
            tbInstitucion.setDireccionRegional(institucionSeleccionada.direccionRegionalC);
            tbInstitucion.setCircuito(institucionSeleccionada.circuitoC);
            tbInstitucion.setTelefono(institucionSeleccionada.tel);
            tbInstitucion.setProvinciaInstitucion(institucionSeleccionada.provincia);
            tbInstitucion.setCantonInstitucion(institucionSeleccionada.canton);
            tbInstitucion.setDistritoInstitucion(institucionSeleccionada.distrito);
            tbInstitucion.setBarrioInstitucion(institucionSeleccionada.barrio);
            tbInstitucion.setDireccion(institucionSeleccionada.direccion);
            if (institucionSeleccionada.logo !== null) {
                tbInstitucion.setLogo(`data:image/jpeg;base64,${removeBase64Prefix(institucionSeleccionada.logo)}`);
            }


            tbInstitucion.setDirector(institucionSeleccionada.nombreDirector);
            tbInstitucion.setEstadoInst(institucionSeleccionada.Estado);

            setTimeout(() => {
                const selectTipoInstitucion = document.getElementById('tipoInstitucion');
                if (selectTipoInstitucion) {
                    selectTipoInstitucion.selectedIndex = institucionSeleccionada.tipoInstitucion;
                }
            }, 0);

        }

        setOpen(!open)

    }

    const Eliminar = async (data) => {
        Swal.fire({
            title: "Estas seguro que deseas dar de baja la institucion?",
            text: "¡No podrás revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, bórrar!",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await getDeleteInstitucion(data)
                if (success.estado) {
                    Swal.fire({
                        title: "¡Eliminado!",
                        text: "La Institucion ha sido dada de baja",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false,

                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });





    }



    const Actualizar = async (event) => {
        event.preventDefault();

        const institucionSeleccionada = instituciones.find(institucion => institucion.codigoPresupuestario === tbInstitucion.codigoInstitucion);

        tbInstitucion.setEstadoInst(true);

        const data = {
            codigoPresupuestario: tbInstitucion.codigoInstitucion,
            nombreInstitucion: tbInstitucion.nombreInstitucion,
            direccionRegional: institucionSeleccionada.direccionRegional,
            circuito: institucionSeleccionada.circuito,
            nombreDirector: tbInstitucion.director,
            tel: tbInstitucion.telefono,
            direccion: tbInstitucion.direccion,
            logo: tbInstitucion.logo,
            Provincia: institucionSeleccionada.Provincia,
            Canton: institucionSeleccionada.Canton,
            Distrito: institucionSeleccionada.Distrito,
            Barrio: institucionSeleccionada.Barrio,
            TipoDireccion: tbInstitucion.tipoDireccion,
            Estado: tbInstitucion.estadoInst,
            tipoInstitucion: parseInt(tbInstitucion.tipoInstitucion)
        };


        const success = await getActualizarInstitucion(data);
        if (success.estado) {
            setOpen(!open);
            fetchData();
        } else {
            ErrorMessage('Notificación del Sistema', success.mensaje || 'Ocurrió un error durante la solicitud al servidor.');
        }

    };






    // Renderizar las instituciones
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio "
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text=" Lista de Instituciones"
                        to="/Menu/ListaInstituciones"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>


            <Col className="floating-container" id="floating-container">

                <Button className=" d-flex justify-content-center  btnAceptar p-3">
                    <Fas.FaPlus />
                </Button>
                <Col className="element-container" >
                    <Button className='p-0  btn btnAceptar float-element tooltip-left  '>

                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Fa6.FaCirclePlus className="me-1 sizeIcon2" />}
                                text="Ingresar Inst."

                                to="/Menu/IngresarInstitucion"
                                classname={'NoDecoration'}
                            />
                        </ul>

                    </Button>
                    <Espaciador espaciador='espaciador-SM' />
                    <Button className='p-0  btn btnAceptar float-element '>

                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Bi.BiSolidInstitution className="me-1" />}
                                text="Inst. Inactivas"

                                to="/Menu/ListaInstitucionesInactivas"
                                classname={'NoDecoration'}
                            />
                        </ul>

                    </Button>
                </Col>
            </Col>

            <Container fluid className='p-0 Mymt-5'>
                <Row>
                    {instituciones
                        .filter(institucion => institucion.codigoPresupuestario !== '0000')
                        .map((institucion, index) => (
                            <Col key={index} className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 pb-0'>
                                <Card className="mb-3 shadow2 rounded-3 carta">
                                    <CardHeader className='py-1'>
                                        <CardTitle>
                                            <Row className=''>
                                                <Col className='col-xl-10 col-lg-9 col-md-9 col-sm-9 col-9 mt-1 '>
                                                    <strong className='mt-1'>{institucion.nombreInstitucion}</strong><br />
                                                </Col>
                                                <Col className='col-xl-2 col-lg-3 col-md-3 col-sm-3 col-3 '>
                                                    <Col className="text-center mt-0">
                                                        {institucion.logo !== null ? (
                                                            <img src={`data:image/jpeg;base64,${removeBase64Prefix(institucion.logo)}`} alt="Foto de institución" className="img-fluid p-0" />
                                                        ) : (
                                                            <img src={Camara} alt="Imagen por defecto" className="img-fluid p-0" />
                                                        )}
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody className="d-flex flex-column">
                                        <Col>
                                            <Row>
                                                <Col md={12}>
                                                    <strong>Código Presupuestario:</strong> {institucion.codigoPresupuestario}<br />
                                                    <strong>Dirección Regional:</strong> {institucion.direccionRegionalC}<br />
                                                    <strong>Circuito:</strong> {institucion.circuitoC}<br />
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col md={12} className="mt-auto d-flex justify-content-between"> {/* Este div empuja el botón hacia abajo */}
                                            <Button className='p-0 mx-1 btn-Ver mt-2 px-2 ' type='button'>
                                                <ul>
                                                    <MenuItem className='p-0'
                                                        text="Ver Funcionarios"
                                                        to={`/Menu/Funcionarios/${institucion.codigoPresupuestario}`}
                                                        classname={'NoDecoration'}
                                                    />
                                                </ul>
                                            </Button>
                                            {/* <Button className='p-0 mx-1 btn-Ver mt-2 px-2 ' type='button'>
                                                <ul>
                                                    <MenuItem className='p-0'
                                                        text="Ver Funcionarios"
                                                        to={`/Menu/Funcionarios/${institucion.codigoPresupuestario}`}
                                                        classname={'NoDecoration'}
                                                    />
                                                </ul>
                                            </Button> */}
                                        </Col>
                                    </CardBody>
                                    <CardFooter className='d-flex justify-content-end'>
                                        <Button color='' className='mx-1 btn-editar' onClick={(e) => Abrirmodal(institucion.codigoPresupuestario)}>Actualizar</Button>
                                        <Button color=' ' type='button' className='mx-1 btn-eliminar' onClick={(e) => Eliminar({ codigoPresupuestario: institucion.codigoPresupuestario, Estado: 0 })}>
                                            Suspender
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        ))}
                </Row>
                <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className='btnAceptar'
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"
                            to="/Menu"
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>

            <Modal isOpen={open} size='xl' fullscreen='lg' className='mt-0'>
                <ModalBody>
                    <Container fluid className='  p-3 shadow border rounded  ' id='institucion'  >
                        <Form onSubmit={Actualizar} id='form1' >
                            <h5 className='py-1' > Actualizar Institución </h5>

                            <Row>
                                <Col md={3} className='py-0'>
                                    <FormGroup>
                                        <Label for="direccionRegional">
                                            Direc. Administrativa
                                        </Label>
                                        <Input
                                            className='input'
                                            required
                                            id="direccionRegional"
                                            name="direccionRegional"
                                            placeholder="Nombre centro Educativo"
                                            type="select"
                                            value={tbInstitucion.tipoDireccion}

                                            onChange={(e) => tbInstitucion.setTipoDireccion(e.target.value)}
                                        >
                                            <option value="" disabled selected>Seleccione tipo Dirección</option>
                                            {tbInstitucion.ListaTipoDereccion.map((tipoDirec, index) => (
                                                <option key={tipoDirec.id} value={tipoDirec.id} selected={index == tbInstitucion.tipoInstitucion}>{tipoDirec.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={3} className='py-0'>
                                    <FormGroup>
                                        <Label for="tipoInstitucion">
                                            Tipo de Institución
                                        </Label>
                                        <Input
                                            className='input'
                                            required
                                            id="tipoInstitucion"
                                            name="tipoInstitucion"
                                            placeholder="Nombre centro Educativo"
                                            type="select"
                                            defaultValue={tbInstitucion.tipoInstitucion}

                                            onChange={(e) =>tbInstitucion.setTipoInstitucion(e.target.value)}
                                        >
                                            <option key="0" value="0" disabled selected >Seleccione tipo Institución</option>
                                            {tbInstitucion.ListaTipoInstitucion.map((tipoInstitu) => (
                                                <option key={tipoInstitu.id} value={tipoInstitu.id}>{tipoInstitu.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={3} className='py-0'>
                                    <FormGroup>
                                        <Label for="codigoInstitucion">
                                            Codigo Presupuestario
                                        </Label>
                                        <Input
                                            readOnly
                                            required
                                            id="codigoInstitucion"
                                            name="codigoInstitucion"
                                            placeholder="0000"
                                            type="text"
                                            value={tbInstitucion.codigoInstitucion}
                                            onChange={(event) => ValidarSoloNumeros(event, tbInstitucion.setCodigoInstitucion, estadosValidacion.setCodigoValido)}
                                            maxLength={4}
                                            minLength={4} className={estadosValidacion.codigoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            title="Este campo no es editable"
                                        />
                                        {!estadosValidacion.codigoValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="codigoInstitucion"
                                                    toggle={toggleTooltip}
                                                >
                                                   Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                    </FormGroup>


                                </Col>
                                <Col md={6} className='py-0'>
                                    <FormGroup>
                                        <Label for="nombreinstitucion">
                                            Institución
                                        </Label>
                                        <Input
                                            required
                                            id="nombreinstitucion"
                                            name="nombreinstitucion"
                                            placeholder="Nombre centro Educativo"
                                            type="text"
                                            value={tbInstitucion.nombreInstitucion}
                                            onChange={(e) => tbInstitucion.setNombreInstitucion(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4} className='py-0'>
                                    <FormGroup>
                                        <Label for="direccionRegional">
                                            Dirección Regional
                                        </Label>
                                        <Input
                                            readOnly
                                            required

                                            id="direccionRegional"
                                            name="direccionRegional"
                                            placeholder="Nombre centro Educativo"
                                            type="text"
                                            defaultValue={tbInstitucion.direccionRegional}
                                            title="Este campo no es editable"
                                            onChange={(event) => GetCircuitos(event, tbInstitucion.setDireccionRegional, tbInstitucion.setListaCircuitos)}
                                        />




                                    </FormGroup>
                                </Col>
                                <Col md={4} className='py-0'>
                                    <FormGroup>
                                        <Label for="circuito">
                                            Circuito
                                        </Label>
                                        <Input
                                            readOnly
                                            required
                                            id="circuito"
                                            name="circuito"
                                            placeholder="Nombre centro Educativo"
                                            type="text"
                                            defaultValue={tbInstitucion.circuito}
                                            title="Este campo no es editable"
                                            onChange={(e) => tbInstitucion.setCircuito(e.target.value)}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md={4} className='py-0'>
                                    <FormGroup>
                                        <Label for="telefono">
                                            Telefono
                                        </Label>
                                        <Input
                                            required
                                            maxLength={8}
                                            minLength={8}
                                            id="telefono"
                                            name="telefono"
                                            placeholder=""
                                            type="tel"
                                            value={tbInstitucion.telefono}
                                            onChange={(event) => ValidarSoloNumeros(event, tbInstitucion.setTelefono, estadosValidacion.setTelefonoValido)}
                                            className={estadosValidacion.telefonoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                        />
                                        {!estadosValidacion.telefonoValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="telefono"
                                                    toggle={toggleTooltip}
                                                >
                                                   Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                    </FormGroup>
                                </Col>
                                <Col md={3} className='py-0'>
                                    <FormGroup>
                                        <Label for="Provincia">
                                            Provincia
                                        </Label>
                                        <Input
                                            readOnly
                                            required
                                            id="Provincia"
                                            name="Provincia"
                                            placeholder="Provincia"
                                            type="text"
                                            Value={tbInstitucion.provinciaInstitucion}
                                            title="Este campo no es editable"
                                            onChange={(event) => GetCantones(event, tbInstitucion.setProvinciaInstitucion, tbInstitucion.setListaCantonesInst)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3} className='py-0'>
                                    <FormGroup>
                                        <Label for="Canton">
                                            Cantón
                                        </Label>
                                        <Input
                                            readOnly
                                            required
                                            id="Canton"
                                            name="Canton"
                                            placeholder="Canton"
                                            type="text"
                                            defaultValue={tbInstitucion.cantonInstitucion}
                                            onChange={(event) => GetDistritos(event, tbInstitucion.provinciaInstitucion, tbInstitucion.setCantonInstitucion, tbInstitucion.setListaDistritosInst)}
                                            title="Este campo no es editable"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3} className='py-0'>
                                    <FormGroup>
                                        <Label for="Distrito">
                                            Distrito
                                        </Label>
                                        <Input
                                            readOnly
                                            required
                                            id="Distrito"
                                            name="Distrito"
                                            placeholder="Distrito"
                                            type="text"
                                            defaultValue={tbInstitucion.distritoInstitucion}
                                            title="Este campo no es editable"
                                            onChange={(event) => GetBarrios(event, tbInstitucion.provinciaInstitucion, tbInstitucion.cantonInstitucion, tbInstitucion.setDistritoInstitucion, tbInstitucion.setListaBarriosInst)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3} className='py-0'>
                                    <FormGroup>
                                        <Label for="Barrio">
                                            Barrio
                                        </Label>
                                        <Input
                                            readOnly
                                            required
                                            id="Barrio"
                                            name="Barrio"
                                            placeholder="Barrio"
                                            type="text"
                                            defaultValue={tbInstitucion.barrioInstitucion}
                                            selected={tbInstitucion.barrioInstitucion}
                                            onChange={(e) => tbInstitucion.setBarrioInstitucion(e.target.value)}
                                            title="Este campo no es editable"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={12} className='py-0'>
                                    <Label for="Direccion">
                                        Dirección
                                    </Label>
                                    <Input
                                        required
                                        id="Direccion"
                                        name="Direccion"
                                        placeholder="200 mt al este de la calle 10"
                                        value={tbInstitucion.direccion}

                                        onChange={(e) => tbInstitucion.setDireccion(e.target.value)}

                                    />
                                </Col>
                                {/* <Col md={4} className='py-3'>
                                    <FormGroup>
                                        <Label for="director">
                                            Nombre del Director
                                        </Label>
                                        <Input
                                            required
                                            id="director"
                                            name="director"
                                            placeholder=""
                                            type="text"
                                            value={director}
                                            onChange={(event) => ValidarSoloLetras(event, setDirector, setdirectorValido)}
                                            className={directorValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                        />
                                        {!directorValido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}

                                    </FormGroup>
                                </Col> */}
                                <Col md={4} className='py-3'>
                                    <FormGroup>
                                        <Label for="file">
                                            Logo de la Institución
                                        </Label>
                                        <Input

                                            id="file"
                                            name="file"
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            onChange={(e) => ObtenerLogo(e, tbInstitucion.setLogo, estadosValidacion.setSelectedLogo)}
                                        />
                                        <br></br>
                                        <Label for="file" className="custom-file-upload">Selec. Archivo</Label>
                                    </FormGroup>
                                </Col>
                                <Col md={2} className='py-3' >
                                    {estadosValidacion.logo ? (
                                        <img src={tbInstitucion.logo} className='LogoInstitucion p-2' alt="Logo Instituión" />
                                    ) : (
                                        <img src={Camara} className='LogoInstitucion p-2' alt="Logo Instituión" />
                                    )}
                                </Col>
                            </Row>

                            <Col className='d-flex justify-content-between'>
                                <Button type="button" onClick={toggle} className=' btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className='btnAceptar ' id='GuardarInstitucion'>
                                    Actualizar Institución
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

        </Container>
    );
};

export default ListaInstituciones;
