import { useState } from 'react';

export const TbAsistencia = () => {
    const [tipoInstitucion, setTipoInstitucion] = useState('');
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [ListaGrados, setListaGrados] = useState([]);
    const [ListaEstudiantes, setListaEstudiantes] = useState([]);
    const [estudiantesSeleccionados, setEstudiantesSeleccionados] = useState([]);
    const [hayAsistencia, setHayAsistencia] = useState(false);
    const [cedulaEstudiante, setCedulaEstudiante] = useState('');
    const [asistencia, setAsistencia] = useState(0);
    const [fecha, setFecha] = useState('');
    const [fecha1, setFecha1] = useState('');
    const [fecha2, setFecha2] = useState('');
    const [fecha3, setFecha3] = useState('');
    const [asignatura, setAsignatura] = useState(0);
    const today = new Date().toISOString().split('T')[0];
    const [grado, setGrado] = useState(0);
    const [seccion, setSeccion] = useState();
    const [materiaSeleccionada, setMateriaSeleccionada] = useState(false)

    const [filtraPorFecha, setFiltrarPorFecha] = useState(false);
    const [filtraPorFechas, setFiltrarPorFechas] = useState(false);
    const [listaHistorialAsistencia, setListaHistorialAsistencia] = useState([]);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(false)
    return {
        tipoInstitucion, setTipoInstitucion,
        loading, setLoading,
        loading2, setLoading2,
        ListaGrados, setListaGrados,
        ListaEstudiantes, setListaEstudiantes,
        estudiantesSeleccionados, setEstudiantesSeleccionados,
        hayAsistencia, setHayAsistencia,
        cedulaEstudiante, setCedulaEstudiante,
        asistencia, setAsistencia,
        fecha, setFecha,
        fecha1, setFecha1,
        fecha2, setFecha2,
        fecha3, setFecha3,
        asignatura, setAsignatura,
        today,
        grado, setGrado,
        seccion, setSeccion,
        materiaSeleccionada, setMateriaSeleccionada,
        filtraPorFecha, setFiltrarPorFecha,
        filtraPorFechas, setFiltrarPorFechas,
        listaHistorialAsistencia, setListaHistorialAsistencia,
        opcionSeleccionada, setOpcionSeleccionada,
    };
};