import React from 'react'


/**
 * Componente para mostrar una animación de carga.
 * @returns {JSX.Element} Elemento JSX que muestra la animación de carga.
 */
const Cargando = () => {
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <span className="loading-text">Cargando...</span>
            
        </div>
    )
}

export default Cargando
