import React, {  } from 'react';
import {
    Container, Col, 
    Label, 
    Breadcrumb} from 'reactstrap';
import MenuItem from '../../../components/MenuItem';



const FrmAsignar_Asignatura = () => {
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Funcionarios"
                        to="/Menu/FrmFuncionarioDocente"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Asignar Materias"
                        to="/Menu/FrmAsignar_Asignatura"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

      


            <Container fluid className='p-0 Mymt-5 shadow3'>
                
            </Container>
        </Container>
    )
}

export default FrmAsignar_Asignatura
