import { useState } from "react";







export const TbEdificios = () => {
    
    const [loading, setLoading] = useState(true);
    const [cbListaEdificios, setCbListaEdificios] = useState([]);
    const [errorTipoEdificio, setErrorTipoEdificio] = useState(false);
    const [ListaEdificios, setListaEdificios] = useState([]);
    const [ListaEdificiosRemodelacion, setListaEdificiosRemodelacion] = useState([]);
    const [openModalRegistrar, setOpenModalRegistrar] = useState(false);
    const [openModalEditar, setOpenModalEditar] = useState(false);
    const [codigoEdificio, setCodigoEdificio] = useState('');
    const [nombreEdificio, setNombreEdificio] = useState('');
    const [tipoEdificio, setTipoEdificio] = useState('');
    const [institucionEdificio, setInstitucionEdificio] = useState('');
    let CodigoPresupuestario;
    let CodigoNombre;
    return {
        codigoEdificio, setCodigoEdificio,
        nombreEdificio, setNombreEdificio,
        tipoEdificio, setTipoEdificio,
        institucionEdificio, setInstitucionEdificio,
        loading, setLoading,
        cbListaEdificios, setCbListaEdificios,
        errorTipoEdificio, setErrorTipoEdificio,
        ListaEdificios, setListaEdificios,
        ListaEdificiosRemodelacion, setListaEdificiosRemodelacion,
        openModalRegistrar, setOpenModalRegistrar,
        openModalEditar, setOpenModalEditar,
        CodigoPresupuestario, 
        CodigoNombre,  
    };
};