import { ErrorMessage, SuccessMessage } from "../CapaUtilidades/Utils/FuncionesDeReutilizacion";
import { obtenerIdInstitucion } from "../CapaUtilidades/Utils/encryptUserData";
import endPoint from "./EndPoints/endPoint";

var url = '';
var estado;
var mensaje = '';


export async function getSecciones(data) {
    url = endPoint.urlGetSecciones;
    let CodigoPresupuestario = obtenerIdInstitucion()
    const data2 = { codigoPresupuestario: CodigoPresupuestario, estado: data };
    let SeccionesConNombreGrados = null;

    if (!url || !data2) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //alert(JSON.stringify(data2));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data2),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const SeccionesResponse = await response.json();
        const Grados = require('../CapaPresentacion/Json/Grados.json');
        SeccionesConNombreGrados = SeccionesResponse.map(Secciones => {
            const gradoID = Grados[Secciones.nivel.toString()];
            //const id = Secciones.NombreNivel.charAt(0);

            //alert(id)

            return {
                ...Secciones,
                NombreNivel: gradoID || '',
                //id: id

            };
        });
       //console.log(JSON.stringify(SeccionesConNombreGrados));
        return SeccionesConNombreGrados; // Devolvemos los datos obtenidos

    } catch (error) {
        console.log(error.message);
        return { estado: 0 };
    }
}

export async function updateSeccion(data) {

    url = endPoint.urlUpdateSeccion;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

        //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            SuccessMessage('Actualizado', 'Seccion Actualizada ');
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
        return true;

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function deleteSeccion(data) {

    url = endPoint.urlDeleteSeccion;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {


        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            estado = responseData.estado;
            return responseData.estado

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado;
            return estado
        }


    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function RestoreSeccion(data) {

    url = endPoint.urlDeleteSeccion;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {


        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            estado = responseData.estado;
            return responseData.estado

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado;
            return estado
        }


    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function SaveSeccion(data) {

    url = endPoint.urlSaveSeccion;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {


        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });


        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        //alert(JSON.stringify(responseData));
        estado = responseData.estado;
        
            return estado;
     


    } catch (error) {
        console.log('Error durante el fetch: =>' + mensaje);


    }
}

export async function ExisteSeccion(data) {

    url = endPoint.urlExisteSeccion;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {


        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();


        estado = responseData.estado;
        return estado




    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}