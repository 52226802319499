// ComponenteOpcion3.js
import React from "react";

const GestionProfesores = () => {
    console.log("ComponenteOpcion3 se está renderizando...");
    return (
        <div className="container-fluid  Divcontenedor">
            <h2 className="text-black">Gestión de Maestros</h2>
        </div>
    );
}

export default GestionProfesores;
