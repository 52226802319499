import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;

export async function ExisteEstudiante(data) {
    const url = endpoint.urlExisteEstudiante;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return { estado: false, datos: null };
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

         // Detect if the response status is 404 (student not found)
         if (response.status === 404) {
            //console.warn('Estudiante no encontrado.');
            return { estado: false, datos: null };
        }

        if (!response.ok) {
            const errorResponseData = await response.json();
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        //console.log(JSON.stringify(responseData));
        const { estado, datos } = responseData;
        return { estado, datos };

    } catch (error) {
        console.log(error);
        return { estado: false, datos: null };
    }
}

export async function RegistrarEstudiante(data) {

    url = endpoint.urlGuardarEstudiante;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}

export async function ActualizarEstudiante(data) {

    url = endpoint.urlActualizarEstudiante;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}

export async function ExisteConsdicionSocio_Economica(data) {
    const url = endpoint.urlListarCondicionSocio_Economica;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return { estado: false, datos: null };
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

         // Detect if the response status is 404 (student not found)
         if (response.status === 404) {
            //console.warn('Estudiante no encontrado.');
            return { estado: false, datos: null };
        }

        if (!response.ok) {
            const errorResponseData = await response.json();
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        //console.log(JSON.stringify(responseData));
        const { estado, Datos } = responseData;
        return estado;
        
    } catch (error) {
        console.log(error);
        return { estado: false, datos: null };
    }
}

export async function ListarConsdicionSocio_Economica(data) {
    const url = endpoint.urlListarCondicionSocio_Economica;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        // Detect if the response status is 404 (student not found)
        if (response.status === 404) {
            //console.warn('Estudiante no encontrado.');
            return null;
        }

        if (!response.ok) {
            const errorResponseData = await response.json();
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        //console.log(JSON.stringify(responseData));
        const { estado, datos } = responseData;

        if (estado) {
            return datos;
        } else {
            return null;
        }

    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function RegistrarCondicionSocioEconomica(data) {

    url = endpoint.urlRegistrarCondicionSocio_Economica;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}

export async function ActualizarCondicionSocioEconomica(data) {

    url = endpoint.urlActualizarCondicionSocio_Economica;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}