import React, { useState, useEffect, useCallback } from 'react';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, Table, ModalHeader, Spinner, InputGroup, InputGroupText, Tooltip } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Md from "react-icons/md";
import * as Io from "react-icons/io";
import * as  Fa from "react-icons/fa";
import * as Fa6 from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { TbEvaluaciones } from '../../../../CapaEntidades/TbEvaluaciones'
import { GetGrados, GetRubrosEvaluativos, GetTrimestres } from '../../../../CapaDatos/DatosCargarSelects';
import { TbAsistencia } from '../../../../CapaEntidades/TbAsistencia';
import { obtenerIdInstitucion, obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { getSeccionesDocente } from '../../../../CapaDatos/DatosSeccionesDocente';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { getTipoInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { getAsignaturasDocente } from '../../../../CapaDatos/DatosAsigDocentes';
import Swal from 'sweetalert2';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { ListarRubrosEvaluaciones } from '../../../../CapaDatos/DatosDocentes/DatosCrearEvaluaciones';
import { ListaMatriculados } from '../../../../CapaDatos/DatosMatricula';

const FrmRegistrarCalificaciones = () => {

  const TBEvaluaciones = TbEvaluaciones();
  const TBAsistencia = TbAsistencia();
  const TBSecciones = TbSecciones();
  const TBFUncionario = TbFuncionario();

  const ObtenerListaSeccionesDelDocente = async () => {
    try {

      const parametros = {
        profesor: obtenerUsuario(),
        annio: new Date().getFullYear().toString(),
        institucion: obtenerIdInstitucion(),
        estado: 1
      }

      const data = await getSeccionesDocente(parametros);
      //alert(JSON.stringify(data))
      // Validar si data está vacío
      if (data.length === 0) {
        TBSecciones.setExistenSecciones(false)
        TBSecciones.setListaSeccionesDocente([]); // Puedes asignar un array vacío o manejar el caso de otra forma
      } else {
        TBSecciones.setExistenSecciones(true)
        TBSecciones.setListaSeccionesDocente(data);
      }

      //console.log(JSON.stringify(data));

      const parametro = { codigoPresupuestario: obtenerIdInstitucion() };
      const tipoIns = await getTipoInstitucion(parametro);
      TBAsistencia.setTipoInstitucion(tipoIns);



      TBAsistencia.setLoading(false);
    } catch (error) {
      TBAsistencia.setLoading(false);
    }
  };

  const obtenerAsignaturasDocente = async () => {
    const param = {
      docente: obtenerUsuario(),
      annio: TBFUncionario.currentYear.toString(),
      institucion: obtenerIdInstitucion(),
      estado: 1
    }

    const lista = await getAsignaturasDocente(param)
    //console.log(JSON.stringify(lista));
    TBFUncionario.setListaAsigDocente(lista);

    const primerCodigoAsignatura = lista[0].codigoAsignatura;
    //alert(primerCodigoAsignatura)
    // Llamar a ObtenerListaEstudiantes con el primer valor de codigoAsignatura
    //ObtenerListaEstudiantes(primerCodigoAsignatura);
    TBAsistencia.setAsignatura(primerCodigoAsignatura)

  };


  useEffect(() => {
    ObtenerListaSeccionesDelDocente();
    obtenerAsignaturasDocente();
    // fetchData();
    GetGrados(TBAsistencia.setListaGrados);

  }, []);

  const GradosFiltrados = TBAsistencia.ListaGrados.filter(grado => {
    // Verificar si la lista de secciones del docente está vacía
    if (!TBSecciones.listaSeccionesDocente || TBSecciones.listaSeccionesDocente.length === 0) {
      //console.log("Actualmente no hay secciones registradas en el sistema. para el docente.");
      return false; // O manejarlo de otra forma si lo prefieres
    }

    // Extraer los niveles de TBSecciones.listaSeccionesDocente
    const nivelesDocente = TBSecciones.listaSeccionesDocente.map(seccion => seccion.nivel);

    if (TBAsistencia.tipoInstitucion === 1) {
      // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
      return grado.id >= -1 && grado.id <= 6 && nivelesDocente.includes(grado.id);
    } else if (TBAsistencia.tipoInstitucion === 2) {
      // Filtrar grados por id y verificar si nivel está en la lista de niveles del docente
      return grado.id >= 7 && grado.id <= 12 && nivelesDocente.includes(grado.id);
    }

    return false;
  });

  useEffect(() => {

    GetTrimestres(TBEvaluaciones.setListaTrimestres)
    GetRubrosEvaluativos(TBEvaluaciones.setRubrosCalificacion)
    //console.log(JSON.stringify(TBEvaluaciones.rubrosCalificacion));
  }, []);


  //  =================================================   Funciones para Listar ==============================================================
  const cargarListaEvaluaciones = async (rugro) => {
    TBEvaluaciones.setLoading(true)
    const data = {
      docente: obtenerUsuario(),
      institucion: obtenerIdInstitucion(),
      cursoLectivo: new Date().getFullYear().toString(),
      estadoEvaluacion: 1
    }
    const lista = await ListarRubrosEvaluaciones(data)

    TBEvaluaciones.setListaExamenes(lista)



    // Filtrar la lista de evaluaciones usando los parámetros dados
    const evaluacionesFiltradas = lista.filter(evaluacion =>
      evaluacion.periodo === TBEvaluaciones.nombreTrimestre &&
      evaluacion.nombreAsignatura === TBEvaluaciones.nombreAsignatura &&
      evaluacion.nivel === TBEvaluaciones.grado &&
      evaluacion.rubro === rugro &&
      evaluacion.cursoLectivo === new Date().getFullYear().toString() &&
      evaluacion.estado === 1 // Filtrar solo evaluaciones con estado 1
    );
    //alert(JSON.stringify(evaluacionesFiltradas))
    // Asignar la lista filtrada a TBEvaluaciones.setListaExamenes1
    TBEvaluaciones.setListaExamenesFiltrada(evaluacionesFiltradas);

    const data2 = {

      cursoLectivo: TBEvaluaciones.añoActual,
      codInstitucion: obtenerIdInstitucion()

    }
    const lista2 = await ListaMatriculados(data2);
    TBEvaluaciones.setListaEstudiantes(lista2)
    TBEvaluaciones.setLoading(false)
  };

  // const fetchData = async () => {
  //   try {
  //     const data = {

  //       cursoLectivo: TBEvaluaciones.añoActual,
  //       codInstitucion: obtenerIdInstitucion()

  //     }
  //     const lista = await ListaMatriculados(data);
  //     TBEvaluaciones.setListaEstudiantes(lista)


  //   } catch (error) {
  //     console.error("Error al obtener los datos del usuario:", error);
  //   }
  // };










  return (
    <Container fluid className=" Divcontenedor ">
      <Col className='border-bottom mb-1 pb-0'>
        <Breadcrumb listTag="div" className='pb-0'>
          <MenuItem
            className='p-0 text-dark'

            text="Inicio"
            to="/Menu"
            classname={'NoDecoration2 tlink pb-0'}
          />
          <Label className=' px-1 py-1 pb-0'>/</Label>
          <MenuItem
            className='p-0 text-dark'

            text="Registrar Evaluaciones "
            to="/Menu/FrmCrearEvaluaciones"
            classname={'NoDecoration2 tlink pb-0'}
          />
        </Breadcrumb>

      </Col>

      <Container fluid className='px-0 border tamañoCont_Evaluaciones shadow2'>
        <Tabs defaultIndex={-1} className={'p-0 m-0  '} >
          <TabList className={'tabs_ m-0 border-bottom1 br-top pt-0'}>
            {TBEvaluaciones.listaTrimestres.map((trimestre, index) => (
              <Tab key={index} value={trimestre.id} onClick={(e) => {
                e.preventDefault(); // Para prevenir comportamiento predeterminado
                TBEvaluaciones.setNombreTrimestre(trimestre.id);
              }}>
                {trimestre.nombre}

              </Tab>
            ))}
          </TabList>

          <Container fluid className=' px-0  rounded-0'>
            {/* Paneles de Trimestres */}
            {TBEvaluaciones.listaTrimestres.map((trimestre, trimestreIndex) => (
              <TabPanel key={trimestreIndex}>
                <Container fluid className='px-0'>
                  {/*  Tabs para las materias */}
                  <Tabs defaultIndex={-1} className={'p-0 m-0'}>
                    <TabList className={'tabs_ m-0  border-bottom1 '}>
                      {TBFUncionario.ListaAsigDocente.map((asignatura, asignaturaIndex) => (
                        <Tab key={asignaturaIndex} onClick={(e) => {
                          e.preventDefault(); // Para prevenir comportamiento predeterminado
                          TBEvaluaciones.setAsignatura(asignatura.codigoAsignatura);
                          TBEvaluaciones.setNombreAsignatura(asignatura.nombreAsignatura);
                        }}>
                          {asignatura.nombreAsignatura}
                        </Tab>
                      ))}
                    </TabList>

                    {/* Paneles de Asignaturas */}
                    {TBFUncionario.ListaAsigDocente.map((asignatura, asignaturasIndex) => (
                      <TabPanel key={asignaturasIndex}>
                        <Container fluid className='px-0'>
                          {/* Tabs para los Grados */}
                          <Tabs defaultIndex={-1}>
                            <TabList className={'tabs_ m-0  border-bottom1'}>
                              {GradosFiltrados.map((grados, gradoindex) => (
                                <Tab key={gradoindex} onClick={(e) => {
                                  e.preventDefault(); // Para prevenir comportamiento predeterminado
                                  TBEvaluaciones.setGrado(grados.id);
                                }}>
                                  {grados.nombre}
                                </Tab>
                              ))}
                            </TabList>

                            {/* Paneles de Asignaturas */} {/* Validacion para ver si existen secciones */}
                            {TBSecciones.existenSecciones ? (
                              <>
                                {GradosFiltrados.map((grado, seccionindex) => (
                                  <TabPanel key={seccionindex}>
                                    {/* Tabs para los Secciones */}
                                    <Tabs defaultIndex={-1} className={'p-0 m-0'}>
                                      <TabList className={'tabs_ m-0  border-bottom1'}>
                                        {TBSecciones.listaSeccionesDocente.filter(sec => sec.nivel === grado.id).map(seccion => (
                                          <Tab key={seccion.nombreSeccion} onClick={(e) => {
                                            e.preventDefault(); // Para prevenir comportamiento predeterminado
                                            TBEvaluaciones.setGrado(seccion.nivel);
                                            TBEvaluaciones.setSeccion(seccion.nombreSeccion)
                                          }}>
                                            {seccion.nombreSeccion}
                                          </Tab>
                                        ))}
                                      </TabList>

                                      {/* Panel para las secciones */}
                                      <TabPanel>

                                        {/* Tabs para los rugros*/}

                                        <Tabs defaultIndex={-1} className={'p-0 m-0'}>
                                          <TabList className={'tabs_ m-0  border-bottom1 '}>
                                            {TBEvaluaciones.rubrosCalificacion.map((rugro, rugroIndex) => (
                                              <Tab key={rugroIndex} value={rugro.id} onClick={(e) => {
                                                e.preventDefault(); // Para prevenir comportamiento predeterminado
                                                TBEvaluaciones.setNombreRugro(rugro.id);
                                                TBEvaluaciones.setMostrarBtnRegistrar(true);
                                                TBEvaluaciones.setVerPanelExamenes(false);
                                                TBEvaluaciones.setMostrarBtnRegistrarTarea(true);
                                                TBEvaluaciones.setVerPanelTareas(false);
                                                cargarListaEvaluaciones(rugro.id);
                                              }}>
                                                {rugro.nombre}
                                              </Tab>
                                            ))}
                                          </TabList>

                                          {/* Panel con la lista de estudiantes y rubro de claificaciones */}

                                          <TabPanel>

                                            {TBEvaluaciones.loading ? (
                                              <Container className="loader-container2">
                                                <div className="loader"></div>
                                                <span className="loading-text">Cargando...</span>

                                              </Container>

                                            ) : (
                                              <Container fluid className='p-0   '>
                                                {TBEvaluaciones.listaExamenesFiltrada.length === 0 ? (
                                                  <Container fluid className='d-flex justify-content-center' >
                                                    <Label className='justify-content-center mt-5'>Actualmente no hay evaluaciones registradas en el sistema.</Label>
                                                  </Container>
                                                ) : (
                                                  <Tabs defaultIndex={-1} className={'p-0 m-0'}>
                                                    <TabList className={'tabs_ m-0  border-bottom1 '}>
                                                      {TBEvaluaciones.listaExamenesFiltrada.map((listaRubros, ListaRubrosindex) => (
                                                        <Tab key={ListaRubrosindex} value={listaRubros.nombreEvaluacion} onClick={(e) => {
                                                          e.preventDefault();
                                                          TBEvaluaciones.setNombreExamen(listaRubros.nombreEvaluacion);
                                                        }}>
                                                          {listaRubros.nombreEvaluacion}
                                                        </Tab>
                                                      ))}
                                                    </TabList>
                                                    {TBEvaluaciones.listaExamenesFiltrada.map((listaRubros, ListaRubrosindex) => (
                                                      <TabPanel>
                                                        <Row className='px-2 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                          <Col>
                                                            <Label for="identificacion" className="me-2">
                                                              <strong>Valor Puntos:</strong>
                                                            </Label>

                                                            <Label for="identificacion" className="me-2">
                                                              {listaRubros.valorPuntos} pts.
                                                            </Label>

                                                          </Col>
                                                          <Col>
                                                            <Label for="identificacion" className="me-2">
                                                              <strong>Valor Procentaje:</strong>
                                                            </Label>

                                                            <Label for="identificacion" className="me-2">
                                                              {listaRubros.valorPorcentaje.toFixed(2)} %.
                                                            </Label>

                                                          </Col>

                                                        </Row>
                                                        <Container fluid className='p-0 '>
                                                          <Row className='px-2 m-0 p-1 pt-0 ' xs={1} sm={2} md={3} lg={4}>
                                                            <Col className='filaColor col-lg-12 col-md-12 col-sm-12 col-12'>
                                                              <Label for="files" className=''>
                                                                <strong>Lista de Estudiantes</strong>
                                                              </Label>

                                                            </Col>
                                                          </Row>
                                                          <Container fluid className='px-2 py-0  table-responsive tamañoPanelTable'>
                                                            <Table className='shadow2-2 border table table-striped table-bordered ' style={{ minWidth: '1000px' }}>
                                                              <thead>
                                                                <tr>
                                                                  <th>Cedula</th>
                                                                  <th>Nombre Completo</th>
                                                                  <th>Puntos Obtenidos</th>
                                                                  <th>Porcentaje Obtenido</th>
                                                                  <th>Calificación</th>
                                                                  <th></th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {TBEvaluaciones.ListaEstudiantes.filter(estu => estu.nivel === TBEvaluaciones.grado && estu.nombreSeccion === TBEvaluaciones.seccion).map(estu => {
                                                                  return (
                                                                    <tr>
                                                                      <td>
                                                                        {estu.cedula}
                                                                      </td>
                                                                      <td>
                                                                        {estu.nombreCompleto} {estu.apellido1}  {estu.apellido2}
                                                                      </td>
                                                                      <td>
                                                                        <Row>
                                                                          <Col lg={8} md={8} sm={8} xs={8} >
                                                                            <Input
                                                                              type="text"
                                                                              className="py-1"
                                                                              value={22}
                                                                            />
                                                                          </Col>
                                                                          <Col lg={4} md={4} sm={4} xs={4}>
                                                                            <Button className='btnAceptar p-1 px-3' >
                                                                                Calcular
                                                                            </Button>
                                                                          </Col>

                                                                        </Row>

                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          type="text"
                                                                          className="py-1"
                                                                          disabled
                                                                          value={13.5.toFixed(2)}
                                                                        /> 

                                                                      </td>
                                                                      <td className='text-center'>
                                                                          <strong>88</strong>
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                })}
                                                              </tbody>
                                                            </Table>
                                                          </Container>
                                                        </Container>
                                                      </TabPanel>
                                                    ))}
                                                  </Tabs>

                                                )}

                                              </Container>
                                            )}
                                          </TabPanel>
                                        </Tabs>
                                      </TabPanel>

                                    </Tabs>
                                  </TabPanel>
                                ))}
                              </>
                            ) : (
                              <Container fluid className='d-flex justify-content-center' >
                                <Label className='justify-content-center mt-5'>Actualmente no hay secciones registradas en el sistema.</Label>
                              </Container>
                            )}

                          </Tabs>
                        </Container>
                      </TabPanel>
                    ))}

                  </Tabs>
                </Container>
              </TabPanel>
            ))
            }
          </Container >

        </Tabs >
      </Container >

      <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
        <ul>
          <MenuItem
            className='btnAceptar'
            icon={<Io.IoMdReturnLeft className="me-1" />}
            text="Volver"
            to="/Menu"
            classname={'NoDecoration'}
          />
        </ul>
      </Button>
    </Container >
  )
}

export default FrmRegistrarCalificaciones
