import { useState } from 'react';


/**
 * @returns Retorna las Variables de Estado para el uso nesesario
 */
export const EstadosIngresarInstitucion = () => {
    const [codigoValido, setCodigoValido] = useState(true); // Estado para controlar la validez del codigo
    const [telefonoValido, setTelefonoValido] = useState(true); // Estado para controlar la validez del número de teléfono
    const [directorValido, setdirectorValido] = useState(true); // Estado para controlar que sean solo letras
    const [nombreValido, setNombreValido] = useState(true); // Estado para controlar el nombre que sean solo letras
    const [apellido1Valido, setapellido1Valido] = useState(true); // Estado para controlar el primer apellido que sean solo letras
    const [apellido2Valido, setapellido2Valido] = useState(true); // Estado para controlar el primer apellido que sean solo letras
    const [telefonoFunPrincipalValido, setTelefonoFunPrincipalvalido] = useState(true); // Estado para controlar este
    const [telefonoFunSecundarioValido, setTelefonoFunSecundariovalido] = useState(true); // Estado para controlar este
    const [correoValido, setcorreoValido] = useState(true); // Estado para controlar este
    const [cedulaValida, setcedulaValidad] = useState(true);
    const [mostrarContrasena, setMostrarContrasena] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
  return {
    codigoValido, setCodigoValido,
    telefonoValido, setTelefonoValido,
    directorValido, setdirectorValido,
    nombreValido, setNombreValido,
    apellido1Valido, setapellido1Valido,
    apellido2Valido, setapellido2Valido,
    telefonoFunPrincipalValido, setTelefonoFunPrincipalvalido,
    telefonoFunSecundarioValido, setTelefonoFunSecundariovalido,
    correoValido, setcorreoValido,
    cedulaValida, setcedulaValidad,
    mostrarContrasena, setMostrarContrasena,
    selectedImage, setSelectedImage,
    selectedLogo, setSelectedLogo,
    isVisible, setIsVisible,
  };
};


