//#region LIsta de Importaciones


import React, { useState, useEffect } from 'react';
import { ValidarSoloLetras, ValidarSoloNumeros, ValidarCorreo } from '../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, InputGroup, InputGroupText, Progress, Tooltip, Breadcrumb, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap'
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Foto from '../../assets/faces/user (3).png'
import Camara from '../../assets/faces/Camara plus.png';
import { TbInstitucion } from '../../../CapaEntidades/TbInstitucion'
import { TbFuncionario } from '../../../CapaEntidades/TbFuncionario'
import { TbUsuarios } from '../../../CapaEntidades/TbUsuarios';
import { EstadosIngresarInstitucion } from '../../../CapaUtilidades/Estados/EstadosIngresarInstitucion';
import { GetProvincias, GetCantones, GetDistritos, GetBarrios, GetPaises } from '../../../CapaDatos/DatosCargarUbicaciones'
import { GetRegionales, GetCircuitos } from '../../../CapaDatos/DatosCargarRegionales'
import { ExisteInstitucion, saveInstitucion } from '../../../CapaDatos/DatosDesarrolladores';
import MenuItem from "../../../CapaPresentacion/components/MenuItem";
import { IoMdReturnLeft } from "react-icons/io";
import { GetCargos, GetNombramientos, GetRoles, GetSexo, GetTipoDereccion, GetTipoInstitucion } from '../../../CapaDatos/DatosCargarSelects';
import { ContraseñaSegura, ErrorMessage, EvaluarSeguridadContraseña, NivelSeguridad, ObtenerImagen, ObtenerLogo } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { ExisteFuncionario, ExisteNombramientoFun, GetDatosPadron, saveFunLaborarInstitucion, saveFuncionario, saveHistoricoNombramientos } from '../../../CapaDatos/DatosFuncionarios';
import { getDatosUsuario, saveUser } from '../../../CapaDatos/DatosUsuarios';
import * as  Fa from "react-icons/fa";
import * as  Tb from "react-icons/tb";
import Swal from 'sweetalert2';
//#endregion



const IngresarInstitucion = () => {

    const tbInstitucion = TbInstitucion();

    const tbFuncionario = TbFuncionario();
    const estadosValidacion = EstadosIngresarInstitucion();

    const tbUsuarios = TbUsuarios();


    const [DatosPerfil, setDatosPerfil] = useState([]);

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };


    const CargarDatos = () => {
        GetPaises(tbFuncionario.setListaPaises)
        GetProvincias(tbFuncionario.setListaProvincias)
        GetProvincias(tbInstitucion.setListaProvinciasInst);
        GetRegionales(tbInstitucion.setListaRegionales)
        GetCargos(tbFuncionario.setListaCargos)
        GetNombramientos(tbFuncionario.setListaNombramientos)
        GetRoles(tbFuncionario.setListaRoles)
        GetTipoDereccion(tbFuncionario.setListaTipoDereccion)
        GetTipoInstitucion(tbFuncionario.setListaTipoInstitucion)
        GetSexo(tbFuncionario.setListaSexo)
        tbUsuarios.setEstadoUser(1)
        tbInstitucion.setEstadoInst(1);

    }


    useEffect(() => {
        const fetchData = async () => {
            CargarDatos();

            const data = await getDatosUsuario();
            setDatosPerfil(data);
        }
        fetchData();
    }, []);


    const MostrarContrasena = () => {
        estadosValidacion.setMostrarContrasena(!estadosValidacion.mostrarContrasena); // Cambia el estado para mostrar o no la contraseña
    };




    const Contraseña_TextChanged = (event) => {
        tbUsuarios.setPassword(event.target.value);
    };

    const nivelSeguridad = EvaluarSeguridadContraseña(tbUsuarios.password);

    const SeleccionarFecha = (e, set) => {
        set(e.target.value)
    }




    const GuardarInstitucion = async (event) => {
        event.preventDefault();



        if (tbInstitucion.codigoInstitucion.length === 4) {
            const institucion = { codigoPresupuestario: tbInstitucion.codigoInstitucion };
            const existe = await ExisteInstitucion(institucion);
            if (!existe) {


                const data = {
                    codigoPresupuestario: tbInstitucion.codigoInstitucion,
                    nombreInstitucion: tbInstitucion.nombreInstitucion,
                    direccionRegional: tbInstitucion.direccionRegional.toString(),
                    circuito: tbInstitucion.circuito.toString(),
                    nombreDirector: "",
                    tel: tbInstitucion.telefono,
                    direccion: tbInstitucion.direccion,
                    logo: tbInstitucion.logo,
                    Provincia: tbInstitucion.provinciaInstitucion.toString(),
                    Canton: tbInstitucion.cantonInstitucion.toString(),
                    Distrito: tbInstitucion.distritoInstitucion.toString(),
                    Barrio: tbInstitucion.barrioInstitucion.toString(),
                    TipoDireccion: tbInstitucion.tipoDireccion,
                    Estado: tbInstitucion.estadoInst,
                    tipoInstitucion: parseInt(tbInstitucion.tipoInstitucion)
                };

                const success = await saveInstitucion(data);

                if (success.estado) {
                    const containerInst = document.querySelector('.showContainer');
                    const container = document.querySelector('.hiddenContainer');
                    if (containerInst) {
                        containerInst.classList.remove('showContainer');
                        containerInst.classList.add('hiddenContainer');
                        container.classList.remove('hiddenContainer');
                        container.classList.add('showContainer');
                        //document.getElementById('currentPage').value = 'Desarrolladores / Registar Funcionario';
                        tbUsuarios.setInstitucionUser(tbInstitucion.codigoInstitucion);
                    }
                } else {
                    ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante la solicitud al servidor.');
                    return;
                }
            } else {
                ErrorMessage('Advertencia', 'La institución que intentas ingresar ya se encuentra registrada.')

                return;
            }
        }
    }


    const GuardarFuncionario = async (event) => {
        event.preventDefault();
        if (tbFuncionario.cedula.length === 9 || tbFuncionario.cedula.length === 12) {
            if (nivelSeguridad === NivelSeguridad.MuySegura || nivelSeguridad === NivelSeguridad.MediaSegura) {

                const existe = await ExisteFuncionario({ cedulaFuncionario: tbFuncionario.cedula })

                if (!existe.estado) {
                    const data =
                    {
                        cedulaFuncionario: tbFuncionario.cedula,
                        nombreCompleto: tbFuncionario.nombre,
                        apellido1: tbFuncionario.apellido1,
                        apellido2: tbFuncionario.apellido2,

                        telefonoPrincipal: tbFuncionario.telefonoFunPrincipal,
                        telefono1: tbFuncionario.telefonoFunSecundario,
                        correo: tbFuncionario.correo,
                        direccion: tbFuncionario.DireccionFuncionario,
                        foto: tbFuncionario.fotoPerfil,
                        nacionalidad: parseInt(tbFuncionario.pais),
                        Provincia: tbFuncionario.selectedProvinciaFuncionario.toString(),
                        Canton: tbFuncionario.selectedCantonFuncionario.toString(),
                        Distrito: tbFuncionario.selectedDistritoFuncionario.toString(),
                        Barrio: tbFuncionario.selectedBarrioFuncionario.toString(),
                        sexo: tbFuncionario.sexo

                    }
                    const success = await saveFuncionario(data);

                    if (success.estado) {
                        let Observacion = '';
                        if (tbFuncionario.Observaciones === '') {
                            Observacion = 'No hay observaciones';
                        } else {
                            Observacion = tbFuncionario.Observaciones
                        }

                        const tbFuncLaboraInstitucion = {
                            institucion: tbInstitucion.codigoInstitucion,
                            cedFuncionario: tbFuncionario.cedula,
                            cargo: parseInt(tbFuncionario.cargo),
                            tipoNombramiento: parseInt(tbFuncionario.tipoNombramiento),
                            fechaInicio: tbFuncionario.fechaIngreso,
                            fechaFinaliza: tbFuncionario.fechaSalida,
                            observaciones: Observacion
                        }
                        //alert(JSON.stringify(tbFuncLaboraInstitucion));




                        const successFuncLaboraInstitucion = await saveFunLaborarInstitucion(tbFuncLaboraInstitucion);


                        if (successFuncLaboraInstitucion.estado) {

                            const dataUser =
                            {
                                usuario: tbUsuarios.usuario,
                                passwd: tbUsuarios.password,
                                idInstitucion: tbInstitucion.codigoInstitucion,
                                permisosUsuario: parseInt(tbUsuarios.permisoUsuario),
                                estado: tbUsuarios.estadoUser

                            }



                            const fechaActual = new Date();
                            const year = fechaActual.getFullYear();
                            const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
                            const day = String(fechaActual.getDate()).padStart(2, '0');
                            const hours = String(fechaActual.getHours()).padStart(2, '0');
                            const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
                            const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
                            const tbHistorico = {
                                cedulaFunc: tbFuncionario.cedula,
                                cargo: parseInt(tbFuncionario.cargo),
                                tipoNombramiento: parseInt(tbFuncionario.tipoNombramiento),
                                fechaInicio: tbFuncionario.fechaIngreso,
                                fechaFinaliza: tbFuncionario.fechaSalida,
                                institucion: tbInstitucion.codigoInstitucion,
                                registradoPor: DatosPerfil[0].nombreCompleto + " " + DatosPerfil[0].apellido1 + " " + DatosPerfil[0].apellido2,
                                fechaRegistro: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000`
                            }

                            await saveHistoricoNombramientos(tbHistorico)
                            const successUser = await saveUser(dataUser)

                            if (successUser.estado) {

                                LimpiarFormulario();

                            } else {
                                ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                                return;
                            }

                        } else {
                            ErrorMessage('Advertencia', existe.mensaje)
                            return;
                        }

                    } else {
                        ErrorMessage('Advertencia', existe.mensaje)
                        return;
                    }

                } else {
                    ErrorMessage('Advertencia', 'Este numero de Identificacion ya se encuentra registrado.')
                    Swal.fire({
                        title: "Notificación del Sistema",
                        text: "Este numero de Identificacion ya se encuentra registrado, Deseas solo registrar la institución en la que va a laborar  y los datos de usuario.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si"
                    }).then(async (result) => {
                        if (result.isConfirmed) {

                            let Observacion = '';
                            if (tbFuncionario.Observaciones === '') {
                                Observacion = 'No hay observaciones';
                            } else {
                                Observacion = tbFuncionario.Observaciones
                            }



                            const datosExiste = {
                                institucion: tbInstitucion.codigoInstitucion,
                                cedFuncionario: tbFuncionario.cedula
                            }
                            const existeFun = await ExisteNombramientoFun(datosExiste)

                            if (!existeFun) {
                                const tbFuncLaboraInstitucion = {
                                    institucion: tbInstitucion.codigoInstitucion,
                                    cedFuncionario: tbFuncionario.cedula,
                                    cargo: parseInt(tbFuncionario.cargo),
                                    tipoNombramiento: parseInt(tbFuncionario.tipoNombramiento),
                                    fechaInicio: tbFuncionario.fechaIngreso,
                                    fechaFinaliza: tbFuncionario.fechaSalida,
                                    observaciones: Observacion
                                }
                                const successFuncLaboraInstitucion = await saveFunLaborarInstitucion(tbFuncLaboraInstitucion);


                                if (successFuncLaboraInstitucion.estado) {

                                    const dataUser =
                                    {
                                        usuario: tbUsuarios.usuario,
                                        passwd: tbUsuarios.password,
                                        idInstitucion: tbInstitucion.codigoInstitucion,
                                        permisosUsuario: parseInt(tbUsuarios.permisoUsuario),
                                        estado: tbUsuarios.estadoUser

                                    }

                                    const successUser = await saveUser(dataUser)

                                    if (successUser.estado) {

                                        LimpiarFormulario();

                                    } else {
                                        ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                                        return;
                                    }

                                } else {
                                    ErrorMessage('Advertencia', 'Ocurrrio un error durante durante el proceso de guardado.')
                                    return;
                                }
                            } else {
                                ErrorMessage('Advertencia', 'Este usuario ya se encontrado registrado en esta institución')
                                return;
                            }
                        }
                    });
                    return;
                }
            }
            else {
                ErrorMessage('Notificación del Sistema', 'Nivel de seguridad de la contraseña no apto.');
                return;
            }

        } else {
            ErrorMessage('Advertencia', 'Ingrese un número de cédula valido.')
            return;
        }
    };


    const LimpiarFormulario = () => {
        const containerInst = document.querySelector('.hiddenContainer');
        const container = document.querySelector('.showContainer');
        if (containerInst) {
            containerInst.classList.remove('hiddenContainer');
            containerInst.classList.add('showContainer');
            container.classList.remove('showContainer');
            container.classList.add('hiddenContainer');

            tbInstitucion.setTipoInstitucion('');
            tbInstitucion.setCodigoInstitucion('');
            tbInstitucion.setNombreInstitucion('');
            tbInstitucion.setDireccionRegional('');
            tbInstitucion.setCircuito('');
            tbInstitucion.setTelefono('');
            tbInstitucion.setProvinciaInstitucion('');
            tbInstitucion.setCantonInstitucion('');
            tbInstitucion.setDistritoInstitucion('');
            tbInstitucion.setBarrioInstitucion('');
            tbInstitucion.setDireccion('');
            tbInstitucion.setLogo(null);
            tbInstitucion.setSelectedLogo(null)
            tbInstitucion.setDirector('');
            tbInstitucion.setEstadoInst(1);
            tbFuncionario.setCedula('');
            tbFuncionario.setNombre('');
            tbFuncionario.setapellido1('');
            tbFuncionario.setapellido2('');
            tbFuncionario.setcargo('');
            tbFuncionario.setTipoNombramiento('');
            tbFuncionario.setTelefonoFunPrincipal('');
            tbFuncionario.setTelefonoFunSecundario('');
            tbFuncionario.setCorreo('');
            tbFuncionario.setDireccionFuncionario('');
            tbFuncionario.setfotoPerfil(null);
            estadosValidacion.setSelectedImage(null)
            tbFuncionario.setPais('');
            tbFuncionario.setSelectedProvinciaFuncionario('');
            tbFuncionario.setSelectedCantonFuncionario('');
            tbFuncionario.setSelectedDistritoFuncionario('');
            tbFuncionario.setSelectedBarrioFuncionario('');
            tbFuncionario.setSexo('');
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 10); // Obtiene yyyy-MM-dd de la cadena ISO
            tbFuncionario.setFechaIngreso('');
            tbFuncionario.setFechaSalida('');
            tbFuncionario.setObservaciones('');
            tbUsuarios.setUsuario('');
            tbUsuarios.setPassword('');
            tbUsuarios.setultIngreso(formattedDate);
            tbUsuarios.setInstitucionUser('');
            tbUsuarios.setPermisoUsuario('');
            tbUsuarios.setEstadoUser('activo');
            CargarDatos();
            const form1 = document.getElementById('form1');
            const form2 = document.getElementById('form2');
            if (form1 && form2) {
                form1.reset();
                form2.reset();
            }
            document.getElementById('direccionadministrativa').value = '0';
            document.getElementById('direccionRegional').value = '0';
            document.getElementById('circuito').value = '0';
            document.getElementById('Provincia').value = '0';
            document.getElementById('Canton').value = '0';
            document.getElementById('Distrito').value = '0';
            document.getElementById('Barrio').value = '0';
            document.getElementById('NacionalidadFuncionario').value = '0';
            document.getElementById('Sexo').value = '0';
            document.getElementById('cargo').value = '0';
            document.getElementById('tiponombramiento').value = '0';
            document.getElementById('ProvinciaFuncionario').value = '0';
            document.getElementById('CantoFuncionarion').value = '0';
            document.getElementById('DistritoFuncionario').value = '0';
            document.getElementById('BarrioFuncionario').value = '0';
            document.getElementById('tipoUsuario').value = '0';
            // document.getElementById('file').value = '';
            // document.getElementById('fileperfil').value = '';
        }
    }


    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };


    const BuscarPersona = async () => {
        try {
            const datosPadron = await GetDatosPadron(tbFuncionario.cedula);
            //console.log(JSON.stringify(datosPadron));

            // Verificar que los resultados existen y tienen datos
            if (datosPadron && datosPadron.results && datosPadron.results.length > 0) {
                const persona = datosPadron.results[0];
                tbFuncionario.setNombre(persona.firstname || '');
                tbFuncionario.setapellido1(persona.lastname1 || '');
                tbFuncionario.setapellido2(persona.lastname2 || '');
            } else {
                ErrorMessage('Notificación del sistema', 'Datos no encontrados o inexistentes')
                tbFuncionario.setNombre('');
                tbFuncionario.setapellido1('');
                tbFuncionario.setapellido2('');
            }
        } catch (error) {
            alert("Error al obtener los datos del padrón:", error);
        }
    }



    return (
        <Container fluid className=" pt-2   Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu/"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Registrar Institución"
                        to="/Menu/IngresarInstitucion"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='  p-3 shadow border rounded showContainer ' id='institucion'  >
                <Form onSubmit={GuardarInstitucion} id='form1' >
                    <h5 className='py-1' > Registro de institución </h5>
                    <Row lg={4} md={2} sm={1} xs={1}>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="direccionRegional">
                                    Tipo dirección Administrativa
                                </Label>
                                <Input
                                    className='input'
                                    required
                                    id="direccionadministrativa"
                                    name="direccionRegional"
                                    placeholder="Nombre centro Educativo"
                                    type="select"
                                    defaultValue={tbInstitucion.tipoDireccion}

                                    onChange={(e) => tbInstitucion.setTipoDireccion(e.target.value)}
                                >
                                    <option value="0" disabled selected>Seleccione tipo Dirección</option>
                                    {tbFuncionario.ListaTipoDereccion.map((tipoDirec) => (
                                        <option key={tipoDirec.id} value={tipoDirec.id}>{tipoDirec.nombre}</option>
                                    ))}
                                </Input>

                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="tipoInstitucion">
                                    Tipo de Institución
                                </Label>
                                <Input
                                    className='input'
                                    required
                                    id="tipoInstitucion"
                                    name="tipoInstitucion"
                                    placeholder="Nombre centro Educativo"
                                    type="select"
                                    defaultValue={tbInstitucion.tipoInstitucion}

                                    onChange={(e) => tbInstitucion.setTipoInstitucion(e.target.value)}
                                >
                                    <option key="0" value="0" disabled selected >Seleccione tipo Institución</option>
                                    {tbFuncionario.ListaTipoInstitucion.map((tipoInstitu) => (
                                        <option key={tipoInstitu.id} value={tipoInstitu.id}>{tipoInstitu.nombre}</option>
                                    ))}
                                </Input>

                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="codigoInstitucion">
                                    Codigo Presupuestario
                                </Label>
                                <Input
                                    required
                                    id="codigoInstitucion"
                                    name="codigoInstitucion"
                                    placeholder="0000"
                                    type="text"
                                    value={tbInstitucion.codigoInstitucion}
                                    onChange={(event) => ValidarSoloNumeros(event, tbInstitucion.setCodigoInstitucion, estadosValidacion.setCodigoValido)}
                                    maxLength={4}
                                    minLength={4} className={estadosValidacion.codigoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                />
                                {!estadosValidacion.codigoValido && (
                                    <Tooltip
                                        placement="right"
                                        isOpen={tooltipOpen}
                                        target="codigoInstitucion"
                                        toggle={toggleTooltip}
                                    >
                                        Solo se admiten caracteres numericos.
                                    </Tooltip>
                                )}
                            </FormGroup>


                        </Col>
                        <Col lg={6} md={6} className='py-0'>
                            <FormGroup>
                                <Label for="nombreinstitucion">
                                    Institución
                                </Label>
                                <Input
                                    required
                                    id="nombreinstitucion"
                                    name="nombreinstitucion"
                                    placeholder="Nombre centro Educativo"
                                    type="text"
                                    value={tbInstitucion.nombreInstitucion}
                                    onChange={(e) => tbInstitucion.setNombreInstitucion(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} className='py-0'>
                            <FormGroup>
                                <Label for="direccionRegional">
                                    Dirección Regional
                                </Label>
                                <Input
                                    required
                                    id="direccionRegional"
                                    name="direccionRegional"
                                    placeholder="Nombre centro Educativo"
                                    type="select"
                                    defaultValue={tbInstitucion.direccionRegional}

                                    onChange={(event) => GetCircuitos(event, tbInstitucion.setDireccionRegional, tbInstitucion.setListaCircuitos)}
                                >
                                    <option value="0" disabled selected>Selecciona la direccion regional</option>
                                    {tbInstitucion.ListaRegionales.map((regional) => (
                                        <option key={regional.id} value={regional.id}>{regional.direccionRegional}</option>
                                    ))}

                                </Input>

                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="circuito">
                                    Circuito
                                </Label>
                                <Input
                                    required
                                    id="circuito"
                                    name="circuito"
                                    placeholder="Nombre centro Educativo"
                                    type="select"
                                    defaultValuevalue={tbInstitucion.circuito}

                                    onChange={(e) => tbInstitucion.setCircuito(e.target.value)}
                                >
                                    <option value="0" disabled selected>Selecciona un circuito</option>
                                    {tbInstitucion.ListaCircuitos.map((circuito, index) => (
                                        <option key={circuito.id} value={circuito.id}>{circuito.nombreCircuito}</option>
                                    ))}
                                </Input>

                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="telefono">
                                    Telefono
                                </Label>
                                <Input
                                    required
                                    maxLength={8}
                                    minLength={8}
                                    id="telefono"
                                    name="telefono"
                                    placeholder=""
                                    type="tel"
                                    value={tbInstitucion.telefono}
                                    onChange={(event) => ValidarSoloNumeros(event, tbInstitucion.setTelefono, estadosValidacion.setTelefonoValido)}
                                    className={estadosValidacion.telefonoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                />
                                {!estadosValidacion.telefonoValido && (
                                    <Tooltip
                                        placement="right"
                                        isOpen={tooltipOpen}
                                        target="telefono"
                                        toggle={toggleTooltip}
                                    >
                                        Solo se admiten caracteres numericos.
                                    </Tooltip>
                                )} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="Provincia">
                                    Provincia
                                </Label>
                                <Input
                                    required
                                    id="Provincia"
                                    name="Provincia"
                                    placeholder="Provincia"
                                    type="select"
                                    defaultValue={tbInstitucion.provinciaInstitucion}
                                    onChange={(event) => GetCantones(event, tbInstitucion.setProvinciaInstitucion, tbInstitucion.setListaCantonesInst)}
                                >
                                    <option value="0" disabled selected  >Seleccione una Provincia</option>
                                    {tbInstitucion.ListaProvinciasInst.map(provincia => (
                                        <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                                    ))}


                                </Input>
                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="Canton">
                                    Cantón
                                </Label>
                                <Input
                                    required
                                    id="Canton"
                                    name="Canton"
                                    placeholder="Canton"
                                    type="select"
                                    defaultValue={tbInstitucion.cantonInstitucion}
                                    onChange={(event) => GetDistritos(event, tbInstitucion.provinciaInstitucion, tbInstitucion.setCantonInstitucion, tbInstitucion.setListaDistritosInst)}

                                >
                                    <option value="0" disabled selected  >Seleccione el Cantón</option>
                                    {tbInstitucion.ListaCantonesInst.map(canton => (
                                        <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                    ))}

                                </Input>
                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="Distrito">
                                    Distrito
                                </Label>
                                <Input
                                    required
                                    id="Distrito"
                                    name="Distrito"
                                    placeholder="Distrito"
                                    type="select"
                                    defaultValue={tbInstitucion.distritoInstitucion}
                                    onChange={(event) => GetBarrios(event, tbInstitucion.provinciaInstitucion, tbInstitucion.cantonInstitucion, tbInstitucion.setDistritoInstitucion, tbInstitucion.setListaBarriosInst)}
                                >
                                    <option value="0" disabled selected  >Seleccione el Distrito</option>
                                    {tbInstitucion.ListaDistritosInst.map(distrito => (
                                        <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                    ))}

                                </Input>
                            </FormGroup>
                        </Col>
                        <Col className='py-0'>
                            <FormGroup>
                                <Label for="Barrio">
                                    Barrio
                                </Label>
                                <Input
                                    required
                                    id="Barrio"
                                    name="Barrio"
                                    placeholder="Barrio"
                                    type="select"
                                    defaultValue={tbInstitucion.barrioInstitucion}

                                    onChange={(e) => tbInstitucion.setBarrioInstitucion(e.target.value)}

                                >
                                    <option value="0" disabled selected>Seleccione el Barrio</option>
                                    {tbInstitucion.ListaBarriosInst.map(barrio => (
                                        <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                    ))}

                                </Input>
                            </FormGroup>
                        </Col>

                        <Col lg={8} md={8} className='py-0'>
                            <FormGroup>
                                <Label for="Direccion">
                                    Dirección
                                </Label>
                                <Input
                                    required
                                    id="Direccion"
                                    name="Direccion"
                                    placeholder="200 mt al este de la calle 10"
                                    value={tbInstitucion.direccion}
                                    onChange={(e) => tbInstitucion.setDireccion(e.target.value)}

                                />
                            </FormGroup>
                        </Col>

                        <Col lg={4} className='py-0'>
                            <FormGroup>
                                <Row lg={2} md={2} sm={2} xs={2}>
                                    <Col  >
                                        <Label for="file" className='pr-2'>
                                            Escudo
                                        </Label>
                                        <Input
                                            id="file"
                                            name="file"
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            onChange={(e) => ObtenerLogo(e, tbInstitucion.setLogo, estadosValidacion.setSelectedLogo)}
                                        />
                                        <br></br>
                                        <Label for="file" className="custom-file-upload">Selec. Archivo</Label>
                                    </Col>

                                    <Col   >
                                        {estadosValidacion.selectedLogo ? (
                                            <img src={estadosValidacion.selectedLogo} className='LogoInstitucion p-2' alt="Logo Instituión" />
                                        ) : (
                                            <img src={Camara} className='LogoInstitucion p-2' alt="Logo Instituión" />
                                        )}
                                    </Col>


                                </Row>



                            </FormGroup>
                        </Col>
                    </Row>

                    <Col className='d-flex justify-content-between'>
                        <Button type="button" className='p-0 btn px-1  btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className='btnAceptar '
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"

                                    to="/Menu"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className='btnAceptar ' id='GuardarInstitucion'>

                            Registrar Institución
                        </Button>
                    </Col>

                </Form>

            </Container>

            <Container fluid className='mt-2 p-3  shadow border rounded  ' >   {/* hiddenContainer */}
                <Form onSubmit={GuardarFuncionario} id='form2'>
                    <h5 className='py-2' > Registrar Administrador </h5>

                    <Accordion className='mb-5' flush open={open} toggle={toggle}>
                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="1" className=' accordionBorder accordion-button2'>Información Personal </AccordionHeader>
                            <AccordionBody accordionId="1" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="Provincia">
                                                Nacionalidad
                                            </Label>
                                            <Input
                                                required
                                                id="NacionalidadFuncionario"
                                                name="NacionalidadFuncionario"
                                                placeholder="NacionalidadFuncionario"
                                                type="select"
                                                defaultValue={tbFuncionario.pais}
                                                onChange={(e) => tbFuncionario.setPais(e.target.value)}
                                            >
                                                <option value="0" disabled selected  >Seleccione la Nacionalidad</option>
                                                {tbFuncionario.ListaPaises.map((paises, index) => (
                                                    <option key={paises.id} value={paises.id}  >{paises.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={9}></Col>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="identificacion" className="me-2">
                                                Identificación
                                            </Label>
                                            <InputGroup>
                                                <Input

                                                    required
                                                    id="identificacion"
                                                    name="identificacion"
                                                    placeholder="0-0000-0000"
                                                    type="text"
                                                    value={tbFuncionario.cedula}
                                                    onChange={(event) => {
                                                        ValidarSoloNumeros(event, tbFuncionario.setCedula, estadosValidacion.setcedulaValidad);
                                                        tbUsuarios.setUsuario(event.target.value);
                                                    }}
                                                    maxLength={12}
                                                    minLength={9}
                                                    className={estadosValidacion.cedulaValida ? ' ' : 'is-invalid  '}
                                                />
                                                <InputGroupText addonType="append" className='p-0'  >
                                                    <Button color="" className='p-0 px-2 border-0' onClick={BuscarPersona}>
                                                        <Fa.FaSearch size={20} />
                                                    </Button>
                                                </InputGroupText>
                                                {!estadosValidacion.cedulaValida &&  (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="identificacion"
                                                    toggle={toggleTooltip}
                                                >
                                                   Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                            </InputGroup>


                                        </FormGroup>
                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="nombre" className="me-2">
                                                Nombre
                                            </Label>
                                            <Input

                                                required
                                                id="nombre"
                                                name="nombre"
                                                placeholder=""
                                                type="text"
                                                value={tbFuncionario.nombre}
                                                onChange={(event) => ValidarSoloLetras(event, tbFuncionario.setNombre, estadosValidacion.setNombreValido)}
                                                className={estadosValidacion.nombreValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!estadosValidacion.nombreValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="nombre"
                                                    toggle={toggleTooltip}
                                                >
                                                   No se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                        </FormGroup>

                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="primerApellido" className="me-2">
                                                Primer Apellido
                                            </Label>
                                            <Input

                                                required
                                                id="primerApellido"
                                                name="primerApellido"
                                                placeholder=""
                                                type="text"
                                                value={tbFuncionario.apellido1}
                                                onChange={(event) => ValidarSoloLetras(event, tbFuncionario.setapellido1, estadosValidacion.setapellido1Valido)}
                                                className={estadosValidacion.apellido1Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!estadosValidacion.apellido1Valido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="primerApellido"
                                                    toggle={toggleTooltip}
                                                >
                                                   No se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                        </FormGroup>

                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="segundoApellido" className="me-2">
                                                Segundo Apellido
                                            </Label>
                                            <Input

                                                required
                                                id="segundoApellido"
                                                name="segundoApellido"
                                                placeholder=""
                                                type="text"
                                                value={tbFuncionario.apellido2}
                                                onChange={(event) => ValidarSoloLetras(event, tbFuncionario.setapellido2, estadosValidacion.setapellido2Valido)}
                                                className={estadosValidacion.apellido2Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!estadosValidacion.apellido2Valido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="segundoApellido"
                                                    toggle={toggleTooltip}
                                                >
                                                   No se admiten caracteres numericos.
                                                </Tooltip>
                                            )}
                                        </FormGroup>

                                    </Col>

                                    <Col md={4} className='py-1'>
                                        <FormGroup>
                                            <Label for="Sexo">
                                                Sexo
                                            </Label>
                                            <Input
                                                required
                                                id="Sexo"
                                                name="Sexo"
                                                placeholder="NacionalidadFuncionario"
                                                type="select"
                                                defaultValue={tbFuncionario.sexo}
                                                onChange={(e) => tbFuncionario.setSexo(e.target.value)}
                                            >
                                                <option value="0" disabled selected  >Seleccione el tipo Sexo</option>
                                                {tbFuncionario.ListaSexo.map(sexo => (
                                                    <option key={sexo.id} value={sexo.id}>{sexo.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={2} className='py-1'>

                                        <FormGroup>
                                            <Label for="files" className='pr-2'>
                                                Foto de Perfil
                                            </Label>
                                            <Input
                                                hidden
                                                id="files"
                                                name="files"
                                                type="file"
                                                accept='image/*'
                                                onChange={(e) => ObtenerImagen(e, estadosValidacion.setSelectedImage, tbFuncionario.setfotoPerfil)}
                                            />
                                            <br></br>
                                            <Label for="files" className="custom-file-upload">Seleccionar Archivo</Label>
                                        </FormGroup>
                                    </Col>

                                    <Col md={4} >
                                        {estadosValidacion.selectedImage ? (
                                            <img src={estadosValidacion.selectedImage} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                        ) : (
                                            <img src={Foto} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                        )}
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3'>
                            <AccordionHeader targetId="2" className=' accordionBorder accordion-button2'>Lugar de Residencia </AccordionHeader>
                            <AccordionBody accordionId="2" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="Provincia">
                                                Provincia
                                            </Label>
                                            <Input
                                                required
                                                id="ProvinciaFuncionario"
                                                name="ProvinciaFuncionario"
                                                placeholder="ProvinciaFuncionario"
                                                type="select"
                                                defaultValue={tbFuncionario.selectedProvinciaFuncionario}
                                                onChange={(event) => GetCantones(event, tbFuncionario.setSelectedProvinciaFuncionario, tbFuncionario.setListaCantones)}
                                            >
                                                <option value="0" disabled selected  >Seleccione una Provincia</option>
                                                {tbFuncionario.ListaProvincias.map(provincia => (
                                                    <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="CantonFuncionario">
                                                Cantón
                                            </Label>
                                            <Input
                                                required
                                                id="CantoFuncionarion"
                                                name="CantonFuncionario"

                                                type="select"
                                                defaultValue={tbFuncionario.selectedCantonFuncionario}
                                                onChange={(event) => GetDistritos(event, tbFuncionario.selectedProvinciaFuncionario, tbFuncionario.setSelectedCantonFuncionario, tbFuncionario.setListaDistritos)}

                                            >
                                                <option value="0" disabled selected  >Seleccione el Cantón</option>
                                                {tbFuncionario.ListaCantones.map(canton => (
                                                    <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="DistritoFuncionario">
                                                Distrito
                                            </Label>
                                            <Input
                                                required
                                                id="DistritoFuncionario"
                                                name="DistritoFuncionario"

                                                type="select"
                                                defaultValue={tbFuncionario.selectedDistritoFuncionario}
                                                onChange={(event) => GetBarrios(event, tbFuncionario.selectedProvinciaFuncionario, tbFuncionario.selectedCantonFuncionario, tbFuncionario.setSelectedDistritoFuncionario, tbFuncionario.setListaBarrios)}
                                            >
                                                <option value="0" disabled selected  >Seleccione el Distrito</option>
                                                {tbFuncionario.ListaDistritos.map(distrito => (
                                                    <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="BarrioFuncionario">
                                                Barrio
                                            </Label>
                                            <Input
                                                required
                                                id="BarrioFuncionario"
                                                name="BarrioFuncionario"

                                                type="select"
                                                defaultValue={tbFuncionario.selectedBarrioFuncionario}


                                                onChange={(e) => tbFuncionario.setSelectedBarrioFuncionario(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Seleccione el Barrio</option>
                                                {tbFuncionario.ListaBarrios.map(barrio => (
                                                    <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} className='py-1'>
                                        <Label for="DireccionFuncionario">
                                            Dirección
                                        </Label>
                                        <Input
                                            required
                                            id="DireccionFuncionario"
                                            name="DireccionFuncionario"
                                            placeholder="200 mt al este de la calle 10"
                                            value={tbFuncionario.DireccionFuncionario}
                                            onChange={(e) => tbFuncionario.setDireccionFuncionario(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3'>
                            <AccordionHeader targetId="3" className=' accordionBorder accordion-button2'>Información de Contacto </AccordionHeader>
                            <AccordionBody accordionId="3" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="telefonfuncionarioo">
                                                Telefono Principal
                                            </Label>
                                            <Input
                                                maxLength={8}
                                                minLength={8}
                                                id="telefonofuncionario"
                                                name="telefonofuncionario"
                                                placeholder=""
                                                type="tel"
                                                value={tbFuncionario.telefonoFunPrincipal}
                                                onChange={(event) => ValidarSoloNumeros(event, tbFuncionario.setTelefonoFunPrincipal, estadosValidacion.setTelefonoFunPrincipalvalido)}
                                                className={estadosValidacion.telefonoFunPrincipalValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!estadosValidacion.telefonoFunPrincipalValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="telefonofuncionario"
                                                    toggle={toggleTooltip}
                                                >
                                                    Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="telefonSecundariofuncionarioo">
                                                Telefono Secundario
                                            </Label>
                                            <Input
                                                maxLength={8}
                                                minLength={8}
                                                id="telefonoSecundariofuncionario"
                                                name="telefonoSecundariofuncionario"
                                                placeholder=""
                                                type="tel"
                                                value={tbFuncionario.telefonoFunSecundario}
                                                onChange={(event) => ValidarSoloNumeros(event, tbFuncionario.setTelefonoFunSecundario, estadosValidacion.setTelefonoFunSecundariovalido)}
                                                className={estadosValidacion.telefonoFunSecundarioValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!estadosValidacion.telefonoFunSecundarioValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="telefonoSecundariofuncionario"
                                                    toggle={toggleTooltip}
                                                >
                                                    Solo se admiten caracteres numericos.
                                                </Tooltip>
                                            )} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className='py-1'>
                                        <FormGroup>
                                            <Label for="email">
                                                Correo Electronico
                                            </Label>
                                            <Input
                                                required
                                                id="email"
                                                name="email"
                                                placeholder=""
                                                type="mail"
                                                value={tbFuncionario.correo}
                                                onChange={(event) => ValidarCorreo(event, tbFuncionario.setCorreo, estadosValidacion.setcorreoValido)}
                                                className={estadosValidacion.correoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!estadosValidacion.correoValido && (
                                                <Tooltip
                                                    placement="right"
                                                    isOpen={tooltipOpen}
                                                    target="email"
                                                    toggle={toggleTooltip}
                                                >
                                                   Formato de correo no valido.
                                                </Tooltip>
                                            )}

                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3'>
                            <AccordionHeader targetId="4" className=' accordionBorder accordion-button2'>Información Laboral </AccordionHeader>
                            <AccordionBody accordionId="4" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="cargo">
                                                Cargo
                                            </Label>
                                            <Input
                                                required
                                                id="cargo"
                                                name="cargo"
                                                placeholder="Nombre centro Educativo"
                                                type="select"
                                                defaultValue={tbFuncionario.cargo}

                                                onChange={(e) => tbFuncionario.setcargo(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Seleccione el Cargo</option>
                                                {tbFuncionario.ListaCargos.slice(0, 2)
                                                    .map(listaCargo => (
                                                        <option key={listaCargo.id} value={listaCargo.id}>{listaCargo.nombre}</option>
                                                    ))}

                                            </Input>

                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="tiponombramiento">
                                                Tipo de Nombramiento
                                            </Label>
                                            <Input
                                                required
                                                id="tiponombramiento"
                                                name="tiponombramiento"

                                                type="select"
                                                defaultValue={tbFuncionario.tipoNombramiento}
                                                onChange={(e) => tbFuncionario.setTipoNombramiento(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Selec. tipo de Nombramiento</option>
                                                {tbFuncionario.ListaNombramientos.map(listaNombramiento => (
                                                    <option key={listaNombramiento.id} value={listaNombramiento.id}>{listaNombramiento.nombre}</option>
                                                ))}

                                            </Input>

                                        </FormGroup>
                                    </Col >
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="fechaingreso">
                                                Fecha de Ingreso
                                            </Label>
                                            <Input
                                                required
                                                id="fechaingreso"
                                                name="fechaingreso"
                                                placeholder=""
                                                type="date"
                                                value={tbFuncionario.fechaIngreso}
                                                onChange={(e) => SeleccionarFecha(e, tbFuncionario.setFechaIngreso)}
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="fechasalida">
                                                Fecha de Salida
                                            </Label>
                                            <Input

                                                id="fechasalida"
                                                name="fechasalida"
                                                placeholder=""
                                                type="date"
                                                value={tbFuncionario.fechaSalida}
                                                onChange={(e) => SeleccionarFecha(e, tbFuncionario.setFechaSalida)}
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="observaciones">
                                                Observaciones
                                            </Label>
                                            <Input

                                                id="observaciones"
                                                name="observaciones"
                                                placeholder=""
                                                type="text"
                                                value={tbFuncionario.Observaciones}
                                                onChange={(e) => tbFuncionario.setObservaciones(e.target.value)}
                                            />
                                        </FormGroup>

                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3'>
                            <AccordionHeader targetId="5" className=' accordionBorder accordion-button2'>Información de Usuario </AccordionHeader>
                            <AccordionBody accordionId="5" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="usuario">
                                                Usuario
                                            </Label>
                                            <Input
                                                readOnly
                                                required
                                                id="usuario"
                                                name="usuario"
                                                placeholder=""
                                                type="text"
                                                value={tbUsuarios.usuario}
                                                onChange={(e) => tbUsuarios.setUsuario(e.target.value)}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <InputGroup className='d-flex justify-content-between '>
                                                <Label for=" password">
                                                    Contraseña
                                                </Label>
                                                <InputGroupText addonType="append" className='p-0 mr-5  border-0 '>
                                                    <Button
                                                        onClick={(e) => ContraseñaSegura(tbUsuarios.setPassword)}

                                                        className=' p-0 px-2 rounded btn-editar '
                                                        title='Generar contraseña segura'>
                                                        <Tb.TbPasswordFingerprint />
                                                    </Button>
                                                </InputGroupText>
                                            </InputGroup>

                                            <InputGroup>
                                                <Input
                                                    required
                                                    id="password"
                                                    name="password"
                                                    placeholder=""
                                                    type={estadosValidacion.mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                    value={tbUsuarios.password}
                                                    onChange={Contraseña_TextChanged}
                                                />
                                                <InputGroupText addonType="append" className='p-0'>
                                                    <Button onClick={MostrarContrasena} color="" className='p-0 px-2'>
                                                        {estadosValidacion.mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                    </Button>
                                                </InputGroupText>
                                            </InputGroup>
                                            {NivelSeguridad && (
                                                <div>
                                                    {nivelSeguridad}
                                                    <Progress
                                                        animated
                                                        value={nivelSeguridad === NivelSeguridad.MuySegura ? 100 : nivelSeguridad === NivelSeguridad.MediaSegura ? 75 : nivelSeguridad === NivelSeguridad.PocoSegura ? 25 : 1}
                                                        color={nivelSeguridad === NivelSeguridad.MuySegura ? 'success' : nivelSeguridad === NivelSeguridad.MediaSegura ? 'warning' : nivelSeguridad === NivelSeguridad.PocoSegura ? 'danger' : 'danger'}
                                                    />
                                                </div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for=" tipoUsuario">
                                                Tipo Usuario
                                            </Label>
                                            <Input
                                                required
                                                id="tipoUsuario"
                                                name=" tipoUsuario"
                                                placeholder=""
                                                type="select"
                                                defaultValue={tbUsuarios.permisoUsuario}
                                                onChange={(e) => tbUsuarios.setPermisoUsuario(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Selec. roll de Usuario</option>
                                                {tbFuncionario.ListaRoles.slice(1, 3).map(roles => (
                                                    <option key={roles.id} value={roles.id}>{roles.nombre}</option>
                                                ))}
                                            </Input>


                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>

                    <Col className='d-flex justify-content-between '>

                        <Button type="button" className='p-0 btn  px-1 btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className='btnAceptar'
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"

                                    to="/Menu"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className='btnAceptar d-flex justify-content-end' id='GuardarFuncionario' >
                            Registrar Funcionario
                        </Button>
                    </Col>

                </Form>
            </Container>



        </Container>
    )
}

export default IngresarInstitucion
