import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "../styles/App.css";
import Login from "../View/Login/Login";
import Menu from "../components/Menu";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css'; 

import { Roll } from "../../CapaUtilidades/Utils/Cahe";
import { obtenerIdInstitucion, obtenerPermisos } from "../../CapaUtilidades/Utils/encryptUserData";

function App() {
    // Estado para controlar el modo oscuro
    const [darkMode, setDarkMode] = useState();

    // Función para cambiar el estado del modo oscuro
    const handleDarkModeToggle = () => {
        const tema = localStorage.getItem('tema');
        setDarkMode(!tema);
    };

    // Cambiar la importación del archivo de estilos según el estado del modo oscuro
    useEffect(() => {
        if (darkMode) {
            import("../styles/AppDark.css")
                //.then(() => //console.log("Modo oscuro activado"))
                .catch(error => console.error("Error al cargar el archivo de estilos para modo oscuro:", error));
        } else {
            import("../styles/App.css")
                //.then(() => console.log("Modo claro activado"))
                .catch(error => console.error("Error al cargar el archivo de estilos para modo claro:", error));
        }
    }, [darkMode]);





    useEffect(() => {
        const bloquearTeclas = (event) => {
            const teclasNavegacion = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'PageUp', 'PageDown'];
            if (teclasNavegacion.includes(event.key)) {
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', bloquearTeclas);

        // Limpiar el evento al desmontar el componente para evitar fugas de memoria
        return () => {
            document.removeEventListener('keydown', bloquearTeclas);
        };
    }, []); // La matriz de dependencias está vacía para que el efecto se ejecute solo una vez al montar el componente


    return (

        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Menu/*" element={<Menu toggleDarkMode={handleDarkModeToggle}/>} />
            </Routes>
        </Router>
    );
}

export default App;
