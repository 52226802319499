// ComponenteOpcion2.js
import React from "react";

const GestionEstudiante = () => {
    console.log("ComponenteOpcion2 se está renderizando...");
    return (
        <div className="container-fluid  Divcontenedor">
            <h2 className="text-black">
                Gestión de Estudiantes</h2>
          
        </div>
    );
}

export default GestionEstudiante;
