// ComponenteOpcion1.js
import React, { useState, useEffect } from 'react';
import { getInstitucion, getInstituciondatos } from "../../../CapaDatos/DatosDesarrolladores";
import {
    Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal,
    ModalBody,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText, Progress,
    Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';
import Camara from '../../assets/faces/Camara plus.png';
import MenuItem from '../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { ContraseñaSegura, EvaluarSeguridadContraseña, NivelSeguridad, removeBase64Prefix } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Cargando from '../../components/Cargando';



let globalInstitucionData = null;


const Dashboard = ({ codigoPresupuestario }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    const [loading, setLoading] = useState(true);
    const [Institucion, setInstitucion] = useState([]);

    const fetchData = async () => {
        try {
            const parametro = { codigoPresupuestario: CodigoPresupuestario };

            const data = await getInstituciondatos(parametro);
            globalInstitucionData = data;
            setInstitucion(data);

            setLoading(false);


        } catch (error) {

            setLoading(false);
        }
    };

    useEffect(() => {
        if (globalInstitucionData) {
            setInstitucion(globalInstitucionData);
            setLoading(false);
        } else {
            fetchData();
        }

        ;
    }, []);


    if (loading) {
        return (
            <Cargando />
        );
    }

    if (Institucion.estado === 0) {
        fetchData();
    }
    if (!Institucion?.length) {


    }


    return (

        <Container className="container-fluid  Divcontenedor text-center">


            
                {Array.isArray(Institucion) && Institucion.length > 0 ? (
                    <Container fluid className='p-0 Mymt-5 total'>
                        {Institucion.map((institucion) => (
                           
                            <Col className="text-center" key={institucion.codigoPresupuestario}>
                                 <Col>
                                 <Label className='NombreInstitucion'>{institucion.nombreInstitucion}</Label>
                                 </Col>
                                {institucion.logo !== null ? (
                                    <img src={`data:image/jpeg;base64,${removeBase64Prefix(institucion.logo)}`} alt="Foto de institución" className="ImageInstitucion" />
                                ) : (
                                    <img src={Camara} alt="Imagen por defecto" className="img-fluid" />
                                )}
                            </Col>
                        ))}
                   </Container>
                ) : (
                    <div></div>
                )}
            

        </Container>
    );
}

export default Dashboard;
