import React, { useState, useEffect } from 'react';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader, Table, ModalHeader, Spinner } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Fa6 from "react-icons/fa6";
import * as  Fc from "react-icons/fc";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import * as Tb from "react-icons/tb";
import * as Io from "react-icons/io";
import * as  Fa from "react-icons/fa";
import { TbActivos } from '../../../../CapaEntidades/TbActivos';
import Swal from 'sweetalert2';
import { obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { getTipoInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';
import { GetGrados } from '../../../../CapaDatos/DatosCargarSelects';
import { getSecciones } from '../../../../CapaDatos/DatosSecciones';
import MenuItem2 from '../../../components/MenuItem2';
import { TbEstudiante } from '../../../../CapaEntidades/TbEstudiante';
import { ListaMatriculados } from '../../../../CapaDatos/DatosMatricula';
import { TbMatricula } from '../../../../CapaEntidades/TbMatricula';


let globalTipoInstitu = null;
let globalSecciones = null;


const FrmExpedientes = ({ codigoPresupuestario, codigoNombre }) => {
  let CodigoPresupuestario = codigoPresupuestario;
  let CodigoNombre = codigoNombre;
  const TBMatricula = TbMatricula();
  const TBEstudiante = TbEstudiante();
  const [tipoInstitucion, setTipoInstitucion] = useState('');
  const [loading, setLoading] = useState(true);
  const [ListaGrados, setListaGrados] = useState([]);
  const [ListaSecciones, setListaSecciones] = useState([]);
  const [ListaEstudiantes, setListaEstudiantes] = useState([]);

  const [orden, setOrden] = useState({
    columna: null,
    ascendente: true
  });

  const [filtros, setFiltros] = useState({
    cedula: '',
    nombreCompleto: '',
    apellido1: '',
    apellido2: ''

  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {

          cursoLectivo: TBMatricula.añoActual,
          codInstitucion: obtenerIdInstitucion()

        }
        const lista = await ListaMatriculados(data);
        setListaEstudiantes(lista)


      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
      }
    };

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const parametro = { codigoPresupuestario: obtenerIdInstitucion() };
      const tipoIns = await getTipoInstitucion(parametro);
      const listaSecciones = await getSecciones(1)

      globalTipoInstitu = tipoIns;
      globalSecciones = listaSecciones;



      setTipoInstitucion(tipoIns);
      setListaSecciones(listaSecciones);

      setLoading(false);

    } catch (error) {
      setLoading(false);
    }
  }


  useEffect(() => {


    fetchData();
    GetGrados(setListaGrados);


  }, []);

  if (loading) {
    return (
      <Cargando />
    );
  }

  const GradosFiltrados = ListaGrados.filter(grado => {

    if (tipoInstitucion === 1) {
      return grado.id >= -1 && grado.id <= 6;
    } else if (tipoInstitucion === 2) {
      return grado.id >= 7 && grado.id <= 12;
    }
    return false;
  })

  const OrdenarColumnas = (columna) => {
    setOrden(prevOrden => ({
      columna: columna,
      ascendente: prevOrden.columna === columna ? !prevOrden.ascendente : true
    }));
  };
  const obtenerIconoOrden = (columna) => {
    if (orden.columna === columna) {
      return orden.ascendente ? <Io.IoMdArrowDropup /> : <Io.IoMdArrowDropdown />
    }
    return null; // Retorna null si la columna no está siendo ordenada
  };

  const ordenarDatos = (datos) => {

    if (orden.columna) {
      return datos.slice().sort((a, b) => {
        const columnaA = a[orden.columna];
        const columnaB = b[orden.columna];
        if (typeof columnaA === 'string' && typeof columnaB === 'string') {
          return orden.ascendente ? columnaA.localeCompare(columnaB) : columnaB.localeCompare(columnaA);
        } else {
          return orden.ascendente ? columnaA - columnaB : columnaB - columnaA;
        }
      });
    } else {
      return datos;
    }
  };
  const Filtrar = (e, columna) => {
    const valor = e.target.value;

    // Limpiar todos los filtros antes de aplicar el nuevo
    setFiltros({
      cedula: '',
      nombreCompleto: '',
      apellido1: '',
      apellido2: '',
      // Añadir aquí cualquier otro filtro que tengas
    });


    setFiltros(prevFiltros => ({
      ...prevFiltros,
      [columna]: valor
    }));
  };
  const filtrarDatos = (datos) => {
    if (!Array.isArray(datos)) {
      //console.error("Los datos proporcionados no son un arreglo.");
      return [];
    }

    return datos.filter(activo => {
      return Object.entries(filtros).every(([columna, valorFiltro]) => {
        if (valorFiltro === '') return true;
        return activo[columna].toLowerCase().includes(valorFiltro.toLowerCase());
      });
    });
  };

  const PasarCedula = (cedula) => {
    sessionStorage.removeItem('Est')
    sessionStorage.setItem('Est', cedula);//Usuario
  }


  return (
    <Container fluid className=" Divcontenedor ">

      <Col>
        <Breadcrumb listTag="div">
          <MenuItem
            className='p-0 text-dark'

            text="Inicio"
            to="/Menu"
            classname={'NoDecoration2 tlink'}
          />
          <Label className=' px-1 py-1'>/</Label>
          <MenuItem
            className='p-0 text-dark'

            text="Expedientes"
            to="/Menu/FrmExpedientes"
            classname={'NoDecoration2 tlink'}
          />

        </Breadcrumb>

      </Col>

      <Container fluid className='p-0 Mymt-1'>
        <Col className='mb-1 pb-1 border-bottom'>
          <Button className='p-0  btn btnAceptar    '>
            <ul>
              <MenuItem
                className=' p-0'
                icon={<Fa6.FaCirclePlus className="me-1 " size={18} />}
                text="Registrar Estudiante "
                to="/Menu/FrmNuevoExpediente" // aca agregar la ruta
                classname={'NoDecoration'}
              />
            </ul>
          </Button>
        </Col>
      </Container>

      <Container fluid className='  px-0  ' >
        <Tabs className={'p-0 m-0  border  '}>
          <TabList className={'tabs_ m-0'}  >
            {GradosFiltrados.map((grados, index) => (
              <Tab key={index}>{grados.nombre}</Tab>
            ))}

          </TabList>
          <Container fluid className=' shadow2 px-0 contenedorTabs rounded-0' >
            {GradosFiltrados.map((grado, index) => (
              <TabPanel key={index}>
                <Container fluid className='px-0'>
                  <Tabs className={'p-0 m-0  border  '}>
                    <TabList className={'tabs_ m-0 pt-1'} >
                      {ListaSecciones.filter(sec => sec.nivel === grado.id).map(seccion => (
                        <Tab>{seccion.nombreSeccion}</Tab>
                      ))}
                    </TabList>
                    {ListaSecciones.filter(sec => sec.nivel === grado.id).map((seccion, secIndex) => (
                      <TabPanel key={secIndex}>
                        <Container fluid className='p-0'>
                          <Table responsive >
                            <thead className='border-bottom'>
                              <tr>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('cedula')}>  Cedula {obtenerIconoOrden('cedula')}</a>
                                </th>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('nombreCompleto')}>  Nombre {obtenerIconoOrden('nombreCompleto')}</a>
                                </th>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('apellido1')}>  Primer Apellido {obtenerIconoOrden('apellido1')}</a>
                                </th>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('apellido2')}>  Segundo Apellido {obtenerIconoOrden('apellido2')}</a>
                                </th>
                              </tr>
                              <tr className='bg-tr'>

                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.cedula}
                                      onChange={(e) => Filtrar(e, 'cedula')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.nombreCompleto}
                                      onChange={(e) => Filtrar(e, 'nombreCompleto')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.apellido1}
                                      onChange={(e) => Filtrar(e, 'apellido1')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.apellido2}
                                      onChange={(e) => Filtrar(e, 'apellido2')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                              </tr>
                            </thead>
                            <tbody className='tablebody' id='tablebody'>
                              {
                                ordenarDatos(filtrarDatos(ListaEstudiantes)).length > 0 ? (
                                  ordenarDatos(filtrarDatos(ListaEstudiantes)).filter(estudiante => estudiante.nivel === grado.id && estudiante.nombreSeccion === seccion.nombreSeccion).map(estudiante => {
                                    return (
                                      <tr className="table-p" key={estudiante.cedula}>
                                        <td>
                                          <Label onClick={() => PasarCedula(estudiante.cedula)}>
                                            <MenuItem2
                                              text={estudiante.cedula}
                                              to="/Menu/FrmExpedienteEstudiante"
                                              cedula={estudiante.cedula}
                                            />
                                          </Label>


                                        </td>
                                        <td>
                                          {estudiante.nombreCompleto}
                                        </td>
                                        <td>
                                          {estudiante.apellido1}
                                        </td>
                                        <td>
                                          {estudiante.apellido2}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="6">No hay estudiantes ingresados en el sistema.</td>
                                  </tr>
                                )}

                            </tbody>
                          </Table>
                        </Container>
                      </TabPanel>
                    ))}
                  </Tabs>
                </Container>

              </TabPanel>
            ))}

          </Container>

        </Tabs>

      </Container>
      <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
        <ul>
          <MenuItem
            className='btnAceptar'
            icon={<Io.IoMdReturnLeft className="me-1" />}
            text="Volver"
            to="/Menu"
            classname={'NoDecoration'}
          />
        </ul>
      </Button>

    </Container >
  )
}

export default FrmExpedientes
