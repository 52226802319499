import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";

import { GetModoAdqui, GetTipoActivos } from '../../../../CapaDatos/DatosCargarSelects';
import { TbActivos } from '../../../../CapaEntidades/TbActivos';
import { ErrorMessage, SuccessMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { ExisteActivo, RegistrarActivo } from '../../../../CapaDatos/DatosActivos';
import { obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';

const FrmRegistrarActivos = ({ codigoPresupuestario, codigoNombre }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    let CodigoNombre = codigoNombre;
    const [errorModoadquisitivo, setErrorModoadquisitivo] = useState(false);
    const [errorTipoActivo, setErrorTipoActivo] = useState(false);
    const TBActivos = TbActivos();





    const fetchData = async () => {
        GetModoAdqui(TBActivos.setListaModos)
        GetTipoActivos(TBActivos.setListaTipoActivos)
        TBActivos.setTipoActivo(0)
        TBActivos.setModoadquisitivo(0)
        TBActivos.setPlacaActivo(obtenerIdInstitucion()+"-")
    }

    useEffect(() => {
        fetchData();
    }, []);

    const SeleccionarDato = (e) => {
        const value = e.target.value;
        TBActivos.setModoadquisitivo(parseInt(value));


    }
    const SeleccionarTipo = (e) => {
        const value = e.target.value;
        //alert(value)
        TBActivos.setTipoActivo(parseInt(value));

    }


    const GuardarActivos = async (event) => {
        event.preventDefault();
        let tipo = TBActivos.tipoActivo
        let modo = TBActivos.modoadquisitivo

        if (tipo !== 0) {
            setErrorTipoActivo(false);
            if (modo !== 0) {
                setErrorModoadquisitivo(false);
               
                const datos = {
                    placaActivo: TBActivos.placaActivo,
                    activoPerteneceInstitucion: CodigoPresupuestario
                }
                const existe = await ExisteActivo(datos);
                
                if (!existe) {


                    const TbActivos_ = {
                        placaActivo: TBActivos.placaActivo,
                        nombreActivo: TBActivos.nombreActivo,
                        estadoActivo: 1,
                        descripcion: TBActivos.descripcion,
                        activoPerteneceInstitucion: CodigoPresupuestario,
                        marca: TBActivos.marca,
                        modelo: TBActivos.modelo,
                        modoadquisitivo: TBActivos.modoadquisitivo,
                        procedencia: TBActivos.procedencia,
                        tipoActivo: TBActivos.tipoActivo,
                        observaciones: '',
                    }

                    const success = await RegistrarActivo(TbActivos_);

                    if (success) {
                        TBActivos.setPlacaActivo('');
                        TBActivos.setNombreActivo('');
                        TBActivos.setDescripcion('');
                        TBActivos.setMarca('');
                        TBActivos.setModelo('');
                        TBActivos.setModoadquisitivo(0);
                        TBActivos.setProcedencia('');
                        TBActivos.setEstadoActivo(1);


                        fetchData();
                        const select = document.getElementById('ModoAdquisitivo');
                        const select2 = document.getElementById('TipoActivo');
                        select.selectedIndex = 0;
                        select2.selectedIndex = 0;
                        SuccessMessage('Registro Existoso', 'El activo a sido registrado')
                    } else {
                        ErrorMessage('Notificación del Sistema', 'No se pudo registrar el activo #' + TBActivos.placaActivo + ' .');
                        return;
                    }

                } else {
                    ErrorMessage('Notificación del Sistema', 'Este numero de placa #' + TBActivos.placaActivo + ' ya se encuentra registrado');
                    return;
                }
            }
            else {
                setErrorModoadquisitivo(true); // Muestra error si no se ha seleccionado un modo adquisitivo
                return
            }
        }
        else {
            setErrorTipoActivo(true); // Muestra error si no se ha seleccionado un modo adquisitivo
            return
        }

    }

    const PerdioFoco = (e) => {
        let placa = e.target.value
        if (!placa.startsWith(CodigoPresupuestario)) {
            // Si no contiene el código presupuestario, agregarlo al inicio del valor
            const nuevaPlacaActivo = CodigoPresupuestario + "-" + placa;
            TBActivos.setPlacaActivo(nuevaPlacaActivo);

        }
    }
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Activos"
                        to="/Menu/FrmActivos"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Registrar Activos"
                        to="/Menu/FrmRegistrarActivos"
                        classname={'NoDecoration2 tlink'}
                    />
                </Breadcrumb>

            </Col>

            <Container fluid className='  p-1 shadow5 border rounded  mt-1' id='institucion'  >
                <Form onSubmit={GuardarActivos} id='form1' className='px-4' >
                    <h5 className='py-2' > Registrar Activos </h5>
                    <Row >
                        <Col md={4}>
                            <FormGroup>
                                <Label for="cod.Presupuestario" className="me-2">
                                    Institución
                                </Label>
                                <Input
                                    readOnly
                                    type="text"
                                    name="cod.Presupuestario"
                                    id="cod.Presupuestario"
                                    placeholder="Código Presupuestario"
                                    value={CodigoNombre}
                                    className='py-1'
                                />
                            </FormGroup>

                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="TipoActivo" className="me-2">
                                    Tipo Activo
                                </Label>
                                <Input
                                    required
                                    className={`py-1 ${errorTipoActivo ? 'is-invalid' : ''}`}
                                    type="select"
                                    name="TipoActivo"
                                    id="TipoActivo"
                                    defaultValue={TBActivos.tipoActivo}
                                    onChange={(e) => TBActivos.setTipoActivo(e.target.value)}
                                >
                                    <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                    {TBActivos.ListaTipoActivos.map((tipo, i) => (
                                        <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                    ))}
                                </Input>

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="placaActivo" className="me-2 ">
                                    Placa Activo
                                </Label>
                                <Input
                                    required
                                    className='py-1'
                                    type="text"
                                    name="placaActivo"
                                    id="placaActivo"
                                    onBlur={(e)=>PerdioFoco(e)}
                                    value={TBActivos.placaActivo}
                                    onChange={(e) => TBActivos.setPlacaActivo(e.target.value)}
                                />
                            </FormGroup>

                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label for="NombreActivo" className="me-2">
                                    Nombre Activo
                                </Label>
                                <Input
                                    className='py-1'
                                    required
                                    type="text"
                                    name="NombreActivo"
                                    id="NombreActivo"
                                    value={TBActivos.nombreActivo}
                                    onChange={(e) => TBActivos.setNombreActivo(e.target.value)}
                                />
                            </FormGroup>

                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="MarcaActivo" className="me-2">
                                    Marca
                                </Label>
                                <Input
                                    className='py-1'
                                    required
                                    type="text"
                                    name="MarcaActivo"
                                    id="MarcaActivo"
                                    value={TBActivos.marca}
                                    onChange={(e) => TBActivos.setMarca(e.target.value)}
                                />
                            </FormGroup>

                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="ModeloActivo" className="me-2">
                                    Modelo
                                </Label>
                                <Input
                                    className='py-1'
                                    required
                                    type="text"
                                    name="ModeloActivo"
                                    id="ModeloActivo"
                                    value={TBActivos.modelo}
                                    onChange={(e) => TBActivos.setModelo(e.target.value)}
                                />
                            </FormGroup>

                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="ModeloActivo" className="me-2">
                                    Descripción
                                </Label>
                                <Input
                                    className='py-1'
                                    required
                                    type="text"
                                    name="ModeloActivo"
                                    id="ModeloActivo"
                                    value={TBActivos.descripcion}
                                    onChange={(e) => TBActivos.setDescripcion(e.target.value)}
                                />
                            </FormGroup>

                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="ModoAdquisitivo" className="me-2">
                                    Modo Adquisitvo
                                </Label>
                                <Input
                                    className={`py-1 ${errorModoadquisitivo ? 'is-invalid' : ''}`}
                                    required
                                    type="select"
                                    name="ModoAdquisitivo"
                                    id="ModoAdquisitivo"
                                    defaultValue={TBActivos.modoadquisitivo}
                                    onChange={(e) => TBActivos.setModoadquisitivo(e.target.value)}
                                >
                                    <option value={0} disabled selected> Selec. modo adquisitivo</option>
                                    {TBActivos.ListaModos.map((modo, i) => (
                                        <option key={modo.id} value={modo.id}  >{modo.nombre}</option>
                                    ))}
                                </Input>

                            </FormGroup>

                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="ModeloActivo" className="me-2">
                                Origen de Adquisición
                                </Label>
                                <Input
                                    className='py-1'
                                    required
                                    type="text"
                                    name="ModeloActivo"
                                    id="ModeloActivo"
                                    value={TBActivos.procedencia}
                                    onChange={(e) => TBActivos.setProcedencia(e.target.value)}
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <Col className='d-flex justify-content-between my-3'>

                        <Button type="button" className='p-0 btn  px-2 btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className='btnAceptar'
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"

                                    to="/Menu/FrmActivos"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className='p-1 btn  px-2 btnAceptar d-flex justify-content-end' id='GuardarFuncionario' >
                            Registrar Activo
                        </Button>
                    </Col>
                </Form>
            </Container>

        </Container>
    )
}

export default FrmRegistrarActivos
