import React, { useState, useEffect } from 'react';
import * as Fa from "react-icons/fa";
import * as Io from "react-icons/io";
import * as Bi from "react-icons/bi";
import { Navigate, NavLink } from 'react-router-dom'; // Importa Navigate en lugar de useHistory
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Label,
  Input,
  FormGroup
} from 'reactstrap';

import face from '../assets/faces/user (3).png'
import MenuItem from './MenuItem';
import { GuardarCahe, limpiarCacheUsuario } from '../../CapaUtilidades/Utils/Cahe';
import { RemoveLocalStorage } from '../../CapaUtilidades/Utils/encryptUserData';
import { getDatosUsuario } from '../../CapaDatos/DatosUsuarios';
import { BsArrowsFullscreen } from "react-icons/bs";
import classnames from 'classnames';
import { TbPerfil } from '../../CapaEntidades/TbPerfil';



function NavbarNav({ toggleSidebar, codigoPresupuestario, IDuser, toggleDarkMode }) {
  let CodigoPresupuestario = codigoPresupuestario;
  let User = IDuser;



  const handleDarkModeClick = () => {
    if (document.getElementById('switch').checked) {
      localStorage.removeItem('Tema')
      localStorage.setItem('Tema', 'true');
      toggleDarkMode();
    } else {
      localStorage.removeItem('Tema')
      localStorage.setItem('Tema', 'false');
      toggleDarkMode();
    };
    window.location.reload();
  };

  const TBPerfil = TbPerfil();

  const [DatosPerfil, setDatosPerfil] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false); // Estado para controlar la redirección después de cerrar sesión
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen); // Invierte el valor de isOpen
  };

  const handleMenuItemClick = () => {
    setIsOpen(false); // Cierra el menú desplegable al hacer clic en una opción
  };

  useEffect(() => {
    const tema = localStorage.getItem('Tema');
    if (tema === 'true') {
      document.getElementById('switch').checked = true;
      toggleDarkMode(); // Llama a la función toggleDarkMode para cambiar el estado del modo oscuro
    } else {
      document.getElementById('switch').checked = false;
    }
  }, [toggleDarkMode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDatosUsuario();

        setDatosPerfil(data);

        TBPerfil.setNombre(data[0].nombreCompleto)
        TBPerfil.setCedula(data[0].cedula)
        TBPerfil.setApellido1(data[0].apellido1)
        TBPerfil.setApellido2(data[0].apellido2)
        
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);




  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    if (isMobile) {

      toggleSidebar();
    }
  };

  const handleLogout = () => {
    RemoveLocalStorage();

    window.location.href = '/';
    setLoggedOut(true); // Cambia el estado para activar la redirección

  };

  // Redirige a la página de inicio de sesión si se ha cerrado sesión
  if (loggedOut) {
    return <Navigate to="/" />;

  }



  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.error('Error al intentar activar la pantalla completa:', err);
      });
    } else {
      document.exitFullscreen();
    }
  };
  return (
    <Navbar className='p-0 mb-0' expand='xl'>
      <NavbarToggler className="custom-toggler" onClick={handleClick} />
      <NavbarBrand className="NameNav px-2"  >SIGAE-APP</NavbarBrand>
      <Nav className="ms-auto p-0" navbar>

        <UncontrolledDropdown nav inNavbar isOpen={isOpen} toggle={handleDropdownToggle}>

          <DropdownToggle className='dropdowntoggles mx-1  ' nav caret >
            {DatosPerfil[0] ? (
              <>
                {DatosPerfil[0].foto ? (
                  <img src={DatosPerfil[0].foto} alt="Foto de perfil" className="profile-img" />
                ) : (
                  <img src={face} alt="Foto de perfil por defecto" className="profile-img" />
                )}

                <span className='text-White-Nav px-2 ' >{DatosPerfil[0].nombreCompleto}  {DatosPerfil[0].apellido1.charAt(0)}.{DatosPerfil[0].apellido2.charAt(0)}  </span>
              </>
            ) : (
              <>
                <img src={face} alt="Foto de perfil por defecto" className="profile-img" />
                <span className='px-3'>Usuario </span>
              </>
            )}
          </DropdownToggle>


          <DropdownMenu >
            <MenuItem
              icon={<Fa.FaUser className="me-1" />}
              text="Mi Perfil"
              classname="text-Drop"
              to="/Menu/Perfil"
              perfil=" text-dark dropdowntoggles py-1"
              onClick={handleMenuItemClick}
            />
            <MenuItem
              icon={<Io.IoMdSettings className="me-1" />}
              text="Configuraciones"
              classname='text-Drop'
              to="/Menu "
              perfil=" text-dark dropdowntoggles py-1"
              onClick={handleMenuItemClick}
            />
            <FormGroup switch>
              <Input type="switch" role="switch" id='switch' onChange={handleDarkModeClick} />
              <Label check>Modo Oscuro</Label>
            </FormGroup>
            <DropdownItem divider />

            <NavLink onClick={handleLogout} className='Perfil' >
              <DropdownItem className='btnCancelar text-white ' > <Fa.FaRunning className="me-2 mb-1" />Cerrar Sesión</DropdownItem>
            </NavLink>

          </DropdownMenu>

        </UncontrolledDropdown>
        <Button title="Pantalla Completa" onClick={toggleFullscreen} className='btnFullScreen  '>
          {isFullscreen ? "Esc" : <BsArrowsFullscreen className=" sizeIcon2" />}
        </Button>
      </Nav>
    </Navbar>

  );
}

export default NavbarNav;
