import React, { useState, useEffect } from 'react';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader, Table, ModalHeader, Spinner } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Fa6 from "react-icons/fa6";
import * as  Fc from "react-icons/fc";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import * as Tb from "react-icons/tb";
import * as Io from "react-icons/io";
import * as  Fa from "react-icons/fa";
import { TbActivos } from '../../../../CapaEntidades/TbActivos';
import Swal from 'sweetalert2';
import { Descargar, ErrorMessage, SuccessMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { GetModoAdqui, GetTipoActivos } from '../../../../CapaDatos/DatosCargarSelects';
import { ActualizarActivo, CambiarEstado, CambiarEstadoActivos, GetListaActivos } from '../../../../CapaDatos/DatosActivos';
import { GetListSeccDocent } from '../../../../CapaDatos/DatosSeccionesDocente';
import { getFuncionariosActivos } from '../../../../CapaDatos/DatosFuncionarios';
import { obtenerIdInstitucion, obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { ActualizarActivoAsignadoProfesor, AsignarActivo, DevolverActivo, ListaActivosAsignados } from '../../../../CapaDatos/DatosAsignarActivos';
import { GetListaEdificios } from '../../../../CapaDatos/DatosEdificios';

let globalData = null;
let globalData2 = null;
let globalData3 = null;
let globalData4 = null;

const FrmActivos = ({ codigoPresupuestario, codigoNombre }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    let CodigoNombre = codigoNombre;
    const currentDate = new Date(); // Obtiene la fecha actual
    const currentYear = currentDate.getFullYear(); // Obtiene el año actual
    const [errorModoadquisitivo, setErrorModoadquisitivo] = useState(false);
    const [errorTipoActivo, setErrorTipoActivo] = useState(false);
    const {
        placaActivo, setPlacaActivo,
        nombreActivo, setNombreActivo,
        descripcion, setDescripcion,
        activoPerteneceInstitucion, setActivoPerteneceInstitucion,
        marca, setMarca,
        modelo, setModelo,
        modoadquisitivo, setModoadquisitivo,
        procedencia, setProcedencia,
        estadoActivo, setEstadoActivo,
        tipoActivo, setTipoActivo,
        observaciones, setObservaciones,
        observacionesNuevas, setObservacionesNuevas,
        FuncionarioEntregada, setFuncionarioEntregada,
        NombreFuncionarioEntregada, setNombreFuncionarioEntregada,
        CodNombre, setCodNombre,
        ListaModos, setListaModos,
        ListaTipoActivos, setListaTipoActivos,
        fechaSolicitud, setFechaSolicitud,
        fechaEntrega, setFechaEntrega,
        placaNueva, setPlacaNueva,
        CedulaFuncionario, setCedulaFuncionario,
        NombreFuncionario, setNombreFuncionario,
        apellido1, setapellido1,
        apellido2, setapellido2,
        codigo, setCodigo,
        edificioAsignacion, setEdificioAsignacion,
        nombreEdificio, setNombreEdificio,
    } = TbActivos();

    const [ListaActivos, setListaActivos] = useState([])
    const [funcionarios, setFuncionarios] = useState([]);
    const [Seleccionadas, setSeleccionadas] = useState([]);
    const [SeleccionadasEdificio, setSeleccionadasEdificio] = useState([]);
    const [ActivosAsignados, setActivosAsignados] = useState([]);
    const [ListaAulas, setListaAulas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [openDañado, setOpenDañado] = useState(false);
    const [openReportarDañado, setOpenReportarDañado] = useState(false);
    const [openDesechados, setOpenDesechados] = useState(false);
    const [openDesechar, setOpenDesechar] = useState(false);
    const [openAsignarDocente, setOpenAsignarDocente] = useState(false);
    const [openAsignarEdificio, setOpenAsignarEdificio] = useState(false);
    const [openActivosAsignadosDocente, setOpenActivosAsignadosDocente] = useState(false);
    const [openActivosAsignadosEdificio, setOpenActivosAsignadosEdificio] = useState(false);
    const [openDevolverActivoDocente, setOpenDevolverActivoDocente] = useState(false);
    const [orden, setOrden] = useState({
        columna: null,
        ascendente: true
    });

    const [filtros, setFiltros] = useState({
        placaActivo: '',
        nombreActivo: '',
        marca: '',
        modelo: '',
        estado: ''
    });


    const [orden2, setOrden2] = useState({
        columna: null,
        ascendente: true
    });

    const [filtros2, setFiltros2] = useState({
        nombreCompleto: '',
        codigoActivo: '',
        nombreActivo: '',
        marca: '',
        modelo: '',
        fechaAsignacion: ''
    });



    const fetchData = async () => {
        try {
            GetModoAdqui(setListaModos)
            GetTipoActivos(setListaTipoActivos)
            const Lista = await GetListaActivos();
            
            globalData = Lista;
            setListaActivos(Lista);


            const data = await getFuncionariosActivos(1);
            globalData2 = data
            setFuncionarios(data);

            const ActivosAsignados = await ListaActivosAsignados({ institucion: obtenerIdInstitucion(), estadoActivo: 0 })
            globalData3 = ActivosAsignados;
            setActivosAsignados(ActivosAsignados);

            const parametros = {
                institucion: obtenerIdInstitucion(),
                estado: 1
            }
            const lista = await GetListaEdificios(parametros);
            globalData4 = lista;
            setListaAulas(lista);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {

            fetchData();
       
    }, []);


    if (loading) {
        return (
            <Cargando />
        );
    }

    const OrdenarColumnas = (columna) => {
        setOrden(prevOrden => ({
            columna: columna,
            ascendente: prevOrden.columna === columna ? !prevOrden.ascendente : true
        }));
    };
    const obtenerIconoOrden = (columna) => {
        if (orden.columna === columna) {
            return orden.ascendente ? <Io.IoMdArrowDropup /> : <Io.IoMdArrowDropdown />
        }
        return null; // Retorna null si la columna no está siendo ordenada
    };

    const ordenarDatos = (datos) => {

        if (orden.columna) {
            return datos.slice().sort((a, b) => {
                const columnaA = a[orden.columna];
                const columnaB = b[orden.columna];
                if (typeof columnaA === 'string' && typeof columnaB === 'string') {
                    return orden.ascendente ? columnaA.localeCompare(columnaB) : columnaB.localeCompare(columnaA);
                } else {
                    return orden.ascendente ? columnaA - columnaB : columnaB - columnaA;
                }
            });
        } else {
            return datos;
        }
    };
    const Filtrar = (e, columna) => {
        const valor = e.target.value;
        setFiltros(prevFiltros => ({
            ...prevFiltros,
            [columna]: valor
        }));
    };
    const filtrarDatos = (datos) => {
        if (!Array.isArray(datos)) {
            console.error("Los datos proporcionados no son un arreglo.");
            return [];
        }

        return datos.filter(activo => {
            return Object.entries(filtros).every(([columna, valorFiltro]) => {
                if (valorFiltro === '') return true;
                return activo[columna].toLowerCase().includes(valorFiltro.toLowerCase());
            });
        });
    };



    const OrdenarColumnas2 = (columna) => {

        setOrden2(prevOrden => ({
            columna: columna,
            ascendente: prevOrden.columna === columna ? !prevOrden.ascendente : true
        }));
    };
    const obtenerIconoOrden2 = (columna) => {

        if (orden2.columna === columna) {
            return orden2.ascendente ? <Io.IoMdArrowDropup /> : <Io.IoMdArrowDropdown />
        }
        return null; // Retorna null si la columna no está siendo ordenada
    };

    const ordenarDatos2 = (datos) => {

        if (orden2.columna) {
            return datos.slice().sort((a, b) => {
                const columnaA = a[orden.columna];
                const columnaB = b[orden.columna];
                if (typeof columnaA === 'string' && typeof columnaB === 'string') {
                    return orden2.ascendente ? columnaA.localeCompare(columnaB) : columnaB.localeCompare(columnaA);
                } else {
                    return orden2.ascendente ? columnaA - columnaB : columnaB - columnaA;
                }
            });
        } else {
            return datos;
        }
    };
    const Filtrar2 = (e, columna) => {
        const valor = e.target.value;
        setFiltros2(prevFiltros => ({
            ...prevFiltros,
            [columna]: valor
        }));
    };
    const filtrarDatos2 = (datos) => {
        if (!Array.isArray(datos)) {
            console.error("Los datos proporcionados no son un arreglo.");
            return [];
        }

        return datos.filter(activo => {
            return Object.entries(filtros2).every(([columna, valorFiltro]) => {
                if (valorFiltro === '') return true;
                return activo[columna].toLowerCase().includes(valorFiltro.toLowerCase());
            });
        });
    };









    const toggle = () => {
        setOpen(!open);

    }

    const toggleReportarDañados = () => {
        setOpenReportarDañado(!openReportarDañado);

    }
    const toggleDañados = () => {
        setOpenDañado(!openDañado);

    }
    const toggleDesechar = () => {
        setOpenDesechar(!openDesechar);

    }
    const toggleDesechados = () => {
        setOpenDesechados(!openDesechados);

    }
    const toggleAsignarDocente = () => {
        setOpenAsignarDocente(!openAsignarDocente);
    }
    const toggleAsignarEdificio = () => {
        setOpenAsignarEdificio(!openAsignarEdificio);
    }
    const toggleActivosAsignadosDocente = () => {
        setOpenActivosAsignadosDocente(!openActivosAsignadosDocente);
    }
    const toggleDevolverActivoDocente = () => {
        setOpenDevolverActivoDocente(!openDevolverActivoDocente);
    }
    const toggleActivosAsignadosEdificio = () => {
        setOpenActivosAsignadosEdificio(!openActivosAsignadosEdificio);
    }

    const Abrirmodal = (data, Array) => {
        //alert(data)
        const ActivoSeleccioando = Array.find(activo => activo.placaActivo === data);
        setPlacaActivo(ActivoSeleccioando.placaActivo)
        setPlacaNueva(ActivoSeleccioando.placaActivo)
        setNombreActivo(ActivoSeleccioando.nombreActivo)
        setMarca(ActivoSeleccioando.marca)
        setModelo(ActivoSeleccioando.modelo)
        setDescripcion(ActivoSeleccioando.Descripcion)
        setModoadquisitivo(ActivoSeleccioando.modoadquisitivo)
        setProcedencia(ActivoSeleccioando.procedencia)
        setTipoActivo(ActivoSeleccioando.tipoActivo)
        setObservaciones(ActivoSeleccioando.observaciones)
        setOpen(!open)
    }

    const AbrirmodalReportarDañados = (data, Array) => {
        const ActivoSeleccioando = Array.find(activo => activo.placaActivo === data);
        const ActivoSeleccionado2 = ActivosAsignados.find(activo => activo.codigoActivo === data);
        if (ActivoSeleccionado2) {
            setCodigo(ActivoSeleccionado2.codigo)
            setEdificioAsignacion(ActivoSeleccionado2.edificioAsignacion)
            setPlacaActivo(ActivoSeleccioando.placaActivo)
            setNombreActivo(ActivoSeleccioando.nombreActivo)
            setMarca(ActivoSeleccioando.marca)
            setModelo(ActivoSeleccioando.modelo)
            setDescripcion(ActivoSeleccioando.Descripcion)
            setModoadquisitivo(ActivoSeleccioando.modoadquisitivo)
            setProcedencia(ActivoSeleccioando.procedencia)
            setTipoActivo(ActivoSeleccioando.tipoActivo)
            setObservaciones(ActivoSeleccioando.observaciones)
            setObservacionesNuevas('')
        } else {
            setPlacaActivo(ActivoSeleccioando.placaActivo)
            setNombreActivo(ActivoSeleccioando.nombreActivo)
            setMarca(ActivoSeleccioando.marca)
            setModelo(ActivoSeleccioando.modelo)
            setDescripcion(ActivoSeleccioando.Descripcion)
            setModoadquisitivo(ActivoSeleccioando.modoadquisitivo)
            setProcedencia(ActivoSeleccioando.procedencia)
            setTipoActivo(ActivoSeleccioando.tipoActivo)
            setObservaciones(ActivoSeleccioando.observaciones)
            setObservacionesNuevas('')
        }

        setOpenReportarDañado(!openDañado)
    }

    const AbrirmodalDañados = (data, Array) => {
        const ActivoSeleccioando = Array.find(activo => activo.placaActivo === data);
        setPlacaActivo(ActivoSeleccioando.placaActivo)
        setNombreActivo(ActivoSeleccioando.nombreActivo)
        setMarca(ActivoSeleccioando.marca)
        setModelo(ActivoSeleccioando.modelo)
        setDescripcion(ActivoSeleccioando.descripcion)
        setModoadquisitivo(ActivoSeleccioando.modoadquisitivo)
        setProcedencia(ActivoSeleccioando.procedencia)
        setTipoActivo(ActivoSeleccioando.tipoActivo)
        setObservaciones(ActivoSeleccioando.observaciones)
        setObservacionesNuevas('')
        setOpenDañado(!openDañado)
    }

    const AbrirmodalDesechar = (data, Array) => {
        const ActivoSeleccioando = Array.find(activo => activo.placaActivo === data);
        const ActivoSeleccionado2 = ActivosAsignados.find(activo => activo.codigoActivo === data);
        if (ActivoSeleccionado2) {
            setCodigo(ActivoSeleccionado2.codigo)
            setEdificioAsignacion(ActivoSeleccionado2.edificioAsignacion)
            setPlacaActivo(ActivoSeleccioando.placaActivo)
            setNombreActivo(ActivoSeleccioando.nombreActivo)
            setMarca(ActivoSeleccioando.marca)
            setModelo(ActivoSeleccioando.modelo)
            setDescripcion(ActivoSeleccioando.descripcion)
            setModoadquisitivo(ActivoSeleccioando.modoadquisitivo)
            setProcedencia(ActivoSeleccioando.procedencia)
            setTipoActivo(ActivoSeleccioando.tipoActivo)
            setObservaciones(ActivoSeleccioando.observaciones)
            setObservacionesNuevas('')
        } else {
            setPlacaActivo(ActivoSeleccioando.placaActivo)
            setNombreActivo(ActivoSeleccioando.nombreActivo)
            setMarca(ActivoSeleccioando.marca)
            setModelo(ActivoSeleccioando.modelo)
            setDescripcion(ActivoSeleccioando.descripcion)
            setModoadquisitivo(ActivoSeleccioando.modoadquisitivo)
            setProcedencia(ActivoSeleccioando.procedencia)
            setTipoActivo(ActivoSeleccioando.tipoActivo)
            setObservaciones(ActivoSeleccioando.observaciones)
            setObservacionesNuevas('')
        }

        setOpenDesechar(!openDesechar)
    }

    const AbrirmodalDesechados = (data, Array) => {
        const ActivoSeleccioando = Array.find(activo => activo.placaActivo === data);
        
        setPlacaActivo(ActivoSeleccioando.placaActivo)
        setNombreActivo(ActivoSeleccioando.nombreActivo)
        setMarca(ActivoSeleccioando.marca)
        setModelo(ActivoSeleccioando.modelo)
        setDescripcion(ActivoSeleccioando.Descripcion)
        setModoadquisitivo(ActivoSeleccioando.modoadquisitivo)
        setProcedencia(ActivoSeleccioando.procedencia)
        setTipoActivo(ActivoSeleccioando.tipoActivo)
        setObservaciones(ActivoSeleccioando.observaciones)
        setOpenDesechados(!openDañado)
    }

    const AbrirmodalAsignarDocente = (data, Array) => {
        setFechaSolicitud('')
        setFechaEntrega('')
        const ActivoSeleccioando = Array.find(activo => activo.placaActivo === data);
        setFuncionarioEntregada(obtenerUsuario())
        const FuncionarioResponsable = funcionarios.filter(funcionario => funcionario.cedulaFuncionario === obtenerUsuario())
        setPlacaActivo(ActivoSeleccioando.placaActivo)
        setNombreActivo(ActivoSeleccioando.nombreActivo)
        setMarca(ActivoSeleccioando.marca)
        setModelo(ActivoSeleccioando.modelo)
        setActivoPerteneceInstitucion(obtenerIdInstitucion())
        //alert(JSON.stringify(FuncionarioResponsable[0]));
        setNombreFuncionarioEntregada(FuncionarioResponsable[0].nombreCompleto + ' ' + FuncionarioResponsable[0].apellido1 + ' ' + FuncionarioResponsable[0].apellido2)
        setOpenAsignarDocente(!openAsignarDocente);
    }


    const AbrirmodalAsignarEdificio = (data, Array) => {
        setFechaSolicitud('')
        setFechaEntrega('')
        const ActivoSeleccioando = Array.find(activo => activo.placaActivo === data);
        setFuncionarioEntregada(obtenerUsuario())
        const FuncionarioResponsable = funcionarios.filter(funcionario => funcionario.cedulaFuncionario === obtenerUsuario())
        setPlacaActivo(ActivoSeleccioando.placaActivo)
        setNombreActivo(ActivoSeleccioando.nombreActivo)
        setMarca(ActivoSeleccioando.marca)
        setModelo(ActivoSeleccioando.modelo)
        setActivoPerteneceInstitucion(obtenerIdInstitucion())
        //alert(JSON.stringify(FuncionarioResponsable[0]));
        setNombreFuncionarioEntregada(FuncionarioResponsable[0].nombreCompleto + ' ' + FuncionarioResponsable[0].apellido1 + ' ' + FuncionarioResponsable[0].apellido2)
        setOpenAsignarEdificio(!openAsignarEdificio);
    }

    const AbrirmodalActivosAsignadosDocente = (data, Array) => {
        const ActivoSeleccioando = Array.find(activo => activo.codigoActivo === data);
        const FuncionarioResponsable = funcionarios.filter(funcionario => funcionario.cedulaFuncionario === ActivoSeleccioando.funcionarioEntrega)
        const estadoActivo = ListaActivos.filter(Lactivo => Lactivo.placaActivo === ActivoSeleccioando.codigoActivo)
        setCodigo(ActivoSeleccioando.codigo)
        setCedulaFuncionario(ActivoSeleccioando.cedulaFuncionario)
        setNombreFuncionario(ActivoSeleccioando.nombreCompleto)
        setapellido1(ActivoSeleccioando.apellido1)
        setapellido2(ActivoSeleccioando.apellido2)
        setEdificioAsignacion(ActivoSeleccioando.edificioAsignacion)
        setNombreEdificio(ActivoSeleccioando.nombreEdificio)
        setPlacaActivo(ActivoSeleccioando.codigoActivo)
        setNombreActivo(ActivoSeleccioando.nombreActivo)
        setMarca(ActivoSeleccioando.marca)
        setModelo(ActivoSeleccioando.modelo)
        setFechaSolicitud(ActivoSeleccioando.fechaAsignacion)
        setFechaEntrega(ActivoSeleccioando.fechaEntrega)
        setFuncionarioEntregada(ActivoSeleccioando.funcionarioEntrega)
        setNombreFuncionarioEntregada(FuncionarioResponsable[0].nombreCompleto + ' ' + FuncionarioResponsable[0].apellido1 + ' ' + FuncionarioResponsable[0].apellido2)
        setObservaciones(estadoActivo[0].observaciones)

        setOpenActivosAsignadosDocente(!openActivosAsignadosDocente);

    }

    const AbrirmodalDevolverActivo = (data, Array) => {
        const ActivoSeleccioando = Array.find(activo => activo.codigoActivo === data);
        //alert(JSON.stringify(ActivoSeleccioando));
        const FuncionarioResponsable = funcionarios.filter(funcionario => funcionario.cedulaFuncionario === ActivoSeleccioando.funcionarioEntrega)
        const estadoActivo = ListaActivos.filter(Lactivo => Lactivo.placaActivo === ActivoSeleccioando.codigoActivo)

        setCodigo(ActivoSeleccioando.codigo)
        setCedulaFuncionario(ActivoSeleccioando.cedulaFuncionario)
        setNombreFuncionario(ActivoSeleccioando.nombreCompleto)
        setapellido1(ActivoSeleccioando.apellido1)
        setapellido2(ActivoSeleccioando.apellido2)
        setEdificioAsignacion(ActivoSeleccioando.edificioAsignacion)
        setNombreEdificio(ActivoSeleccioando.nombreEdificio)
        setPlacaActivo(ActivoSeleccioando.codigoActivo)
        setNombreActivo(ActivoSeleccioando.nombreActivo)
        setMarca(ActivoSeleccioando.marca)
        setModelo(ActivoSeleccioando.modelo)
        setFechaSolicitud(ActivoSeleccioando.fechaAsignacion)
        setFechaEntrega(ActivoSeleccioando.fechaEntrega)
        setFuncionarioEntregada(ActivoSeleccioando.funcionarioEntrega)
        setNombreFuncionarioEntregada(FuncionarioResponsable[0].nombreCompleto + ' ' + FuncionarioResponsable[0].apellido1 + ' ' + FuncionarioResponsable[0].apellido2)
        setObservaciones(estadoActivo[0].observaciones)

        setOpenDevolverActivoDocente(!openDevolverActivoDocente);

    }


    const SeleccionarDato = (e) => {
        const value = e.target.value;
        setModoadquisitivo(value);

    }

    const Actualizar = async (e, placa, estado) => {
        e.preventDefault();




        const parametros = {
            placaActivo: placa,
            nombreActivo: nombreActivo,
            estadoActivo: estado,  // 1 para Bueno, 2 para Malo, 0 para No existe
            descripcion: descripcion,
            activoPerteneceInstitucion: CodigoPresupuestario,
            marca: marca,
            modelo: modelo,
            modoadquisitivo: modoadquisitivo,
            procedencia: procedencia,
            tipoActivo: tipoActivo,
            observaciones: observaciones,
            placaNueva: placaNueva
        }

        const success = await ActualizarActivo(parametros)

        if (success) {
            setPlacaActivo('');
            setNombreActivo('');
            setDescripcion('');
            setMarca('');
            setModelo('');
            setModoadquisitivo(0);
            setProcedencia('');
            setEstadoActivo(1);


            const select = document.getElementById('ModoAdquisitivo');
            const select2 = document.getElementById('TipoActivo');
            select.selectedIndex = 0;
            select2.selectedIndex = 0;
            fetchData();
            toggle();
            SuccessMessage('Registro Existoso', 'El activo a sido actualizado')
        } else {
            ErrorMessage('Notificación del sistema', 'Ocurrio un error al actualizar el Activo')
            return
        }

    }

    const ReportarDaño = (event, placa, estado) => {
        event.preventDefault();
        var fecha = new Date().toISOString().slice(0, 10).replace(/-/g, "/");
        let observacion = '';
        if (observaciones.length === 0 || observaciones === '') {
            observacion = "➔ " + observaciones + observacionesNuevas + " => fecha de reporte " + fecha;
        } else {
            observacion = observaciones + ".\n➔ " + observacionesNuevas + " => fecha de reporte " + fecha;
        }

        Swal.fire({
            title: "Estas seguro de reportar este activo como dañado?",
            text: "¡ Podras restaurarla desde la opción Activos Dañados ¡",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, Reportar!",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const ActivoSeleccionado2 = ActivosAsignados.find(activo => activo.codigoActivo === placa);

                if (ActivoSeleccionado2) {
                    const datos = {
                        placaActivo: placa,
                        nuevoEstado: estado,
                        nuevaObservacion: observacion
                    }
                    const parametros = {
                        codigoAsignacion: codigo,
                        edificioAsignacion: edificioAsignacion,
                        fechaAsignacion: fechaSolicitud.substring(0, 10),
                        fechaEntrega: fecha,
                        funcionarioEntrega: FuncionarioEntregada
                    }
                    const parametros2 = {
                        codigoAsignacion: codigo,
                        estadoEntrega: 1
                    }

                    const Success2 = await ActualizarActivoAsignadoProfesor(parametros)
                    const Success3 = await DevolverActivo(parametros2)
                    const success = await CambiarEstadoActivos(datos);

                    if (success && Success2 && Success3) {
                        Swal.fire({
                            title: "¡Reportado!",
                            text: "El activo a sido reportado como dañado, ",
                            icon: "success",
                            timer: 1500, // Tiempo en milisegundos (en este caso, 3 segundos)
                            timerProgressBar: true, // Barra de progreso de tiempo
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                        toggleReportarDañados();
                        fetchData();
                    } else {
                        ErrorMessage('Notificación del Sistema', success.mensaje);
                        return;

                    }
                } else {
                    const datos = {
                        placaActivo: placa,
                        nuevoEstado: estado,
                        nuevaObservacion: observacion
                    }
                    const success = await CambiarEstadoActivos(datos);

                    if (success) {
                        Swal.fire({
                            title: "¡Reportado!",
                            text: "El activo a sido reportado como dañado, ",
                            icon: "success",
                            timer: 1500, // Tiempo en milisegundos (en este caso, 3 segundos)
                            timerProgressBar: true, // Barra de progreso de tiempo
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                        toggleReportarDañados();
                        fetchData();
                    } else {
                        ErrorMessage('Notificación del Sistema', success.mensaje);
                        return;

                    }
                }



            }
        });

    }

    const RestauraActivo = (event, placa, estado) => {
        event.preventDefault();
        var fecha = new Date().toISOString().slice(0, 10).replace(/-/g, "/");
        const datos = {
            placaActivo: placa,
            nuevoEstado: estado,
            nuevaObservacion: observaciones + ".\n➔ " + observacionesNuevas + " => fecha de restauración " + fecha
        }
        Swal.fire({
            title: "Estas seguro de restaurar este activo?",
            text: "¡ Podras verlo  desde la opción Lista Activos  ¡",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, Restaurar!",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {

                // const success = await RestoreSeccion(data);
                const success = await CambiarEstadoActivos(datos);
                if (success) {
                    Swal.fire({
                        title: "¡Reportado!",
                        text: "El activo a sido restaurado, ",
                        icon: "success",
                        timer: 1500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false,
                        allowOutsideClick: false,
                    });
                    toggleDañados();
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });

    }




    const DesecharActivo = async (event, placa, estado) => {

        event.preventDefault();



        Swal.fire({
            title: "Estas seguro que deseas desechar este activo?",
            text: " El activo ya NO estara disponible para ser asignado ni podra restaurarse?",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, Desechar!",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                var fecha = new Date().toISOString().slice(0, 10).replace(/-/g, "/");
                const ActivoSeleccionado2 = ActivosAsignados.find(activo => activo.codigoActivo === placa);

                if (ActivoSeleccionado2) {
                    const datos = {
                        placaActivo: placa,
                        nuevoEstado: estado,
                        nuevaObservacion: observaciones + ", " + observacionesNuevas
                    }
                    const parametros = {
                        codigoAsignacion: codigo,
                        edificioAsignacion: edificioAsignacion,
                        fechaAsignacion: fechaSolicitud.substring(0, 10),
                        fechaEntrega: fecha,
                        funcionarioEntrega: FuncionarioEntregada
                    }
                    const parametros2 = {
                        codigoAsignacion: codigo,
                        estadoEntrega: 1
                    }

                    const Success2 = await ActualizarActivoAsignadoProfesor(parametros)
                    const Success3 = await DevolverActivo(parametros2)
                    const success = await CambiarEstadoActivos(datos);

                    if (success && Success2 && Success3) {
                        Swal.fire({
                            title: "¡Reportado!",
                            text: "El activo a sido reportado como dañado, ",
                            icon: "success",
                            timer: 1500, // Tiempo en milisegundos (en este caso, 3 segundos)
                            timerProgressBar: true, // Barra de progreso de tiempo
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                        toggleDesechar();
                        fetchData();
                    } else {
                        ErrorMessage('Notificación del Sistema', success.mensaje);
                        return;

                    }
                } else {
                    const datos = {
                        placaActivo: placa,
                        nuevoEstado: estado,
                        nuevaObservacion: observaciones + ", " + observacionesNuevas
                    }
                    const success = await CambiarEstadoActivos(datos);

                    if (success) {
                        Swal.fire({
                            title: "¡Reportado!",
                            text: "El activo a sido reportado como dañado, ",
                            icon: "success",
                            timer: 1500, // Tiempo en milisegundos (en este caso, 3 segundos)
                            timerProgressBar: true, // Barra de progreso de tiempo
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                        toggleDesechar();
                        fetchData();
                    } else {
                        ErrorMessage('Notificación del Sistema', success.mensaje);
                        return;

                    }
                }

            }
        });
    };

    const AsignarActivoDocente = async (event) => {
        event.preventDefault();
        if (Seleccionadas.length > 0) {
            const parametros = {
                cedulaFuncAsignar: Seleccionadas,
                edificioAsignacion: 1,
                codigoActivo: placaActivo,
                fechaAsignacion: fechaSolicitud,
                fechaEntrega: fechaEntrega,
                institucionAsigna: activoPerteneceInstitucion,
                funcionarioEntrega: FuncionarioEntregada
            }

            const Success = await AsignarActivo(parametros)
            if (Success) {

                fetchData();
                toggleAsignarDocente()
                setPlacaActivo('')
                setNombreActivo('')
                setMarca('')
                setModelo('')
                setActivoPerteneceInstitucion('')
                setFechaSolicitud('')
                setFechaEntrega('')
                setNombreFuncionarioEntregada('')
                SuccessMessage('Existoso', 'Activo asignado correctamente')
            } else {
                ErrorMessage('Notificación del Sistema', 'No se pudo asignar el activo')
            }
        }
        else {
            ErrorMessage('Notificación del Sistema', 'Debe seleccionar un funcionario')
        }
    }

    const AsignarActivoEdificio = async (event) => {
        event.preventDefault();
        if (Seleccionadas.length > 0) {
            const parametros = {
                cedulaFuncAsignar: Seleccionadas,
                edificioAsignacion: parseInt(SeleccionadasEdificio),
                codigoActivo: placaActivo,
                fechaAsignacion: fechaSolicitud,
                fechaEntrega: fechaEntrega,
                institucionAsigna: activoPerteneceInstitucion,
                funcionarioEntrega: FuncionarioEntregada
            }

            const Success = await AsignarActivo(parametros)
            if (Success) {

                fetchData();
                toggleAsignarEdificio()
                setPlacaActivo('')
                setNombreActivo('')
                setMarca('')
                setModelo('')
                setActivoPerteneceInstitucion('')
                setFechaSolicitud('')
                setFechaEntrega('')
                setNombreFuncionarioEntregada('')
                SuccessMessage('Existoso', 'Activo asignado correctamente')
            } else {
                ErrorMessage('Notificación del Sistema', 'No se pudo asignar el activo')
            }
        }
        else {
            ErrorMessage('Notificación del Sistema', 'Debe seleccionar un funcionario')
        }
    }

    const DevolverActivoDocente = async (event) => {
        event.preventDefault();

        const parametros = {
            codigoAsignacion: codigo,
            edificioAsignacion: 1,
            fechaAsignacion: fechaSolicitud.substring(0, 10),
            fechaEntrega: fechaEntrega.substring(0, 10),
            funcionarioEntrega: FuncionarioEntregada
        }
        const parametros2 = {
            codigoAsignacion: codigo,
            estadoEntrega: 1
        }

        const Success = await ActualizarActivoAsignadoProfesor(parametros)
        const Success2 = await DevolverActivo(parametros2)
        if (Success && Success2) {
            fetchData();
            toggleDevolverActivoDocente();
            SuccessMessage('Exitoso', 'Activo retornado correctamente')
        } else {
            ErrorMessage('Notificación del Sistema', 'No se pudo devolver el activo')
            return;
        }


    }


    const IrDescargar = (estado, nombre) => {
        if (ListaActivos && ListaActivos.length > 0) {
            const lista = ListaActivos.filter(listaActivos => listaActivos.estadoActivo === estado)
            Descargar(lista, nombre)
        } else {
            ErrorMessage('Notificación del Sistema', 'No hay activos para descargar')
        }
    }
    const IrDescargarAsignados = (estado, nombre) => {
        if (ActivosAsignados && ActivosAsignados.length > 0) {
            const lista = ActivosAsignados.filter(listaActivos => listaActivos.estadoEntrega === estado)
            Descargar(lista, nombre)
        } else {
            ErrorMessage('Notificación del Sistema', 'No hay activos para descargar')
        }
    }

    const Seleccion = (e) => {
        const value = e.target.value;
        //alert(value);
        setSeleccionadas('');

        setSeleccionadas(value);




    };
    const SeleccionEdificio = (e) => {
        const value = e.target.value;
        //alert(value);
        setSeleccionadasEdificio('');

        setSeleccionadasEdificio(value);




    };






    return (
        <Container fluid className=" Divcontenedor ">

            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Activos"
                        to="/Menu/FrmActivos"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='p-0 Mymt-5'>
                <Col className='mb-2 pb-2 border-bottom'>
                    <Button className='p-0  btn btnAceptar    '>
                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Fa6.FaCirclePlus className="me-1 " size={18} />}
                                text="Activos "
                                to="/Menu/FrmRegistrarActivos" // aca agregar la ruta
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                </Col>
            </Container>

            <Container fluid className='  px-0  ' >

                <Tabs className={'p-0 m-0  border  '}>

                    <TabList className={'tabs_ '} >
                        <Tab>Activos</Tab>
                        <Tab>Dañados</Tab>
                        <Tab>Desechados</Tab>
                        <Tab>Asig. a Docentes</Tab>
                        <Tab>Asig. a Edificio</Tab>

                    </TabList>
                    <Container fluid className=' shadow2 px-0 cont rounded-0' >
                        <TabPanel   >

                            <Table responsive size="lg">
                                <thead className='border-bottom'>
                                    <tr>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('placaActivo')}>  Placa Activo {obtenerIconoOrden('placaActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('nombreActivo')}>Nombre Activo {obtenerIconoOrden('nombreActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('marca')}>Marca {obtenerIconoOrden('marca')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('modelo')} >Modelo {obtenerIconoOrden('modelo')}</a>
                                        </th>

                                        <th colSpan={5} className='pb-0'>
                                            <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' onClick={() => IrDescargar('1', 'Lista activos')} />
                                        </th>

                                    </tr>

                                    <tr className='bg-tr'>

                                        <th className='p-0 px-2 pb-1   '>
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.placaActivo}
                                                    onChange={(e) => Filtrar(e, 'placaActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.nombreActivo}
                                                    onChange={(e) => Filtrar(e, 'nombreActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.marca}
                                                    onChange={(e) => Filtrar(e, 'marca')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1' >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.modelo}
                                                    onChange={(e) => Filtrar(e, 'modelo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th colSpan={5} className='p-0 px-2 pb-1' > </th>
                                    </tr>
                                </thead>
                                <tbody className='tablebody' id='tablebody' >
                                    {ordenarDatos(filtrarDatos(ListaActivos)).filter(activo => activo.estadoActivo === '1').length > 0 ? (
                                        ordenarDatos(filtrarDatos(ListaActivos)).filter(activo => activo.estadoActivo === '1').map((activo, index) => {
                                            // Verificar si el activo está asignado
                                            const estaAsignado = Array.isArray(ActivosAsignados) && ActivosAsignados.some(item => item.codigoActivo === activo.placaActivo);
                                            return (
                                                <tr className="table-p" key={index}>
                                                    <td>{activo.placaActivo}</td>
                                                    <td>{activo.nombreActivo}</td>
                                                    <td>{activo.marca}</td>
                                                    <td>{activo.modelo}</td>

                                                    <td><Fa.FaEye title='Ver mas...' className='mb-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => Abrirmodal(activo.placaActivo, ListaActivos)} /></td>
                                                    <td><Fa.FaTimesCircle title='Reportar como dañado' className='mb-1  eli2' cursor={'pointer'} color='#314843' size={16} onClick={(e) => AbrirmodalReportarDañados(activo.placaActivo, ListaActivos)} /></td>
                                                    {!estaAsignado && ( // Mostrar solo si no está asignado
                                                        <>
                                                            <td><Fa.FaUserCheck title='Asignar a Funcionario' className='mb-1  Asignar' cursor={'pointer'} color='#314843' size={18} onClick={(e) => AbrirmodalAsignarDocente(activo.placaActivo, ListaActivos)} /></td>
                                                            <td><Fa6.FaHouseCircleCheck title='Asignar a Edificio' className='mb-1  Asignar' cursor={'pointer'} color='#314843' size={18} onClick={(e) => AbrirmodalAsignarEdificio(activo.placaActivo, ListaActivos)} /></td>
                                                        </>
                                                    )}
                                                    {estaAsignado && (
                                                        <>
                                                            <td colSpan="2"></td> {/* Añadir dos <td> vacíos */}
                                                        </>
                                                    )}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No hay activos ingresados en el sistema.</td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>

                        </TabPanel>

                        <TabPanel  >
                            <Table responsive size="sm" >
                                <thead className='border-bottom'>
                                    <tr>

                                        <th className='pb-0 '>
                                            <a className='cursor' onClick={() => OrdenarColumnas('placaActivo')}>  Placa Activo {obtenerIconoOrden('placaActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('nombreActivo')}>Nombre Activo {obtenerIconoOrden('nombreActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('marca')}>Marca {obtenerIconoOrden('marca')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('modelo')} >Modelo {obtenerIconoOrden('modelo')}</a>
                                        </th>
                                        <th colSpan={2} className='pb-0'>
                                            <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' onClick={() => IrDescargar('2', 'Lista activos Dañados')} />
                                        </th>
                                    </tr>

                                    <tr className='bg-tr'>

                                        <th className='p-0 px-2 pb-1   '>
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1 "
                                                    value={filtros.placaActivo}
                                                    onChange={(e) => Filtrar(e, 'placaActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.nombreActivo}
                                                    onChange={(e) => Filtrar(e, 'nombreActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.marca}
                                                    onChange={(e) => Filtrar(e, 'marca')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1' >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.modelo}
                                                    onChange={(e) => Filtrar(e, 'modelo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th colSpan={2} className='p-0 px-2 pb-1' >  </th>
                                    </tr>
                                </thead>
                                <tbody className='tablebody' id='tablebody' >
                                    {ordenarDatos(filtrarDatos(ListaActivos)).filter(activo => activo.estadoActivo === '2').length > 0 ? (
                                        ordenarDatos(filtrarDatos(ListaActivos)).filter(activo => activo.estadoActivo === '2').map((activo, index) => (

                                            <tr className="table-p" key={index}>
                                                <td >{activo.placaActivo}</td>
                                                <td  >{activo.nombreActivo}</td>
                                                <td  >{activo.marca}</td>
                                                <td  >{activo.modelo}</td>
                                                <td>
                                                    <Fa.FaEye className='mb-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => AbrirmodalDañados(activo.placaActivo, ListaActivos)} />
                                                </td>
                                                <td className=''>
                                                    <Md.MdDelete className='mb-1 eli2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => AbrirmodalDesechar(activo.placaActivo, ListaActivos)} />
                                                </td>
                                            </tr>

                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No hay activos reportados como dañados en el sistema.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </TabPanel>

                        <TabPanel  >
                            <Table responsive size="sm">
                                <thead className='border-bottom'>
                                    <tr>

                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('placaActivo')}>  Placa Activo {obtenerIconoOrden('placaActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('nombreActivo')}>Nombre Activo {obtenerIconoOrden('nombreActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('marca')}>Marca {obtenerIconoOrden('marca')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas('modelo')} >Modelo {obtenerIconoOrden('modelo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' onClick={() => IrDescargar('3', 'Lista activos desechados')} />
                                        </th>
                                    </tr>

                                    <tr className='bg-tr'>

                                        <th className='p-0 px-2 pb-1   '>
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.placaActivo}
                                                    onChange={(e) => Filtrar(e, 'placaActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.nombreActivo}
                                                    onChange={(e) => Filtrar(e, 'nombreActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.marca}
                                                    onChange={(e) => Filtrar(e, 'marca')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1' >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.modelo}
                                                    onChange={(e) => Filtrar(e, 'modelo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1' ></th>
                                    </tr>
                                </thead>
                                <tbody className='tablebody' id='tablebody' >
                                    {ordenarDatos(filtrarDatos(ListaActivos)).filter(activo => activo.estadoActivo === '3').length > 0 ? (
                                        ordenarDatos(filtrarDatos(ListaActivos)).filter(activo => activo.estadoActivo === '3').map((activo, index) => (

                                            <tr className="table-p" key={index}>
                                                <td>{activo.placaActivo}</td>
                                                <td>{activo.nombreActivo}</td>
                                                <td>{activo.marca}</td>
                                                <td>{activo.modelo}</td>
                                                <td>
                                                    <Fa.FaEye className='mb-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => AbrirmodalDesechados(activo.placaActivo, ListaActivos)} />
                                                </td>

                                            </tr>

                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No hay activos desechados en el sistema.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </TabPanel>

                        <TabPanel>
                            <Table responsive size="sm" >
                                <thead className='border-bottom'>
                                    <tr>

                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('nombreCompleto')}>  Docente / Funcionario {obtenerIconoOrden2('nombreCompleto')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('codigoActivo')}>Codigo Activo {obtenerIconoOrden2('codigoActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('nombreActivo')}>Activo {obtenerIconoOrden2('nombreActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('fechaAsignacion')} >Fecha de Asignación{obtenerIconoOrden2('fechaAsignacion')}</a>
                                        </th>
                                        <th colSpan={2} className='pb-0'>
                                            <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' onClick={() => IrDescargarAsignados(0, 'Lista activos Asignados')} />
                                        </th>
                                    </tr>

                                    <tr className='bg-tr'>

                                        <th className='p-0 px-2 pb-1   '>
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.placaActivo}
                                                    onChange={(e) => Filtrar2(e, 'nombreCompleto')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.codigoActivo}
                                                    onChange={(e) => Filtrar2(e, 'codigoActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.nombreActivo}
                                                    onChange={(e) => Filtrar2(e, 'nombreActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1' >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.fechaAsignacion}
                                                    onChange={(e) => Filtrar2(e, 'fechaAsignacion')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>

                                    </tr>
                                </thead>
                                <tbody className='tablebody' id='tablebody' >
                                    {ordenarDatos2(filtrarDatos2(ActivosAsignados)).filter(activo => activo.estadoEntrega === 0 && activo.edificioAsignacion === 1).length > 0 ? (
                                        ordenarDatos2(filtrarDatos2(ActivosAsignados)).filter(activo => activo.estadoEntrega === 0 && activo.edificioAsignacion === 1).map((activo, index) => {
                                            // Verificar si el activo está asignado
                                            let nombreCompleto = activo.nombreCompleto + ' ' + activo.apellido1 + ' ' + activo.apellido2
                                            let fechaAsignacion = activo.fechaAsignacion.substring(0, 10)
                                            return (
                                                <tr className="table-p" key={index}>
                                                    <td>{nombreCompleto}</td>
                                                    <td>{activo.codigoActivo}</td>
                                                    <td>{activo.nombreActivo}</td>
                                                    <td>{fechaAsignacion}</td>
                                                    <td >
                                                        <Fa.FaEye title='Ver mas...' className='mb-1 mx-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => AbrirmodalActivosAsignadosDocente(activo.codigoActivo, ActivosAsignados)} />
                                                    </td>
                                                    <td>
                                                        <Fa.FaUndo title='Devolver activo' className='mb-1 mx-1 Asignar' cursor={'pointer'} color='#314843' size={15} onClick={(e) => AbrirmodalDevolverActivo(activo.codigoActivo, ActivosAsignados)} />
                                                    </td>

                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No hay activos asignados en el sistema para mostrar.</td>
                                        </tr>
                                    )}



                                </tbody>
                            </Table>
                        </TabPanel>

                        <TabPanel>
                            <Table responsive size="sm"  >
                                <thead className='border-bottom'>
                                    <tr>

                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('nombreCompleto')}>  Docente / Funcionario {obtenerIconoOrden2('nombreCompleto')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('codigoActivo')}>Codigo Activo {obtenerIconoOrden2('codigoActivo')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('nombreActivo')}>Activo {obtenerIconoOrden2('nombreActivo')}</a>
                                        </th>

                                        <th className='pb-0'>
                                            <a className='cursor' onClick={() => OrdenarColumnas2('fechaAsignacion')} >Fecha de Asignación{obtenerIconoOrden2('fechaAsignacion')}</a>
                                        </th>
                                        <th colSpan={2} className='pb-0'>
                                            <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' onClick={() => IrDescargarAsignados(0, 'Lista activos Asignados')} />
                                        </th>
                                    </tr>

                                    <tr className='bg-tr'>

                                        <th className='p-0 px-2 pb-1   '>
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.placaActivo}
                                                    onChange={(e) => Filtrar2(e, 'nombreCompleto')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.codigoActivo}
                                                    onChange={(e) => Filtrar2(e, 'codigoActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.nombreActivo}
                                                    onChange={(e) => Filtrar2(e, 'nombreActivo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>


                                        <th className='p-0 px-2 pb-1' >
                                            <Col className="input-with-icon   col-12">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros2.fechaAsignacion}
                                                    onChange={(e) => Filtrar2(e, 'fechaAsignacion')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>

                                    </tr>
                                </thead>
                                <tbody className='tablebody' id='tablebody' >
                                    {ordenarDatos2(filtrarDatos2(ActivosAsignados)).filter(activo => activo.estadoEntrega === 0 && activo.edificioAsignacion !== 1).length > 0 ? (
                                        ordenarDatos2(filtrarDatos2(ActivosAsignados)).filter(activo => activo.estadoEntrega === 0 && activo.edificioAsignacion !== 1).map((activo, index) => {
                                            // Verificar si el activo está asignado
                                            let nombreCompleto = activo.nombreCompleto + ' ' + activo.apellido1 + ' ' + activo.apellido2
                                            let fechaAsignacion = activo.fechaAsignacion.substring(0, 10)
                                            return (
                                                <tr className="table-p" key={index}>
                                                    <td>{nombreCompleto}</td>
                                                    <td>{activo.codigoActivo}</td>
                                                    <td>{activo.nombreActivo}</td>
                                                    <td>{fechaAsignacion}</td>
                                                    <td >
                                                        <Fa.FaEye title='Ver mas...' className='mb-1 mx-1 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => AbrirmodalActivosAsignadosDocente(activo.codigoActivo, ActivosAsignados)} />
                                                    </td>
                                                    <td >
                                                        <Fa.FaUndo title='Devolver activo' className='mb-1 mx-1 Asignar' cursor={'pointer'} color='#314843' size={15} onClick={(e) => AbrirmodalDevolverActivo(activo.codigoActivo, ActivosAsignados)} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No hay activos asignados en el sistema para mostrar.</td>

                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </TabPanel>

                    </Container>
                </Tabs>

            </Container>

            <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<Io.IoMdReturnLeft className="me-1" />}
                        text="Volver"
                        to="/Menu"
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>

            <Modal isOpen={open} size='lg' fullscreen='lg' fade className='mt-2 shadow2 ' backdrop="static">
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Actualizar Activo  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow2 border rounded  ' id='institucion'  >
                        <Form id='form1' onSubmit={(e) => Actualizar(e, placaActivo, 1)}>
                            <Row >
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Institución
                                        </Label>
                                        <Input
                                            readOnly
                                            type="text"
                                            name="cod.Presupuestario"
                                            id="cod.Presupuestario"
                                            placeholder="Código Presupuestario"
                                            value={CodigoNombre}
                                            className='py-1'
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="TipoActivo" className="me-2">
                                            Tipo Activo
                                        </Label>
                                        <Input
                                            required
                                            className={`py-1 ${errorTipoActivo ? 'is-invalid' : ''}`}
                                            type="select"
                                            name="TipoActivo"
                                            id="TipoActivo"
                                            defaultValue={tipoActivo}
                                            onChange={(e) => setTipoActivo(e.target.value)}
                                        >
                                            <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                            {ListaTipoActivos.map((tipo, i) => (
                                                <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa Activo
                                        </Label>
                                        <Input
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaNueva}
                                            onChange={(e) => setPlacaNueva(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Nombre Activo
                                        </Label>
                                        <Input
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Descripción
                                        </Label>
                                        <Input
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="ModoAdquisitivo" className="me-2">
                                            Modo Adquisitvo
                                        </Label>
                                        <Input
                                            className={`py-1 ${errorModoadquisitivo ? 'is-invalid' : ''}`}
                                            required
                                            type="select"
                                            name="ModoAdquisitivo"
                                            id="ModoAdquisitivo"
                                            defaultValue={modoadquisitivo}
                                            onChange={(e) => SeleccionarDato(e)}
                                        >
                                            <option value={0} disabled selected> Selec. modo adquisitivo</option>
                                            {ListaModos.map((modo, i) => (
                                                <option key={modo.id} value={modo.id}  >{modo.nombre}</option>
                                            ))}
                                        </Input>
                                        {errorModoadquisitivo && <div className="invalid-feedback">Por favor, seleccione un modo adquisitivo.</div>}
                                    </FormGroup>

                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Origen de Adquisición
                                        </Label>
                                        <Input
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={procedencia}
                                            onChange={(e) => setProcedencia(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Historial de daños y reparaciones del Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggle} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Actualizar Activo
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={openReportarDañado} size='lg' fullscreen='lg' fade className='mt-1 shadow2 ' backdrop="static">
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Reportar activo dañado  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow2 border rounded  ' id='institucion'  >
                        <Form id='form2' onSubmit={(e) => ReportarDaño(e, placaActivo, 2)}>
                            <Row >
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Institución
                                        </Label>
                                        <Input
                                            readOnly
                                            disabled
                                            type="text"
                                            name="cod.Presupuestario"
                                            id="cod.Presupuestario"
                                            placeholder="Código Presupuestario"
                                            value={CodigoNombre}
                                            className='py-1'
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="TipoActivo" className="me-2">
                                            Tipo Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className={`py-1 ${errorTipoActivo ? 'is-invalid' : ''}`}
                                            type="select"
                                            name="TipoActivo"
                                            id="TipoActivo"
                                            defaultValue={tipoActivo}
                                            onChange={(e) => setTipoActivo(e.target.value)}
                                        >
                                            <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                            {ListaTipoActivos.map((tipo, i) => (
                                                <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaActivo}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Nombre Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Descripción
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="ModoAdquisitivo" className="me-2">
                                            Modo Adquisitvo
                                        </Label>
                                        <Input
                                            disabled
                                            className={`py-1 ${errorModoadquisitivo ? 'is-invalid' : ''}`}
                                            required
                                            type="select"
                                            name="ModoAdquisitivo"
                                            id="ModoAdquisitivo"
                                            defaultValue={modoadquisitivo}
                                            onChange={(e) => SeleccionarDato(e)}
                                        >
                                            <option value={0} disabled selected> Selec. modo adquisitivo</option>
                                            {ListaModos.map((modo, i) => (
                                                <option key={modo.id} value={modo.id}  >{modo.nombre}</option>
                                            ))}
                                        </Input>
                                        {errorModoadquisitivo && <div className="invalid-feedback">Por favor, seleccione un modo adquisitivo.</div>}
                                    </FormGroup>

                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Origen de Adquisición
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={procedencia}
                                            onChange={(e) => setProcedencia(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Historial de Observaciones
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={7}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Nueva observacion de Daño
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observacionesNuevas}
                                            onChange={(e) => setObservacionesNuevas(e.target.value)}
                                        />
                                        <span className='LetraPeq text-danger'> * Ingresa el daño que posee el activo por el cual se esta dando como dañado </span>
                                    </FormGroup>

                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-1'>
                                <Button type="button" onClick={toggleReportarDañados} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Reportar Activo
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={openDañado} size='lg' fullscreen='lg' fade className='mt-1 shadow2 ' backdrop="static">
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Activo Dañado  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow2 border rounded  ' id='institucion'  >
                        <Form id='form1' onSubmit={(e) => RestauraActivo(e, placaActivo, 1)}>
                            <Row >
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Institución
                                        </Label>
                                        <Input
                                            readOnly
                                            disabled
                                            type="text"
                                            name="cod.Presupuestario"
                                            id="cod.Presupuestario"
                                            placeholder="Código Presupuestario"
                                            value={CodigoNombre}
                                            className='py-1'
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="TipoActivo" className="me-2">
                                            Tipo Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className={`py-1 ${errorTipoActivo ? 'is-invalid' : ''}`}
                                            type="select"
                                            name="TipoActivo"
                                            id="TipoActivo"
                                            defaultValue={tipoActivo}
                                            onChange={(e) => setTipoActivo(e.target.value)}
                                        >
                                            <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                            {ListaTipoActivos.map((tipo, i) => (
                                                <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaActivo}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Nombre Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Descripción
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="ModoAdquisitivo" className="me-2">
                                            Modo Adquisitvo
                                        </Label>
                                        <Input
                                            disabled
                                            className={`py-1 ${errorModoadquisitivo ? 'is-invalid' : ''}`}
                                            required
                                            type="select"
                                            name="ModoAdquisitivo"
                                            id="ModoAdquisitivo"
                                            defaultValue={modoadquisitivo}
                                            onChange={(e) => SeleccionarDato(e)}
                                        >
                                            <option value={0} disabled selected> Selec. modo adquisitivo</option>
                                            {ListaModos.map((modo, i) => (
                                                <option key={modo.id} value={modo.id}  >{modo.nombre}</option>
                                            ))}
                                        </Input>
                                        {errorModoadquisitivo && <div className="invalid-feedback">Por favor, seleccione un modo adquisitivo.</div>}
                                    </FormGroup>

                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Procedencia
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={procedencia}
                                            onChange={(e) => setProcedencia(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Historial de Observaciones
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={7}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Nueva observacion de Daño
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observacionesNuevas}
                                            onChange={(e) => setObservacionesNuevas(e.target.value)}
                                        />
                                        <span className='LetraPeq text-danger'> * Ingresa el daño que posee el activo por el cual se esta dando como dañado </span>
                                    </FormGroup>

                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-1'>
                                <Button type="button" onClick={toggleDañados} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Restaurar Activo
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={openDesechar} size='lg' fullscreen='lg' fade className='mt-1 shadow2 ' backdrop="static">
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >   Desechar activo dañado  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow2 border rounded  ' id='institucion'  >
                        <Form id='form1' onSubmit={(e) => DesecharActivo(e, placaActivo, 3)} >
                            <Row >
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Institución
                                        </Label>
                                        <Input
                                            readOnly
                                            disabled
                                            type="text"
                                            name="cod.Presupuestario"
                                            id="cod.Presupuestario"
                                            placeholder="Código Presupuestario"
                                            value={CodigoNombre}
                                            className='py-1'
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="TipoActivo" className="me-2">
                                            Tipo Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className={`py-1 ${errorTipoActivo ? 'is-invalid' : ''}`}
                                            type="select"
                                            name="TipoActivo"
                                            id="TipoActivo"
                                            defaultValue={tipoActivo}
                                            onChange={(e) => setTipoActivo(e.target.value)}
                                        >
                                            <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                            {ListaTipoActivos.map((tipo, i) => (
                                                <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaActivo}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Nombre Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Descripción
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="ModoAdquisitivo" className="me-2">
                                            Modo Adquisitvo
                                        </Label>
                                        <Input
                                            disabled
                                            className={`py-1 ${errorModoadquisitivo ? 'is-invalid' : ''}`}
                                            required
                                            type="select"
                                            name="ModoAdquisitivo"
                                            id="ModoAdquisitivo"
                                            defaultValue={modoadquisitivo}
                                            onChange={(e) => SeleccionarDato(e)}
                                        >
                                            <option value={0} disabled selected> Selec. modo adquisitivo</option>
                                            {ListaModos.map((modo, i) => (
                                                <option key={modo.id} value={modo.id}  >{modo.nombre}</option>
                                            ))}
                                        </Input>
                                        {errorModoadquisitivo && <div className="invalid-feedback">Por favor, seleccione un modo adquisitivo.</div>}
                                    </FormGroup>

                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Procedencia
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={procedencia}
                                            onChange={(e) => setProcedencia(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Historial de Observaciones
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={7}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Nueva observacion de Daño
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observacionesNuevas}
                                            onChange={(e) => setObservacionesNuevas(e.target.value)}
                                        />
                                        <span className='LetraPeq text-danger'> * Ingresa el daño que posee el activo por el cual se esta dando como dañado </span>
                                    </FormGroup>

                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-1'>
                                <Button type="button" onClick={toggleDesechar} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Desechar Activo
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={openDesechados} size='lg' fullscreen='lg' fade className='mt-2 shadow2 ' backdrop="static">
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Activo desechado  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow border rounded  ' id='institucion'  >
                        <Form id='form1' >
                            <Row >
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Institución
                                        </Label>
                                        <Input
                                            readOnly
                                            disabled
                                            type="text"
                                            name="cod.Presupuestario"
                                            id="cod.Presupuestario"
                                            placeholder="Código Presupuestario"
                                            value={CodigoNombre}
                                            className='py-1'
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="TipoActivo" className="me-2">
                                            Tipo Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className={`py-1 ${errorTipoActivo ? 'is-invalid' : ''}`}
                                            type="select"
                                            name="TipoActivo"
                                            id="TipoActivo"
                                            defaultValue={tipoActivo}
                                            onChange={(e) => setTipoActivo(e.target.value)}
                                        >
                                            <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                            {ListaTipoActivos.map((tipo, i) => (
                                                <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaActivo}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Nombre Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Descripción
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="ModoAdquisitivo" className="me-2">
                                            Modo Adquisitvo
                                        </Label>
                                        <Input
                                            disabled
                                            className={`py-1 ${errorModoadquisitivo ? 'is-invalid' : ''}`}
                                            required
                                            type="select"
                                            name="ModoAdquisitivo"
                                            id="ModoAdquisitivo"
                                            defaultValue={modoadquisitivo}
                                            onChange={(e) => SeleccionarDato(e)}
                                        >
                                            <option value={0} disabled selected> Selec. modo adquisitivo</option>
                                            {ListaModos.map((modo, i) => (
                                                <option key={modo.id} value={modo.id}  >{modo.nombre}</option>
                                            ))}
                                        </Input>
                                        {errorModoadquisitivo && <div className="invalid-feedback">Por favor, seleccione un modo adquisitivo.</div>}
                                    </FormGroup>

                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Procedencia
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={procedencia}
                                            onChange={(e) => setProcedencia(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="observacion" className="me-2">
                                            Observaciones del Daño
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required

                                            type="textarea"
                                            name="observacion"
                                            id="observacion"
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                
                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggleDesechados} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                                </Button>


                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={openAsignarDocente} size='xl' fullscreen='xl' fade className='mt-2 shadow2' backdrop='static'>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow3 border rounded  '>
                        <Form id='formDocente' onSubmit={AsignarActivoDocente} className='px-2'>
                            <h5 className='py-2' > Asignar Activo a Docente  </h5>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaActivo}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Nombre Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Fecha de Asignación
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required
                                            type="date"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={fechaSolicitud}
                                            onChange={(e) => setFechaSolicitud(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Fecha de Entrega
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required
                                            type="date"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={fechaEntrega}
                                            onChange={(e) => setFechaEntrega(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={12} className=' '>
                                    <Label>Lista de Docentes</Label>

                                    <Row className='border rounded p-1'>

                                        {Array.isArray(funcionarios) && funcionarios.map((fun, index) => (
                                            <Col md={3} key={index} >

                                                <FormGroup radio>
                                                    <Input
                                                        type="radio"
                                                        id={`radio-${index}`}
                                                        name="funcionario" // Aquí agregamos el atributo 'name'
                                                        value={fun.cedulaFuncionario}
                                                        onChange={Seleccion}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`radio-${index}`}>
                                                        {fun.nombreCompleto} {fun.apellido1} {fun.apellido2}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Responsable
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={NombreFuncionarioEntregada}
                                            
                                        />
                                    </FormGroup>

                                </Col>

                            </Row>
                            <Col className='d-flex justify-content-between mt-1'>
                                <Button type="button" onClick={toggleAsignarDocente} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Asiganar
                                </Button>
                            </Col>
                        </Form>
                    </Container>
                </ModalBody>
            </Modal>

            <Modal isOpen={openAsignarEdificio} size='xl' fullscreen='lg' fade className='mt-2 shadow2' backdrop='static'>
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Asignar activo a aula o edificio  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow2 border rounded  '>
                        <Form id='formEdificio' onSubmit={AsignarActivoEdificio}>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa Activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaActivo}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Nombre Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Fecha de Asignación
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required
                                            type="date"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={fechaSolicitud}
                                            onChange={(e) => setFechaSolicitud(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Fecha de Entrega
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required
                                            type="date"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={fechaEntrega}
                                            onChange={(e) => setFechaEntrega(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={12} className=' '>
                                    <Label>Selecciones el Docente que solicita el activo</Label>

                                    <Row className='border rounded p-1'>

                                        {Array.isArray(funcionarios) && funcionarios.map((fun, index) => (
                                            <Col md={3} key={index} >

                                                <FormGroup radio>
                                                    <Input
                                                        type="radio"
                                                        id={`radio-${index}`}
                                                        name="funcionario" // Aquí agregamos el atributo 'name'
                                                        value={fun.cedulaFuncionario}
                                                        onChange={Seleccion}
                                                    />
                                                    <Label radio className='negrita' htmlFor={`radio-${index}`}>
                                                        {fun.nombreCompleto} {fun.apellido1} {fun.apellido2}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>

                                </Col>
                                <Col md={12} className='py-2'>
                                    <Label>Seleccione el aula o Edificio al cual será asignado el activo</Label>
                                    <Row className='border rounded p-1'>
                                        {Array.isArray(ListaAulas) && ListaAulas.length === 0 ? (
                                            <Label className="text-center text-muted">No hay edificios disponibles o registrados.</Label>
                                        ) : (
                                            ListaAulas.filter(Edificio => Edificio.codigo !== 1)
                                            .sort((a, b) => a.nombre.localeCompare(b.nombre))
                                            .map((Edificio, index) => (
                                                <Col md={2} key={index}>
                                                    <FormGroup radio>
                                                        <Input
                                                            type="radio"
                                                            id={`radio2-${index}`}
                                                            name="edificio" // Aquí agregamos el atributo 'name'
                                                            value={Edificio.codigo}
                                                            onChange={SeleccionEdificio}
                                                        />
                                                        <Label radio className='negrita' htmlFor={`radio2-${index}`}>
                                                            {Edificio.nombre}
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            ))
                                        )}
                                    </Row>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Responsable
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={NombreFuncionarioEntregada}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>

                            </Row>

                            <Col className='d-flex justify-content-between mt-1'>
                                <Button type="button" onClick={toggleAsignarEdificio} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Asignar
                                </Button>
                            </Col>
                        </Form>
                    </Container>
                </ModalBody>
            </Modal>

            <Modal isOpen={openActivosAsignadosDocente} size='md' fullscreen='lg' fade className='mt-0 shadow2' backdrop='static'>

                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-0' > Activo asignado  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>

                    <Form id='formEdificio' className='p-3 shadow4 border rounded'>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Cedula Funcionario:</strong>
                                    </Label>
                                    <Label>{CedulaFuncionario}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Funcionario:</strong>
                                    </Label>
                                    <Label> {NombreFuncionario + ' ' + apellido1 + ' ' + apellido2}</Label>


                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Placa Activo:</strong>
                                    </Label>
                                    <Label>{placaActivo}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Activo:</strong>
                                    </Label>
                                    <Label>{nombreActivo}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup >
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Marca:</strong>
                                    </Label>
                                    <Label>{marca}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Modelo:</strong>
                                    </Label>
                                    <Label>{modelo}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Ubicación:</strong>
                                    </Label>
                                    <Label>{nombreEdificio}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Fecha en que se realizo la solicitud:</strong>
                                    </Label>
                                    <Label>{fechaSolicitud.substring(0, 10)}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Fecha pactada para devolución del activo:</strong>
                                    </Label>
                                    <Label>{fechaEntrega.substring(0, 10)}</Label>

                                </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Historia de estado del activo:</strong>
                                    </Label>
                                    {observaciones.split('➔').map((segmento, index) => {
                                        // Añadir salto de línea antes de cada segmento excepto en el primero
                                        return (
                                            <React.Fragment key={index}>
                                                {index !== 0 && <br />} {/* Agrega un salto de línea excepto en el primer segmento */}
                                                <Label>{index !== 0 ? '➔' : ''} {segmento.trim()}</Label>
                                            </React.Fragment>
                                        );
                                    })}
                                </FormGroup>
                            </Col>
                            <Col md={10}>
                                <FormGroup>
                                    <Label for="placaActivo" className="me-2 ">
                                        <strong>Funcionario que autorizo la entrega del Activo:</strong>
                                    </Label>
                                    <Label>{NombreFuncionarioEntregada}</Label>

                                </FormGroup>

                            </Col>

                        </Row>
                        <Col className='d-flex justify-content-between mt-1'>
                            <Button type="button" onClick={toggleActivosAsignadosDocente} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                            </Button>
                        </Col>
                    </Form>

                </ModalBody>
            </Modal>

            <Modal isOpen={openDevolverActivoDocente} size='md' fullscreen='xl' fade className='mt-2 shadow2' backdrop='static'>
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Devolución de Activo  </h5>
                </ModalHeader>
                <ModalBody className='p-0'>
                    <Container fluid className='  p-3 shadow2 border rounded  '>
                        <Form id='formDocente' onSubmit={DevolverActivoDocente} className='px-2'>
                            <Row>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Cedula Funcionario
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={CedulaFuncionario}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={7}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Funcionario
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={NombreFuncionario + ' ' + apellido1 + ' ' + apellido2}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="placaActivo" className="me-2 ">
                                            Placa activo
                                        </Label>
                                        <Input
                                            disabled
                                            required
                                            className='py-1'
                                            type="text"
                                            name="placaActivo"
                                            id="placaActivo"
                                            value={placaActivo}
                                            onChange={(e) => setPlacaActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="NombreActivo" className="me-2">
                                            Activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="NombreActivo"
                                            id="NombreActivo"
                                            value={nombreActivo}
                                            onChange={(e) => setNombreActivo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Marca
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Modelo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup  >
                                        <Label for="ModeloActivo" className="me-2">
                                            Ubicación del activo
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={nombreEdificio}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}></Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="MarcaActivo" className="me-2">
                                            Fecha de Asignación
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="date"
                                            name="MarcaActivo"
                                            id="MarcaActivo"
                                            value={fechaSolicitud.substring(0, 10)}
                                            onChange={(e) => setFechaSolicitud(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Fecha de Entrega
                                        </Label>
                                        <Input

                                            className='py-1'
                                            required
                                            type="date"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={fechaEntrega.substring(0, 10)}
                                            onChange={(e) => setFechaEntrega(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>

                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="ModeloActivo" className="me-2">
                                            Responsable
                                        </Label>
                                        <Input
                                            disabled
                                            className='py-1'
                                            required
                                            type="text"
                                            name="ModeloActivo"
                                            id="ModeloActivo"
                                            value={NombreFuncionarioEntregada}
                                            onChange={(e) => setModelo(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>

                            </Row>
                            <Col className='d-flex justify-content-between mt-1'>
                                <Button type="button" onClick={toggleDevolverActivoDocente} className=' p-1 px-2 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft size={15} className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-2' id='GuardarInstitucion'>
                                    Devolver Activo
                                </Button>
                            </Col>
                        </Form>
                    </Container>
                </ModalBody>
            </Modal>

        </Container>

    )
}

export default FrmActivos
