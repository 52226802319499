import CryptoJS from 'crypto-js';

export const encryptUserData = (param) => {
    const encrypted = CryptoJS.AES.encrypt(param, 'CryptoJS.AES.encryptJyJ2024@').toString();
   
    return encrypted;
};

export const decryptUserData = (param) => {
    const bytes = CryptoJS.AES.decrypt(param, 'CryptoJS.AES.encryptJyJ2024@');
    const decryptedDataString = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedDataString;
};

export const GuardarEnLocalStorage = (usuario,idInstitucion,permisosUsuario,nombreinstitucion) => {
   
    const encrypted1 = encryptUserData(usuario);
    const encrypted2 = encryptUserData(idInstitucion);
    const encrypted3 = encryptUserData(permisosUsuario);
    const encrypted4 = encryptUserData(nombreinstitucion);
    sessionStorage.setItem('SKY', encrypted1);//Usuario
    sessionStorage.setItem('ESDLC', encrypted2);//IdINstitucion
    sessionStorage.setItem('LV', encrypted3);//Permisos
    sessionStorage.setItem('ECDLS', encrypted4);//Nombre Institucion
    sessionStorage.setItem('TKO', 'true');
};

export const obtenerUsuario = () => {
    const encryptedUserData = sessionStorage.getItem('SKY');//Usuario
    if (encryptedUserData) {
       
        return decryptUserData(encryptedUserData);
    }
    return null; 
};

export const obtenerIdInstitucion = () => {
    const encryptedUserData = sessionStorage.getItem('ESDLC');//institucion
    if (encryptedUserData) {
        
        return decryptUserData(encryptedUserData);
    }
    return null; 
};

export const obtenerPermisos = () => {
    const encryptedUserData = sessionStorage.getItem('LV');//rol
    if (encryptedUserData) {
      
        //alert(decryptUserData(encryptedUserData));
        return decryptUserData(encryptedUserData);
    }
    return null; 
};

export const ObtenerNombreInst = () => {
    const encryptedUserData = sessionStorage.getItem('ECDLS');//Nombre
    if (encryptedUserData) {
      
        
        return decryptUserData(encryptedUserData);
    }
    return null; 
};

export const RemoveLocalStorage = () => {
   sessionStorage.removeItem('TKO');
sessionStorage.removeItem('SKY');
sessionStorage.removeItem('ESDLC');
sessionStorage.removeItem('LV');
sessionStorage.removeItem('ECDLS');

};