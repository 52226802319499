import { useState } from 'react';

/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbPersona
 */
export const TbPersona = () => {
    const [tipoIdentificacion, setTipoIdentificacion] = useState(0);
    const [identificacion, setIdentificacion] = useState('');
    const [nombreFami, setNombreFami] = useState('');
    const [apellido1Fami, setApellido1Fami] = useState('');
    const [apellido2Fami, setApellido2Fami] = useState('');
    const [generoFami, setGeneroFami] = useState(0);
    const [estadoCivil, setEstadoCivil] = useState(0);
    const [viveConEstudiante, setViveConEstudiante] = useState(0);
    const [telefonoFami, setTelefonoFami] = useState('');
    const [correoFami, setCorreoFami] = useState('');
    const [parentesco, setParentesco] = useState(0);
    const [otroParentesco, setOtroParentesco] = useState('');
    const [nacionalidadFami, setNacionalidadFami] = useState(41);
    const [condicionFami, setCondicionFami] = useState(0);
    const [provinciaFami, setProvinciaFami] = useState(0);
    const [cantonFami, setCantonFami] = useState('');
    const [distritoFami, setDistritoFami] = useState('');
    const [barrioFami, setBarrioFami] = useState('');
    const [direccionFami, setDireccionFami] = useState('');
    const [nivelEscolaridad, setNivelEscolaridad] = useState(0);
    const [ocupacion, setOCupacion] = useState(0);
    const [otraOcupacion, setOtraOcupacion] = useState('');
    const [lugarTrabajo, setLugarTrabajo] = useState('');
    const [tipoNacionalidad, setTipoNacionalidad] = useState(0);


    const [identificacionValida, setIdentificacionValidad] = useState(true);
    const [nombreFamiValido, setNombreFamiValido] = useState(true);
    const [apellido1FamiValido, setapellido1FamiValido] = useState(true);
    const [apellido2FamiValido, setapellido2FamiValido] = useState(true);
    const [telefonoFamiValido, setTelefonoFamiValido] = useState(true);
    const [correoFamiValido, setCorreoFamiValido] = useState(true);
    const [otroParentescoValido, setOtroparentescoValido] = useState(true);
    const [otraOcupacionValido, setOtraOcupacionValido] = useState(true);
    const [existe, setExiste] = useState(false);

    const [ListaEstadoCivil, setListaEstadoCivil] = useState([]);
    const [ListaParentescos, setListaParentescos] = useState([]);
    const [ListaNivelEscolaridad, setListaNivelEscolaridad] = useState([]);
    const [ListaOcupacion, setListaOcupacion] = useState([]);
    const [ListaGeneroFami, setListaGeneroFami] = useState([]);
    const [ListaPaisesFami, setListaPaisesFami] = useState([]);
    const [ListaProvinciasFami, setListaProvinciasFami] = useState([]);
    const [ListaCantonesFami, setListaCantonesFami] = useState([]);
    const [ListaDistritosFami, setListaDistritosFami] = useState([]);
    const [ListaBarriosFami, setListaBarriosFami] = useState([]);
    const [datosPersona, setDatosPersona] = useState([]);
    const [ListaTipoID, setListaTipoID]  = useState([]);

    return {
        tipoIdentificacion, setTipoIdentificacion,
        identificacion, setIdentificacion,
        nombreFami, setNombreFami,
        apellido1Fami, setApellido1Fami,
        apellido2Fami, setApellido2Fami,
        generoFami, setGeneroFami,
        estadoCivil, setEstadoCivil,
        viveConEstudiante, setViveConEstudiante,
        telefonoFami, setTelefonoFami,
        correoFami, setCorreoFami,
        parentesco, setParentesco,
        otroParentesco, setOtroParentesco,
        nacionalidadFami, setNacionalidadFami,
        condicionFami, setCondicionFami,
        provinciaFami, setProvinciaFami,
        cantonFami, setCantonFami,
        distritoFami, setDistritoFami,
        barrioFami, setBarrioFami,
        direccionFami, setDireccionFami,
        nivelEscolaridad, setNivelEscolaridad,
        ocupacion, setOCupacion,
        otraOcupacion, setOtraOcupacion,
        lugarTrabajo, setLugarTrabajo,
        tipoNacionalidad, setTipoNacionalidad,

        identificacionValida, setIdentificacionValidad,
        nombreFamiValido, setNombreFamiValido,
        apellido1FamiValido, setapellido1FamiValido,
        apellido2FamiValido, setapellido2FamiValido,
        telefonoFamiValido, setTelefonoFamiValido,
        correoFamiValido, setCorreoFamiValido,
        otroParentescoValido, setOtroparentescoValido,
        otraOcupacionValido, setOtraOcupacionValido,
        existe, setExiste,

        ListaEstadoCivil, setListaEstadoCivil,
        ListaParentescos, setListaParentescos,
        ListaNivelEscolaridad, setListaNivelEscolaridad,
        ListaOcupacion, setListaOcupacion,
        ListaGeneroFami, setListaGeneroFami,
        ListaPaisesFami, setListaPaisesFami,
        ListaProvinciasFami, setListaProvinciasFami,
        ListaCantonesFami, setListaCantonesFami,
        ListaDistritosFami, setListaDistritosFami,
        ListaBarriosFami, setListaBarriosFami,
        datosPersona, setDatosPersona,
        ListaTipoID, setListaTipoID,
    };
};



