import React, { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import Dashboard from "../View/Dashboard/Dashboard";
import GestionEstudiante from "../View/GestionEstudiante";
import GestionProfesores from "../View/GestionProfesores";
import Perfil from "../View/Perfil/Perfil";
import IngresarInstitucion from "../View/Developers/IngresarInstitucion";
import Funcionarios from "../View/Developers/Funcionarios/Funcionarios"
import ListaInstituciones from "../View/Developers/ListaInstituciones";
import ActualizarInstitucion from "../View/Developers/Administradores";
import ListaInstitucionesInactivas from "../View/Developers/ListaInstitucionesInactivas";
import FuncionariosInactivos from "../View/Developers/Funcionarios/FuncionariosInactivos";
import RegistrarFuncionario from "../View/Developers/Funcionarios/RegistrarFuncionario";
import FrmAsignaturas from "../View/Administrativo/Asignaturas/FrmAsignaturas";
import FrmRegis_Asignatura from "../View/Administrativo/Asignaturas/FrmRegis_Asignatura";
import FrmFuncionarioDocente from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionarioDocente";
import FrmFuncionariosDocentesInactivos from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionariosDocentesInactivos";
import FrmRegistrarFuncionarioDocente from "../View/Administrativo/Funcionarios_Y_Docentes/FrmRegistrarFuncionarioDocente";
import FrmInstitucion from "../View/Administrativo/Institucion/FrmInstitucion";
import FrmAsignar_Asignatura from "../View/Administrativo/Asignaturas/FrmAsignar_Asignatura";
import FrmAsignaturasInactivas from "../View/Administrativo/Asignaturas/FrmAsignaturasInactivas";
import FrmSecciones from "../View/Administrativo/Secciones/FrmSecciones";
import FrmSeccionesInactivas from "../View/Administrativo/Secciones/FrmSeccionesInactivas";
import FrmRegistrarSecciones from "../View/Administrativo/Secciones/FrmRegistrarSecciones";
import FrmFuncionarios from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionarios";
import FrmRegistrarFuncionario from "../View/Administrativo/Funcionarios_Y_Docentes/FrmRegistrarFuncionario";
import FrmFuncionariosInactivos from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionariosInactivos";
import FrmActivos from "../View/Administrativo/Activos/FrmActivos";
import FrmRegistrarActivos from "../View/Administrativo/Activos/FrmRegistrarActivos";
import FrmEdificios from "../View/Administrativo/Edificios/FrmEdificios";
import FrmExpedientes from "../View/Administrativo/Expedientes/FrmExpedientes";
import FrmNuevoExpediente from "../View/Administrativo/Expedientes/FrmNuevoExpediente";
import FrmNuevoExpedienteEstudiante from "../View/Administrativo/Expedientes/FrmExpedienteEstudiante";
import FrmAsistencia from "../View/Docentes/Asistencia/FrmAsistencia";
import FrmCalendario from "../View/Docentes/CalendarioActividades/FrmCalendario";
import FrmComunicados from "../View/Docentes/Comunicados/FrmComunicados";
import FrmAsistenciaEstuduante from "../View/Docentes/Asistencia/FrmAsistenciaEstuduante";
import FrmLectorQR from "../View/LectorQR/FrmLectorQR";
import FrmGenerarQR from "../View/Estudiantes/QR/FrmGenerarQR";
import FrmRegistrarCalificaciones from "../View/Docentes/ResgistroCalificaciones/FrmRegistrarCalificaciones";
import FrmCrearEvaluaciones from "../View/Docentes/CrearEvaluaciones/FrmCrearEvaluaciones";
 

const AppRoutes = ({ institucion, usuario, NombreInsti, CodNombre }) => {
    return (
        <Routes>



            <Route path="/" element={<Dashboard codigoPresupuestario={institucion} />} />

            <Route path="/GestionEstudiante" element={<GestionEstudiante />} />
            <Route path="/GestionProfesores" element={<GestionProfesores />} />
            <Route path="/Perfil" element={<Perfil codigoPresupuestario={institucion} IDuser={usuario} />} />
            <Route path="/IngresarInstitucion" element={<IngresarInstitucion />} />
            <Route path="/ListaInstituciones" element={<ListaInstituciones />} />
            <Route path="/ListaInstitucionesInactivas" element={<ListaInstitucionesInactivas />} />
            <Route path="/ActualizarInstitucion" element={<ActualizarInstitucion />} />
            <Route path="/FrmInstitucion" element={<FrmInstitucion codigoPresupuestario={institucion} />} />
            <Route path="/Funcionarios/:codigoPresupuestario" element={<Funcionarios />} />
            <Route path="/FuncionariosInactivos/:codigoPresupuestario" element={<FuncionariosInactivos />} />
            <Route path="/RegistrarFuncionario/:codigoPresupuestario" element={<RegistrarFuncionario />} />


            <Route path="/FrmAsignaturas" element={<FrmAsignaturas codigoPresupuestario={institucion} />} />
            <Route path="/FrmRegis_Asignatura" element={<FrmRegis_Asignatura codigoPresupuestario={institucion} />} />
            <Route path="/FrmAsignaturasInactivas" element={<FrmAsignaturasInactivas codigoPresupuestario={institucion} />} />

            <Route path="/FrmFuncionarioDocente" element={<FrmFuncionarioDocente codigoPresupuestario={institucion} />} />
            <Route path="/FrmFuncionariosDocentesInactivos" element={<FrmFuncionariosDocentesInactivos codigoPresupuestario={institucion} />} />
            <Route path="/FrmRegistrarFuncionarioDocente" element={<FrmRegistrarFuncionarioDocente codigoPresupuestario={institucion} />} />
            <Route path="/FrmFuncionarios" element={<FrmFuncionarios codigoPresupuestario={institucion} />} />
            <Route path="/FrmRegistrarFuncionario" element={<FrmRegistrarFuncionario codigoPresupuestario={institucion} />} />
            <Route path="/FrmFuncionariosInactivos" element={<FrmFuncionariosInactivos codigoPresupuestario={institucion} />} />

            <Route path="/FrmAsignar_Asignatura" element={<FrmAsignar_Asignatura codigoPresupuestario={institucion} />} />
            <Route path="/FrmSecciones" element={<FrmSecciones codigoPresupuestario={institucion} />} />
            <Route path="/FrmSeccionesInactivas" element={<FrmSeccionesInactivas />} />
            <Route path="/FrmRegistrarSecciones" element={<FrmRegistrarSecciones codigoPresupuestario={institucion} />} />

            <Route path="/FrmActivos" element={<FrmActivos codigoPresupuestario={institucion} codigoNombre = {CodNombre} />} />
            <Route path="/FrmRegistrarActivos" element={<FrmRegistrarActivos codigoPresupuestario={institucion} codigoNombre = {CodNombre} />} />

            <Route path="/FrmEdificios" element={<FrmEdificios codigoPresupuestario={institucion} codigoNombre = {CodNombre} />} />

            <Route path="/FrmExpedientes" element={<FrmExpedientes codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>
            <Route path="/FrmNuevoExpediente" element={<FrmNuevoExpediente codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>
            <Route path="/FrmExpedienteEstudiante" element={<FrmNuevoExpedienteEstudiante codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>
       



            {/* RUTAS PARA LOS MODULOS PARA DOCENTES */}
            <Route path="/FrmAsistencia" element={<FrmAsistencia codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>
            <Route path="/FrmAsistenciaEstudiante" element={<FrmAsistenciaEstuduante codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>
            <Route path="/FrmCalendario" element={<FrmCalendario codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>
            <Route path="/FrmComunicados" element={<FrmComunicados codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>
            <Route path="/FrmCrearEvaluaciones" element = {<FrmCrearEvaluaciones codigoPresupuestario={institucion} codigoNombre = {CodNombre}/>}/>
            <Route path="/FrmRegistrarCalificaciones" element = {<FrmRegistrarCalificaciones codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>

            {/* RUTAS PARA LOS MODULOS PARA ESTUDIANTES */}
            <Route path="/FrmGenerarQR" element={<FrmGenerarQR codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>


            {/* RUTA PARA LECTOR QR */}

            <Route path="/FrmLectorQR" element = {<FrmLectorQR codigoPresupuestario={institucion} codigoNombre = {CodNombre} />}/>

        </Routes>
    )
}

export default AppRoutes
