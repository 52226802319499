import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';
import EdificiosJson from '../CapaPresentacion/Json/TiposEdificios.json';

var url = '';
var estado;


export async function GetListaEdificios(data) {
    url = endpoint.urlListaEdificios;
    let ListaEdificiosModificado = null;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const Edificios = await response.json();


        // Mapear la lista de edificios y agregar el nombre del tipo
        ListaEdificiosModificado = Edificios.map(Edif => {
            const nombreTipo = EdificiosJson[Edif.tipo.toString()];
            return {
                ...Edif,
                nombreTipoEdificio: nombreTipo || '' // Si no se encuentra el tipo, establece el nombre como vacío
            };
        });

        return ListaEdificiosModificado || [];

    } catch (error) {
        console.log(error.message);
        return [];
    }
}
export async function GetListaEdificios2(data) {
    url = endpoint.urlListaEdificios;
    let ListaEdificiosModificado = null;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const Edificios = await response.json();


        // Mapear la lista de edificios y agregar el nombre del tipo
        ListaEdificiosModificado = Edificios.map(Edif => {
            const nombreTipo = EdificiosJson[Edif.tipo.toString()];
            return {
                ...Edif,
                nombreTipoEdificio: nombreTipo || '' // Si no se encuentra el tipo, establece el nombre como vacío
            };
        });

        return ListaEdificiosModificado || [];

    } catch (error) {
        console.log(error.message);
        return [];
    }
}
export async function RegistrarEdificios(data) {

    url = endpoint.urlRegistrarEdificio;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return { estado: estado, mensaje: error };
    }
}

export async function EditarEdificios(data) {

    url = endpoint.urlActualizarEdificio;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return { estado: estado, mensaje: error };
    }
}

export async function CambiarEstadoEdificios(data) {

    url = endpoint.urlCambiarEstadoEdificio;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return { estado: estado, mensaje: error };
    }
}