import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { TbEstudiante } from '../../CapaEntidades/TbEstudiante';


const MenuItem2 = ({ to, text,cedula }) => {
   
    return (
        <li className='NoDecoration3'>
            <NavLink
                className={'text-link '}
                to={{
                    pathname: to,
                    state: { cedula }  // Aquí se pasan las props adicionales
                }}
                title='Has click para ver toda la información'
            >
                {text}
            </NavLink>
        </li>
    )
}

export default MenuItem2
