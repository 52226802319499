import React, { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';

import NavbarNav from "./NavbarNav";
import Sidebar from "./SidebarSuperUsuario";
import Dashboard from "../View/Dashboard/Dashboard";
import GestionEstudiante from "../View/GestionEstudiante";
import GestionProfesores from "../View/GestionProfesores";
import Perfil from "../View/Perfil/Perfil";
import IngresarInstitucion from "../View/Developers/IngresarInstitucion";
import Funcionarios from "../View/Developers/Funcionarios/Funcionarios"
import ListaInstituciones from "../View/Developers/ListaInstituciones";
import ActualizarInstitucion from "../View/Developers/Administradores";
import ListaInstitucionesInactivas from "../View/Developers/ListaInstitucionesInactivas";
import FuncionariosInactivos from "../View/Developers/Funcionarios/FuncionariosInactivos";
import RegistrarFuncionario from "../View/Developers/Funcionarios/RegistrarFuncionario";

import { ObtenerNombreInst, obtenerIdInstitucion, obtenerPermisos, obtenerUsuario } from "../../CapaUtilidades/Utils/encryptUserData";
import SidebarAdministrador from "./SidebarAdministrador";
import FrmAsignaturas from "../View/Administrativo/Asignaturas/FrmAsignaturas";
import FrmRegis_Asignatura from "../View/Administrativo/Asignaturas/FrmRegis_Asignatura";
import FrmFuncionarioDocente from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionarioDocente";
import FrmFuncionariosDocentesInactivos from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionariosDocentesInactivos";
import FrmRegistrarFuncionarioDocente from "../View/Administrativo/Funcionarios_Y_Docentes/FrmRegistrarFuncionarioDocente";
import FrmInstitucion from "../View/Administrativo/Institucion/FrmInstitucion";
import FrmAsignar_Asignatura from "../View/Administrativo/Asignaturas/FrmAsignar_Asignatura";
import SidebarPadres from "./SidebarPadres";
import FrmAsignaturasInactivas from "../View/Administrativo/Asignaturas/FrmAsignaturasInactivas";
import FrmSecciones from "../View/Administrativo/Secciones/FrmSecciones";
import FrmSeccionesInactivas from "../View/Administrativo/Secciones/FrmSeccionesInactivas";
import FrmRegistrarSecciones from "../View/Administrativo/Secciones/FrmRegistrarSecciones";
import FrmFuncionarios from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionarios";
import FrmRegistrarFuncionario from "../View/Administrativo/Funcionarios_Y_Docentes/FrmRegistrarFuncionario";
import FrmFuncionariosInactivos from "../View/Administrativo/Funcionarios_Y_Docentes/FrmFuncionariosInactivos";
import AppRoutes from "../routes/AppRoutes";
import SidebarDocentes from "./SidebarDocentes";
function Menu({ Roll, IdInstitucion, toggleDarkMode }) {

    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [roll, setRoll] = useState(null);
    const [institucion, setInstitucion] = useState(null);
    const [usuario, setUsuario] = useState(null);
    const [nombreInstitucion, setNombreInstitucion] = useState('');
    const [codigoNombre, setCodigoNombre] = useState('');

    const handleDarkModeToggle = () => {
        toggleDarkMode();
    };
    useEffect(() => {
        // Obtener los permisos del usuario al montar el componente
        const usuarioRoll = obtenerPermisos();
        setRoll(usuarioRoll);

        // Obtener la institución al montar el componente
        const idInstitucion = obtenerIdInstitucion();
        setInstitucion(idInstitucion);

        const IDusuario = obtenerUsuario();
        setUsuario(IDusuario);
        
        const NombreInstitucion = ObtenerNombreInst();
        setNombreInstitucion(NombreInstitucion)

        setCodigoNombre(idInstitucion + " - " +NombreInstitucion)
    }, []);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200); // Define isMobile como verdadero si el ancho de la ventana es menor que 768px
            setIsSidebarOpen(window.innerWidth >= 1200); // Oculta el sidebar si la ventana es menor a 768px
        };

        handleResize(); // Verifica el tamaño inicial de la ventana

        window.addEventListener("resize", handleResize); // Agrega el event listener al resize

        // Limpia el event listener cuando el componente se desmonta
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };




    return (
        <div className="container-fluid m-0 p-0 fondoAzul">
            <NavbarNav toggleSidebar={toggleSidebar} codigoPresupuestario={institucion} IDuser={usuario} toggleDarkMode={handleDarkModeToggle} />

            <div className="flex">

                {roll === '1' && (
                    <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />// nosotros
                )}
                {roll === '2' && (
                    <SidebarAdministrador isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />// Director
                )}
                {roll === '3' && (
                    <SidebarAdministrador isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />// administrativo
                )}
                {roll === '4' && (
                    <SidebarDocentes isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />// docentes
                )}
                {roll === '7' && (
                    <SidebarPadres isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />// padres
                )}
                <div className="content w-100">

                    <AppRoutes institucion={institucion} usuario={usuario} NombreInsti={nombreInstitucion} CodNombre={codigoNombre}/>

                </div>

            </div>

        </div>
    );
}

export default Menu;
