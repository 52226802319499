import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter,
    Label,
    Breadcrumb,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody
} from 'reactstrap';
import Fotop from '../../../assets/faces/user (3).png';
import { removeBase64Prefix } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { ErrorMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import { RestoreNombramiento, getFuncionariosActivos } from '../../../../CapaDatos/DatosFuncionarios';
import { useParams } from 'react-router-dom';
import { RestoreUser } from '../../../../CapaDatos/DatosUsuarios';

let globalData = null;
const FrmFuncionariosInactivos = ({ codigoPresupuestario }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    const [loading, setLoading] = useState(true);
    const [funcionarios, setFuncionarios] = useState([]);
    const [openA, setOpenA] = useState('1');









    const fetchData = async () => {
        try {
            const parametro = { idInstitucion: CodigoPresupuestario, Estado: 0 };

            const data = await getFuncionariosActivos(0);
             globalData = data
            setFuncionarios(data);
            setLoading(false);

        } catch (error) {

            setLoading(false);
        }
    };

    useEffect(() => {
        
            fetchData();
        

    }, []);




    if (loading) {
        return (
            <Cargando />
        );
    }

    if (funcionarios.estado === 0) {
        return (
            <SinDatos
                mensaje={"Error interno del servidor"}
                error={"Lo sentimos, a ocurrido un error con el servidor, por favor comunicate con el departamento de Soporte de J&J TechSolutions"}
                route={"/Menu/FrmFuncionarios"}
            />
        );
    }
    if (!funcionarios?.length) {
        // Mensaje si no hay instituciones registradas
        return (
            <SinDatos
                mensaje={"Notificacion del Sistema"}
                error={"No existen funcionarios suspendidos para esta institucion"}
                route={"/Menu/FrmFuncionarios"}

            />
        );
    }










    const Restaurar = async (data, data2) => {
        //alert(JSON.stringify(data2));
        Swal.fire({
            title: "Estas seguro que deseas restaurar este funcionario ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, restaurar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await RestoreUser(data)
                const success2 = await RestoreNombramiento(data2);
                if (success.estado && success2.estado) {
                    Swal.fire({
                        title: "¡Restaurado!",
                        text: "El Usuario ha sido restaurado",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false
                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });



    }

    const toggleA = (id) => {
        setOpenA(openA === id ? null : id); // Abre o cierra el acordeón según el id
    };

    // Renderizar las instituciones
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Funcionarios"
                        to={"/Menu/FrmFuncionarioDocente"}
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Funcionarios Suspendidos"
                        to={"/Menu/FrmFuncionariosDocentesInactivos"}
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>
            <h3>Funcionarios suspendidos</h3>
            <Container fluid className='p-0 Mymt-5'>
                <Row>
                    {funcionarios
                        .filter(Funcionario => ![1, 4, 5, 6, 7].includes(Funcionario.cargo))
                        .map((Funcionario, index) => (
<Col className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 pb-2 px-2 ' >
                                <Accordion flush className='mt-1  ' open={openA} toggle={() => toggleA(index)}>
                                    <AccordionItem className=' mb-3 rounded-0'>
                                        <AccordionHeader className='shadow rounded-0 accordionBorder accordion-button2 accordion-button2-pr-0' targetId={index}>

                                            <Row className='px-3' >


                                                <Col className=' col-10 p-0'>
                                                    <Label className='font-size'><strong>Funcionario:</strong> <br /> {Funcionario.nombreCompleto} {Funcionario.apellido1} {Funcionario.apellido2}</Label>
                                                </Col>
                                                <Col className=' col-2'>
                                                    <div className="text-center mt-0">
                                                        {Funcionario.foto !== null ? (
                                                            <img src={`data:image/jpeg;base64,${removeBase64Prefix(Funcionario.foto)}`} alt="Foto de institución" className=" FotoPerfil2 p-0" />
                                                        ) : (
                                                            <img src={Fotop} alt="Imagen por defecto" className="FotoPerfil2 p-2" />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AccordionHeader>
                                        <AccordionBody className='shadow rounded-0' accordionId={index}>
                                            <Row>
                                                <Col md={12} className=' accordionBorder pb-2'>
                                                    {/* <strong> Cedula: </strong><br />{Funcionario.cedulaFuncionario}<br /> */}
                                                    <strong>Cargo:</strong><br /> {Funcionario.CargoC} <br />
                                                    <strong>Telefono: </strong> <br />{Funcionario.telefonoPrincipal} / {Funcionario.telefono1}

                                                </Col>
                                                {/* <Col md={12} className="mt-auto d-flex justify-content-between accordionBorder pb-2"> 
                                                    <Button className=' mx-1 btn-Ver mt-2 px-2 ' type='button' onClick={(e) => Abrirmodal2(Funcionario.cedulaFuncionario)}>
                                                        Añadir Asignaturas
                                                    </Button>
                                                    <Button className=' mx-1 btn-Ver mt-2 px-2 ' type='button' onClick={(e) => Abrirmodal3(Funcionario.cedulaFuncionario)}>
                                                        Retirar Asignaturas
                                                    </Button>
                                                </Col> */}
                                                <Col md={12} className='d-flex justify-content-end mt-2 '>
                                                    <Button color=' ' type='button' className='mx-1 btn-eliminar'
                                                        onClick={(e) => Restaurar(
                                                            {
                                                                usuario: Funcionario.cedulaFuncionario,
                                                                idInstitucion: CodigoPresupuestario,
                                                                estado: 1
                                                            },
                                                            {
                                                                idNombramiento: Funcionario.codigoNombramientoInstitucion,
                                                                estado: 1
                                                            }
                                                        )}
                                                    >
                                                        Restaurar
                                                    </Button>


                                                </Col>
                                            </Row>
                                        </AccordionBody>

                                    </AccordionItem>
                                </Accordion>
                            </Col>


                        ))}
                </Row>
                <Button type="button" className='p-0 btn   btnCancelar px-3    d-flex justify-content-start' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className=' '
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"

                            to={"/Menu/FrmFuncionarioDocente"}
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>


        </Container>
    );
};

export default FrmFuncionariosInactivos
