import React from 'react'

/**
 * Componente Barra
 * Representa una barra simple.
 * @returns {JSX.Element} Componente Barra.
 */
const Barra = () => {
    return (
        <div className="barra">
            <div className="franja-barra"></div>
        </div>
    )
}

export default Barra
