import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;

export async function ExisteMatricula(data) {

    url = endpoint.urlExisteMatricula;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
         //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        estado = responseData.estado;
        //alert(estado)
        return estado;

    } catch (error) {
        console.log(error)
        return estado;
    }
}

export async function ListaMatriculados(data) {
    url = endpoint.urlListaMatriculas;

    //alert(JSON.stringify(data));
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responsePersona = await response.json();

        //console.log(responsePersona);
        return responsePersona || []; // Devolvemos los datos obtenidos o un array vacío si no hay datos

    } catch (error) {
        console.log(error.message);
        return [];
    }
}

export async function MatricularEstudiante(formData) {

    const url = endpoint.urlMatricularEstudiante;
    // for (let [key, value] of formData.entries()) {
    //     if (value instanceof File) {
    //         console.log(`${key}: ${value.name}`);
    //     } else {
    //         console.log(`${key}: ${value}`);
    //     }
    // }
    if (!url || !formData) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        const estado = responseData.estado;
        return {
            estado: responseData.estado,
            mensaje: responseData.mensaje
        };

    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function ActualizarMatriculaEstudiante(formData) {

    const url = endpoint.urlActualizarMatricula;

    if (!url || !formData) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        const estado = responseData.estado;
        return estado;

    } catch (error) {
        console.log(error);
        return null;
    }
}