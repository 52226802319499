import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;

export async function ExisteActivo(data) {
    
    url = endpoint.urlExisteActivo;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       //alert(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.existe;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}


export async function RegistrarActivo(data) {
    
    url = endpoint.urlRegistrarActivo;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       //alert(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}


export async function GetListaActivos(data) {
    url = endpoint.urlListaActivos;
    let CodigoPresupuestario = obtenerIdInstitucion()
    const data2 = { activoPerteneceInstitucion: CodigoPresupuestario}; 
    if (!url || !data2) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data2),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const Activos = await response.json();
        
      
        return Activos.datos || []; // Devolvemos los datos obtenidos o un array vacío si no hay datos

    } catch (error) {
        console.log(error.message);
        return [];
    }
}

export async function CambiarEstadoActivos(data) {
    
    url = endpoint.urlCambiarEstadoActivos;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       //alert(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}

export async function ActualizarActivo(data) {
    
    url = endpoint.urlActualizarActivo;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       //alert(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}