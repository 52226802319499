import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardFooter, Label, Breadcrumb } from 'reactstrap';
import { TbAsignaturas } from '../../../../CapaEntidades/TbAsignaturas';
import { getAsignaturas } from '../../../../CapaDatos/DatosAsignaturas';
import Espaciador from '../../../components/Espaciador';
import * as Fa6 from "react-icons/fa6";
import * as Bi from "react-icons/bi";
import { IoMdReturnLeft } from "react-icons/io";
import MenuItem from '../../../components/MenuItem';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import { ErrorMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import { RestoreAsignatura, deleteAsignatura } from '../../../../CapaDatos/DatosAsignaturas';

const FrmAsignaturasInactivas = ({ codigoPresupuestario }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    const [loading, setLoading] = useState(true);
    const [Asignaturas, setAsignaturas] = useState([]);
    const [open, setOpen] = useState(false);
    const {
      CodigoAsignatura, setCodigoAsignatura,
      NombreAsignatura, setNombreAsignatura,
      CalificacionMinima, setCalificacionMinima,
      AsignaturaCentro, setAsignaturaCentro,
      CalificacionValida, setCalificacionValida,
      AsignaturaValida, setAsignaturaValida
    } = TbAsignaturas();
  

    const fetchData = async () => {
        try {
    
          const Estado = 0;
    
          const data = await getAsignaturas(Estado);
    
          setAsignaturas(data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };
    
      useEffect(() => {
    
        fetchData();
      }, []);

    const Restaurar = async (param, IdInstitucion) => {
        setAsignaturaCentro(CodigoPresupuestario)
        const data = {
    
          codigoPresupuestario: IdInstitucion,
          codigoAsignatura: param,
          estado: "1"
        }
    
        Swal.fire({
          
          title: "Restaurar Asignatura",
          text: "¡Estas seguro que deseas restaurar esta asignatura?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "¡Sí, Restaurar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
    
            const success = await RestoreAsignatura(data);
    
            if (success) {
              Swal.fire({
                title: "¡Restaurado!",
                text: "La Asignatura a sido restaurada",
                icon: "success",
                timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                timerProgressBar: true, // Barra de progreso de tiempo
                showConfirmButton: false
              });
              fetchData();
            } else {
              ErrorMessage('Notificación del Sistema', success.mensaje);
              return;
    
            }
    
          }
        });
      };
    
  if (loading) {
    return (
      <Cargando />
    );


  }

    if (Asignaturas.estado === 0) {
        fetchData();
        // if (Asignaturas.estado === 0) {
    
        //   return (
        //     <SinDatos
        //       mensaje={"Error Interno del Servidor"}
        //       error={"Lo sentimos, a ocurrido un error con el servidor comunicate con el departamento de TI"}
        //       route="/Menu" />
        //   );
        // }
      }
    
      if (!Asignaturas?.length) {
    
        return (
          <SinDatos
            mensaje={"Notificacion del Sistema"}
            error={"Lo sentimos, no existen asignaturas eliminadas"}
            route="/Menu/FrmAsignaturas" />
        );
      }
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu/FrmAsignaturas"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Asignaturas Inactivas"
                        to="/Menu/FrmAsignaturasInactivas"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='fixed'>
                <Col className="floating-container" id="floating-container">


                    <Col className="element-container" >
                        <Button className='p-0  btn btnAceptar float-element tooltip-left  '>
                            <ul>
                                <MenuItem
                                    className=' p-0'
                                    icon={<Fa6.FaCirclePlus className="me-1 sizeIcon2" />}
                                    text="Ing. Asignatura"
                                    to="/Menu/FrmAsignatura"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Espaciador espaciador='espaciador-SM' />
                        <Button className='p-0  btn btnAceptar float-element '>
                            <ul>
                                <MenuItem
                                    className=' p-0'
                                    icon={<Bi.BiSolidInstitution className="me-1" />}
                                    text="Asig. Inactivas"

                                    to={"/Menu/FrmAsignaturasInactivas"}
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                    </Col>
                </Col>



            </Container>
            <Container fluid className='p-0 Mymt-5'>
                {Asignaturas === null ? (
                    <Col>
                        <p>No hay asignaturas disponibles.</p>
                    </Col>
                ) : (
                    <Row >
                        {Asignaturas.map((asig, index) => (
                            <Col key={index} className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 pb-1'>
                                <Card className="mb-3 shadow2 rounded-3 ">
                                    <CardBody className="d-flex flex-column py-4">
                                        <Col>
                                            <Row>
                                                <Col md={12}>
                                                    <strong>Código Asignatura: </strong> {asig.codigoAsignatura}<br />
                                                </Col>
                                                <Col md={12}>
                                                    <strong>Asignatura: </strong>{asig.nombreAsignatura}

                                                </Col>
                                                <Col md={12}>
                                                    <strong>Calificación Minima: </strong> {asig.calificacionMinima}<br />
                                                </Col>

                                            </Row>
                                        </Col>
                                    </CardBody>
                                    <CardFooter className='d-flex justify-content-end'>
                                        <Button color=' ' type='button' className='mx-1 btn-eliminar' onClick={(e) => Restaurar(asig.codigoAsignatura, asig.asignaturaCentro)}>
                                            Restaurar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
                <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className='btnAceptar'
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"
                            to="/Menu/FrmAsignaturas"
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>
        </Container >
    )
}

export default FrmAsignaturasInactivas
