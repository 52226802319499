import * as Validaciones from './validaciones';

/**
 * Metodo para validar si el valor proporcionado contiene solo numeros.
 * @param {Event} event - El evento que activó la validación.
 * @param {inputValue} setInput - Parametro para establecer el valor de entrada validado.
 * @param {void}
 */
 export const ValidarSoloNumeros = (event, setInput, setEstado) => {
    const inputValue = event.target.value;
    if (inputValue === '' || Validaciones.validarSoloNumeros(inputValue)) {
        setInput(inputValue);
        setEstado(true); // El código es válido

    } else {

        setEstado(false); // El código no es válido

    }
};

/**
 * Metodo para validar si el valor proporcionado contiene solo numeros un punto y 2 decimales.
 * @param {Event} event - El evento que activó la validación.
 * @param {Function} setInput - Parametro para establecer el valor de entrada validado.
 * @param {Function} setEstado - Parametro para establecer el estado de validez del valor de entrada.
 */
export const ValidarSoloNumerosPuntoDecimal = (event, setInput, setEstado) => {
    const inputValue = event.target.value;

    // Permite que el campo esté vacío o cumpla con la validación
    if (inputValue === '' || Validaciones.validarSoloNumerosPuntoDecimal(inputValue)) {
        setInput(inputValue);
        setEstado(true); // El valor es válido
    } else {
        setEstado(false); // El valor no es válido
    }
};


/**
 * Método para validar si el valor proporcionado contiene solo letras y espacios.
 * @param {Event} event - El evento que activó la validación.
 * @param {inputValue} setInput - Parámetro para establecer el valor de entrada validado.
 * @param {Estado} setEstado - Estado para establecer la validez.
 * @returns {void}
 */
export const ValidarSoloLetras = (event, setInput, setEstado) => {
    const inputValue = event.target.value;
    if (inputValue === '' || Validaciones.validarSoloLetrasYEspacios(inputValue)) {
        
        setInput(inputValue);
        setEstado(true); // es solo letras
    } else {
        setEstado(false); // no es solo letras
    }
};

export const ValidarSoloLetras2 = (inputValue, setInput, setEstado) => {
  
    if (inputValue === '' || Validaciones.validarSoloLetrasYEspacios(inputValue)) {
       
        setInput(inputValue);
        setEstado(true); // es solo letras
    } else {
        setEstado(false); // no es solo letras
    }
};

/**
 * Método para validar si el valor proporcionado contiene solo letras, números, y el guión (-).
 * @param {Event} event - El evento que activó la validación.
 * @param {inputValue} setInput - Parámetro para establecer el valor de entrada validado.
 * @param {Estado} setEstado - Estado para establecer la validez.
 * @returns {void}
 */
export const ValidarLetrasNumerosGuion = (event, setInput, setEstado) => {
    const inputValue = event.target.value;
    if (inputValue === '' || /^[a-zA-Z0-9-óÓ]*$/.test(inputValue)) {
        setInput(inputValue);
        setEstado(true); // es solo letras, números, y guion
    } else {
        setEstado(false); // no es solo letras, números, y guion
    }
};



/**
 * Metodo para validar si el valor proporcionado contiene solo letras y espacios.
 * @param {Event} event - El evento que activó la validación.
 * @param {inputValue} setInput - Parametro para establecer el valor de entrada validado.
 * @param {Estado} setEstado - Estado para establecer la validez.
 *  @returns {void}
 */
export const ValidarCorreo = (event, setInput, setEstado) => {
    const nuevoCorreo = event.target.value;
    setInput(nuevoCorreo);
    setEstado(Validaciones.validarCorreoElectronico(nuevoCorreo));
};


