//#region LIsta de Importaciones


import React, { useState, useEffect } from 'react';
import { ValidarSoloLetras, ValidarSoloNumeros, ValidarCorreo } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, InputGroup, InputGroupText, Progress, Breadcrumb, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap'
import { useHistory } from 'react-router-dom';


import { CiSearch } from "react-icons/ci";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Foto from '../../../assets/faces/user (3).png'

import { TbInstitucion } from '../../../../CapaEntidades/TbInstitucion'
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario'
import { TbUsuarios } from '../../../../CapaEntidades/TbUsuarios';
import { EstadosIngresarInstitucion } from '../../../../CapaUtilidades/Estados/EstadosIngresarInstitucion';
import { GetProvincias, GetCantones, GetDistritos, GetBarrios, GetPaises } from '../../../../CapaDatos/DatosCargarUbicaciones'
import { GetRegionales, GetCircuitos } from '../../../../CapaDatos/DatosCargarRegionales'
import { ExisteInstitucion, saveInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';
import MenuItem from "../../../components/MenuItem";
import { IoMdReturnLeft } from "react-icons/io";
import { GetCargos, GetNombramientos, GetRoles, GetSexo, GetTipoDereccion } from '../../../../CapaDatos/DatosCargarSelects';
import { ContraseñaSegura, ErrorMessage, EvaluarSeguridadContraseña, ImageDefecto, NivelSeguridad, ObtenerImagen, ObtenerLogo, SuccessMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { ExisteFuncionario, ExisteNombramientoFun, GetDatosPadron, saveFunLaborarInstitucion, saveFuncionario, saveHistoricoNombramientos } from '../../../../CapaDatos/DatosFuncionarios';
import { ExisteUsuario, getDatosUsuario, saveUser } from '../../../../CapaDatos/DatosUsuarios';
import { useParams } from 'react-router-dom';
import * as  Tb from "react-icons/tb";
import * as  Fa from "react-icons/fa";
import Swal from 'sweetalert2';
//#endregion

const FrmRegistrarFuncionarioDocente = ({ codigoPresupuestario }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    const [ListaRegionales, setListaRegionales] = useState([]);
    const [ListaCircuitos, setListaCircuitos] = useState([]);
    const [ListaProvinciasInst, setListaProvinciasInst] = useState([]);
    const [ListaCantonesInst, setListaCantonesInst] = useState([]);
    const [ListaDistritosInst, setListaDistritosInst] = useState([]);
    const [ListaBarriosInst, setListaBarriosInst] = useState([]);
    const [openAC, setOpenAC] = useState('');
    const [DatosPerfil, setDatosPerfil] = useState([]);
    const [ListaPaises, setListaPaises] = useState([]);
    const [ListaProvincias, setListaProvincias] = useState([]);
    const [ListaCantones, setListaCantones] = useState([]);
    const [ListaDistritos, setListaDistritos] = useState([]);
    const [ListaBarrios, setListaBarrios] = useState([]);
    const [ListaCargos, setListaCargos] = useState([]);
    const [ListaNombramientos, setListaNombramientos] = useState([]);
    const [ListaRoles, setListaRoles] = useState([]);
    const [ListaTipoDereccion, setListaTipoDereccion] = useState([]);
    const [ListaSexo, setListaSexo] = useState([]);
    const [progressColor, setProgressColor] = useState('');
    const [levelMessage, setLevelMessage] = useState('');

    const CargarDatos = async () => {
        GetPaises(setListaPaises);
        GetProvincias(setListaProvincias);
        GetProvincias(setListaProvinciasInst);
        GetRegionales(setListaRegionales);
        GetCargos(setListaCargos);
        GetNombramientos(setListaNombramientos);
        GetRoles(setListaRoles);
        GetTipoDereccion(setListaTipoDereccion);
        GetSexo(setListaSexo);
    };

    useEffect(() => {
        const fetchData = async () => {
            await CargarDatos();
            const data = await getDatosUsuario();
            setDatosPerfil(data);
        };

        fetchData();
    }, []);




    const {
        cedula, setCedula,
        nombre, setNombre,
        apellido1, setapellido1,
        apellido2, setapellido2,
        cargo, setcargo,
        tipoNombramiento, setTipoNombramiento,
        telefonoFunPrincipal, setTelefonoFunPrincipal,
        telefonoFunSecundario, setTelefonoFunSecundario,
        correo, setCorreo,
        DireccionFuncionario, setDireccionFuncionario,
        fotoPerfil, setfotoPerfil,
        pais, setPais,
        selectedProvinciaFuncionario, setSelectedProvinciaFuncionario,
        selectedCantonFuncionario, setSelectedCantonFuncionario,
        selectedDistritoFuncionario, setSelectedDistritoFuncionario,
        selectedBarrioFuncionario, setSelectedBarrioFuncionario,
        sexo, setSexo,
        fechaIngreso, setFechaIngreso,
        fechaSalida, setFechaSalida,
        Observaciones, setObservaciones
    } = TbFuncionario();

    const {
        idUsuario, setidUsurio,
        usuario, setUsuario,
        password, setPassword,
        ultIngreso, setultIngreso,
        institucionUser, setInstitucionUser,
        permisoUsuario, setPermisoUsuario,
        estadoUser, setEstadoUser,
    } = TbUsuarios();

    const {
        codigoValido, setCodigoValido,
        telefonoValido, setTelefonoValido,
        directorValido, setdirectorValido,
        nombreValido, setNombreValido,
        apellido1Valido, setapellido1Valido,
        apellido2Valido, setapellido2Valido,
        telefonoFunPrincipalValido, setTelefonoFunPrincipalvalido,
        telefonoFunSecundarioValido, setTelefonoFunSecundariovalido,
        correoValido, setcorreoValido,
        cedulaValida, setcedulaValidad,
        mostrarContrasena, setMostrarContrasena,
        selectedImage, setSelectedImage,
        selectedLogo, setSelectedLogo,
    } = EstadosIngresarInstitucion();


    const MostrarContrasena = () => {
        setMostrarContrasena(!mostrarContrasena); // Cambia el estado para mostrar o no la contraseña
    };

    const Contraseña_TextChanged = (event) => {
        setPassword(event.target.value);
    };

    const nivelSeguridad = EvaluarSeguridadContraseña(password);


    const GuardarFuncionario = async (event) => {
        event.preventDefault();
        if (cedula.length === 9 || cedula.length === 12) {
            if (nivelSeguridad === NivelSeguridad.MuySegura || nivelSeguridad === NivelSeguridad.MediaSegura) {
                const existe = await ExisteFuncionario({ cedulaFuncionario: cedula })

                if (!existe.estado) {


                    const data =
                    {
                        cedulaFuncionario: cedula,
                        nombreCompleto: nombre.toUpperCase(),
                        apellido1: apellido1.toUpperCase(),
                        apellido2: apellido2.toUpperCase(),

                        telefonoPrincipal: telefonoFunPrincipal,
                        telefono1: telefonoFunSecundario,
                        correo: correo,
                        direccion: DireccionFuncionario,
                        foto: fotoPerfil,
                        nacionalidad: parseInt(pais),
                        Provincia: selectedProvinciaFuncionario.toString(),
                        Canton: selectedCantonFuncionario.toString(),
                        Distrito: selectedDistritoFuncionario.toString(),
                        Barrio: selectedBarrioFuncionario.toString(),
                        sexo: sexo

                    }

                    const success = await saveFuncionario(data);

                    if (success.estado) {

                        let Observacion = '';
                        if (Observaciones === '') {
                            Observacion = 'No hay observaciones';
                        } else {
                            Observacion = Observaciones
                        }

                        const tbFuncLaboraInstitucion = {
                            institucion: codigoPresupuestario,
                            cedFuncionario: cedula,
                            cargo: parseInt(cargo),
                            tipoNombramiento: parseInt(tipoNombramiento),
                            fechaInicio: fechaIngreso,
                            fechaFinaliza: fechaSalida,
                            observaciones: Observacion
                        }
                        //alert(JSON.stringify(tbFuncLaboraInstitucion));
                        const successFuncLaboraInstitucion = await saveFunLaborarInstitucion(tbFuncLaboraInstitucion);


                        if (successFuncLaboraInstitucion.estado) {

                            const dataUser =
                            {
                                usuario: usuario,
                                passwd: password,
                                idInstitucion: codigoPresupuestario,
                                permisosUsuario: parseInt(permisoUsuario),
                                estado: estadoUser

                            }

                            const successUser = await saveUser(dataUser)

                            if (successUser.estado) {

                                LimpiarFormulario();

                            } else {
                                ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                                return;
                            }

                        } else {
                            ErrorMessage('Advertencia', existe.mensaje)
                            return;
                        }


                    } else {
                        ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                        return;
                    }

                } else {
                    ErrorMessage('Advertencia', 'Este numero de Identificacion ya se encuentra registrado.')
                    Swal.fire({
                        title: "Notificación del Sistema",
                        text: "Este numero de Identificacion ya se encuentra registrado, Deseas solo registrar la institución en la que va a laborar  y los datos de usuario.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si"
                    }).then(async (result) => {
                        if (result.isConfirmed) {

                            let Observacion = '';
                            if (Observaciones === '') {
                                Observacion = 'No hay observaciones';
                            } else {
                                Observacion = Observaciones
                            }

                            const datosExiste = {
                                institucion: codigoPresupuestario,
                                cedFuncionario: cedula
                            }
                            const existeFun = await ExisteNombramientoFun(datosExiste)

                            if (existeFun) {
                                const tbFuncLaboraInstitucion = {
                                    institucion: codigoPresupuestario,
                                    cedFuncionario: cedula,
                                    cargo: parseInt(cargo),
                                    tipoNombramiento: parseInt(tipoNombramiento),
                                    fechaInicio: fechaIngreso,
                                    fechaFinaliza: fechaSalida,
                                    observaciones: Observacion
                                }
                                const successFuncLaboraInstitucion = await saveFunLaborarInstitucion(tbFuncLaboraInstitucion);


                                if (successFuncLaboraInstitucion.estado) {

                                    const dataUser =
                                    {
                                        usuario: usuario,
                                        passwd: password,
                                        idInstitucion: codigoPresupuestario,
                                        permisosUsuario: parseInt(permisoUsuario),
                                        estado: estadoUser

                                    }
                                    const fechaActual = new Date();
                                    const year = fechaActual.getFullYear();
                                    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
                                    const day = String(fechaActual.getDate()).padStart(2, '0');
                                    const hours = String(fechaActual.getHours()).padStart(2, '0');
                                    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
                                    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
                                    const tbHistorico = {
                                        cedulaFunc: cedula,
                                        cargo: parseInt(cargo),
                                        tipoNombramiento: parseInt(tipoNombramiento),
                                        fechaInicio: fechaIngreso,
                                        fechaFinaliza: fechaSalida,
                                        institucion: codigoPresupuestario,
                                        registradoPor: DatosPerfil[0].nombreCompleto + " " + DatosPerfil[0].apellido1 + " " + DatosPerfil[0].apellido2,
                                        fechaRegistro: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000`
                                    }

                                    await saveHistoricoNombramientos(tbHistorico)
                                    const successUser = await saveUser(dataUser)

                                    if (successUser.estado) {

                                        LimpiarFormulario();

                                    } else {
                                        ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                                        return;
                                    }

                                } else {
                                    ErrorMessage('Advertencia', 'Ocurrrio un error durante durante el proceso de guardado.')
                                    return;
                                }
                            } else {
                                ErrorMessage('Advertencia', 'Este usuario ya se encontrado registrado en esta institución')
                                return;
                            }
                        }
                    });
                    return;
                }
            } else {
                ErrorMessage('Notificación del Sistema', 'Nivel de seguridad de la contraseña no apto.');
                return;
            }
        } else {
            ErrorMessage('Advertencia', 'Ingrese un número de cédula valido.')
            return;
        }
    };

    const LimpiarFormulario = () => {
        setCedula('');
        setNombre('');
        setapellido1('');
        setapellido2('');
        setcargo('');
        setTipoNombramiento('');
        setTelefonoFunPrincipal('');
        setTelefonoFunSecundario('');
        setCorreo('');
        setDireccionFuncionario('');
        setfotoPerfil(null);
        setSelectedImage(null);
        setPais('');
        setSelectedProvinciaFuncionario('');
        setSelectedCantonFuncionario('');
        setSelectedDistritoFuncionario('');
        setSelectedBarrioFuncionario('');
        setSexo('');
        setUsuario('');
        setPassword('');
        setInstitucionUser('');
        setPermisoUsuario('');
        setEstadoUser(1);

        CargarDatos();

        const resetElementValue = (elementId) => {
            const element = document.getElementById(elementId);
            if (element !== null) {
                element.value = '';
            }
        };

        resetElementValue('NacionalidadFuncionario');
        resetElementValue('Sexo');
        resetElementValue('cargo');
        resetElementValue('tiponombramiento');
        resetElementValue('ProvinciaFuncionario');
        resetElementValue('CantonFuncionario');
        resetElementValue('DistritoFuncionario');
        resetElementValue('BarrioFuncionario');
        resetElementValue('tipoUsuario');
        resetElementValue('fileperfil');
    };

    const toggleAC = (id) => {
        if (openAC === id) {
            setOpenAC();
        } else {
            setOpenAC(id);
        }
    };

    const SeleccionarFecha = (e, set) => {
        set(e.target.value);
    };

    const BuscarPersona = async () => {
        try {
            const datosPadron = await GetDatosPadron(cedula);
            //console.log(JSON.stringify(datosPadron));
    
            // Verificar que los resultados existen y tienen datos
            if (datosPadron && datosPadron.results && datosPadron.results.length > 0) {
                const persona = datosPadron.results[0];
                setNombre(persona.firstname.toUpperCase() || '');
                setapellido1(persona.lastname1.toUpperCase() || '');
                setapellido2(persona.lastname2.toUpperCase() || '');
            } else {
                ErrorMessage('Notificación del sistema','Datos no encontrados o inexistentes')
                setNombre('');
                setapellido1('');
                setapellido2('');
            }
        } catch (error) {
            alert("Error al obtener los datos del padrón:", error);
        }
    }



    return (
        <Container fluid className=" pt-2   Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Funcionarios"
                        to={"/Menu/FrmFuncionarioDocente"}
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Registrar Funcionario"
                        to={"/Menu/FrmRegistrarFuncionarioDocente"}
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='mt-2 p-3  shadow border rounded' >
                <Form onSubmit={GuardarFuncionario}>
                    <h5 className='py-1' > Registrar Funcionario o Docente </h5>
                    <Accordion className='mb-5  ' flush open={openAC} toggle={toggleAC}>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="1" className=' accordionBorder accordion-button2'>Información Personal </AccordionHeader>
                            <AccordionBody accordionId="1" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="Provincia">
                                                Nacionalidad
                                            </Label>
                                            <Input
                                                required
                                                id="NacionalidadFuncionario"
                                                name="NacionalidadFuncionario"
                                                placeholder="NacionalidadFuncionario"
                                                type="select"
                                                defaultValue={pais}
                                                onChange={(e) => setPais(e.target.value)}
                                            >
                                                <option value="0" disabled selected  >Seleccione la Nacionalidad</option>
                                                {ListaPaises.map((paises, index) => (
                                                    <option key={paises.id} value={paises.id}  >{paises.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={9}></Col>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="identificacion" className="me-2">
                                                Identificación
                                            </Label>
                                            <InputGroup>
                                                <Input

                                                    required
                                                    id="identificacion"
                                                    name="identificacion"
                                                    placeholder="0-0000-0000"
                                                    type="text"
                                                    value={cedula}
                                                    onChange={(event) => {
                                                        ValidarSoloNumeros(event, setCedula, setcedulaValidad);
                                                        setUsuario(event.target.value);
                                                    }}
                                                    maxLength={12}
                                                    minLength={9}
                                                    className={cedulaValida ? ' ' : 'is-invalid  '}
                                                />
                                                <InputGroupText addonType="append" className='p-0'  >
                                                    <Button color="" className='p-0 px-2 border-0' onClick={BuscarPersona}>
                                                        <Fa.FaSearch size={20} />
                                                    </Button>
                                                </InputGroupText>
                                                {!cedulaValida && <div className="invalid-feedback">Formato de Cedula no valido</div>}
                                            </InputGroup>


                                        </FormGroup>
                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="nombre" className="me-2">
                                                Nombre
                                            </Label>
                                            <Input

                                                required
                                                id="nombre"
                                                name="nombre"
                                                placeholder=""
                                                type="text"
                                                value={nombre}
                                                onChange={(event) => ValidarSoloLetras(event, setNombre, setNombreValido)}
                                                className={nombreValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!nombreValido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                        </FormGroup>

                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="primerApellido" className="me-2">
                                                Primer Apellido
                                            </Label>
                                            <Input

                                                required
                                                id="primerApellido"
                                                name="primerApellido"
                                                placeholder=""
                                                type="text"
                                                value={apellido1}
                                                onChange={(event) => ValidarSoloLetras(event, setapellido1, setapellido1Valido)}
                                                className={apellido1Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!apellido1Valido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                        </FormGroup>

                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="segundoApellido" className="me-2">
                                                Segundo Apellido
                                            </Label>
                                            <Input

                                                required
                                                id="segundoApellido"
                                                name="segundoApellido"
                                                placeholder=""
                                                type="text"
                                                value={apellido2}
                                                onChange={(event) => ValidarSoloLetras(event, setapellido2, setapellido2Valido)}
                                                className={apellido2Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!apellido2Valido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                        </FormGroup>

                                    </Col>

                                    <Col md={4} className='py-1'>
                                        <FormGroup>
                                            <Label for="Sexo">
                                                Sexo
                                            </Label>
                                            <Input
                                                required
                                                id="Sexo"
                                                name="Sexo"
                                                placeholder="NacionalidadFuncionario"
                                                type="select"
                                                defaultValue={sexo}
                                                onChange={(e) => setSexo(e.target.value)}
                                            >
                                                <option value="0" disabled selected  >Seleccione el tipo Sexo</option>
                                                {ListaSexo.map(sexo => (
                                                    <option key={sexo.id} value={sexo.id}>{sexo.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={3} className='py-1 '>

                                        <FormGroup>
                                            <Label for="files" className='pr-2'>
                                                Foto de Perfil
                                            </Label>
                                            <Input
                                            className=''
                                                hidden
                                                id="files"
                                                name="files"
                                                type="file"
                                                accept='image/*'
                                                onChange={(e) => ObtenerImagen(e, setSelectedImage, setfotoPerfil)}
                                            />
                                            <br></br>
                                            <Label for="files" className="custom-file-upload ">Seleccionar Archivo</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} >

                                        {selectedImage ? (
                                            <img src={selectedImage} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                        ) : (
                                            <img src={Foto} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                        )}


                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="2" className=' accordionBorder accordion-button2'>Lugar de Residencia </AccordionHeader>
                            <AccordionBody accordionId="2" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="Provincia">
                                                Provincia
                                            </Label>
                                            <Input
                                                required
                                                id="ProvinciaFuncionario"
                                                name="ProvinciaFuncionario"
                                                placeholder="ProvinciaFuncionario"
                                                type="select"
                                                defaultValue={selectedProvinciaFuncionario}
                                                onChange={(event) => GetCantones(event, setSelectedProvinciaFuncionario, setListaCantones)}
                                            >
                                                <option value="0" disabled selected  >Seleccione una Provincia</option>
                                                {ListaProvincias.map(provincia => (
                                                    <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="CantonFuncionario">
                                                Cantón
                                            </Label>
                                            <Input
                                                required
                                                id="CantoFuncionarion"
                                                name="CantonFuncionario"

                                                type="select"
                                                defaultValue={selectedCantonFuncionario}
                                                onChange={(event) => GetDistritos(event, selectedProvinciaFuncionario, setSelectedCantonFuncionario, setListaDistritos)}

                                            >
                                                <option value="0" disabled selected  >Seleccione el Cantón</option>
                                                {ListaCantones.map(canton => (
                                                    <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="DistritoFuncionario">
                                                Distrito
                                            </Label>
                                            <Input
                                                required
                                                id="DistritoFuncionario"
                                                name="DistritoFuncionario"

                                                type="select"
                                                defaultValue={selectedDistritoFuncionario}
                                                onChange={(event) => GetBarrios(event, selectedProvinciaFuncionario, selectedCantonFuncionario, setSelectedDistritoFuncionario, setListaBarrios)}
                                            >
                                                <option value="0" disabled selected  >Seleccione el Distrito</option>
                                                {ListaDistritos.map(distrito => (
                                                    <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="BarrioFuncionario">
                                                Barrio
                                            </Label>
                                            <Input
                                                required
                                                id="BarrioFuncionario"
                                                name="BarrioFuncionario"

                                                type="select"
                                                defaultValue={selectedBarrioFuncionario}


                                                onChange={(e) => setSelectedBarrioFuncionario(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Seleccione el Barrio</option>
                                                {ListaBarrios.map(barrio => (
                                                    <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} className='py-1'>
                                        <Label for="DireccionFuncionario">
                                            Dirección
                                        </Label>
                                        <Input
                                            required
                                            id="DireccionFuncionario"
                                            name="DireccionFuncionario"
                                            placeholder="200 mt al este de la calle 10"
                                            value={DireccionFuncionario}
                                            onChange={(e) => setDireccionFuncionario(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="3" className=' accordionBorder accordion-button2'>Información de Contacto </AccordionHeader>
                            <AccordionBody accordionId="3" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="telefonfuncionarioo">
                                                Telefono Principal
                                            </Label>
                                            <Input
                                                maxLength={8}
                                                minLength={8}
                                                id="telefonofuncionario"
                                                name="telefonofuncionario"
                                                placeholder=""
                                                type="tel"
                                                value={telefonoFunPrincipal}
                                                onChange={(event) => ValidarSoloNumeros(event, setTelefonoFunPrincipal, setTelefonoFunPrincipalvalido)}
                                                className={telefonoFunPrincipalValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!telefonoFunPrincipalValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="telefonSecundariofuncionarioo">
                                                Telefono Secundario
                                            </Label>
                                            <Input
                                                maxLength={8}
                                                minLength={8}
                                                id="telefonoSecundariofuncionario"
                                                name="telefonoSecundariofuncionario"
                                                placeholder=""
                                                type="tel"
                                                value={telefonoFunSecundario}
                                                onChange={(event) => ValidarSoloNumeros(event, setTelefonoFunSecundario, setTelefonoFunSecundariovalido)}
                                                className={telefonoFunSecundarioValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!telefonoFunSecundarioValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className='py-1'>
                                        <FormGroup>
                                            <Label for="email">
                                                Correo Electronico
                                            </Label>
                                            <Input
                                                required
                                                id="email"
                                                name="email"
                                                placeholder=""
                                                type="mail"
                                                value={correo}
                                                onChange={(event) => ValidarCorreo(event, setCorreo, setcorreoValido)}
                                                className={correoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!correoValido && <div className="invalid-feedback">Formato de correo no valido.</div>}

                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="4" className=' accordionBorder accordion-button2'>Información Laboral </AccordionHeader>
                            <AccordionBody accordionId="4" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="cargo">
                                                Cargo
                                            </Label>
                                            <Input
                                                required
                                                id="cargo"
                                                name="cargo"
                                                placeholder="Nombre centro Educativo"
                                                type="select"
                                                defaultValue={cargo}

                                                onChange={(e) => setcargo(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Seleccione el Cargo</option>
                                                {ListaCargos.filter((cargo, index) => [3, 4, 5, 6].includes(index)).map(listaCargo => (
                                                    <option key={listaCargo.id} value={listaCargo.id}>{listaCargo.nombre}</option>
                                                ))}

                                            </Input>

                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="tiponombramiento">
                                                Tipo de Nombramiento
                                            </Label>
                                            <Input
                                                required
                                                id="tiponombramiento"
                                                name="tiponombramiento"

                                                type="select"
                                                defaultValue={tipoNombramiento}
                                                onChange={(e) => setTipoNombramiento(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Selec. tipo de Nombramiento</option>
                                                {ListaNombramientos.map(listaNombramiento => (
                                                    <option key={listaNombramiento.id} value={listaNombramiento.id}>{listaNombramiento.nombre}</option>
                                                ))}

                                            </Input>

                                        </FormGroup>
                                    </Col >
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="fechaingreso">
                                                Fecha de Ingreso
                                            </Label>
                                            <Input
                                                required
                                                id="fechaingreso"
                                                name="fechaingreso"
                                                placeholder=""
                                                type="date"
                                                value={fechaIngreso}
                                                onChange={(e) => SeleccionarFecha(e, setFechaIngreso)}
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="fechasalida">
                                                Fecha de Salida
                                            </Label>
                                            <Input

                                                id="fechasalida"
                                                name="fechasalida"
                                                placeholder=""
                                                type="date"
                                                value={fechaSalida}
                                                onChange={(e) => SeleccionarFecha(e, setFechaSalida)}
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="observaciones">
                                                Observaciones
                                            </Label>
                                            <Input

                                                id="observaciones"
                                                name="observaciones"
                                                placeholder=""
                                                type="text"
                                                value={Observaciones}
                                                onChange={(e) => setObservaciones(e.target.value)}
                                            />
                                        </FormGroup>

                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="5" className=' accordionBorder accordion-button2'>Información de Usuario </AccordionHeader>
                            <AccordionBody accordionId="5" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="usuario">
                                                Usuario
                                            </Label>
                                            <Input
                                                readOnly
                                                required
                                                id="usuario"
                                                name="usuario"
                                                placeholder=""
                                                type="text"
                                                value={usuario}
                                                onChange={(e) => setUsuario(e.target.value)}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <InputGroup className='d-flex justify-content-between '>
                                                <Label for=" password">
                                                    Contraseña
                                                </Label>
                                                <InputGroupText addonType="append" className='p-0 mr-5  border-0 '>
                                                    <Button
                                                        onClick={(e) => ContraseñaSegura(setPassword)}
                                                        color=''
                                                        className='p-0 px-2 rounded btn-editar '
                                                        title='Generar contraseña segura'>
                                                        <Tb.TbPasswordFingerprint />
                                                    </Button>
                                                </InputGroupText>
                                            </InputGroup>

                                            <InputGroup>
                                                <Input
                                                    required
                                                    id="password"
                                                    name="password"
                                                    placeholder=""
                                                    type={mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                    value={password}
                                                    onChange={Contraseña_TextChanged}
                                                />
                                                <InputGroupText addonType="append" className='p-0'>
                                                    <Button onClick={MostrarContrasena} color="" className='p-0 px-2'>
                                                        {mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                    </Button>
                                                </InputGroupText>
                                            </InputGroup>
                                            {NivelSeguridad && (
                                                <div>
                                                    {nivelSeguridad}
                                                    <Progress

                                                        value={nivelSeguridad === NivelSeguridad.MuySegura ? 100 : nivelSeguridad === NivelSeguridad.MediaSegura ? 75 : nivelSeguridad === NivelSeguridad.PocoSegura ? 25 : 1}
                                                        color={nivelSeguridad === NivelSeguridad.MuySegura ? 'success' : nivelSeguridad === NivelSeguridad.MediaSegura ? 'warning' : nivelSeguridad === NivelSeguridad.PocoSegura ? 'danger' : 'danger'}
                                                    />
                                                </div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for=" tipoUsuario">
                                                Tipo Usuario
                                            </Label>
                                            <Input
                                                required
                                                id="tipoUsuario"
                                                name=" tipoUsuario"
                                                placeholder=""
                                                type="select"
                                                defaultValue={permisoUsuario}
                                                onChange={(e) => setPermisoUsuario(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Selec. roll de Usuario</option>
                                                {ListaRoles.filter((roll, index) => [3].includes(index)).map(roles => (
                                                    <option key={roles.id} value={roles.id}>{roles.nombre}</option>
                                                ))}

                                            </Input>


                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                    </Accordion>
                    <Col className='d-flex justify-content-between '>

                        <Button type="button" className='p-0 btn   btnCancelar d-flex justify-content-start px-2' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className='btnAceptar'
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"

                                    to={"/Menu/FrmFuncionarioDocente"}
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className='p-1  px-2 btn btnAceptar d-flex justify-content-end' id='GuardarFuncionario' >
                            Registrar Funcionario
                        </Button>
                    </Col>

                </Form>
            </Container>



        </Container>
    )
}

export default FrmRegistrarFuncionarioDocente
