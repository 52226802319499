import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { ExisteSeccion, SaveSeccion } from '../../../../CapaDatos/DatosSecciones';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { GetGrados } from '../../../../CapaDatos/DatosCargarSelects';
import { ValidarLetrasNumerosGuion, ValidarSoloNumeros } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { ErrorMessage, SuccessMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { ObtenerNombreInst, obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { getInstituciondatos, getTipoInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';


const FrmRegistrarSecciones = ({ codigoPresupuestario }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    const [ListaGrados, setListaGrados] = useState([]);
    const [tipoInstitucion, setTipoInstitucion] = useState('');

    const {
        IdSeccion, setIDSeccion,
        NombreSeccion, setNombreSeccion,
        Nivel, setNivel,
        CantMax, setCantMax,
        IdInstitucion, setIdInstitucion,
        NombreInst, setNombreInst,
        EstadoSeccion, setEstadoSeccion,
        CantidadValida, setCantidadValida,
        SeccionValida, setSeccionValida
    } = TbSecciones();

    useEffect(() => {
        const fetchData = async () => {
            GetGrados(setListaGrados);
            const idInstitucion = obtenerIdInstitucion();
            setIdInstitucion(idInstitucion);
            setNombreInst(ObtenerNombreInst());

            try {
                const tipoIns = await getTipoInstitucion({ codigoPresupuestario: idInstitucion });
                
                setTipoInstitucion(tipoIns);
            } catch (error) {
                console.error("Error fetching tipoInstitucion:", error);
            }
        };

        fetchData();
    }, []);
    const GradosFiltrados = ListaGrados.filter ( grado => {
       
        if (tipoInstitucion === 1) {
            return grado.id >= -1 && grado.id <=6;
        }else if (tipoInstitucion === 2) {
         return grado.id >= 7 && grado.id <= 12;
        }
        return false;
    })

    const GuardarSeccion = async (event) => {
        event.preventDefault();
        const partesNombreSeccion = NombreSeccion.split('-');

        if (partesNombreSeccion.length > 1 && partesNombreSeccion[1].trim().length > 0) {
            const dataExiste = {
                idInstitucion: IdInstitucion,
                nombreSeccion: NombreSeccion,
            }
            if (parseInt(CantMax) > 0) {
                const existe = await ExisteSeccion(dataExiste);

                if (!existe) {
                    const data = {
                        nombreSeccion: NombreSeccion,
                        nivel: Nivel.toString(),
                        cantMax: parseInt(CantMax),
                        institucion: IdInstitucion,
                        estado: EstadoSeccion
                    }



                    const success = await SaveSeccion(data);

                    if (success) {
                        const grado = document.getElementById('nivel');
                        grado.selectedIndex = 0;
                        setNombreSeccion('');
                        setCantMax(0);
                        SuccessMessage('Exitoso', 'Sección registrada con exito')

                    } else {
                        ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante el proceso de guardado')
                        return;
                    }
                } else {
                    ErrorMessage('Notificación del Sistema', 'La seccion ya se encuentra registrada')
                    return;
                }
            } else {
                ErrorMessage('Notificación del Sistema', 'Cantidad de alumnos inválido. Asegúrate de ingresar la catidad maxima de Alumnos ya que no puede ser 0');
                return;
            }

        } else {
            // Si no hay datos después del '-', mostrar un mensaje de error
            ErrorMessage('Notificación del Sistema', 'Nombre de sección inválido. Asegúrate de ingresar datos después del "-"');
            return;
        }







    }

    const CrearNombreSeccion = (e) => {
        let seccion = parseInt(e.target.value); // Convertir a número
        //alert(seccion)
        setNivel(seccion);
        //alert(seccion);
        if (seccion === -1) {
            setNombreSeccion("Materno" + "-");
        } else if (seccion === 0) {
            setNombreSeccion("Transición" + "-");
        } else {
            setNombreSeccion(seccion + "-");
        }
    }

    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Secciones"
                        to="/Menu/FrmSecciones"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Registrar Secciones"
                        to="/Menu/FrmRegistrarSecciones"
                        classname={'NoDecoration2 tlink'}
                    />
                </Breadcrumb>

            </Col>

            <Container fluid className='  p-3 shadow3 border rounded  ' id='institucion'  >
                <Form onSubmit={GuardarSeccion} id='form1' className='px-5' >
                    <h5 className='py-2' > Actualizar Sección </h5>
                    <Row >
                        <Col md={6}>
                            <FormGroup>
                                <Label for="cod.Presupuestario" className="me-2">
                                    Código Presupuestario
                                </Label>

                                <Input

                                    readOnly
                                    required
                                    id="cod.Presupuestario"
                                    name="cod.Presupuestario"

                                    type="text"
                                    value={IdInstitucion + " - " + NombreInst}

                                />
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup className='mb-0'>
                                <Label for="nivel">
                                    Nivel
                                </Label>
                                <Input
                                    required
                                    id="nivel"
                                    name="nivel"

                                    type="select"
                                    defaultValue={Nivel}
                                    onChange={(e) => CrearNombreSeccion(e)}
                                >
                                    <option key={0} value={0} disabled selected >Seleccione el grado</option>
                                    {GradosFiltrados.map((grados, index) => (
                                        <option key={grados.id} value={grados.id}  >{grados.nombre}</option>
                                    ))}

                                </Input>
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <Label for="seccion" className="me-2">
                                    Nombre de la Sección
                                </Label>

                                <Input
                                    required

                                    id="seccion"
                                    name="seccion"

                                    type="text"
                                    value={NombreSeccion}
                                    onChange={(e) => ValidarLetrasNumerosGuion(e, setNombreSeccion, setSeccionValida)}
                                    className={SeccionValida ? ' ' : 'is-invalid  '}
                                />

                                {!SeccionValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}
                                {SeccionValida && (
                                    <p className='LetraPeq'><strong>* Ejemplo 7-1 ó 7-A.</strong></p>
                                )}

                            </FormGroup>
                        </Col>




                        <Col md={6}>
                            <FormGroup>
                                <Label for="cantMax" className="me-2">
                                    Cantidad Maxima de alumnos
                                </Label>

                                <Input
                                    required
                                    id="cantMax"
                                    name="cantMax"

                                    type="number"
                                    value={CantMax}
                                    onChange={(event) => ValidarSoloNumeros(event, setCantMax, setCantidadValida)}
                                    maxLength={2}
                                    minLength={2}
                                    className={CantidadValida ? ' ' : 'is-invalid  '}
                                />

                                {!CantidadValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}


                            </FormGroup>
                        </Col>

                    </Row>
                    <Col className='d-flex justify-content-between '>

                        <Button type="button" className='p-0 btn  px-2 btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className='btnAceptar'
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"

                                    to="/Menu/FrmSecciones"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className='p-1 btn  px-2 btnAceptar d-flex justify-content-end' id='GuardarFuncionario' >
                            Registrar Sección
                        </Button>
                    </Col>

                </Form>

            </Container>
        </Container>
    )
}

export default FrmRegistrarSecciones
