import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import * as Pi from "react-icons/pi";
import * as Gi from "react-icons/gi";
import * as Fc from "react-icons/fc";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import MenuItem from "./MenuItem";
import { Roll } from "../../CapaUtilidades/Utils/Cahe";
import { obtenerIdInstitucion } from "../../CapaUtilidades/Utils/encryptUserData";

 


/**
 * Componente Sidebar menu lateral
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.isOpen - Indica si la barra lateral está abierta.
 * @param {function} props.toggleSidebar - Función para alternar la visibilidad de la barra lateral.
 * @returns {JSX.Element} Componente Sidebar.
 */
const Sidebar = ({ isOpen, toggleSidebar }) => {
 

  


  const [activeSubMenu, setActiveSubMenu] = useState(null);

   /**
   * Maneja el clic en un elemento de menú para alternar la visibilidad del submenú correspondiente.
   * @param {string} menuId - Identificador del menú.
   */
  const handleNavLinkClick = (menuId) => {
    setActiveSubMenu(activeSubMenu === menuId ? null : menuId);
  };

  const handleSidebarItemClick = () => {
    if (window.innerWidth <= 1200) {
      toggleSidebar();
    }
    setActiveSubMenu(null);
  };

     
  return (
    <div className={`sidebar ${isOpen ? "" : "hidden"}`}>
      <ul>
        <MenuItem
          icon={<Fa.FaDesktop className="me-1" />}
          text="Dashboard"
          onClick={handleSidebarItemClick}
          to="/Menu"
          classname={'text-sidebar'}
        />

        <MenuItem //Gestion Estudiantes
          icon={<Pi.PiStudentFill className="me-1 sizeIcon" />}
          text="Gestion Estudiantes"
          subMenuItems={[
            { to: "/Menu/GestionEstudiante", text: "opc1", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc2", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc3", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc4", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc5", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionEstudiantes"}
          onClick={() => handleNavLinkClick("gestionEstudiantes")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar'}
        />

        <MenuItem // Gestion Docentes
          icon={<Gi.GiTeacher className="me-1 sizeIcon" />}
          text="Gestion Docentes"
          subMenuItems={[
            { to: "/Menu/GestionProfesores", text: "opc1", onClick: handleSidebarItemClick },
            { to: "/Menu/Administradores", text: "aqui", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc3", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc4", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "opc5", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionProfesores"}
          onClick={() => handleNavLinkClick("gestionProfesores")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar'}
        />

        <MenuItem // Gestion Adminstrativa
          icon={<Gi.GiTeacher className="me-1 sizeIcon" />}
          text="Gestion Adminstrativa"
          subMenuItems={[
            
            { to: "/Menu/FrmFuncionarioDocente", text: "Funcionarios", onClick: handleSidebarItemClick },
            { to: "/Menu/FrmAsignaturas", text: "Asignaturas", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "Aulas", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "Secciones", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "Activos", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "Grupo Familiar", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "Encargado Legal", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "Estudiantes", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: "Prematriculas", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionAdministrativa"}
          onClick={() => handleNavLinkClick("gestionAdministrativa")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar'}
        />




        <MenuItem // Item para la opcion de superusurios
          icon={<Md.MdDeveloperMode className="me-1 sizeIcon" />}
          text="Desarrolladores"
          subMenuItems={[
            { to: "/Menu/IngresarInstitucion", text: "Ingresar Institución", onClick: handleSidebarItemClick },
            { to: "/Menu/ListaInstituciones", text: "Instituciones", onClick: handleSidebarItemClick },
            
             
          ]}
          isActive={activeSubMenu === "desarrolladores"}
          onClick={() => handleNavLinkClick("desarrolladores")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar'}
        />
      </ul>
    </div>
  );
};

export default Sidebar;
