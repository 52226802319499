import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;


export async function inicioSesion(data){
    url = endpoint.urlLogin;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        
        const response = await fetch(url, {
           
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            //throw new Error(`HTTP error! status: ${response.status}`);
            return null;
        }

        const DatosUsuario = await response.json();
    
      
        
        if (DatosUsuario.estado === false) {
            
           
            return [];
           
        }
       
        return DatosUsuario

    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function inicioSesionEncargado(data){
    url = endpoint.urlLoginEncargado;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //console.log(data);
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        //console.log(JSON.stringify(response))
        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            //throw new Error(`HTTP error! status: ${response.status}`);
            return null;
        }

        const DatosUsuario = await response.json();
    
      
        
        if (DatosUsuario.estado === false) {
            
            return [];        
        }
       
        return DatosUsuario

    } catch (error) {
        console.log(error);
        return null;
    }
}