import { useState } from 'react';
import { obtenerIdInstitucion, obtenerUsuario } from '../CapaUtilidades/Utils/encryptUserData';

export const TbComunicados = () => {

    const [ListaEncargados, setListaEncargados] = useState([]);
    const [selectedEncargados, setSelectedEncargados] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [listaEnviados, setListaEnviados] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [enviando, setEnviando] = useState(false);
    const [mensaje, setMensaje]= useState('')
    const [destinatario, setDestinatario] = useState('');
    const fechaEnvio = new Date().toISOString().split('T')[0];
    const enviadoPor = obtenerUsuario();
    const institucionEnvia = obtenerIdInstitucion();
    const cursoLectivo = new Date().getFullYear();
    const [detalleComunicado , setDetalleComunicado] = useState('');
    const medioComunicado = "whatsapp"  ;
    const [selectAll, setSelectAll] = useState(false);




    

    return {


        ListaEncargados, setListaEncargados,
        selectedEncargados, setSelectedEncargados,
        loading, setLoading,
        modalOpen, setModalOpen,
        listaEnviados, setListaEnviados,
        showEmojiPicker, setShowEmojiPicker,
        enviando, setEnviando,
        mensaje, setMensaje,
        destinatario, setDestinatario,
        fechaEnvio,
        enviadoPor,
        institucionEnvia,
        cursoLectivo,
        detalleComunicado, setDetalleComunicado,
        medioComunicado, 
        selectAll, setSelectAll,
        

        

    };
};