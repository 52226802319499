import React, { useEffect, useState } from 'react';
import { Container, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, Breadcrumb, Row } from 'reactstrap';
import MenuItem from '../../../components/MenuItem';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';  // Importa el locale español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ErrorMessage, InfoMessage, SuccessMessage, WarningMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { TbAgenda } from '../../../../CapaEntidades/TbAgenda';
import { obtenerUsuario } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { ActualizarEventoAgenda, EliminarEventoAgenda, ListarAgenda, RegistrarEventoAgenda } from '../../../../CapaDatos/DatosDocentes/DatosAgenda';

// Configura moment en español
moment.locale('es'); // Establece el idioma español en moment
const localizer = momentLocalizer(moment);


const FrmCalendario = () => {

    const TBAgenda = TbAgenda();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ListarAgenda({ CedulaDocente: obtenerUsuario() });

                TBAgenda.setEventos(data)
                //console.log(JSON.stringify(data))
            } catch (error) {
                console.error("Error al obtener los datos del usuario:", error);
            }
        };

        fetchData();
    }, []);

    const SeleccionarSlot = (slotInfo) => {
        const fechaActual = new Date(); // Obtiene la fecha actual
        const fechaSeleccionada = new Date(slotInfo.start); // Asumiendo que slotInfo.start es la fecha del slot

        // Normalizamos ambas fechas a medianoche para comparar solo el día, mes y año
        fechaActual.setHours(0, 0, 0, 0);
        fechaSeleccionada.setHours(0, 0, 0, 0);

        // Compara la fecha seleccionada con la fecha actual
        if (fechaSeleccionada >= fechaActual) {
            TBAgenda.setSeleccionarSlot(slotInfo);
            TBAgenda.setModalOpen(true);
        } else {
            WarningMessage("Notificación del Sistema", "Imposible registrar un evento en una fecha anterior a la actual")


            // Aquí puedes manejar el caso en que la fecha sea anterior, como mostrar un mensaje o ignorar la selección.
        }
    };

    // Abre el modal al seleccionar un evento
    const SeleccionarEvento = (event) => {
        TBAgenda.setSeleccionarEvento(event);
        TBAgenda.setEventModalOpen(true);
    };

    // Cierra el modal de agregar evento
    const closeModal = () => {
        TBAgenda.setModalOpen(false);
        TBAgenda.setNuevoEvento({ title: '', start: '', end: '' });
    };
    const closeModalActualizar = () => {
        TBAgenda.setModalActualizarOpen(false);
        //TBAgenda.setNuevoEvento({ title: '', start: '', end: '' });
    };
    // Cierra el modal del evento
    const closeEventModal = () => {
        TBAgenda.setEventModalOpen(false);
        TBAgenda.setSeleccionarEvento(null);
    };

    // Añade un nuevo evento al calendario
    const AgregarEvento = async (event) => {
        event.preventDefault();

        // Verificar que los campos no estén vacíos
        if (!TBAgenda.nuevoEvento.title || !TBAgenda.nuevoEvento.start || !TBAgenda.nuevoEvento.end) {
            InfoMessage('Por favor, completa todos los campos.');
            return; // Salir de la función si algún campo está vacío
        }

        // Crear DateTime para el nuevo evento
        const startDateTime = new Date(TBAgenda.seleccionarSlot.start);
        const endDateTime = new Date(TBAgenda.seleccionarSlot.start);

        // Ajusta la hora inicial y final
        const [startHour, startMinute] = TBAgenda.nuevoEvento.start.split(':').map(Number);
        const [endHour, endMinute] = TBAgenda.nuevoEvento.end.split(':').map(Number);

        startDateTime.setHours(startHour);
        startDateTime.setMinutes(startMinute);

        endDateTime.setHours(endHour);
        endDateTime.setMinutes(endMinute);

        // Verificar que el evento nuevo no se solape con eventos existentes
        const eventosSolapados = TBAgenda.eventos.some(eventoExistente => {
            const eventoStart = new Date(eventoExistente.start);
            const eventoEnd = new Date(eventoExistente.end);

            // Verificar si el nuevo evento se solapa con el evento existente
            return (startDateTime < eventoEnd && endDateTime > eventoStart);
        });

        if (eventosSolapados) {
            InfoMessage('Notificación del Sistema', 'Ya se encuentra un evento agendado. Por favor, selecciona otro horario.');
            return; // Salir de la función si hay un solapamiento
        }

        // Extraer partes de la fecha y hora
        const AnioInicial = startDateTime.getFullYear();
        const MesInicial = String(startDateTime.getMonth() + 1).padStart(2, '0'); // Mes es 0-indexado
        const DiaInicial = String(startDateTime.getDate()).padStart(2, '0');
        const HoraInicial = String(startDateTime.getHours()).padStart(2, '0');
        const MinutoInicial = String(startDateTime.getMinutes()).padStart(2, '0');

        // Extraer partes de la fecha y hora
        const AnioFinal = endDateTime.getFullYear();
        const MesFinal = String(endDateTime.getMonth() + 1).padStart(2, '0'); // Mes es 0-indexado
        const DiaFinal = String(endDateTime.getDate()).padStart(2, '0');
        const HoraFinal = String(endDateTime.getHours()).padStart(2, '0');
        const MinutoFinal = String(endDateTime.getMinutes()).padStart(2, '0');
        // Si no hay solapamiento, agregar el nuevo evento
        const evento = {
            Titulo: TBAgenda.nuevoEvento.title,
            AnioInicial: parseInt(AnioInicial),
            MesInicial: parseInt(MesInicial),
            DiaInicial: parseInt(DiaInicial),
            HoraInicial: parseInt(HoraInicial),
            MinutoInicial: parseInt(MinutoInicial),
            AnioFinal: parseInt(AnioFinal),
            MesFinal: parseInt(MesFinal),
            DiaFinal: parseInt(DiaFinal),
            HoraFinal: parseInt(HoraFinal),
            MinutoFinal: parseInt(MinutoFinal),
            CedulaDocente: obtenerUsuario()

        }

        const success = await RegistrarEventoAgenda(evento)

        if (success) {
            SuccessMessage('Sistema de Notificación', 'El evento ha sido registrado exitosamente.');
            const data = await ListarAgenda({ CedulaDocente: obtenerUsuario() });

            TBAgenda.setEventos(data)
            closeModal(); // Cierra el modal después de agregar el evento
        } else {
            ErrorMessage('Sistema de Notificación', 'No fue posible registrar el evento en la agenda. Por favor, intente nuevamente.');
            closeModal(); // Cierra el modal después de agregar el evento
        }


    };


    const ActualizarEvento = async (e, id) => {
        e.preventDefault();


        // Verificar que nuevoEvento no sea null o undefined y que los campos no estén vacíos
        if (!TBAgenda.nuevoEvento || !TBAgenda.nuevoEvento.title || !TBAgenda.nuevoEvento.start || !TBAgenda.nuevoEvento.end) {
            InfoMessage('Por favor, completa todos los campos.');
            return; // Salir de la función si algún campo está vacío
        }


        const startDateTime = new Date(TBAgenda.nuevoEvento.start);
        const endDateTime = new Date(TBAgenda.nuevoEvento.end);
        // Obtener horas y minutos directamente de los objetos Date
        const startHour = startDateTime.getHours();
        const startMinute = startDateTime.getMinutes();
        const endHour = endDateTime.getHours();
        const endMinute = endDateTime.getMinutes();

        // Actualizar los DateTime con las horas y minutos correctos
        startDateTime.setHours(startHour);
        startDateTime.setMinutes(startMinute);

        endDateTime.setHours(endHour);
        endDateTime.setMinutes(endMinute);

   

        // Extraer partes de la fecha y hora
        const AnioInicial = startDateTime.getFullYear();
        const MesInicial = String(startDateTime.getMonth() + 1).padStart(2, '0'); // Mes es 0-indexado
        const DiaInicial = String(startDateTime.getDate()).padStart(2, '0');
        const HoraInicial = String(startDateTime.getHours()).padStart(2, '0');
        const MinutoInicial = String(startDateTime.getMinutes()).padStart(2, '0');

        // Extraer partes de la fecha y hora
        const AnioFinal = endDateTime.getFullYear();
        const MesFinal = String(endDateTime.getMonth() + 1).padStart(2, '0'); // Mes es 0-indexado
        const DiaFinal = String(endDateTime.getDate()).padStart(2, '0');
        const HoraFinal = String(endDateTime.getHours()).padStart(2, '0');
        const MinutoFinal = String(endDateTime.getMinutes()).padStart(2, '0');
        // Si no hay solapamiento, agregar el nuevo evento
        // Verificar que el evento nuevo no se solape con eventos existentes

        const evento = {
            idAgenda: TBAgenda.nuevoEvento.id,
            Titulo: TBAgenda.nuevoEvento.title,
            AnioInicial: parseInt(AnioInicial),
            MesInicial: parseInt(MesInicial),
            DiaInicial: parseInt(DiaInicial),
            HoraInicial: parseInt(HoraInicial),
            MinutoInicial: parseInt(MinutoInicial),
            AnioFinal: parseInt(AnioFinal),
            MesFinal: parseInt(MesFinal),
            DiaFinal: parseInt(DiaFinal),
            HoraFinal: parseInt(HoraFinal),
            MinutoFinal: parseInt(MinutoFinal),
            CedulaDocente: obtenerUsuario()

        }
        //console.log(JSON.stringify(evento))
        const success = await ActualizarEventoAgenda(evento)

        if (success) {
            SuccessMessage('Sistema de Notificación', 'El evento ha sido actualizado exitosamente.');
            const data = await ListarAgenda({ CedulaDocente: obtenerUsuario() });

            TBAgenda.setEventos(data)
            closeModalActualizar();
        } else {
            ErrorMessage('Sistema de Notificación', 'No fue posible actualizar el evento en la agenda. Por favor, intente nuevamente.');
            closeModalActualizar();
        }

    };





    const MostarModalActualizar = (id) => {

        // Encuentra el evento seleccionado
        const selectedEvent = TBAgenda.eventos.find(event => event.ID === id);
        //alert(JSON.stringify(selectedEvent));
        if (selectedEvent) {
            // Cierra el modal actual
            TBAgenda.setEventModalOpen(false);

            // Actualiza el estado de newEvent con los datos del evento seleccionado
            TBAgenda.setNuevoEvento({
                id: selectedEvent.ID,
                title: selectedEvent.title,
                start: selectedEvent.start, // formato de hora
                end: selectedEvent.end
            });
            console.log(JSON.stringify(TBAgenda.nuevoEvento));
            // Abre el modal de actualización
            TBAgenda.setModalActualizarOpen(true);
        }
    };

    const EliminarEvento = async (id) => {

       // alert(id)
        const success = await EliminarEventoAgenda({ idAgenda: parseInt(id) })

        if (success) {
            closeEventModal(); // Cierra el modal después de eliminar el evento
            const data = await ListarAgenda({ CedulaDocente: obtenerUsuario() });

            TBAgenda.setEventos(data)
        } else {
            ErrorMessage("Notificación del Sistema", "Ocurrio un error al eliminar el evento agendado")
        }
    };





    return (
        <Container fluid className="Divcontenedor">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'
                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className='px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'
                        text="Calendario"
                        to="/Menu/FrmCalendario"
                        classname={'NoDecoration2 tlink'}
                    />
                </Breadcrumb>
            </Col>

            <Container fluid className='px-0 contenedorAgenda'>
                <Calendar
                    localizer={localizer}
                    events={TBAgenda.eventos}
                    selectable
                    startAccessor="start"
                    endAccessor="end"
                    
                    className="custom-calendar shadow" // Aplica la clase CSS
        
                    messages={{

                        next: "Sig",
                        previous: "Ant",
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Día",
                        agenda: "Agenda",
                        date: "Fecha",
                        time: "Hora",
                        event: "Evento",
                        noEventsInRange: "No hay eventos en este rango.",
                    }}
                    onSelectSlot={SeleccionarSlot} // Detecta cuando se selecciona un día o un rango
                    onSelectEvent={SeleccionarEvento} // Detecta cuando se selecciona un evento
                />
            </Container>

            {/* Modal para agregar evento */}
            <Modal className='mt-4' isOpen={TBAgenda.modalOpen} >
                <ModalHeader className='color-header-form px-3 py-2' toggle={closeModal}>
                    Agregar Evento
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={AgregarEvento}>
                        <Row >
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="eventTitle">Descripción del Evento</Label>
                                    <Input
                                        type="textarea"
                                        id="eventTitle"
                                        placeholder="Ingrese el evento a agendar"
                                        value={TBAgenda.nuevoEvento.title}
                                        onChange={(e) => TBAgenda.setNuevoEvento({ ...TBAgenda.nuevoEvento, title: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="eventStart">Hora inicial</Label>
                                    <Input
                                        type="time"
                                        id="eventStart"
                                        value={TBAgenda.nuevoEvento.start}
                                        onChange={(e) => TBAgenda.setNuevoEvento({ ...TBAgenda.nuevoEvento, start: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="eventEnd">Hora Final</Label>
                                    <Input
                                        type="time"
                                        id="eventEnd"
                                        value={TBAgenda.nuevoEvento.end}
                                        onChange={(e) => TBAgenda.setNuevoEvento({ ...TBAgenda.nuevoEvento, end: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col className='d-flex justify-content-between mt-3'>
                            <Button className=' p-1     btnCancelar px-3' onClick={closeModal}>
                                Cancelar
                            </Button>
                            <Button type='submit' className='btnAceptar p-1 px-3'  >
                                Agregar
                            </Button>
                        </Col>
                    </Form>
                </ModalBody>
            </Modal>

            {/* Modal para mostrar evento seleccionado */}
            {
                TBAgenda.seleccionarEvento && (
                    <Modal className='mt-4' isOpen={TBAgenda.eventModalOpen} >
                        <ModalHeader className='color-header-form px-3' toggle={closeEventModal}>
                            <h5 className='py-1 m-1' >  Agenda Personal  </h5>
                        </ModalHeader>
                        <ModalBody>
                            <p><strong> {TBAgenda.seleccionarEvento.title}</strong></p>
                            <p><strong>Inicio:</strong> {moment(TBAgenda.seleccionarEvento.start).format('DD MMM YYYY, h:mm a')}</p>
                            <p><strong>Fin:</strong> {moment(TBAgenda.seleccionarEvento.end).format('DD MMM YYYY, h:mm a')}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className=' p-1     btnCancelar px-3' onClick={(e) => EliminarEvento(TBAgenda.seleccionarEvento.ID)}>
                                Eliminar
                            </Button>
                            {(() => {
                                const fechaActual = new Date();
                                const fechaInicioEvento = new Date(TBAgenda.seleccionarEvento.start);

                                // Normalizamos las fechas para ignorar la hora
                                fechaActual.setHours(0, 0, 0, 0);
                                fechaInicioEvento.setHours(0, 0, 0, 0);

                                // Solo mostramos el botón si la fecha de inicio del evento es >= a la fecha actual
                                if (fechaInicioEvento >= fechaActual) {
                                    return (
                                        <Button className='btnAceptar p-1 px-3' onClick={(e) => MostarModalActualizar(TBAgenda.seleccionarEvento.ID)}>
                                            Modificar
                                        </Button>
                                    );
                                }
                                return null;
                            })()}
                        </ModalFooter>
                    </Modal>
                )
            }
            {
                TBAgenda.modalActualizarOpen && (
                    <Modal isOpen={TBAgenda.modalActualizarOpen} >
                        <ModalHeader className='color-header-form px-3 py-2' toggle={closeModalActualizar}>
                            Modificar Evento
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={ActualizarEvento}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="eventTitle">Descripción del Evento</Label>
                                            <Input
                                                type="textarea"
                                                id="eventTitle"
                                                placeholder="Ingrese el evento a agendar"
                                                value={TBAgenda.nuevoEvento.title} // Aquí se muestra la descripción actual
                                                onChange={(e) => TBAgenda.setNuevoEvento({ ...TBAgenda.nuevoEvento, title: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="eventStart">Hora inicial</Label>
                                            <Input
                                                type="time"
                                                id="eventStart"
                                                value={new Date(TBAgenda.nuevoEvento.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                onChange={(e) => {
                                                    const updatedStart = new Date(TBAgenda.nuevoEvento.start);
                                                    const [hours, minutes] = e.target.value.split(':');
                                                    updatedStart.setHours(parseInt(hours, 10));
                                                    updatedStart.setMinutes(parseInt(minutes, 10));
                                                    TBAgenda.setNuevoEvento({ ...TBAgenda.nuevoEvento, start: updatedStart.toISOString() });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="eventEnd">Hora Final</Label>
                                            <Input
                                                type="time"
                                                id="eventEnd"
                                                value={new Date(TBAgenda.nuevoEvento.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                onChange={(e) => {
                                                    const updatedEnd = new Date(TBAgenda.nuevoEvento.end);
                                                    const [hours, minutes] = e.target.value.split(':');
                                                    updatedEnd.setHours(parseInt(hours, 10));
                                                    updatedEnd.setMinutes(parseInt(minutes, 10));
                                                    TBAgenda.setNuevoEvento({ ...TBAgenda.nuevoEvento, end: updatedEnd.toISOString() });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Col className='d-flex justify-content-between mt-3'>
                                    <Button className='p-1 btnCancelar px-3' onClick={closeModalActualizar}>
                                        Cancelar
                                    </Button>
                                    <Button type='submit' className='btnAceptar p-1 px-3'>
                                        Modificar
                                    </Button>
                                </Col>
                            </Form>
                        </ModalBody>
                    </Modal>
                )
            }

        </Container >
    );
};

export default FrmCalendario;
