import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardFooter, Label, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader } from 'reactstrap';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { RestoreSeccion, getSecciones } from '../../../../CapaDatos/DatosSecciones';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { GetGrados } from '../../../../CapaDatos/DatosCargarSelects';
import { ErrorMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';

const FrmSeccionesInactivas = () => {

    const [loading, setLoading] = useState(true);
    const [Secciones, setSecciones] = useState([]);
    const [open, setOpen] = useState(false);
    const [ListaGrados, setListaGrados] = useState([]);
    const [openA, setOpenA] = useState('1');

    const {
        IdSeccion, setIDSeccion,
        NombreSeccion, setNombreSeccion,
        Nivel, setNivel,
        CantMax, setCantMax,
        IdInstitucion, setIdInstitucion,
        EstadoSeccion, setEstadoSeccion,
        CantidadValida, setCantidadValida,
        SeccionValida, setSeccionValida
    } = TbSecciones();

    const fetchData = async () => {
        try {

            const Estado = 0;

            const data = await getSecciones(Estado);

            setSecciones(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        GetGrados(setListaGrados)
        fetchData();

    }, []);

    if (loading) {
        return (
            <Cargando />
        );
    }

    if (Secciones.estado === 0) {
        fetchData();
    }

    if (!Secciones?.length) {

      
    }





    const Eliminar = async (param) => {

        const data = {

            idSeccion: param,
            estado: 1
        }

        Swal.fire({
            title: "Estas seguro que deseas restaurar esta Seccion?",
             
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, Restaurar!",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await RestoreSeccion(data);

                if (success) {
                    Swal.fire({
                        title: "¡Restaurada!",
                        text: "La Sección a sido Restaurada.",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false,
                        allowOutsideClick: false,
                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });
    };




    const toggleA = (id) => {
        setOpenA(openA === id ? null : id); // Abre o cierra el acordeón según el id
    };


    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Secciones"
                        to="/Menu/FrmSecciones"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Secciones Inactivas"
                        to="/Menu/FrmSeccionesInactivas"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>



            <Container fluid className='p-0 Mymt-5'>

            {Secciones !== null && Secciones.length > 0 ? (

                     <Row>
                     {/* Agrupar las secciones por el primer carácter del nombre del nivel */}
                     {Object.entries(
                         Secciones.reduce((acc, Secc) => {
                             let firstChar = Secc.nombreSeccion.substring(0, 2).replace('-', '').replace('a', '').replace('r', '');
                             console.log(firstChar);
                             if (firstChar === 'M') {

                                 firstChar = -1; // Si es 'M', asignar '-1'

                             } else if (firstChar === 'T') {
                                 firstChar = 0; // Si es 'T', asignar '0'
                             }
                             if (!acc[firstChar]) {
                                 acc[firstChar] = [];

                             }

                             acc[firstChar].push(Secc);
                             //console.log(acc);
                             return acc;
                         }, {})
                     ).sort((a, b) => a[0] - b[0]).map(([firstChar, Secciones]) => (
                         <Col key={firstChar} className='col-12'>
                             <Accordion className='mt-1' open={openA} toggle={() => toggleA(firstChar)}> {/* Usa firstChar como id */}
                                 <AccordionItem className=' mb-3 rounded-0'>
                                     <AccordionHeader className='shadow2 rounded-0 accordion-button2' targetId={firstChar}>Listado de secciones inactivas de {Secciones[0].NombreNivel}</AccordionHeader> {/* Accede a NombreNivel dentro de Secciones[0] */}
                                     <AccordionBody className='shadow2 rounded-0' accordionId={firstChar}>
                                         <Row>
                                             {Secciones.map((Secc, index) => (

                                                 <Col className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 pb-1'>
                                                     <Card key={index} className="mb-3 shadow2 rounded-3">
                                                         <CardBody className="d-flex flex-column py-4">
                                                             <Col>
                                                                 <Row>
                                                                     <Col md={12}>
                                                                         <strong>Seccion: </strong> <text>{Secc.nombreSeccion}</text> <br />
                                                                     </Col>
                                                                     <Col md={12}>
                                                                         <strong>Nivel: </strong>{Secc.NombreNivel}
                                                                     </Col>
                                                                     <Col md={12}>
                                                                         <strong>Cant. Alumnos Maxima: </strong> {Secc.cantMax}<br />
                                                                     </Col>
                                                                 </Row>
                                                             </Col>
                                                         </CardBody>
                                                         <CardFooter className='d-flex justify-content-end'>

                                                             <Button color=' ' type='button' className='mx-1 btn-eliminar' onClick={(e) => Eliminar(Secc.idSeccion)}>
                                                                 Restaurar
                                                             </Button>
                                                         </CardFooter>
                                                     </Card>
                                                 </Col>

                                             ))}
                                         </Row>
                                     </AccordionBody>
                                 </AccordionItem>
                             </Accordion>
                         </Col>
                     ))}
                 </Row>
                ) : (
                    <Col>
                    <p>No hay Secciones registradas inactivas.</p>
                </Col>
                )}
                <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-4' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className='btnAceptar'
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"
                            to="/Menu"
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>



        </Container>
    )
}

export default FrmSeccionesInactivas
